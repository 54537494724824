import {
  AiOutlinePhone,
  AiOutlineWhatsApp,
  AiOutlineMail,
  AiOutlineCloud,
  AiOutlineLink,
  AiOutlineEnvironment,
} from "react-icons/ai"

// import Trans from "components/i18next"
import Trans from "components/Trans"

export const companyDetailsAssets = (selectedJob) => [
  {
    icon: <AiOutlinePhone />,
    label: <Trans langKey={"PHONE_NO"} />,
    cssClass: "others",
    value: selectedJob.contact_number,
    action: () => {
      selectedJob.contact_number &&
        window.open(`tel:+${selectedJob.contact_number}`, "_blank")
    },
  },
  {
    icon: <AiOutlineWhatsApp />,
    label: "WhatsApp",
    cssClass: "others",
    value: `wa.me/${selectedJob.company_whatsapp}`,
    action: () => {
      selectedJob.company_whatsapp &&
        window.open(
          `//api.whatsapp.com/send?phone=${
            selectedJob.company_whatsapp
          }&text=${"Lorem 50"}`,
          "_blank"
        )
    },
  },
  {
    icon: <AiOutlineMail />,
    label: <Trans langKey={"EMAIL"} />,
    cssClass: "others",
    value: selectedJob.contact_email,
    action: () => {
      selectedJob.contact_email &&
        window.open(`mailto:${selectedJob.contact_email}`, "_blank")
    },
  },
  {
    icon: <AiOutlineCloud />,
    label: <Trans langKey={"COMPANY_SITE"} />,
    cssClass: "others",
    value: selectedJob.company.website_url,
    action: () => {
      selectedJob.company.website_url &&
        window.open(`https://${selectedJob.company.website_url}`, "_blank")
    },
  },
  {
    icon: <AiOutlineLink />,
    label: <Trans langKey={"SOCIAL_MEDIA"} />,
    cssClass: "social",
    value: selectedJob.company.social_media,
    action: () => {
      selectedJob.company.social_media &&
        window.open(`https://${selectedJob.company.social_media}`, "_blank")
    },
  },
  {
    icon: <AiOutlineEnvironment />,
    label: <Trans langKey={"COMPANY_ADDRESS"} />,
    cssClass: "address",
    value: `${selectedJob.company_address}, ${selectedJob.postcode}, ${selectedJob.state}, ${selectedJob.country}`, 
    action: () => {
      selectedJob.contact_instagram &&
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${selectedJob.contact_instagram}`,
          "_blank"
        )
    },
  },
]
