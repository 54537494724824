import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CustomModal from "components/Modal";
import Pagination from "components/Pagination";
import CustomTable from "components/Table";
import TemplateContainerMain from "components/Template";
import PageTitle from "components/Title/PageTitle";
import moment from "moment";
import { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Card, CardBody, Col, Row } from "reactstrap";
import { compose } from "redux";
import FilterForm from "./Form";
import ReportAPI from "./action.js";

class SalesReport extends Component {
	componentDidMount = () => {};

	componentDidUpdate = (pp) => {
		const { reportPages, getReport } = this.props;

		if (pp.reportPages !== reportPages) {
			// get api
		}
	};

	renderReportTable = () => {
		const { reportList, onLoad } = this.props;

		return (
			<CustomTable
				data={reportList}
				showPagination={false}
				loading={onLoad}
				columnsContent={[
					{
						Header: "Invoice No.",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Advertiser Name",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Billing Name",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Order Description",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Plan Price",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Selling Price",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Tax",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Price After Tax",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Payment Method",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Agent ID",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Agent Name",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Agent Lead ID",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Agent Lead Name",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Promo Code",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Print Ads Reference",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
					{
						Header: "Payment Ref No.",
						accessorKey: "",
						centerColumn: true,
						isStatusButton: false,
						Cell: () => {},
					},
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getReport, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="SC Sales Report Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getReport}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	render = () => {
		const { onChangeHOC, reportPages, reportCount } = this.props;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="SC Sales Report"
										buttons={[
											// {
											// 	color: "",
											// 	className: "btn-outline-primary mr-2",
											// 	onClick: () => {},
											// 	content: (
											// 		<>
											// 			<span>Export</span>
											// 		</>
											// 	),
											// },
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderReportTable()}
														<Pagination
															pageIndex={reportPages}
															totalCount={reportCount}
															onChangePage={(val) =>
																onChangeHOC("reportPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.props.onLoad && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ReportAPI)(SalesReport);
