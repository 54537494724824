import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CustomModal from "components/Modal";
import Pagination from "components/Pagination";
import CustomTable from "components/Table";
import TemplateContainerMain from "components/Template";
import PageTitle from "components/Title/PageTitle";
import moment from "moment";
import _ from "lodash";
import { RolesPermission } from "Config";
import { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Card, CardBody, Col, Row } from "reactstrap";
import { compose } from "redux";
import FilterForm from "./Form";
import ReportAPI from "./action.js";
import { CheckUserRolesPermissons } from "actions/roles";

class MonthlySalesReport extends Component {
	state = {
		filterQuery: "",
	};

	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(13)) {
			return this.props.history.push("/");
		}
		this.props.getReport();
	};

	componentDidUpdate = (pp) => {
		const { reportPages, getReport } = this.props;
		const { filterQuery } = this.state;
		if (pp.reportPages !== reportPages) {
			getReport(`${!!filterQuery ? filterQuery : ""}`);
		}
	};

	renderReportTable = () => {
		const { reportList, onLoad } = this.props;

		return (
			<CustomTable
				data={reportList}
				showPagination={false}
				loading={onLoad}
				columnsContent={[
					{
						Header: "Transaction ID",
						accessorKey: "transaction_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.transaction_id}</>;
						},
					},
					{
						Header: "Payment Ref No.",
						accessorKey: "payment_reference_number",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.payment_reference_number}</>;
						},
					},
					{
						Header: "Post ID",
						accessorKey: "post_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.post_id}</>;
						},
					},
					{
						Header: "Transaction Invoice Date",
						accessorKey: "transaction_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return (
								<>{moment(val.transaction_date).format("DD/MM/YYYY hh:mm A")}</>
							);
						},
					},
					{
						Header: "Advertiser ID",
						accessorKey: "advertiser_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.advertiser_id}</>;
						},
					},
					{
						Header: "Advertiser Name",
						accessorKey: "advertiser_name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.advertiser_name}</>;
						},
					},
					{
						Header: "Post Title",
						accessorKey: "post_title",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.post_title.en}</>;
						},
					},
					// {
					// 	Header: "Specialization",
					// 	accessorKey: "specialization",
					// 	centerColumn: true,
					// 	isStatusButton: false,
					// 	Cell: (val) => {return <>{val.specialization?.name?.en}</>},
					// },
					{
						Header: "Specialization",
						accessorKey: "multi_specializations",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return (
								<>
									{val.multi_specializations
										?.map((specialization) => specialization.name?.en)
										.join(", ")}
								</>
							);
						},
					},
					{
						Header: "Plans",
						accessorKey: "plans",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return (
								<>
									{val.plans?.map((plan, index) => (
										<div key={`${plan.name.en}_${index}`}>
											{`${plan.name.en} - ${plan.price}`}
										</div>
									))}
								</>
							);
						},
					},
					{
						Header: "Agent ID",
						accessorKey: "agent_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.agent_id}</>;
						},
					},
					{
						Header: "Agent Name",
						accessorKey: "agent_name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.agent_name}</>;
						},
					},
					{
						Header: "Order Description",
						accessorKey: "order_description",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.order_description}</>;
						},
					},
					{
						Header: "Order Price",
						accessorKey: "order_price",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.order_price}</>;
						},
					},
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getReport, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Monthly Sales Report Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getReport}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	render = () => {
		const { onChangeHOC, reportPages, reportCount } = this.props;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Monthly Sales Report"
										buttons={[
											// {
											// 	color: "",
											// 	className: "btn-outline-primary mr-2",
											// 	onClick: () => {},
											// 	content: (
											// 		<>
											// 			<span>Export</span>
											// 		</>
											// 	),
											// },
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderReportTable()}
														<Pagination
															pageIndex={reportPages}
															totalCount={reportCount}
															onChangePage={(val) =>
																onChangeHOC("reportPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.props.onLoad && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ReportAPI)(MonthlySalesReport);
