import { RolesPermission } from "Config";

export const CheckUserRolesPermissons = (roles) => {
    let selectedRole = null;
    let chosenRoles = [];

    roles?.map((role) => {
        chosenRoles.push(RolesPermission.find(r => r.id === role.id));
    });

    selectedRole = roles?.find(r => r.id === chosenRoles.find(role => role.permissions.length).id);

    return {
        ...selectedRole,
        id: selectedRole?.id
    };
}