import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { toast } from "react-toastify";

import configureStore from "./store/config";
import ECRouter from "./router";
import AxiosInterceptors from "utils/axios-interceptors";
// import 'utils/i18'
import {IntlProvider} from 'use-intl';
import "./stylesheets/base.scss";
import enTranlsationData from './messages/en.json'

const store = configureStore();
const persistor = persistStore(store);

toast.configure();

const App = () => {
	return (
		<IntlProvider messages={enTranlsationData} locale="en">
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<HashRouter>
						<ECRouter />
					</HashRouter>
				</PersistGate>
			</Provider>
		</IntlProvider>
	);
};

export default App;
