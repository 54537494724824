import Display from "./display";
import Form from "./form";
import "../../index.scss";
function Edu(props) {
	return (
		<>{props.mode === "edit" ? <Form {...props} /> : <Display {...props} />}</>
	);
}

export default Edu;
