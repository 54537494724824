import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			bannerInhouseList: [],
			bannerInhousePages: 1,
			bannerInhouseCount: 0,
			showFilterModal: false,
			showEditModal: false,
			previewData: {},
			showPublishModal: false,
			showSuspendModal: false,
			showDisableModal: false,
			selectedBannerId: "",
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getBanner = (querystring = "") => {
			Get(
				"/popup-banners?limit=10&type=inhouse&location=Jobmacha" + querystring,
				this.getBannerSuccess,
				this.getBannerError,
				this.load
			);
		}
		getBannerSuccess = (payload) => {
			this.setState({
				bannerInhouseList: payload.data,
				bannerInhouseCount: payload.count,
			});
		};
		getBannerError = (error) => requestError(error);

		getBannerById = (querystring = "") =>
			Get(
				"/popup-banners?limit=10&type=inhouse" + querystring,
				this.getBannerByIdSuccess,
				this.getBannerByIdError,
				this.load
			);
		getBannerByIdSuccess = (payload) => {
			this.setState({
				previewData: payload.data[0],
			});
		};
		getBannerByIdError = (error) => requestError(error);

		updateBanner = (dataToSubmit) => {
			Put(
				`/popup-banners/${dataToSubmit.id}`,
				dataToSubmit,
				this.updateBannerSuccess,
				this.updateBannerError,
				this.load
			);
		};
		updateBannerSuccess = () => {
			this.getBanner();
			this.setState({
				// close modal states
				showSuspendModal: false,
				showDisableModal: false,
			});
			requestSuccess("Banner updated successfully.");
		};
		updateBannerError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					bannerInhouseList={this.state.bannerInhouseList}
					bannerInhousePages={this.state.bannerInhousePages}
					bannerInhouseCount={this.state.bannerInhouseCount}
					onLoadBanner={this.state.loading}
					getBanner={this.getBanner}
					getBannerById={this.getBannerById}
					updateBanner={this.updateBanner}
					onChangeHOC={this.onChangeHOC}
					showFilterModal={this.state.showFilterModal}
					showEditModal={this.state.showEditModal}
					showPublishModal={this.state.showPublishModal}
					showSuspendModal={this.state.showSuspendModal}
					showDisableModal={this.state.showDisableModal}
					selectedBannerId={this.state.selectedBannerId}
					previewData={this.state.previewData}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
