import React, { Component } from "react";
import { connect } from "react-redux";

import Icon from "assets/images/icon.png";

import {
	setEnableClosedSidebar,
	setEnableMobileMenu,
	setEnableMobileMenuSmall,
} from "reducers/ThemeOptions";

class Logo extends Component {
	state = {
		active: false,
		mobile: false,
		activeSecondaryMenuMobile: false,
	};

	render = () => {
		return (
			<div className="app-header__logo">
				<img
					src={Icon}
					alt="mywheels-logo"
					style={{ height: 35, width: "auto", objectFit: "cover" }}
				/>
			</div>
		);
	};
}

const mapStateToProps = (state) => ({
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

export default connect(mapStateToProps, {
	setEnableClosedSidebar,
	setEnableMobileMenu,
	setEnableMobileMenuSmall,
})(Logo);
