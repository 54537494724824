import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
  const [bannerId, setBannerId] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [status, setStatus] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [publishedDate, setPublishedDate] = useState("");

  const handleClear = () => {
    setBannerId("");
    setBannerTitle("");
    setRedirectLink("");
    setStatus("");
    setCreatedDate("");
    setPublishedDate("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (bannerId) {
      queryString += `&id=${bannerId}`;
    }
    if (bannerTitle) {
      queryString += `&title=${bannerTitle}`;
    }
    if (redirectLink) {
      queryString += `&link_url=${redirectLink}`;
    }
    if (status) {
      queryString += `&status=${status}`;
    }
    if (createdDate) {
      queryString += `&created_at=${createdDate}`;
    }
    if (publishedDate) {
      queryString += `&start_date=${publishedDate}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-banner-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="popupId">Banner ID</Label>
                <Input
                  placeholder="Enter Message ID"
                  name="bannerId"
                  id="bannerId"
                  value={bannerId}
                  onChange={(e) => setBannerId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="bannerTitle">Banner Title</Label>
                <Input
                  placeholder="Enter Banner Title"
                  name="bannerTitle"
                  id="bannerTitle"
                  value={bannerTitle}
                  onChange={(e) => setBannerTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="redirectLink">Redirect Link</Label>
                <Input
                  placeholder="Enter Redirect Link"
                  name="redirectLink"
                  id="redirectLink"
                  value={redirectLink}
                  onChange={(e) => setRedirectLink(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option disabled value="">
                    Select Status
                  </option>
                  <option>scheduled</option>
                  <option>active</option>
                  <option>failed</option>
                  <option>expired</option>
                  <option>disabled</option>
                </Input>
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="createdDate">Created Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="createdDate"
                  id="createdDate"
                  value={createdDate}
                  onChange={(e) => setCreatedDate(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="publishedDate">Published Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="publishedDate"
                  id="publishedDate"
                  value={publishedDate}
                  onChange={(e) => setPublishedDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Apply",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("bannerInhousePages", 1);
            },
          },
        ]}
      />
    </>
  );
}
