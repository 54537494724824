
import { Component } from "react";

import { Delete, Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			jobSpecs: [],
			hasSentCode: false,
			hasVerified: false,
		};

		onChangeSettingHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getSpecializations = () => {
			Get(
				`/jobads/specializations`,
				this.getSpecializationsSuccess,
				this.getSpecializationsError,
				this.load
			);
		};
		getSpecializationsSuccess = (payload) => {
			if (payload?.data.length > 0) {
				this.setState({ jobSpecs: payload.data });
			}
		};
		getSpecializationsError = (error) => requestError(error);

		postJobSeekerSpec = (specID, userID) =>
			Post(
				`/jobseeker-profile-specializations`,
				{
					jobseeker_profile_id: this.props.jobSeekerProfile.id,
					specialization_id: specID,
				},
				() => {
					this.props.getSelectedJobSeekerProfile(userID)
				},
				(error) => requestError(error.message),
				this.load,
				true
			);
		deleteJobSeekerSpec = (id, userID) =>
			Delete(
				`/jobseeker-profile-specializations/${id}`,
				() => {
					this.props.getSelectedJobSeekerProfile(userID)
				},
				(error) => requestError(error.message),
				this.load,
				true
			);

		updatePassword = (dataToSubmit) => {
			Put(
				`/change-password`,
				dataToSubmit,
				() => requestSuccess("Success!"),
				(error) => requestError(error.message),
				this.load,
				true
			);
		};
		updatePasswordSuccess = () => requestSuccess("Reset Password Successful!");
		updatePasswordError = (err) => requestError(err.message);

		verifyOTP = (email, code) =>
			Get(
				`email/verify-otp?email=${email}&content=${code}`,
				() => this.verifyOTPSuccess(),
				this.verifyOTPError,
				this.load,
				true
			);
		verifyOTPSuccess = () => {
			this.setState({
				hasVerified: true,
			});
		};
		verifyOTPError = (error) => requestError(error);

		sendOTP = (dataToSubmit) =>
			Post(
				`/email/otp`,
				{
					... dataToSubmit,
					platform: 'jobmacha'
				},
				this.sendOTPSuccess,
				this.sendOTPError,
				this.load,
				true
			);
		sendOTPSuccess = () => {
			this.setState({
				hasSentCode: true,
			});
			requestSuccess(
				"验证码已发送至您的电子邮件！Check your email with the verification code"
			);
		};
		sendOTPError = (error) => requestError(error.message);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					verifyOTP={this.verifyOTP}
					updatePassword={this.updatePassword}
					sendOTP={this.sendOTP}
					onChangeSettingHOC={this.onChangeSettingHOC}
					getSpecializations={this.getSpecializations}
					postJobSeekerSpec={this.postJobSeekerSpec}
					deleteJobSeekerSpec={this.deleteJobSeekerSpec}
				/>
			);
		};
	}
	return WithHOC;
};

export default HOC;
