import ShareModal from "components/Modal/share";
import Moment from "moment";
import { useState } from "react";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { Button } from "reactstrap";
import DefaultImg from 'assets/images/redsquare.png'

import './index.scss';
function JobCard({ item, context }) {
	// Context
	const [showShare, setShowShare] = useState(false);

	// General States
	return (
		<div className={`e-classified-job__card ${item.is_premium && "premium"}`}>
			{item.is_premium && (
				<div className="e-classifed-job__label-card bg-primary">
					<p className="e-classifed-job__label text-white">PREMIUM</p>
				</div>
			)}
			<a
				href={`/jobs${item.slug}`}
				className="e-classified-job__top-container"
			>
				<div className="e-classified-job__image-container">
					<img
						src={item.company.logo_url || DefaultImg }
						alt={`job_${item.id}_logo`}
					/>
				</div>
				<div className="e-classified-job__desc-container">
					<div className="e-classified-job__title-container">
						<p className="e-classified-job__title text-black">
							{item?.job_title?.zh || "N/A"}
						</p>
						<p className="e-classified-job__title text-black">
							{item?.job_title?.en || "N/A"}
						</p>
						<p className="e-classified-job__company">
							{item.company.name || "N/A"}
						</p>
					</div>
				</div>
			</a>
			{context !== "applied" && (
				<div className="e-classified-job__tag-container">
					<div className="e-classified-job__tag bg-primary">
						<p className="e-classified-job__tag-text text-white">
							{item.employment_type === "full_time" && (
								'Full Time'
							)}
							{item.employment_type === "part_time" && (
								'Part Time'
							)}
							{item.employment_type === "internship" && (
								'Internship'
							)}
						</p>
					</div>
					{item.education_level && (
						<div className="e-classified-job__tag bg-primary">
							<p className="e-classified-job__tag-text text-white">
								{item.education_level?.name?.en || "N/A"}
							</p>
						</div>
					)}
					{item.total_experience && (
						<div className="e-classified-job__tag bg-primary">
							<p className="e-classified-job__tag-text text-white">
								{`${item.total_experience} `}
								Year ExperienceYear Experience
							</p>
						</div>
					)}
				</div>
			)}
			<div className="e-classified-job__bottom-container">
				<div className="e-classified-job__salary-container">
					<p className="e-classified-job__salary text-primary">
						{`${item.currency || "RM"} ${item.min_salary || "N/A"} - ${
							item.max_salary || "N/A"
						}`}
					</p>
					<p className="e-classified-job__location">
						{item.state ?? "N/A"}, {item.country ?? "N/A"}
					</p>
				</div>
				<div className="e-classified-job__button-container">
					{context === "applied" && (
						<span style={{ fontSize: "0.875rem", color: "#bcbcbc" }}>
							{Moment(item.appplied_date).format("DD/MM/YYYY")}
							{` applied`}
						</span>
					)}
					{context !== "applied" && (
						<>
							<Button
								color={"transparent"}
								className="e-classified-job__share-button"
								onClick={() => setShowShare(true)}>
								<AiOutlineShareAlt fontSize={20} color={"#000"} />
							</Button>
							<Button
								color={"transparent"}
								className="e-classified-job__share-button"
								onClick={() => {}}>
								{!item.is_applied ? (
									<BsBookmark fontSize={20} color={"#000"} />
								) : (
									<BsBookmarkFill fontSize={20} color={"#000"} />
								)}
							</Button>
						</>
					)}
				</div>
			</div>
			{item.is_premium && (
				<div className="e-classified-job__premium-strip bg-primary" />
			)}

			{
				console.log( showShare )
			}
			<ShareModal
				title={'Share'}
				open={showShare}
				shareLink={ `/` }
				onCancel={() => setShowShare(false)}
			/>
		</div>
	);
}

export default JobCard;
