import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import CreateBannerAPI from "./actions/index.js";
import ImageHOC from './actions/image.js'
import { CreateBannerForm } from "./Form.js";

import LoadingIndicator from "components/Indicator/LoadingOverlay.js";

const CreateBannerPageWrapper = (props) => {
	const { id } = useParams();

	return <CreateBannerPage id={!!id ? id : null} {...props} />;
};
class CreateBannerPage extends Component {
	render = () => {
		const { createNewBanner, id, getBanner, updateBanner } = this.props;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<PageTitle heading="Manage Banner/ <b>Manage In-House Banner</b>"></PageTitle>
								<h5>{!!id ? "Edit" : "Create New"} In-House Banner</h5>{" "}
								<br></br>
								<CreateBannerForm
									onSubmit={( val, deleteAssetsList) => {
										!id ? createNewBanner( val ) : updateBanner( val, deleteAssetsList )
									}}
									id={id}
									getBanner={getBanner}
								/>
							</div>
						</div>
					</div>
					<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
				</TemplateContainerMain>
				{(this.props.onLoad || this.props.onLoadImage ) && <LoadingIndicator />}
			</>
		);
	};
}

export default compose(ImageHOC, CreateBannerAPI)(CreateBannerPageWrapper);
