import { Switch } from "antd";
import { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";

function ViewOthers({ setIsEditing, candidateForm, onChangeForm, ...props }) {
	const [ownTransport, setOwnTransport] = useState(false);
	const [aboutMe, setAboutMe] = useState("");

	useEffect(() => {
		setOwnTransport(props.jobSeekerProfile.own_transportation);
		setAboutMe(props.jobSeekerProfile.about_me);
	}, [props.jobSeekerProfile]);

	return (
		<>
			<div className="d-flex">
				<h5 className="mb-3 e-classified-cont-header">Other Info</h5>
			</div>
			<div className="e-classified-profile-view-card">
				<p className="e-classified-cont-edit-label mb-3">Own Transport</p>
				<div className="d-flex align-items-cente mb-4">
					<p
						className="text-black"
						style={{ fontSize: "0.875rem", fontWeight: 500 }}>
						{candidateForm.own_transportation ? "Yes" : "No"}
					</p>
					<Switch
						className="ml-auto"
						checked={candidateForm.own_transportation}
						onChange={(val) => onChangeForm("own_transportation", val)}
					/>
				</div>
				<div className="d-flex align-items-center">
					<p className="e-classified-cont-edit-label mb-2">Special Remark</p>
					<div className="ml-auto">
						<span className="text-secondary" style={{ fontSize: "0.875rem" }}>
							{`${aboutMe.length}/500`}
							Characters
						</span>
					</div>
				</div>
				<Input
					value={candidateForm.about_me}
					type={"textarea"}
					onChange={(e) => onChangeForm("about_me", e.target.value)}
				/>
				<p className="text-black" style={{ fontSize: "0.875rem" }}></p>
			</div>
			<div className="d-flex mt-3">
				<Button
					color="primary"
					size={"lg"}
					className={"ml-auto"}
					disabled={aboutMe.length > 500}
					onClick={() =>
						props.updateJobSeekerProfile({
							...candidateForm,
						})
					}>
					Store
				</Button>
			</div>
		</>
	);
}

export default ViewOthers;
