import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Put, Post } from "utils/axios";
import { requestError, requestSuccess } from "../../../utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			postList: [],
			postPages: 1,
			postCount: 0,
			jobQuery: "",
			showFilterModal: false,
			showConfirmModal: false,
			showRecommendModal: false,
			selectedCandidate: null,
			checkedJob: [],
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getPost = (seekerId, specs, query) => {
			const route = `/job-ads?&type=job&limit=10&offset=${
				(this.state.postPages - 1) * 10
			}&specialization_ids=[
				${specs}
			]&jobseeker_profile_id=${seekerId}${query || ""}`;

			Get(route, this.getPostSuccess, this.getPostError, this.load);
		};
		getPostSuccess = (payload) => {
			this.setState({
				postList: payload.data,
				postCount: payload.count,
				checkedJob: [],
			});
		};
		getPostError = (error) => requestError(error);

		getSelectedCandidate = (id) =>
			Get(
				`/jobseeker-profiles/${id}`,
				(payload) => this.setState({ selectedCandidate: payload?.data?.[0] }),
				requestError,
				this.load
			);

		recommendCandidate = (dataToSubmit) => {
			Post(
				`/shortlist-candidates-for-jobad`,
				dataToSubmit,
				this.recommendCandidateSuccess,
				this.recommendCandidateError,
				this.load
			);
		};
		recommendCandidateSuccess = (payload) => {
			requestSuccess(payload.message);
			let tempSpecs = _.map(
				this.state.selectedCandidate.specializations,
				"specialization_id"
			);

			this.getPost(this.state.selectedCandidate.id, tempSpecs);
			this.setState({
				showRecommendModal: false,
				checkedJob: [],
			});
		};
		recommendCandidateError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onLoadSample={this.state.loading}
					onChangeHOC={this.onChangeHOC}
					getPost={this.getPost}
					getSelectedCandidate={this.getSelectedCandidate}
					recommendCandidate={this.recommendCandidate}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
