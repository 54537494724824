"use client"

import React, { useEffect } from "react"
import { Button, ButtonGroup } from "reactstrap";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const CartButton = ({
  value,
  disabledMinus,
  disabledPlus,
  
  onChangeData
}) => {

  const [quantity, setQuantity] = React.useState(value)

  useEffect(() => {
    setQuantity(value)
  }, [value])

  const onChangeQuantity = (value) => {
    setQuantity(value)
    onChangeData(value)
  }

  return(
    <div className="custom-cart-button">
      <Button
        color="light"
        disabled={disabledMinus}
        onClick={() => onChangeQuantity(value - 1)}>
        <AiOutlineMinus />
      </Button>
      <span>{ quantity }</span>
      <Button
        color="light"
        disabled={disabledPlus}
        onClick={() => onChangeQuantity(value + 1)}>
        <AiOutlinePlus />
      </Button>
    </div>
  )
}

export default CartButton;