import React from "react"
import { Button, FormGroup } from "reactstrap"
import { Modal, Row, Col, Avatar, Card, Divider } from "antd"
import {
  EditOutlined,
  CloseCircleOutlined,
  UserOutlined,
  ShareAltOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  MailOutlined,
  CloudOutlined,
  LinkOutlined,
  EnvironmentOutlined,
  DownloadOutlined
} from "@ant-design/icons"
import { FaRegBookmark } from "react-icons/fa"
import _ from 'lodash'
// import { useLocale } from "next-intl"

// import Trans from "components/i18next"
import Trans from "components/Trans"
import CustomCarousel from "components/ImageSlider"
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import './index.scss'
import { useHistory } from 'react-router-dom'

const PreviewModal = (props) => {
  const history = useHistory();
  // const locale = useLocale()
  const locale = 'en'
  let { selectedCompany = {}, showPreviewModal, onChangeCompaniesHOC } = props

  return (
    <Modal
      width="75rem"
      open={showPreviewModal}
      footer={null}
      closeIcon={null}
      className="preview-modal"
    >
      <div className="top-bg" />
      <div className="preview-header d-flex justify-content-end">
        <Button color="primary" className="mr-2 fs-6 fw-normal" onClick={() => props.getCompanyImage(selectedCompany.id)}>
          <DownloadOutlined style={{ margin: "0.25rem" }}/>
          <Trans context={"COMPANY"} langKey={"DOWNLOAD_AS_IMAGE"} />
        </Button>
        <Button
          outline
          color="primary"
          className="mr-2 fs-6"
          onClick={() => {
            let url = "advertiser-profile-individual";

            if (selectedCompany.type === 'company') {
                url = "advertiser-profile-company";
            } 

            onChangeCompaniesHOC("showPreviewModal", false);
            history.push(`/${url}/update/${selectedCompany.id}`);
          }}
        >
          <EditOutlined />
        </Button>
        <Button
          outline
          color="primary"
          className="mr-2 fs-6"
          onClick={() => onChangeCompaniesHOC("showPreviewModal", false)}
        >
          <CloseCircleOutlined />
        </Button>
      </div>
      <div className="preview-content">
        <h6>
          <Trans context={"COMPANY"} langKey={"USER_VIEW"} />
        </h6>
        <Row gutter={8} align="middle" className="my-4">
          <Col xs={8} md={4}>
            <Avatar
              size={{ xs: 50, sm: 75, md: 75, lg: 100, xl: 125, xxl: 125 }}
              src={selectedCompany.logo_url}
              icon={<UserOutlined />}
            />
          </Col>
          <Col xs={16} md={10}>
            <div className="fw-bold fs-5">{selectedCompany.name}</div>
            <div className="fw-semibold fs-5 text-primary">{selectedCompany.specialization?.name?.[locale]}</div>
          </Col>
          <Col xs={12} md={5}>
            <Button color="primary" className="mr-2 w-100 fs-6">
              <Trans context={"COMPANY"} langKey={"SAVE_COMPANY"} />{" "}
              <FaRegBookmark />
            </Button>
          </Col>
          <Col xs={12} md={5}>
            <Button outline color="primary" className="mr-2 w-100 fs-6">
              <Trans langKey={"SHARE"} />{" "}
              <ShareAltOutlined />
            </Button>
          </Col>
        </Row>
        <Row gutter={[8, 24]}>
          <Col xs={24} md={12}>
            <Card style={{'border-top': '8px solid var(--primary)', 'box-shadow': '0px 4px 6px rgba(147, 147, 147, 0.25)', height: '100%'}}>
              <h6>
                <Trans langKey={"COMPANY_DESC"} />
              </h6>
              <div>{selectedCompany.description || "N/A"}</div>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card style={{'border-top': '8px solid var(--primary)', 'box-shadow': '0px 4px 6px rgba(147, 147, 147, 0.25)', height: '100%'}}>
              <h6>
                <Trans langKey={"COMPANY_DETAILS"} />
              </h6>
              <table>
                <tr>
                  <td>
                    <PhoneOutlined />{" "}
                    <Trans context={"COMPANY"} langKey={"CONTACT_NUMBER"} />:
                  </td>
                  <td className="text-primary">{selectedCompany.company_contact || "N/A"}</td>
                </tr>
                <tr>
                  <td>
                    <WhatsAppOutlined />{" "}
                    <Trans context={"COMPANY"} langKey={"WHATSAPP"} />:
                  </td>
                  <td className="text-primary">{selectedCompany.whatsapp_number || "N/A"}</td>
                </tr>
                <tr>
                  <td>
                    <MailOutlined />{" "}
                    <Trans langKey={"EMAIL"} />:
                  </td>
                  <td className="text-primary">{selectedCompany.email || "N/A"}</td>
                </tr>
                <tr>
                  <td>
                    <CloudOutlined />{" "}
                    <Trans context={"COMPANY"} langKey={"WEBSITE_URL"} />:
                  </td>
                  <td className="text-primary">{selectedCompany.website_url || "N/A"}</td>
                </tr>
                <tr>
                  <td>
                    <LinkOutlined />{" "}
                    <Trans langKey={"SOCIAL_MEDIA"} />:
                  </td>
                  <td className="text-primary">{selectedCompany.social_media || "N/A"}</td>
                </tr>
                <tr>
                  <td>
                    <EnvironmentOutlined />{" "}
                    <Trans langKey={"COMPANY_ADDRESS"} />:
                  </td>
                  <td className="text-primary">:{`${selectedCompany.advertiser_address}, ${selectedCompany.postcode} ${selectedCompany.city}, ${selectedCompany.country}` || "N/A"}</td>
                </tr>
              </table>
            </Card>
          </Col>
        </Row>
        <div className="my-3">
          {!_.isEmpty(selectedCompany.company_galleries) &&
            selectedCompany.company_galleries.length > 0 && (
              <>
                <h6 className="mt-5">
                  <Trans context={"COMPANY"} langKey={"WORKING_ENVIRONMENT"} />
                </h6>
                <CustomCarousel>
                  {selectedCompany.company_galleries.map((image) => (
                    <div className="square-container">
                      <img src={image} className="px-3" />
                    </div>
                  ))}
                </CustomCarousel>
              </>
            )}
        </div>
        <Divider />
        <h6>
          <Trans context={"COMPANY"} langKey={"EDITOR_VIEW"} />
        </h6>
        <Card className="advertiser-profile mb-3" style={{'border': 'none'}}>
          <h6 className="mb-4">
            <Trans context={"ADVERTISER"} langKey={"ADVERTISER_PROFILE"} />
          </h6>
          <FormGroup>
            <div className="label-text">
              <Trans context={"COMPANY"} langKey={"PIC_NAME"} />
            </div>
            <div className="fw-bold">{selectedCompany.person_in_charge_name || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans context={"COMPANY"} langKey={"PIC_CONTACT_NUMBER"} />
            </div>
            <div className="fw-bold">{selectedCompany.person_in_charge_contact || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans context={"COMPANY"} langKey={"PIC_WA_NUMBER"} />
            </div>
            <div className="fw-bold">{selectedCompany.person_in_charge_whatsapp || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans context={"COMPANY"} langKey={"PIC_EMAIL_ADDRESS"} />
            </div>
            <div className="fw-bold">{selectedCompany.person_in_charge_email || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans context={"COMPANY"} langKey={"INDUSTRY"} />
            </div>
            <div className="fw-bold">{selectedCompany.industry?.name?.[locale] || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"ADDRESS"} />
            </div>
            <div className="fw-bold">{selectedCompany.advertiser_address || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"POSTCODE"} />
            </div>
            <div className="fw-bold">{selectedCompany.postcode || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"CITY"} />
            </div>
            <div className="fw-bold">{selectedCompany.city || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"STATE"} />
            </div>
            <div className="fw-bold">{selectedCompany.state || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"COUNTRY"} />
            </div>
            <div className="fw-bold">{selectedCompany.country || "N/A"}</div>
          </FormGroup>
        </Card>
        <Card className="billing-info" style={{'border': 'none'}}>
          <h6 className="mb-4"><Trans context={"COMPANY"} langKey={"BILLING_INFO"} /></h6>
          <FormGroup>
            <div className="label-text">
              <Trans context={"COMPANY"} langKey={"BILLING_NAME"} />
            </div>
            <div className="fw-bold">{selectedCompany.billing_name || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"EMAIL_ADD"} />
            </div>
            <div className="fw-bold">{selectedCompany.billing_email || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"ADDRESS"} />
            </div>
            <div className="fw-bold">{selectedCompany.billing_address || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"POSTCODE"} />
            </div>
            <div className="fw-bold">{selectedCompany.billing_postcode || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"CITY"} />
            </div>
            <div className="fw-bold">{selectedCompany.billing_city || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"STATE"} />
            </div>
            <div className="fw-bold">{selectedCompany.billing_state || "N/A"}</div>
          </FormGroup>
          <FormGroup>
            <div className="label-text">
              <Trans langKey={"COUNTRY"} />
            </div>
            <div className="fw-bold">{selectedCompany.billing_country || "N/A"}</div>
          </FormGroup>
          <div className="label-text fw-semibold">
            <Trans context={"COMPANY"} langKey={"AGENT_NAME"} />:{" "}
            {selectedCompany?.agent?.name || "-"}
          </div>
        </Card>
        {props.onLoad && <LoadingOverlay />}
      </div>
    </Modal>
  )
}
export default PreviewModal
