import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'

import { requestError, requestSuccess } from 'utils/requestHandler'
import { Get, Post, Put } from 'utils/axios'

const PAYMENT_ATTRIBUTES = {
  user_id: null,
  plan_id: null,
  addOns: [],
  voucher: {},
  print_ads_id: '',
  voucherCode: '',
  print_price: 0,
  tax: 0,
  subtotal: 0,
  ec_total: 0,
  discount: 0,
  grand_total: 0,
  voucher_id: null,
  is_bundle_print: false,
  payment_key: '',
  description: '',
  job_ads_id: '',
  company_id: '',
  order_plans: [],
  status: 'pending',
  type: 'jobs'
}

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      postPayment: PAYMENT_ATTRIBUTES,
      showConfirmModal: false,

      plans: [],
      plansRaw: [],
      packages: [],
    }

    onChangeUpgradeHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getPlans = () => Get(
      `/plans`,
      this.getPlansSuccess,
      error => requestError( error ),
      this.load,
    )
    getPlansSuccess = payload => {
      let temp = [{
        title: 'General',
        data: payload.data.filter( item => item.category === 'General' ).map( item => {
          return {
            ...item,
            price_amount: item.price.length > 0 ? item.price[0].amount : 0,
          }
        })
      }, {
        title: 'Featured',
        data: payload.data.filter( item => item.category === 'Featured' ).map( item => {
          return {
            ...item,
            price_amount: item.price.length > 0 ? item.price[0].amount : 0,
          }
        })
      }, {
        title: 'Facebook',
        data: payload.data.filter( item => item.category === 'Facebook' ).map( item => {
          return {
            ...item,
            price_amount: item.price.length > 0 ? item.price[0].amount : 0,
          }
        })
      }]
      this.setState({ plans: temp, plansRaw: payload.data })
    }

    validateVoucher = () => {
      const todayDate = moment().format( 'YYYY-MM-DD' )

      Get(
        `/validate-voucher?code=${this.state.postPayment.voucherCode}&start_date=${todayDate}&end_date=${todayDate}`,
        this.validateVoucherSuccess,
        error => requestError( error ),
        this.load,
        true
      )
    }
    validateVoucherSuccess = payload => {
      if( payload.data.length > 0 ){
        this.setState({ postPayment: {
          ...this.state.postPayment,
          voucher: payload.data[0],
          voucher_id: payload.data[0].id,
        } })
      }
    }

    createOrder = (datatToSubmit, callback) => {
      if( datatToSubmit.id ){
        Put(
          `/orders/${datatToSubmit.id}`,
          datatToSubmit,
          payload => this.createOrderSuccess(payload, callback),
          error => requestError( error ),
          this.load,
          true
        )
      } else {
        Post(
          `/orders`,
          datatToSubmit,
          payload => this.createOrderSuccess(payload, callback),
          error => requestError( error ),
          this.load,
          true
        )
      }
    }
    createOrderSuccess = (payload, callback) => {
      this.setState({ postPayment: {
        id: payload.data.id,
        ...this.state.postPayment,
        // payment_link: payload.data.payment_link,
      } })
      callback(payload)
      requestSuccess( payload.message )
    }

    getPaymentKey = (dataToSubmit) => {
      Post(
        `/payment-gateway/response`,
        dataToSubmit,
        payload => this.getPaymentKeySuccess(payload),
        error => requestError( error ),
        this.load,
        true
      )
    }
    getPaymentKeySuccess = (payload) => {
      this.setState({ payment_key: payload.data}, () => {
        document.getElementById("jobads-payment-gateway")?.submit()
      })
      requestSuccess( payload.message )
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          { ...this.state }
          onLoadSummary={ this.state.loading }
          
          getPlans={ this.getPlans }
          createOrder={ this.createOrder }
          getPaymentKey={ this.getPaymentKey }
          validateVoucher={ this.validateVoucher }
          onChangeUpgradeHOC={ this.onChangeUpgradeHOC }/>
      )
    }
  }
  return WithHOC
}

export default HOC