
import { Component } from "react";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			states: [],
		};

		onChangeSettingHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		updateUser = (userID, dataToSubmit) =>
			Put(
				`/users/${userID}`,
				dataToSubmit,
				() => {},
				(error) => requestError(error.message),
				this.load
			);

		uploadPdf = (dataToSubmit, cb) => {
			Post(
				`/assets-upload-resume`,
				dataToSubmit,
				(payload) => cb(payload.data),
				this.uploadPdfError,
				this.load,
				true
			);
		};
		uploadPdfError = (error) => requestError(error.message);

		deleteAsset = (id) =>
			Delete(
				`/assets/${id}`,
				(payload) => console.log(payload),
				(error) => requestError(error.message),
				this.load,
				true
			);

		getStates = (id) => {
			this.setState({ selectedCountry: id });
			Get(
				`/countries/${id}/states`,
				this.getStatesSuccess,
				(error) => requestError(error),
				this.load
			);
		};
		getStatesSuccess = (response) => {
			if (response?.data.length > 0) {
				this.setState({
					states: response.data.sort((a, b) => a.name.localeCompare(b.name)),
				});
			}
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onLoadProfile={this.state.loading}
					updateUser={this.updateUser}
					getStates={this.getStates}
					deleteAsset={this.deleteAsset}
					uploadPdf={this.uploadPdf}
				/>
			);
		};
	}
	return WithHOC;
};

export default HOC;
