import React, { useEffect } from "react";
import { compose } from "redux";
import { Card, CardBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PaymentGatewayForm from "components/PaymentGatewayForm";
import CreatePostAPI from "./action.js";

const PostCompletePage = (props) => {
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();
  const token = decodeURIComponent(query.get("token"));

  useEffect(() => {
    if (token) {
      document.getElementById("redirect-payment-gateway")?.submit();
    }
  }, [token]);

  return (
    <>
      <TemplateContainerMain>
        <TemplateHeader
          onClickToggleProfileModal={() => {}}
          history={props.history}
          user={props.data.ProfileReducer.profile}
        />
        <div className="app-main">
          <TemplateSidemenu
            userPermissions={props.userPermissions}
            user={props.user}
            userRole={props.userRole}
          />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Card>
                <CardBody>
                  <div className="d-flex flex-column">
                    <span>
                      Please wait while redirecting you to the payment page...
                    </span>
                    <PaymentGatewayForm
                      id="redirect-payment-gateway"
                      value={token}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </TemplateContainerMain>
    </>
  );
};

export default compose(CreatePostAPI)(PostCompletePage);
