import { AiFillLock, AiOutlineFileDone, AiOutlineUser } from "react-icons/ai";
import { BsBookmark } from "react-icons/bs";

export const MenuData = [
	{
		label: "My Profile",
		icon: <AiOutlineUser />,
		children: [
			{
				label: "Profile & Experience",
				route: "profile-exp",
			},
			{
				label: "Skills",
				route: "skill",
			},
			{
				label: "Others",
				route: "other",
			},
			{
				label: "My Resume",
				route: "my-resume",
			},
		],
	},
	{
		label: "Applied Jobs",
		icon: <AiOutlineFileDone />,
		route: "applied-jobs",
	},
	{
		label: "My Saved",
		labelContext: "ME",
		icon: <BsBookmark />,
		children: [
			{
				label: "Saved Jobs",
				route: "saved-jobs",
			},
			{
				label: "Saved Company",
				route: "saved-advertisers",
			},
			{
				label: "Saved Articles",
				route: "saved-articles",
			},
		],
	},
	{
		label: "Account Settings",
		labelContext: "ME",
		icon: <AiFillLock />,
		route: "settings",
	},
];

export const CategoryOptions = [
	{ id: 0, label: "全部" },
	{ id: 2, label: "职场看透透" },
	{ id: 3, label: "生活贴士" },
	{ id: 4, label: "时下Fun事" },
	{ id: 5, label: "新鲜人专区" },
	{ id: 6, label: "求职攻略" },
];
