import React, { useState, useEffect } from "react";
import { Post, Get, Delete, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";

const roleTitle = {
  5: "Agent Manager",
  6: "Agent Lead",
  7: "Agent",
};

export default function AgentCodeSearch({
  roleId,
  onSelectionChanged,
  defaultValue,
}) {
  const [inputCode, setInputCode] = useState("");
  const [defaultValueLocal, setDefaultValueLocal] = useState(null);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (typeof defaultValue === "number") {
      Get(
        `/users?id=${defaultValue}&role_ids=${roleId}&platform=jobmacha`,
        (response) => {
          const res = response.data.map((e) => ({
            value: e.id,
            label: e.name,
          }))[0];
          setDefaultValueLocal(res);
          onSelectionChanged && onSelectionChanged(res);
        },
        (error) => {
          requestError(error);
        },
        () => {}
      );
    } else {
      setDefaultValueLocal(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <FormGroup>
        <Label style={{ fontWeight: "bold" }}>{roleTitle[roleId]} Code</Label>
        <Row>
          <Col xs={11}>
            <Input
              type={"text"}
              placeholder={`Enter ${roleTitle[roleId]} Code`}
              onChange={(e) => {
                setInputCode(e.target.value);
              }}
            />
          </Col>
          <Col xs={1}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                Get(
                  `/users?code=${inputCode}&role_ids=${roleId}&platform=jobmacha`,
                  (response) =>
                    setOptions(
                      response.data.map((e) => ({
                        value: e.id,
                        label: e.name,
                      }))
                    ),
                  (error) => {
                    requestError(error);
                  },
                  () => {}
                );
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Label style={{ fontWeight: "bold" }}>{roleTitle[roleId]}</Label>
        <Select
          name="agentLeadSelect"
          placeholder={`Select ${roleTitle[roleId]}`}
          isClearable
          value={defaultValueLocal}
          //   isSearchable
          onInputChange={(newValue) => {
            if (!newValue) return;
            Get(
              `/users?name=${newValue}&role_ids=${roleId}&platform=jobmacha`,
              (response) =>
                setOptions(
                  response.data.map((e) => ({
                    value: e.id,
                    label: e.name,
                  }))
                ),
              (error) => {
                requestError(error);
              },
              () => {}
            );
          }}
          onChange={(value) => {
            onSelectionChanged && onSelectionChanged(value);
          }}
          options={options}
          //   isLoading={agentLeadLoading}
          //   value={agentLead}
        />
      </FormGroup>
    </>
  );
}
