import React, { useEffect, useState } from "react";
import _ from "lodash";
import CKEditor from "components/Input/RTE.js";
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import { connect } from "react-redux";
import { CustomInput } from "components/Input/CustomInput";
import ImageField from "components/Modal/ImageField";
import CustomSelectMultiple from "components/Input/SelectMulti";
import PaymentGatewayForm from "components/PaymentGatewayForm";
import CustomModal from "components/Modal";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import ECFormInput from "components/Input/FormInput"
import {
  JobRequirements,
  employmentTypeOptions,
  shiftTypeOptions,
  planOptions,
  categoryMappings,
} from "./constants";
import { validateStep } from "./helpers";
import { requestError, requestSuccess } from "utils/requestHandler";
import getHostedURL from "utils/hostURL";
import { copyInput } from "utils/helper";
import {
  ExperienceData,
  ShiftTypes,
  EmploymentTypes,
  LanguageData,
  Taggings,
} from "Config";
import "../index.scss";
import {
  BENEFIT_OTHERS_ID,
  COUNTRY_MALAYSIA_ID,
  PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID,
  PACKAGE_CONST_FACEBOOK_PLAN_ID,
  PACKAGE_CONST_PLAN_C_ID,
  PACKAGE_CONST_POSTING_PLAN_ID,
  TAX_RATE,
} from "constants/Constants";
import PreviewPlanModal from "components/Modal/PreviewPlan";
import PreviewJob from "../PreviewJob";

import ECModalTrigger from "./components/ModalTrigger/ModalTrigger";
import AdvertiserTypeahead from "./components/AdvertiserTypeahead";
import Trans from "components/Trans";
import ECRadio from "components/Input/Radio";
import ConfirmationModal from "components/Nextjs/Confirmation";

function CreatePostForm({
  mode,
  user,
  agentList,
  selectedPost,
  benefitList,
  advertiserList,
  natureList,
  educationLevelList,
  positionLevelList,
  packageList,
  regionsList,
  planList,
  countriesList,
  statesList,
  getState,
  validateVoucher,
  createJobAd,
  saveAsDraft,
  payment_key,
  orderNo,
  loading,
  createJobAdsBenefit,
  deleteJobAdsBenefits,
  createJobAdsGallery,
  deleteJobAdsGallery,
  createJobAdsBranch,
  updateJobAdsBranch,
  deleteJobAdsBranch,
  preview,
  onChangeHOC,
  showPreviewModal,
  downloadImage,
  history
}) {
  const [step, setStep] = useState(0);
  const [isOther, setOther] = useState(false);
  const [newBenefitZH, setNewBenefitZH] = useState("");
  const [newBenefitEN, setNewBenefitEN] = useState("");
  const [paymentLinkModalOpen, setPaymentLinkModalOpen] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [companyEmail, setCompanyEmail] = useState("");
  const [
    isShowPurchaseJobMachaFacebookPinPostModal,
    setIsShowPurchaseJobMachaFacebookPinPostModal,
  ] = useState(false);
  const [
    isShowPurchaseJobMachaFacebookPinPostModalShown,
    setIsShowPurchaseJobMachaFacebookPinPostModalShown,
  ] = useState(false);

  const onClosePaymentLinkModal = () => {
    setPaymentLinkModalOpen(false);
    setPaymentLink("");
  };

  useEffect(() => {
    getState(COUNTRY_MALAYSIA_ID);
  }, []);

  const [packageRegionId, setPackageRegionId] = useState(null);
  const [packageRegionName, setPackageRegionName] = useState(null);
  // form states
  const [formData, setFormData] = useState({
    currency: "MYR",
    companyId: null,
    isCompanyConfidential: false,
    agentId: null,
    jobTitleCN: "",
    jobTitleEN: "",
    specialization: "",
    experience: "",
    vacancies: 0,
    educationLevel: "",
    language: [],
    employmentType: "",
    positionLevel: "",
    shiftTypes: [],
    tags: [],
    jobTypes: [],
    minSalary: 0,
    maxSalary: 0,
    isSalaryNegotiable: false,
    jobTypeFreshGrad: false,
    jobTypeWorkFromHome: false,
    jobRequirements: [],
    otherJobRequirements: {
      checked: false,
      othersCN: "",
      othersEN: "",
    },
    jobResponsibilitiesCN: "",
    jobResponsibilitiesEN: "",
    benefits: [],
    photos: [],
    headquarterInfo: {
      email: null,
      contactNumberPrefix: "+60",
      contactNumber: "",
      whatsappNumber: "",
      alternativeContactNumberPrefix: "",
      alternativeContactNumber: "",
      address: "",
      postcode: "",
      city: "",
      state: "",
      stateId: 0,
      country: "Malaysia",
      countryId: COUNTRY_MALAYSIA_ID,
    },
    branches: [],
    planType: "Ads Plan",
    package: "",
    plan: planList,
    promoCode: "",
    promoCodeData: "",
    isBundlePrint: false,
    printAdsId: "",
    printPrice: 0,
  });

  useEffect(() => {
    if (selectedPost && !_.isEmpty(selectedPost)) {
      setFormData({
        id: selectedPost?.id ?? "",
        status: selectedPost?.status ?? "",
        companyId: selectedPost?.company_id ?? null,
        isCompanyConfidential: selectedPost?.is_confidential,
        agentId: selectedPost?.agent_id ?? null,
        jobTitleCN: selectedPost?.job_title?.zh ?? "",
        jobTitleEN: selectedPost?.job_title?.en ?? "",
        specialization: selectedPost?.specialization?.id ?? "",
        experience: selectedPost?.total_experience ?? "",
        vacancies: selectedPost?.total_vacancies ?? 0,
        educationLevel: selectedPost?.education_level?.id ?? "",
        language: selectedPost?.language ?? [],
        employmentType: selectedPost?.employment_type ?? "",
        positionLevel: selectedPost?.position_level_id ?? "",
        shiftTypes: selectedPost?.shift_type ?? [],
        tags: selectedPost?.tags ?? [],
        minSalary: selectedPost?.min_salary ?? 0,
        maxSalary: selectedPost?.max_salary ?? 0,
        isSalaryNegotiable: selectedPost?.is_negotiable ?? false,
        jobTypeFreshGrad:
          selectedPost?.job_types?.includes("Fresh Graduate") ?? false,
        jobTypeWorkFromHome:
          selectedPost?.job_types?.includes("Work From Home") ?? false,
        jobRequirements:
          selectedPost?.job_requirements?.map((item) => ({
            category: item.category,
            content: item.content?.map((content) => {
              return JobRequirements.find(
                (requirement) => requirement.value === item.category
              ).options.find(
                (option) => option.en === content.en && option.zh === content.zh
              )?.value;
            }),
          })) || [],
        otherJobRequirements: {
          checked:
            selectedPost?.job_description?.en ||
            selectedPost?.job_description?.zh
              ? true
              : false,
          othersCN: selectedPost?.job_description?.zh ?? "",
          othersEN: selectedPost?.job_description?.en ?? "",
        },
        jobResponsibilitiesCN: selectedPost?.job_responsibilities?.zh ?? "",
        jobResponsibilitiesEN: selectedPost?.job_responsibilities?.en ?? "",
        benefits:
          selectedPost?.jobads_benefits?.map((item) => ({
            id: item.id,
            benefits_id: item?.benefits?.id ?? "",
            name: item?.name ?? item?.benefits?.name ?? "",
          })) ?? [],
        photos: selectedPost?.jobads_galleries ?? [],
        headquarterInfo: {
          email: selectedPost?.contact_email ?? null,
          contactNumberPrefix: "+60",
          contactNumber: selectedPost?.company_contact ?? "",
          whatsappNumber: selectedPost?.contact_number ?? "",
          alternativeContactNumberPrefix:
            selectedPost?.alternative_contact_number,
            //  ? "+60" : "",
          alternativeContactNumber:
            selectedPost?.alternative_contact_number ?? "",
          address: selectedPost?.company_address ?? "",
          postcode: selectedPost?.postcode ?? "",
          city: selectedPost?.city ?? "",
          state: selectedPost?.state ?? "",
          stateId: selectedPost?.state_id ?? 0,
          country: selectedPost?.country ?? "",
          countryId: selectedPost?.country_id ?? 0,
        },
        branches:
          selectedPost?.job_locations?.map((branch) => ({
            id: branch.id,
            name: branch.branch_name,
            email: branch.contact_email,
            address: branch.address,
            postcode: branch.postcode,
            city: branch.city,
            state: branch.state,
            stateId: branch.state_id,
            country: branch.country,
            countryId: branch.country_id,
            contactNumberPrefix: "+60",
            contactNumber: branch.contact_number,
            whatsappNumber: branch.contact_whatsapp,
            alternativeContactNumberPrefix: branch.alternative_contact_number,
              // ? "+60"
              // : "",
            alternativeContactNumber: branch.alternative_contact_number,
          })) ?? [],
        planType: "Ads Plan",
        package: "",
        plan: planList,
        promoCode: "",
        promoCodeData: "",
        isBundlePrint: false,
        printAdsId: "",
        printPrice: 0,
        currency: selectedPost.currency,
      });
    }
  }, [selectedPost]);

  const getStepComponent = () => {
    switch (step) {
      case 0:
        return renderStep1();

      case 1:
        return renderStep2();

      case 2:
        return renderStep3();

      case 3:
        return renderStep4();

      case 4:
        return renderStep5();

      default:
        return null;
    }
  };

  const decrementStep = () => {
    setStep((prev) => prev - 1);
  };

  const handleInputChange = (field, value) => {
    if (field === "planType") {
      handleInputChange(
        "plan",
        planList.map((e) => ({
          ...(formData.plan.find((f) => f.id === e.id) ?? e),
          ...(e.id === 1 && {
            quantity: value === "Ads Plan" ? 0 : 1,
          }),
        }))
      );
    }
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleMultiSelectChange = (field, value) => {
    const tmpData = [...formData[field]];
    const index = tmpData.indexOf(value);
    if (index === -1) {
      tmpData.push(value);
    } else {
      tmpData.splice(index, 1);
    }

    setFormData((prevData) => ({ ...prevData, [field]: tmpData }));
  };

  const handleHQInputChange = (field, value) => {
    const newHeadquarterInfo = {
      ...formData.headquarterInfo,
    };
    newHeadquarterInfo[field] = value;

    handleInputChange("headquarterInfo", newHeadquarterInfo);
  };

  const handleBranchInputChange = (index, field, value) => {
    const newBranches = [...formData.branches];
    newBranches[index][field] = value;
    const newHeadquarterInfo = {
      ...formData.headquarterInfo,
    };
    newHeadquarterInfo.branches = newBranches;

    handleInputChange("headquarterInfo", newHeadquarterInfo);
  };

  const calculateSubTotal = () => {
    return (
      (parseFloat(
        packageList.find((item) => item.id === formData.package)?.price_list.non_agent.amount
      ) || 0) +
      (formData.plan.reduce(
        (accumulator, currentValue) =>
          currentValue.price
            ? accumulator +
              currentValue.price[0].amount * (currentValue?.quantity ?? 0)
            : 0,
        0
      ) || 0)
    );
  };

  const getDiscount = () => {
    return formData.promoCodeData?.type === "amount"
      ? formData.promoCodeData.value
      : formData.promoCodeData?.type === "percentage"
      ? (calculateSubTotal() * formData.promoCodeData.value) / 100
      : 0;
  };

  const calculateECTotal = () => {
    const ecTotal = calculateSubTotal() - getDiscount() + getTax();

    return ecTotal < 0 ? 0 : ecTotal;
  };

  const getTax = () => {
    return (calculateSubTotal() - getDiscount()) * TAX_RATE;
  };

  const getAddOns = () => {
    const packagePlans = [];
    if (formData.package) {
      packageList
        .find((item) => item.id === formData.package)
        ?.plans.forEach((plan) => packagePlans.push(plan.id));
    }

    const addOns = [...new Set([...formData.plan, ...packagePlans])].map(
      (item) => planList.find((plan) => plan.id === item)
    );
    addOns.forEach((plan) => {
      plan.quantity =
        packagePlans.filter((pp) => plan.id === pp).length +
        formData.plan.filter((p) => plan.id === p).length;
    });

    return addOns;
  };

  const handleSubmit = (type = "") => {
    if (!formData.planType) {
      requestError("Plan type is required");
      return;
    } else if (formData.planType === "Ads Plan" && !formData.package) {
      requestError("Package is required");
      return;
    } else if (formData.planType === "Ads Slot" && !formData.plan.length) {
      requestError("Plan is required");
      return;
    }

    const jobTypes = [];
    if (formData.jobTypeFreshGrad) {
      jobTypes.push("Fresh Graduate");
    }
    if (formData.jobTypeWorkFromHome) {
      jobTypes.push("Work From Home");
    }

    const dataToSubmit = {
      ...(formData.id ? { id: formData.id } : {}),
      company_id: formData.companyId,
      job_types: jobTypes,
      is_confidential: formData.isCompanyConfidential,
      job_title: {
        en: formData.jobTitleEN,
        zh: formData.jobTitleCN,
      },
      total_experience: formData.experience,
      total_vacancies: parseInt(formData.vacancies),
      min_salary: parseInt(formData.minSalary),
      max_salary: parseInt(formData.maxSalary),
      is_negotiable: formData.isSalaryNegotiable,
      employment_type: formData.employmentType,
      language: formData.language,
      job_requirements: formData.jobRequirements.map((item) => ({
        category: item.category,
        content: item.content?.map((content) => {
          return JobRequirements.find(
            (requirement) => requirement.value === item.category
          ).options.find((option) => option.value === content);
        }),
      })),
      job_description: {
        en: formData.otherJobRequirements.othersEN,
        zh: formData.otherJobRequirements.othersCN,
      },
      job_responsibilities: {
        en: formData.jobResponsibilitiesEN,
        zh: formData.jobResponsibilitiesCN,
      },
      company_contact:
        // formData.headquarterInfo.contactNumberPrefix +
        formData.headquarterInfo.contactNumber,
      contact_number: formData.headquarterInfo.whatsappNumber,
      contact_email: formData.headquarterInfo.email,
      alternative_contact_number: formData.headquarterInfo
        .alternativeContactNumber,
        // ? formData.headquarterInfo.alternativeContactNumberPrefix +
        //   formData.headquarterInfo.alternativeContactNumber
        // : null,
      company_address: formData.headquarterInfo.address,
      country_id: formData.headquarterInfo.countryId,
      country: formData.headquarterInfo.country,
      state_id: formData.headquarterInfo.stateId,
      state: formData.headquarterInfo.state,
      city: formData.headquarterInfo.city,
      city_id: 0,
      postcode: formData.headquarterInfo.postcode,
      status: "active",
      is_pending_payment: false,
      education_level_id: +formData.educationLevel,
      position_level_id: formData.positionLevel,
      specialization_id: formData.specialization,
      shift_type: formData.shiftTypes,
      tags: formData.tags,
      agent_id: formData.agentId,
      is_published: false,
      views: 0,
      // fields used for /jobads-benefits api call
      benefits: formData.benefits,
      // fields used for /jobads-galleries api call
      photos: formData.photos.map((photo) => photo.url),
      // fields used for /jobads-locations api call
      branches: formData.branches,
      // fields used for /orders api call
      user_id: user.id,
      is_bundle_print: formData.isBundlePrint,
      print_ads_id: formData.isBundlePrint ? formData.printAdsId : "",
      subtotal: calculateSubTotal(),
      ec_total: calculateECTotal(),
      grand_total: calculateECTotal() + formData.printPrice,
      voucher_id: formData.promoCodeData.id,
      discount: getDiscount(),
      tax: getTax(),
      addOns: formData.plan,
      // order_plans: getAddOns().map((item) => item.id),
      print_price: formData.printPrice,
      package_id: formData.package ? formData.package : null,
      selectedRegionIdInPackage: packageRegionId,
      post_payment_url: `${getHostedURL()}/post/complete`,
      // fields used for /order-plans api call
      plan: [],
      currency: formData.currency,
    };

    // add selected package plan to addon
    let packagePlanIds = packageList
      .find((e) => e.id === formData.package)
      .plans.map((e) => e.id);
    dataToSubmit.addOns = planList.map((e) => ({
      ...(formData.plan.find((f) => f.id === e.id) ?? e),
      selected_region_ids:
        e.id === 15 ? [...(e?.selected_region_ids ?? []), packageRegionId] : [],
      quantity:
        (formData.plan.find((f) => f.id === e.id)?.quantity ?? 0) +
        (packagePlanIds.includes(e.id) ? 1 : 0),
    }));

    dataToSubmit.addOns = dataToSubmit.addOns.filter((e) => e?.quantity > 0);

    createJobAd(
      dataToSubmit,
      () => history.push("/post")
    );
  };

  const handleSaveDraft = (isShowResponseMessage = true) => {
    const jobTypes = [];
    if (formData.jobTypeFreshGrad) {
      jobTypes.push("Fresh Graduate");
    }
    if (formData.jobTypeWorkFromHome) {
      jobTypes.push("Work From Home");
    }

    const dataToSubmit = {
      ...(formData.id ? { id: formData.id } : {}),
      company_id: formData.companyId,
      job_types: jobTypes,
      is_confidential: formData.isCompanyConfidential,
      job_title: {
        en: formData.jobTitleEN,
        zh: formData.jobTitleCN,
      },
      total_experience: formData.experience,
      total_vacancies: parseInt(formData.vacancies),
      min_salary: parseInt(formData.minSalary),
      max_salary: parseInt(formData.maxSalary),
      is_negotiable: formData.isSalaryNegotiable,
      employment_type: formData.employmentType,
      language: formData.language,
      job_requirements: formData.jobRequirements.map((item) => ({
        category: item.category,
        content: item.content?.map((content) => {
          return JobRequirements.find(
            (requirement) => requirement.value === item.category
          ).options.find((option) => option.value === content);
        }),
      })),
      job_description: {
        en: formData?.otherJobRequirements?.othersEN,
        zh: formData?.otherJobRequirements?.othersCN,
      },
      job_responsibilities: {
        en: formData?.jobResponsibilitiesEN ?? "",
        zh: formData?.jobResponsibilitiesCN ?? "",
      },
      company_contact:
        formData.headquarterInfo.contactNumberPrefix +
        formData.headquarterInfo.contactNumber,
      contact_number: formData.headquarterInfo.whatsappNumber,
      contact_email: formData.headquarterInfo.email,
      alternative_contact_number: formData.headquarterInfo
        .alternativeContactNumber,
        // ? formData.headquarterInfo.alternativeContactNumberPrefix +
        //   formData.headquarterInfo.alternativeContactNumber
        // : null,
      company_address: formData.headquarterInfo.address,
      country_id: formData.headquarterInfo.countryId,
      country: formData.headquarterInfo.country,
      state_id: formData.headquarterInfo.stateId,
      state: formData.headquarterInfo.state,
      city: formData.headquarterInfo.city,
      city_id: 0,
      postcode: formData.headquarterInfo.postcode,
      status: mode !== "Create" ? "active" : "draft",
      education_level_id: +formData.educationLevel,
      position_level_id: formData.positionLevel,
      specialization_id: formData.specialization,
      shift_type: formData.shiftTypes,
      tags: formData.tags,
      agent_id: formData.agentId,
      is_published: false,
      views: 0,
      // fields used for /jobads-benefits api call
      benefits: formData.benefits,
      // fields used for /jobads-galleries api call
      photos: formData.photos.map((photo) => photo.url),
      // fields used for /jobads-locations api call
      branches: formData.branches,
      // fields used for /orders api call
      user_id: user.id,
      is_bundle_print: formData.isBundlePrint,
      print_ads_id: formData.isBundlePrint ? formData.printAdsId : "",
      subtotal: calculateSubTotal(),
      ec_total: calculateECTotal(),
      grand_total: calculateECTotal() + formData.printPrice,
      voucher_id: formData.promoCodeData.id,
      discount: getDiscount(),
      tax: getTax(),
      addOns: formData.plan,
      // order_plans: getAddOns().map((item) => item.id),
      print_price: formData.printPrice,
      package_id: formData.package ? formData.package : null,
      selectedRegionIdInPackage: packageRegionId,
      post_payment_url: `${getHostedURL()}/post/complete`,
      // fields used for /order-plans api call
      plan: formData.plan,
      currency: formData.currency,
    };

    if (formData.package === PACKAGE_CONST_PLAN_C_ID) {
      let selectedPlanRegion = planList.find(
        (e) => e.region && e.region?.map((f) => f.id)?.includes(packageRegionId)
      );
      dataToSubmit.addOns = dataToSubmit.addOns.map((e) =>
        e.id === selectedPlanRegion.id
          ? {
              ...e,
              selected_region_ids: [
                ...(e?.selected_region_ids ?? []),
                packageRegionId,
              ],
              quantity: (e?.selected_region_ids?.length ?? 0) + 1,
            }
          : e
      );
    }
    dataToSubmit.addOns = dataToSubmit.addOns.filter((e) => e?.quantity > 0);

    saveAsDraft(
      dataToSubmit,
      (data) => setFormData({ ...formData, id: data.id }),
      { isShowResponseMessage }
    );
  };

  const handleCreateBenefits = (benefit) => {
    const newBenefits = [...formData.benefits];

    createJobAdsBenefit(
      {
        job_ads_id: formData.id,
        name: { en: benefit.name.en, zh: benefit.name.zh },
        benefits_id: benefit.id,
      },
      (data) => {
        newBenefits.push({
          benefits_id: benefit.id,
          name: benefit?.name,
          id: data.id,
        });
        handleInputChange("benefits", newBenefits);
      }
    );
  };

  const handleCreateNewBenefits = () => {
    handleCreateBenefits({
      id: BENEFIT_OTHERS_ID,
      name: {
        en: newBenefitEN,
        zh: newBenefitZH,
      },
    });
    setNewBenefitEN("");
    setNewBenefitZH("");
    setOther(false);
  };

  const handleChangePlans = (plan) => {
    const newPlan = formData.plan.includes(plan.id)
      ? formData.plan.filter((item) => item !== plan.id)
      : [...formData.plan, plan.id];

    handleInputChange("plan", newPlan);
  };

  const handleIsShowPurchaseJobMachaFacebookPinPostModal = async (
    dismissPopUp = false,
    reset = false
  ) => {
    if (reset) {
      handleInputChange(
        "plan",
        planList.map((e) => ({
          ...(formData.plan.find((f) => f.id === e.id) ?? e),
          quantity: [
            PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID,
            PACKAGE_CONST_FACEBOOK_PLAN_ID,
          ].includes(e.id)
            ? 0
            : formData.plan.find((f) => f.id === e.id)?.quantity ?? 0,
        }))
      );
    }

    if (
      formData.plan.find((f) => f.id === PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID)
        ?.quantity > 0
    )
      return;

    handleInputChange(
      "plan",
      planList.map((e) => ({
        ...(formData.plan.find((f) => f.id === e.id) ?? e),
        quantity: [
          PACKAGE_CONST_FACEBOOK_PLAN_ID,
          PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID,
        ].includes(e.id)
          ? 1
          : formData.plan.find((f) => f.id === e.id)?.quantity ?? 0,
      }))
    );

    if (!isShowPurchaseJobMachaFacebookPinPostModalShown && !dismissPopUp) {
      setIsShowPurchaseJobMachaFacebookPinPostModalShown(true);
      setIsShowPurchaseJobMachaFacebookPinPostModal(true);
    }
  };

  useEffect(() => {
    const planIndex = planList.findIndex(
      (plan) => plan.id === PACKAGE_CONST_POSTING_PLAN_ID
    );
    let plan = planList[planIndex];

    if (plan && _.isEmpty(formData.plan)) {
      handleChangePlans(plan);
    }
  }, [planList, formData.planType === "Ads Slot"]);

  const renderStep1 = () => {
    return (
      <>
        <Card className="main-card mb-3">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="step-graphic mr-2 d-flex align-items-center justify-content-center">
                {step + 1}
              </div>
              <h4 className="font-weight-bold">Select Advertiser</h4>
            </div>
            <FormGroup>
              <AdvertiserTypeahead
                defaultValue={formData.companyId}
                onSelectionChanged={(selectedOption) => {
                  handleInputChange("companyId", selectedOption?.value);
                  setCompanyEmail(
                    selectedOption?.data?.email ??
                      selectedOption?.data?.person_in_charge_email ??
                      ""
                  );
                }}
              />
              <FormGroup check className="mt-2">
                <Input
                  id="isCompanyConfidential"
                  type="checkbox"
                  checked={formData.isCompanyConfidential}
                  onChange={(e) =>
                    handleInputChange("isCompanyConfidential", e.target.checked)
                  }
                />
                <Label check>Confidential Advertiser</Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <CustomInput
                label={
                  <>
                    PIC Login Email{" "}
                    <span className="text-muted">
                      (For agent served advertisers only)
                    </span>{" "}
                    <span className="text-danger">*</span>
                  </>
                }
                type={"text"}
                id={"jobTitleCN"}
                placeholder={"Enter PIC Login Email "}
                value={companyEmail}
                disabled
              />
            </FormGroup>
          </CardBody>
        </Card>
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => validateStep(step, formData, setStep)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <Card className="main-card mb-3">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="step-graphic mr-2 d-flex align-items-center justify-content-center">
                {step + 1}
              </div>
              <h4 className="font-weight-bold">Job Details</h4>
            </div>
            <Row>
              <Col md={6}>
                <CustomInput
                  label={
                    <>
                      Job Title (CN) <span className="text-danger">*</span>
                    </>
                  }
                  type={"text"}
                  id={"jobTitleCN"}
                  placeholder={"Enter Job Title (CN) "}
                  value={formData.jobTitleCN}
                  onChange={(e) =>
                    handleInputChange("jobTitleCN", e.target.value)
                  }
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  label={
                    <>
                      Job Title (EN) <span className="text-danger">*</span>
                    </>
                  }
                  type={"text"}
                  id={"jobTitleEN"}
                  placeholder={"Enter Job Title (EN) "}
                  value={formData.jobTitleEN}
                  onChange={(e) =>
                    handleInputChange("jobTitleEN", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <ECModalTrigger
                  label={
                    <Trans context="ADVERTISER" langKey={"SPECIALIZATION"} />
                  }
                  options={natureList}
                  value={formData.specialization}
                  required={true}
                  placeholder={"Select Job Specialization"}
                  placeholder_zh={"选择职务类别"}
                  onChangeData={(val) =>
                    handleInputChange("specialization", val)
                  }
                />
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor={"experience"} style={{ fontWeight: "bold" }}>
                    Experience <span className="text-danger">*</span>
                  </Label>
                  <Select
                    id="experience"
                    placeholder="Select"
                    options={ExperienceData}
                    value={ExperienceData.filter(
                      (option) => option.value === formData.experience
                    )}
                    onChange={(selectedOption) =>
                      handleInputChange("experience", selectedOption.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <CustomInput
                  label={
                    <>
                      Vacancies <span className="text-danger">*</span>
                    </>
                  }
                  type={"number"}
                  id={"vacancies"}
                  placeholder={"Enter vacancies"}
                  value={formData.vacancies}
                  onChange={(e) =>
                    handleInputChange("vacancies", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label
                    htmlFor={"educationLevel"}
                    style={{ fontWeight: "bold" }}
                  >
                    Education Level
                  </Label>
                  <Select
                    id="educationLevel"
                    placeholder="Select"
                    isClearable
                    isSearchable
                    options={educationLevelList.map((level) => ({
                      label: level.name.en,
                      value: level.id,
                    }))}
                    value={
                      formData.educationLevel
                        ? {
                            value: educationLevelList.find(
                              (level) => level.id === formData.educationLevel
                            ).id,
                            label: educationLevelList.find(
                              (level) => level.id === formData.educationLevel
                            ).name.en,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("educationLevel", selectedOption.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor={"language"} style={{ fontWeight: "bold" }}>
                    Language
                  </Label>
                  <Select
                    isMulti
                    id="language"
                    placeholder="Select"
                    options={LanguageData.map((e) => ({
                      label: e.en,
                      value: e.value,
                    }))}
                    defaultValue={LanguageData.filter((e) =>
                      formData.language?.includes(e.value)
                    ).map((e) => ({
                      label: e?.en,
                      value: e?.value,
                    }))}
                    onChange={(selectedOption) => {
                      handleInputChange(
                        "language",
                        selectedOption.map((e) => e.value)
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label htmlFor={"employmentType"} style={{ fontWeight: "bold" }}>
                Select Employment Type <span className="text-danger">*</span>
              </Label>
              <div
                className="d-flex flex-wrap"
                style={{
                  gap: "1rem",
                }}
              >
                {EmploymentTypes.map((option) => (
                  <div
                    className={`single-select-option ${
                      formData.employmentType === option.id ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleInputChange("employmentType", option.id)
                    }
                  >
                    {option.en}
                  </div>
                ))}
              </div>
            </FormGroup>

            <FormGroup>
              <Label htmlFor={"positionLevel"} style={{ fontWeight: "bold" }}>
                Select Position Level <span className="text-danger">*</span>
              </Label>
              <div
                className="d-flex flex-wrap"
                style={{
                  gap: "1rem",
                }}
              >
                {positionLevelList.map((position) => (
                  <div
                    className={`single-select-option ${
                      formData.positionLevel === position.id ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleInputChange("positionLevel", position.id)
                    }
                  >
                    {position.name.en}
                  </div>
                ))}
              </div>
            </FormGroup>

            <Row>
              <Col md={6}>
                <ECRadio
                  label={<Trans context="ADVERTISER" langKey={"SHIFT_TYPE"} />}
                  options={ShiftTypes}
                  required={true}
                  multiple={true}
                  value={formData.shiftTypes}
                  optionValuelKey={"id"}
                  onChangeData={(val) => handleInputChange("shiftTypes", val)}
                />
              </Col>

              <Col md={6}>
                <ECRadio
                  label={<Trans context="ADVERTISER" langKey={"TAGGING"} />}
                  options={Taggings}
                  required={true}
                  multiple={true}
                  value={formData.tags}
                  optionValuelKey={"id"}
                  onChangeData={(val) => handleInputChange("tags", val)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label
                    htmlFor="headquarterContactNumber"
                    style={{ fontWeight: "bold" }}
                  >
                    Minimum Salary <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        disabled={formData.isSalaryNegotiable}
                        color="#ffffff"
                        caret
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "0.25rem 0 0 0.25rem",
                        }}
                      >
                        {formData.currency}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            handleInputChange("currency", "MYR");
                          }}
                        >
                          MYR
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            handleInputChange("currency", "SGD");
                          }}
                        >
                          SGD
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      type={"number"}
                      id={"minSalary"}
                      placeholder={"Eg: RM 2000"}
                      value={formData.minSalary}
                      disabled={formData.isSalaryNegotiable}
                      onChange={(e) =>
                        handleInputChange("minSalary", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label
                    htmlFor="headquarterContactNumber"
                    style={{ fontWeight: "bold" }}
                  >
                    Maximum Salary <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        disabled={formData.isSalaryNegotiable}
                        color="#ffffff"
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "0.25rem 0 0 0.25rem",
                        }}
                      >
                        {formData.currency}
                      </DropdownToggle>
                    </UncontrolledDropdown>
                    <Input
                      type={"number"}
                      id={"maxSalary"}
                      placeholder={"Eg: RM 5000"}
                      value={formData.maxSalary}
                      disabled={formData.isSalaryNegotiable}
                      onChange={(e) =>
                        handleInputChange("maxSalary", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup check>
              <Input
                id="isSalaryNegotiable"
                type="checkbox"
                // value={formData.isSalaryNegotiable}
                checked={formData.isSalaryNegotiable}
                onChange={(e) => {
                  handleInputChange("isSalaryNegotiable", e.target.checked);
                  if (e.target.checked) {
                    handleInputChange("minSalary", "");
                    handleInputChange("maxSalary", "");
                  }
                }}
              />
              <Label check>Salary negotiable?</Label>
            </FormGroup>
            <FormGroup className="mt-3">
              <Label htmlFor={"jobType"} style={{ fontWeight: "bold" }}>
                Job Type
              </Label>
              <div className="d-flex">
                <div className="d-flex mr-2 justify-content-center">
                  <Label
                    htmlFor={"jobTypeFreshGrad"}
                    className="font-weight-bold mr-2"
                  >
                    Fresh Graduate Available
                  </Label>
                  <Switch
                    checked={formData.jobTypeFreshGrad}
                    onChange={(checked) =>
                      handleInputChange("jobTypeFreshGrad", checked)
                    }
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#1890FF"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Label
                    htmlFor={"jobTypeWorkFromHome"}
                    className="font-weight-bold mr-2"
                  >
                    Work From Home
                  </Label>
                  <Switch
                    checked={formData.jobTypeWorkFromHome}
                    onChange={(checked) =>
                      handleInputChange("jobTypeWorkFromHome", checked)
                    }
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#1890FF"
                  />
                </div>
              </div>
            </FormGroup>
          </CardBody>
        </Card>
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => handleSaveDraft()}
              >
                {mode === "Create" ? "Save as Draft" : "Save"}
              </button>
            </div>
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={decrementStep}
              >
                Back
              </button>
            </div>
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  handleSaveDraft(false);
                  validateStep(step, formData, setStep);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderStep3 = () => {
    return (
      <>
        <Card className="main-card mb-3">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="step-graphic mr-2 d-flex align-items-center justify-content-center">
                {step + 1}
              </div>
              <h4 className="font-weight-bold">Job Descriptions</h4>
            </div>
            <FormGroup>
              <Label htmlFor={"jobRequirements"} style={{ fontWeight: "bold" }}>
                Job Requirements <span className="text-danger">*</span>
              </Label>
              {JobRequirements.map((requirement, index) => {
                const value = _.find(formData.jobRequirements, {
                  category: requirement.value,
                });

                return (
                  <>
                    <FormGroup check className="mt-2">
                      <Input
                        id={`requiredCertificate${index}Checked`}
                        type="checkbox"
                        checked={value}
                        onChange={(e) => {
                          const newJobRequirements = [
                            ...formData.jobRequirements,
                          ];
                          if (e.target.checked) {
                            newJobRequirements.push({
                              category: requirement.value,
                              content: [],
                            });
                          } else {
                            let jobRequirementIndex =
                              newJobRequirements.findIndex(
                                (item) => item.category === requirement.value
                              );
                            newJobRequirements.splice(jobRequirementIndex, 1);
                          }

                          handleInputChange(
                            "jobRequirements",
                            newJobRequirements
                          );
                        }}
                      />
                      <Label check>{requirement.en}</Label>
                    </FormGroup>
                    {value && requirement?.options?.length > 0 && (
                      <CustomSelectMultiple
                        options={requirement.options}
                        value={value.content || []}
                        formGroupClass="mt-2"
                        placeholder={requirement.placeholder}
                        onChangeData={(val) => {
                          const newJobRequirements = [
                            ...formData.jobRequirements,
                          ];
                          let index = newJobRequirements.findIndex(
                            (item) => item.category === requirement.value
                          );
                          newJobRequirements[index].content = val;
                          handleInputChange(
                            "jobRequirements",
                            newJobRequirements
                          );
                        }}
                      />
                    )}
                  </>
                );
              })}
            </FormGroup>
            <FormGroup check className="mt-2">
              <Input
                id="otherJobRequirementsChecked"
                type="checkbox"
                checked={formData.otherJobRequirements.checked}
                onChange={(e) => {
                  const newOtherJobRequirements = {
                    ...formData.otherJobRequirements,
                  };
                  newOtherJobRequirements.checked = e.target.checked;

                  handleInputChange(
                    "otherJobRequirements",
                    newOtherJobRequirements
                  );
                }}
              />
              <Label check>Others</Label>
            </FormGroup>
            {formData.otherJobRequirements.checked && (
              <>
                <Row className="mt-3">
                  <Col xs={6}>
                    <FormGroup>
                      <Label
                        htmlFor={"othersCN"}
                        style={{ fontWeight: "bold" }}
                      >
                        其他（CN）<span className="text-danger">*</span>
                      </Label>
                      <CKEditor
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const newOtherJobRequirements = {
                            ...formData.otherJobRequirements,
                          };
                          newOtherJobRequirements.othersCN = data;

                          handleInputChange(
                            "otherJobRequirements",
                            newOtherJobRequirements
                          );
                        }}
                        data={formData.otherJobRequirements.othersCN}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label
                        htmlFor={"othersEN"}
                        style={{ fontWeight: "bold" }}
                      >
                        Others （EN） <span className="text-danger">*</span>
                      </Label>
                      <CKEditor
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const newOtherJobRequirements = {
                            ...formData.otherJobRequirements,
                          };
                          newOtherJobRequirements.othersEN = data;

                          handleInputChange(
                            "otherJobRequirements",
                            newOtherJobRequirements
                          );
                        }}
                        data={formData.otherJobRequirements.othersEN}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            <Row className="mt-3">
              <Col xs={6}>
                <FormGroup>
                  <Label
                    htmlFor={"jobRequirementsCN"}
                    style={{ fontWeight: "bold" }}
                  >
                    职位描述(CN)<span className="text-danger">*</span>
                  </Label>
                  <CKEditor
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleInputChange("jobResponsibilitiesCN", data);
                    }}
                    data={formData.jobResponsibilitiesCN}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label
                    htmlFor={"jobResponsibilitiesEN"}
                    style={{ fontWeight: "bold" }}
                  >
                    Job Responsibilities (EN){" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <CKEditor
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleInputChange("jobResponsibilitiesEN", data);
                    }}
                    data={formData.jobResponsibilitiesEN}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup className="mt-3">
              <Label htmlFor={"employmentType"} style={{ fontWeight: "bold" }}>
                Benefits <span className="text-danger">*</span>
              </Label>
              <div className="d-flex flex-wrap mb-3" style={{ gap: "0.3rem" }}>
                {formData.benefits.map((benefit, index) => {
                  return (
                    <div className="multi-select-item" style={{ margin: 0 }}>
                      {benefit?.name?.en}
                      <button
                        type="button"
                        className="close text-white ml-2"
                        aria-label="Close"
                        onClick={() => {
                          deleteJobAdsBenefits(benefit.id, () => {
                            const newBenefits = [...formData.benefits];
                            newBenefits.splice(index, 1);
                            handleInputChange("benefits", newBenefits);
                          });
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="w-100 border-bottom" />
              <div className="d-flex flex-wrap mt-3">
                {benefitList
                  .sort((a, b) => a.id - b.id)
                  .map((option) => {
                    const newBenefits = [...formData.benefits];
                    const index = newBenefits.findIndex(
                      (item) => item.benefits_id === option.id
                    );

                    if (index !== -1 && option.id !== BENEFIT_OTHERS_ID) {
                      return;
                    }

                    return (
                      <div
                        className={`multi-select-option mb-2 ${
                          isOther &&
                          option.id === BENEFIT_OTHERS_ID &&
                          "selected"
                        }`}
                        onClick={() => {
                          if (option.id === BENEFIT_OTHERS_ID) {
                            setOther(true);
                            return;
                          }

                          createJobAdsBenefit(
                            {
                              job_ads_id: formData.id,
                              name: { en: option.name.en, zh: option.name.zh },
                              benefits_id: option.id,
                            },
                            (data) => {
                              newBenefits.push({
                                benefits_id: option.id,
                                name: option?.name,
                                id: data.id,
                              });
                              handleInputChange("benefits", newBenefits);
                            }
                          );
                        }}
                      >
                        + {option?.name?.en}
                      </div>
                    );
                  })}
              </div>
            </FormGroup>
            {isOther && (
              <>
                <div className="d-flex" style={{ gap: 12 }}>
                  <FormGroup style={{ flexGrow: 1 }}>
                    <Input
                      placeholder="Enter Benefits (CN)"
                      size={"lg"}
                      onKeyUp={(e) =>
                        e.key === "Enter" && handleCreateNewBenefits()
                      }
                      onChange={(e) => setNewBenefitZH(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup style={{ flexGrow: 1 }}>
                    <Input
                      placeholder="Enter Benefits (EN)"
                      size={"lg"}
                      onKeyUp={(e) =>
                        e.key === "Enter" && handleCreateNewBenefits()
                      }
                      onChange={(e) => setNewBenefitEN(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <p>Press Enter to add</p>
              </>
            )}
            <FormGroup className="mt-4">
              <Label htmlFor={"photo"} className="font-weight-bold">
                Working Environment
              </Label>
              <br></br>
              <span className="text-secondary">
                It's recommended to upload photos related to your company, such
                as the work environment, team-building activities, annual
                dinners, and company trips.
                <br />
                This will help candidates know your company better!
                <br />
                <br />
                *Size: 1280px x 720px
                <br />
                *Ratio: 16:9
                <br />
                *Max Size: 2MB
              </span>
              <br></br>
              <ImageField
                imageData={formData.photos}
                onAddFile={(file) => {
                  createJobAdsGallery(
                    {
                      job_ads_id: formData.id,
                      url: file,
                    },
                    (data) => {
                      const newPhotos = [...formData.photos];
                      newPhotos.push({ url: file, id: data.id });
                      handleInputChange("photos", newPhotos);
                    }
                  );
                }}
                onRemove={(photo, photoIndex) => {
                  const newPhotos = [...formData.photos];
                  deleteJobAdsGallery(newPhotos[photoIndex].id, () => {
                    newPhotos.splice(photoIndex, 1);
                    handleInputChange("photos", newPhotos);
                  });
                }}
              />
            </FormGroup>
          </CardBody>
        </Card>
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => handleSaveDraft()}
              >
                {mode === "Create" ? "Save as Draft" : "Save"}
              </button>
            </div>
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={decrementStep}
              >
                Back
              </button>
            </div>
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => validateStep(step, formData, setStep)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderStep4 = () => {
    return (
      <>
        <Card className="main-card mb-3">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="step-graphic mr-2 d-flex align-items-center justify-content-center">
                {step + 1}
              </div>
              <h4 className="font-weight-bold">Contact / Locations</h4>
            </div>
            <h5 className="font-weight-bold">HQ (Headquarter)</h5>
            <CustomInput
              label={
                <>
                  Email
                  <span className="text-danger">*</span>
                </>
              }
              type={"text"}
              id={"headquarterEmail"}
              placeholder={"Enter your email"}
              value={formData.headquarterInfo.email}
              onChange={(e) => {
                const newHeadquarterInfo = { ...formData.headquarterInfo };
                newHeadquarterInfo.email = e.target.value;

                handleInputChange("headquarterInfo", newHeadquarterInfo);
              }}
            />
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label
                    htmlFor="headquarterContactNumber"
                    style={{ fontWeight: "bold" }}
                  >
                    Contact Number <span className="text-danger">*</span>
                  </Label>
                  <ECFormInput
                    context="contact_number"
                    required={true}
                    countryCode={true}
                    value={formData.headquarterInfo.contactNumber}
                    placeholder="Eg: 11-23456789"
                    onChangeData={(value) => {
                      const newHeadquarterInfo = {
                        ...formData.headquarterInfo,
                      };
                      newHeadquarterInfo.contactNumber = value;

                      handleInputChange(
                        "headquarterInfo",
                        newHeadquarterInfo
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label
                    htmlFor="headquarterWhatsappNumber"
                    style={{ fontWeight: "bold" }}
                  >
                    WhatsApp Number{" "}
                  </Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h6
                      style={{
                        fontWeight: "bold",
                        paddingRight: "2%",
                        verticalAlign: "middle",
                      }}
                    >
                      wa.me/
                    </h6>
                    <Input
                      type={"text"}
                      id="headquarterWhatsappNumber"
                      value={formData.headquarterInfo.whatsappNumber}
                      placeholder={"Eg: 601234567890"}
                      onChange={(e) => {
                        const newHeadquarterInfo = {
                          ...formData.headquarterInfo,
                        };
                        newHeadquarterInfo.whatsappNumber = e.target.value;

                        handleInputChange(
                          "headquarterInfo",
                          newHeadquarterInfo
                        );
                      }}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {formData.headquarterInfo.alternativeContactNumberPrefix && (
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label
                      htmlFor={"alternativeContactNumber"}
                      style={{ fontWeight: "bold" }}
                    >
                      Alternative Contact Number{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <ECFormInput
                      context="contact_number"
                      required={true}
                      countryCode={true}
                      value={formData.headquarterInfo.alternativeContactNumber}
                      placeholder="Eg: 11-23456789"
                      onChangeData={(value) => {
                        handleHQInputChange(
                          "alternativeContactNumber",
                          value
                        )
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={1}>
                  <Button
                    onClick={() => {
                      const newHeadquarterInfo = {
                        ...formData.headquarterInfo,
                      };
                      newHeadquarterInfo.alternativeContactNumberPrefix = "";
                      newHeadquarterInfo.alternativeContactNumber = "";
                      handleInputChange("headquarterInfo", newHeadquarterInfo);
                    }}
                    style={{ backgroundColor: "none", marginTop: "30px" }}
                    className="pe-7s-close-circle"
                  />
                </Col>
              </Row>
            )}
            {!formData.headquarterInfo.alternativeContactNumberPrefix && (
              <div
                className="add-new-container mb-3"
                onClick={() => {
                  const newHeadquarterInfo = { ...formData.headquarterInfo };
                  newHeadquarterInfo.alternativeContactNumberPrefix = "+60";

                  handleInputChange("headquarterInfo", newHeadquarterInfo);
                }}
              >
                <div className="pe-7s-plus mr-2" />
                Add alternative contact number
              </div>
            )}
            <CustomInput
              label={
                <>
                  Address
                  <span className="text-danger">*</span>
                </>
              }
              type={"text"}
              id={"headquarterAddress"}
              placeholder={"Enter Address"}
              value={formData.headquarterInfo.address}
              onChange={(e) => {
                const newHeadquarterInfo = { ...formData.headquarterInfo };
                newHeadquarterInfo.address = e.target.value;

                handleInputChange("headquarterInfo", newHeadquarterInfo);
              }}
            />
            <Row>
              <Col xs={3}>
                <CustomInput
                  label={
                    <>
                      Postcode
                      <span className="text-danger">*</span>
                    </>
                  }
                  type={"text"}
                  id={"headquarterAddress"}
                  placeholder={"Enter postcode"}
                  value={formData.headquarterInfo.postcode}
                  onChange={(e) => {
                    const newHeadquarterInfo = { ...formData.headquarterInfo };
                    newHeadquarterInfo.postcode = e.target.value;

                    handleInputChange("headquarterInfo", newHeadquarterInfo);
                  }}
                />
              </Col>
              <Col xs={3}>
                <CustomInput
                  label={
                    <>
                      City
                      <span className="text-danger">*</span>
                    </>
                  }
                  type={"text"}
                  id={"headquarterCity"}
                  value={formData.headquarterInfo.city}
                  onChange={(e) => {
                    const newHeadquarterInfo = { ...formData.headquarterInfo };
                    newHeadquarterInfo.city = e.target.value;

                    handleInputChange("headquarterInfo", newHeadquarterInfo);
                  }}
                />
              </Col>
              <Col xs={3}>
                <CustomInput
                  label={
                    <>
                      State
                      <span className="text-danger">*</span>
                    </>
                  }
                  type={"select"}
                  id={"headquarterState"}
                  value={formData.headquarterInfo.state}
                  onChange={(e) => {
                    const selectedState = statesList.find(
                      (state) => state.name === e.target.value
                    );
                    const newHeadquarterInfo = { ...formData.headquarterInfo };
                    newHeadquarterInfo.state = selectedState.name;
                    newHeadquarterInfo.stateId = selectedState.id;

                    handleInputChange("headquarterInfo", newHeadquarterInfo);
                  }}
                >
                  <option value="" disabled>
                    Select state
                  </option>
                  {statesList.map((state) => (
                    <option id={state.id} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
              <Col xs={3}>
                <CustomInput
                  label={
                    <>
                      Country
                      <span className="text-danger">*</span>
                    </>
                  }
                  type={"select"}
                  id={"headquarterCountry"}
                  value={formData.headquarterInfo.country}
                  onChange={(e) => {
                    const selectedCountry = countriesList.find(
                      (country) => country.name === e.target.value
                    );
                    const newHeadquarterInfo = { ...formData.headquarterInfo };
                    newHeadquarterInfo.country = selectedCountry.name;
                    newHeadquarterInfo.countryId = selectedCountry.id;

                    handleInputChange("headquarterInfo", newHeadquarterInfo);
                    getState(selectedCountry.id);
                  }}
                >
                  {countriesList.map((country) => (
                    <option id={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {formData.branches.map((branch, index) => (
          <Card className="main-card mb-3">
            <CardBody>
              <div className="d-flex justify-content-between">
                <h5 className="font-weight-bold">Branch {index + 1}</h5>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      let temp = {
                        job_ads_id: formData.id,
                        contact_number: branch.contactNumber,
                        branch_name: branch.name,
                        contact_email: branch.email,
                        address: branch.address,
                        contact_whatsapp: branch.whatsappNumber,
                        alternative_contact_number:
                          branch.alternativeContactNumber,
                        postcode: branch.postcode,
                        country_id: branch.countryId,
                        country: branch.country,
                        state_id: branch.stateId,
                        state: branch.state,
                        city: branch.city,
                      };
                      if (branch.id) {
                        updateJobAdsBranch({ ...temp, id: branch.id });
                      } else {
                        createJobAdsBranch(temp, (data) => {
                          const newBranches = [...formData.branches];
                          newBranches[index].id = data.id;
                          handleInputChange("branches", newBranches);
                        });
                      }
                    }}
                    children={"Save"}
                  />
                  <Button
                    onClick={() => {
                      if (branch.id) {
                        deleteJobAdsBranch(
                          { job_ads_id: formData.id, id: branch.id },
                          () => {
                            const newBranches = [...formData.branches];
                            newBranches.splice(index, 1);

                            handleInputChange("branches", newBranches);
                          }
                        );
                      } else {
                        const newBranches = [...formData.branches];
                        newBranches.splice(index, 1);

                        handleInputChange("branches", newBranches);
                      }
                    }}
                    style={{ backgroundColor: "none" }}
                    className="pe-7s-close-circle ml-2"
                  />
                </div>
              </div>

              <CustomInput
                label={
                  <>
                    Branch Name
                    <span className="text-danger">*</span>
                  </>
                }
                type={"text"}
                id={`branchName${index + 1}`}
                placeholder={"Enter your branch name"}
                value={formData.branches[index].name}
                onChange={(e) => {
                  const newBranches = [...formData.branches];
                  newBranches[index].name = e.target.value;

                  handleInputChange("branches", newBranches);
                }}
              />

              {/* <CustomInput
                label={
                  <>
                    Branch Email
                    <span className="text-danger">*</span>
                  </>
                }
                type={"text"}
                id={`branchName${index + 1}`}
                placeholder={"Enter your branch email"}
                value={formData.branches[index].email}
                onChange={(e) => {
                  const newBranches = [...formData.branches];
                  newBranches[index].email = e.target.value;

                  handleInputChange("branches", newBranches);
                }}
              /> */}
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label
                      htmlFor={`branchContactNumber${index + 1}`}
                      style={{ fontWeight: "bold" }}
                    >
                      Contact Number <span className="text-danger">*</span>
                    </Label>
                    <ECFormInput
                      context="contact_number"
                      required={true}
                      countryCode={true}
                      value={formData.branches[index].contactNumber}
                      placeholder="Eg: 11-23456789"
                      onChangeData={(value) => {
                        const newBranches = [...formData.branches];
                        newBranches[index].contactNumber = value;

                        handleInputChange("branches", newBranches);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label
                      htmlFor={`branchWhatsappNumber${index + 1}`}
                      style={{ fontWeight: "bold" }}
                    >
                      WhatsApp Number{" "}
                    </Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          paddingRight: "2%",
                          verticalAlign: "middle",
                        }}
                      >
                        wa.me/
                      </h6>
                      <Input
                        type={"text"}
                        id={`branchWhatsappNumber${index + 1}`}
                        value={formData.branches[index].whatsappNumber}
                        placeholder={"Eg: 601234567890"}
                        onChange={(e) => {
                          const newBranches = [...formData.branches];
                          newBranches[index].whatsappNumber = e.target.value;

                          handleInputChange("branches", newBranches);
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              {formData.branches[index].alternativeContactNumberPrefix && (
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label
                        htmlFor={`branch${index + 1}AlternativeContactNumber`}
                        style={{ fontWeight: "bold" }}
                      >
                        Alternative Contact Number{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <ECFormInput
                        context="contact_number"
                        required={true}
                        countryCode={true}
                        value={formData.branches[index].alternativeContactNumber}
                        placeholder="Eg: 11-23456789"
                        onChangeData={(value) => {
                          handleBranchInputChange(
                            index,
                            "alternativeContactNumber",
                            value
                          )
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={1}>
                    <Button
                      onClick={() => {
                        const newBranches = [...formData.branches];
                        newBranches[index].alternativeContactNumberPrefix = "";
                        newBranches[index].alternativeContactNumber = "";
                        const newHeadquarterInfo = {
                          ...formData.headquarterInfo,
                        };
                        newHeadquarterInfo.branches = newBranches;
                        handleInputChange(
                          "headquarterInfo",
                          newHeadquarterInfo
                        );
                      }}
                      style={{ backgroundColor: "none", marginTop: "30px" }}
                      className="pe-7s-close-circle"
                    />
                  </Col>
                </Row>
              )}
              {!formData.branches[index].alternativeContactNumberPrefix && (
                <div
                  className="add-new-container mb-3"
                  onClick={() => {
                    const newBranches = [...formData.branches];
                    newBranches[index].alternativeContactNumberPrefix = "+60";

                    const newHeadquarterInfo = {
                      ...formData.headquarterInfo,
                    };
                    newHeadquarterInfo.branches = newBranches;

                    handleInputChange("headquarterInfo", newHeadquarterInfo);
                  }}
                >
                  <div className="pe-7s-plus mr-2" />
                  Add alternative contact number
                </div>
              )}
              <CustomInput
                label={
                  <>
                    Address
                    <span className="text-danger">*</span>
                  </>
                }
                type={"text"}
                id={`branchAddress${index + 1}`}
                placeholder={"Enter address"}
                value={formData.branches[index].address}
                onChange={(e) => {
                  const newBranches = [...formData.branches];
                  newBranches[index].address = e.target.value;

                  handleInputChange("branches", newBranches);
                }}
              />
              <Row>
                <Col xs={3}>
                  <CustomInput
                    label={
                      <>
                        Postcode
                        <span className="text-danger">*</span>
                      </>
                    }
                    type={"text"}
                    id={`branchPostcode${index + 1}`}
                    placeholder={"Enter postcode"}
                    value={formData.branches[index].postcode}
                    onChange={(e) => {
                      const newBranches = [...formData.branches];
                      newBranches[index].postcode = e.target.value;

                      handleInputChange("branches", newBranches);
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <CustomInput
                    label={
                      <>
                        City
                        <span className="text-danger">*</span>
                      </>
                    }
                    type={"text"}
                    id={`branchCity${index + 1}`}
                    value={formData.branches[index].city}
                    onChange={(e) => {
                      const newBranches = [...formData.branches];
                      newBranches[index].city = e.target.value;

                      handleInputChange("branches", newBranches);
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <CustomInput
                    label={
                      <>
                        State
                        <span className="text-danger">*</span>
                      </>
                    }
                    type={"select"}
                    id={`branchState${index + 1}`}
                    value={formData.branches[index].state}
                    onChange={(e) => {
                      const selectedState = statesList.find(
                        (state) => state.name === e.target.value
                      );
                      const newBranches = [...formData.branches];
                      newBranches[index].state = selectedState.name;
                      newBranches[index].stateId = selectedState.id;

                      handleInputChange("branches", newBranches);
                    }}
                  >
                    <option value="" disabled>
                      Select state
                    </option>
                    {statesList.map((state) => (
                      <option id={state.id} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
                <Col xs={3}>
                  <CustomInput
                    label={
                      <>
                        Country
                        <span className="text-danger">*</span>
                      </>
                    }
                    type={"select"}
                    id={`branchCountry${index + 1}`}
                    value={formData.branches[index].country}
                    onChange={(e) => {
                      const selectedCountry = countriesList.find(
                        (country) => country.name === e.target.value
                      );
                      const newBranches = [...formData.branches];
                      newBranches[index].country = selectedCountry.name;
                      newBranches[index].countryId = selectedCountry.id;
                      handleInputChange("branches", newBranches);

                      getState(selectedCountry.id);
                    }}
                  >
                    <option value="" disabled>
                      Select state
                    </option>
                    {countriesList.map((country) => (
                      <option id={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}

        <Card className="main-card mb-3">
          <CardBody>
            <div
              className="add-new-container"
              onClick={() => {
                const newBranches = [...formData.branches];
                newBranches.push({
                  email: "",
                  contactNumberPrefix: "+60",
                  contactNumber: "",
                  whatsappNumber: "",
                  alternativeContactNumberPrefix: "",
                  alternativeContactNumber: "",
                  address: "",
                  postcode: "",
                  city: "",
                  state: "",
                  stateId: null,
                  country: "",
                  countryId: null,
                });
                handleInputChange("branches", newBranches);
              }}
            >
              <div className="pe-7s-plus mr-2" />
              Add another branch
            </div>
          </CardBody>
        </Card>
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => handleSaveDraft()}
              >
                {mode === "Create" ? "Save as Draft" : "Save"}
              </button>
            </div>
            <div className="col-auto" style={{ padding: "6px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={decrementStep}
              >
                Back
              </button>
            </div>
            {(mode === "Create" || formData?.status === "draft") && (
              <div className="col-auto" style={{ padding: "6px" }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => validateStep(step, formData, setStep)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderStep5 = () => {
    return (
      <>
        <Row>
          <Col xs={8}>
            <Card className="main-card mb-3">
              <CardBody>
                <div className="d-flex mb-3">
                  <div className="step-graphic mr-2 d-flex align-items-center justify-content-center">
                    {step + 1}
                  </div>
                  <h4 className="font-weight-bold">Purchase Summary</h4>
                </div>
                <FormGroup>
                  <Label htmlFor={"plan"} style={{ fontWeight: "bold" }}>
                    {formData.planType === "Ads Plan"
                      ? "Choose an affordable plan that suits you"
                      : "Choose your favorite Ad Feature"}
                  </Label>
                  <div
                    className="d-flex flex-wrap"
                    style={{
                      gap: "1rem",
                    }}
                  >
                    {planOptions.map((option) => (
                      <div
                        className={`single-select-option ${
                          formData.planType === option ? "selected" : ""
                        }`}
                        onClick={() => {
                          handleInputChange("planType", option);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                </FormGroup>

                {formData.planType === "Ads Plan" && (
                  <>
                    <FormGroup>
                      <Label htmlFor={"package"} style={{ fontWeight: "bold" }}>
                        Select Plan <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex flex-column">
                        {packageList.map((option) => (
                          <div
                            className={`package-option ${
                              formData.package === option.id ? "selected" : ""
                            }`}
                            onClick={(e) => {
                              if (e.target.id === "preview_post") {
                                return;
                              }

                              handleInputChange("package", option.id);
                            }}
                          >
                            <div>
                              <span className="font-weight-bold mr-2">
                                {option.name.en}
                              </span>
                              <span
                                className="font-weight-bold"
                                style={{ color: "#334E7C" }}
                              >
                                {option.display_price}
                              </span>
                            </div>
                            <span style={{ color: "#595959" }}>
                              {option.plans
                                .map(
                                  (plan) =>
                                    `${plan.name.en} (${plan.detail.en})`
                                )
                                .join(", ")}
                            </span>
                            {option.id === PACKAGE_CONST_PLAN_C_ID &&
                              formData.package === option.id && (
                                <Select
                                  id="regions"
                                  className="mt-2"
                                  options={regionsList}
                                  placeholder={
                                    "Select ONE regional Facebook only"
                                  }
                                  onChange={(selectedRegion) => {
                                    setPackageRegionId(selectedRegion.value);
                                    setPackageRegionName(selectedRegion.label);
                                  }}
                                />
                              )}

                            <div class="d-flex">
                              <div
                                id="preview_post"
                                className="d-flex mt-2 align-items-center"
                                style={{ gap: "0.2rem", cursor: "pointer" }}
                                onClick={() => {
                                  onChangeHOC(true, "preview");
                                  setSelectedPlan(option);
                                }}
                              >
                                <i className="pe-lg pe-7s-look blue-filter" />
                                Preview Post
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  </>
                )}
                {formData.planType && (
                  <FormGroup>
                    <Label htmlFor={"addons"} style={{ fontWeight: "bold" }}>
                      Add Ons{" "}
                      <span className="font-weight-normal text-secondary">
                        (Multi-select)
                      </span>
                    </Label>
                    <div className="upgrade-form">
                      {[...new Set(planList.map((plan) => plan.category))]
                        .filter((e) => e !== "PlanC")
                        .map((category) => (
                          <div className="each-selection">
                            <span className="font-weight-bold">
                              {categoryMappings[category] || category}
                            </span>
                            {planList
                              .filter((plan) => plan.category === category)
                              .map((plan) => {
                                if (
                                  plan.name.en === "Editing" &&
                                  plan.category === "General"
                                ) {
                                  return;
                                }

                                if (
                                  formData.planType === "Ads Plan" &&
                                  plan.name.en === "Posting"
                                ) {
                                  return;
                                }

                                return (
                                  <div>
                                    <div className="selection-row">
                                      <div className="selection-item">
                                        <Input
                                          style={{
                                            ...([11, 12, 1].includes(
                                              plan.id
                                            ) && {
                                              pointerEvents: "none",
                                              tabIndex: -1,
                                            }),
                                          }}
                                          name={`${category}${plan.name.en}Checked`}
                                          id={`${category}${plan.name.en}Checked`}
                                          className="selection-item-checkbox"
                                          type="checkbox"
                                          checked={
                                            formData.plan.find(
                                              (e) => e.id === plan.id
                                            )?.quantity ?? 0
                                          }
                                          onChange={async (ev) => {
                                            handleInputChange(
                                              "plan",
                                              planList.map((e) => ({
                                                ...(formData.plan.find(
                                                  (f) => f.id === e.id
                                                ) ?? e),
                                                ...(plan.id === e.id && {
                                                  quantity: ev.target.checked
                                                    ? 1
                                                    : 0,
                                                }),
                                              }))
                                            );
                                            if (
                                              plan.id ===
                                                PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID &&
                                              ev.target.checked
                                            )
                                              handleIsShowPurchaseJobMachaFacebookPinPostModal();
                                            if (
                                              plan.id ===
                                                PACKAGE_CONST_FACEBOOK_PLAN_ID &&
                                              !ev.target.checked
                                            )
                                              handleIsShowPurchaseJobMachaFacebookPinPostModal(
                                                null,
                                                true
                                              );
                                          }}
                                        />
                                        <div className="item-name-group">
                                          <div className="item-name">
                                            <b>{plan.name.en}</b> (
                                            {plan.detail.en}){" "}
                                            <i
                                              className="pe-lg pe-7s-look blue-filter"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                onChangeHOC(true, "preview");
                                                setSelectedPlan({
                                                  plans: [plan],
                                                });
                                              }}
                                            />
                                          </div>
                                          <b className="item-price">
                                            RM {plan.price[0].amount.toFixed(2)}
                                          </b>
                                        </div>
                                      </div>
                                      <InputGroup
                                        className="quantity-selector"
                                        style={{
                                          ...([
                                            11,
                                            12,
                                            1,
                                            PACKAGE_CONST_FACEBOOK_PLAN_ID,
                                          ].includes(plan.id) && {
                                            pointerEvents: "none",
                                            tabIndex: -1,
                                          }),
                                        }}
                                      >
                                        <Button
                                          outline
                                          onClick={async () => {
                                            handleInputChange(
                                              "plan",
                                              planList.map((e) => ({
                                                ...(formData.plan.find(
                                                  (f) => f.id === e.id
                                                ) ?? e),
                                                quantity: Math.max(
                                                  0,
                                                  (formData.plan.find(
                                                    (f) => f.id === e.id
                                                  )?.quantity ?? 0) +
                                                    (e.id === plan.id ? -1 : 0)
                                                ),
                                              }))
                                            );
                                            if (
                                              [
                                                PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID,
                                                PACKAGE_CONST_FACEBOOK_PLAN_ID,
                                              ].includes(plan.id)
                                            )
                                              handleIsShowPurchaseJobMachaFacebookPinPostModal(
                                                true
                                              );
                                          }}
                                        >
                                          -
                                        </Button>
                                        <Input
                                          type="number"
                                          name={`${category}${plan.name.en}Quantity`}
                                          id={`${category}${plan.name.en}Quantity`}
                                          value={
                                            formData.plan.find(
                                              (e) => e.id === plan.id
                                            )?.quantity ?? 0
                                          }
                                          onChange={async (evt) => {
                                            handleInputChange(
                                              "plan",
                                              planList.map((e) => ({
                                                ...(formData.plan.find(
                                                  (f) => f.id === e.id
                                                ) ?? e),
                                                quantity:
                                                  e.id === plan.id
                                                    ? parseInt(
                                                        evt.target?.value
                                                      ) ?? 0
                                                    : formData.plan.find(
                                                        (f) => f.id === e.id
                                                      )?.quantity ?? 0,
                                              }))
                                            );
                                            if (
                                              plan.id ===
                                              PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID
                                            )
                                              handleIsShowPurchaseJobMachaFacebookPinPostModal();
                                          }}
                                        />
                                        <Button
                                          outline
                                          onClick={async () => {
                                            handleInputChange(
                                              "plan",
                                              planList.map((e) => ({
                                                ...(formData.plan.find(
                                                  (f) => f.id === e.id
                                                ) ?? e),
                                                quantity:
                                                  (formData.plan.find(
                                                    (f) => f.id === e.id
                                                  )?.quantity ?? 0) +
                                                  (e.id === plan.id ? 1 : 0),
                                              }))
                                            );
                                            if (
                                              plan.id ===
                                              PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID
                                            )
                                              handleIsShowPurchaseJobMachaFacebookPinPostModal();
                                          }}
                                        >
                                          +
                                        </Button>
                                      </InputGroup>
                                    </div>

                                    {plan.region?.length > 0 && (
                                      <Select
                                        className="pt-2"
                                        isMulti
                                        id="region"
                                        placeholder="Select"
                                        options={plan.region.map((e) => ({
                                          label: e.name.en,
                                          value: e.id,
                                        }))}
                                        onChange={(selectedOption) => {
                                          handleInputChange(
                                            "plan",
                                            planList.map((e) => ({
                                              ...(formData.plan.find(
                                                (f) => f.id === e.id
                                              ) ?? e),
                                              ...(e.id === plan.id && {
                                                selected_region_ids:
                                                  selectedOption.map(
                                                    (e) => e.value
                                                  ),
                                                selected_region_ids_raw:
                                                  selectedOption,
                                                quantity: selectedOption.length,
                                              }),
                                            }))
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        ))}
                    </div>
                  </FormGroup>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xs={4}>
            <Card className="main-card mb-3">
              <CardBody>
                <div className="d-flex flex-column">
                  <span className="font-weight-bold mb-2">Price Details</span>
                  {formData.planType === "Ads Plan" && formData.package && (
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between">
                        <span className="font-weight-bold">
                          <div
                            className="pe-7s-check"
                            style={{
                              color: "var(--primary)",
                            }}
                          ></div>{" "}
                          {
                            packageList.find(
                              (packageItem) =>
                                packageItem.id === formData.package
                            ).name.en
                          }
                        </span>
                        <span className="font-weight-bold">
                          RM{" "}
                          {
                            packageList.find(
                              (packageItem) =>
                                packageItem.id === formData.package
                            ).price_list.non_agent.amount
                          }
                        </span>
                      </div>
                      {packageList
                        .find(
                          (packageItem) => packageItem.id === formData.package
                        )
                        .plans.map((plan) => (
                          <div className="d-flex justify-content-between">
                            <span className="text-secondary">
                              {plan.name.en}
                            </span>
                            <span className="text-secondary">
                              {plan.detail.en}
                            </span>
                          </div>
                        ))}
                      {formData.package === PACKAGE_CONST_PLAN_C_ID && (
                        <div className="d-flex justify-content-between">
                          <span className="text-secondary">Region</span>
                          <span className="text-secondary">
                            {packageRegionName}
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {formData.plan.filter((e) => e.quantity > 0).length > 0 && (
                    <div className="d-flex justify-content-between">
                      <span className="font-weight-bold">
                        Add Ons x
                        {formData.plan.reduce(
                          (a, e) => a + (e?.quantity ?? 0),
                          0
                        )}
                      </span>
                    </div>
                  )}

                  {formData.plan
                    .filter((e) => e.quantity > 0)
                    .map((item) => (
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between">
                          <span className="font-weight-bold">
                            <div
                              className="pe-7s-check"
                              style={{
                                color: "var(--primary)",
                              }}
                            />{" "}
                            {item.name.en} x{item.quantity}
                          </span>
                          <span className="font-weight-bold">
                            RM{" "}
                            {(item.quantity * item.price[0].amount).toFixed(2)}
                          </span>
                        </div>

                        <span className="text-secondary">{item.detail.en}</span>
                        {item.selected_region_ids_raw && (
                          <div className="d-flex justify-content-between">
                            <span className="text-secondary">Region</span>
                            <span className="text-secondary">
                              {item.selected_region_ids_raw
                                .map((e) => e.label)
                                .join()}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                  <div className="my-2 border-bottom border-secondary" />

                  <span className="font-weight-bold">Promo Code</span>
                  <div className="d-flex align-items-center">
                    <CustomInput
                      type={"text"}
                      id={"promoCode"}
                      placeholder="Enter Promo Code"
                      value={formData.promoCode}
                      onChange={(e) =>
                        handleInputChange("promoCode", e.target.value)
                      }
                      style={{
                        width: "100%",
                        marginRight: "2rem",
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ height: "fit-content" }}
                      onClick={() => {
                        if (!formData.promoCode) {
                          requestError("No voucher code entered");
                        } else {
                          validateVoucher(formData.promoCode, (payload) => {
                            if (payload.data) {
                              if (payload.isValid) {
                                requestSuccess(
                                  "Voucher code applied successfully"
                                );
                                handleInputChange(
                                  "promoCodeData",
                                  payload.data
                                );
                              } else {
                                handleInputChange("promoCodeData", "");
                                requestError("Invalid voucher code");
                              }
                            }
                          });
                        }
                      }}
                    >
                      Apply
                    </button>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">Sub-Total</span>
                    <span className="font-weight-bold">
                      RM {calculateSubTotal().toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold text-secondary">
                      Discount
                    </span>
                    <span className="font-weight-bold text-secondary">
                      -RM {getDiscount().toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">Taxes (8%)</span>
                    <span className="font-weight-bold">
                      RM {getTax().toFixed(2)}
                    </span>
                  </div>
                  <div className="my-2 border-bottom border-secondary" />
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">Total:</span>
                    <h4
                      className="font-weight-bold"
                      style={{ color: "#334E7C" }}
                    >
                      RM {calculateECTotal().toFixed(2)}
                    </h4>
                  </div>
                  <FormGroup check className="my-2">
                    <Input
                      id="isBundlePrint"
                      type="checkbox"
                      checked={formData.isBundlePrint}
                      onChange={(e) => {
                        handleInputChange("isBundlePrint", e.target.checked);
                        if (!e.target.checked) {
                          handleInputChange("printAdsId", "");
                          handleInputChange("printPrice", 0);
                        }
                      }}
                    />
                    <Label check className="font-weight-bold">
                      Bundle with Print?
                    </Label>
                  </FormGroup>
                  {formData.isBundlePrint && (
                    <>
                      <CustomInput
                        label="Print Ads Reference"
                        type={"text"}
                        id={"printAdsId"}
                        placeholder={"Enter Reference Number"}
                        value={formData.printAdsId}
                        onChange={(e) =>
                          handleInputChange("printAdsId", e.target.value)
                        }
                      />
                      <CustomInput
                        label={
                          <>
                            Print Ads Price (Inc Tax){" "}
                            <span className="text-secondary font-weight-normal">
                              (Inc SST)
                            </span>
                          </>
                        }
                        type={"number"}
                        id={"printPrice"}
                        placeholder={"Enter Price"}
                        value={formData.printPrice}
                        onChange={(e) =>
                          handleInputChange(
                            "printPrice",
                            parseInt(e.target.value) || 0
                          )
                        }
                      />
                      <div className="my-2 border-bottom border-secondary" />
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="font-weight-bold">Grand Total:</span>
                        <h4
                          className="font-weight-bold"
                          style={{ color: "#334E7C" }}
                        >
                          RM{" "}
                          {(calculateECTotal() + formData.printPrice).toFixed(
                            2
                          )}
                        </h4>
                      </div>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
            <div className="container-fluid">
              <div className="row">
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={handleSubmit}
                >
                  Submit Post
                </button>
              </div>
              <div className="row mt-2">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={() => onChangeHOC(true, "showPreviewModal")}
                >
                  Preview Post
                </button>
              </div>
              <div className="row mt-2">
                <button
                  type="button"
                  className="btn btn-link w-100"
                  onClick={handleSaveDraft}
                >
                  Save as Draft
                </button>
              </div>
            </div>
          </Col>
        </Row>
        {/* <PaymentGatewayForm id="jobads-payment-gateway" value={payment_key} /> */}
        <CustomModal
          isOpen={paymentLinkModalOpen}
          onClose={() => onClosePaymentLinkModal()}
          title={
            !paymentLink ? (
              <>
                <div className="pe-7s-info" /> Do you want to generate payment
                link?
              </>
            ) : (
              <>
                <div className="pe-7s-check" /> Payment link generated
                successfully!
              </>
            )
          }
        >
          <CustomModalBody>
            {!paymentLink && <span>Please confirm this action.</span>}
            <InputGroup>
              <Input id={"paymentLink"} value={paymentLink} disabled />
              <Button
                color="link"
                disabled={!paymentLink}
                onClick={() => copyInput("paymentLink")}
                id="paymentLinkCopyButton"
              >
                <div className="pe-lg pe-7s-copy-file" />
              </Button>
              <UncontrolledTooltip
                placement="top"
                trigger="click"
                target="paymentLinkCopyButton"
                autohide
              >
                Copied!
              </UncontrolledTooltip>
            </InputGroup>
            <span className="text-secondary">
              Order No: {orderNo || "1903042001"}
            </span>
          </CustomModalBody>

          <CustomModalFooter
            rightButton={[
              {
                color: "secondary",
                outline: true,
                content: "Cancel",
                onClick: () => onClosePaymentLinkModal(),
              },
              {
                color: "primary",
                content: !paymentLink ? "Generate Payment Link" : "Done",
                onClick: () =>
                  !paymentLink
                    ? handleSubmit("paymentLink")
                    : setPaymentLinkModalOpen(false),
              },
            ]}
          />
          {loading && <LoadingOverlay />}
        </CustomModal>
        {selectedPlan && preview === true && (
          <PreviewPlanModal
            isOpen={preview}
            onClose={() => onChangeHOC(false, "preview")}
            isPackage={true}
            packages={selectedPlan?.plans ?? []}
          />
        )}
        {/* {selectedPost && (
          <PreviewJob
            selectedJob={selectedPost}
            isOpen={showPreviewModal}
            onLoad={loading}
            onToggleDownload={downloadImage}
            onClose={() => onChangeHOC(false, "showPreviewModal")}
          />
        )} */}

        <ConfirmationModal
          isOpen={isShowPurchaseJobMachaFacebookPinPostModal}
          title={
            <Trans
              context={"ADVERTISER"}
              langKey={"PLAN_IS_REQUIRED_TO_PURCHASE_FIRST"}
            />
          }
          positiveText={<Trans langKey={"CONFIRM"} />}
          positiveAction={() => {
            setIsShowPurchaseJobMachaFacebookPinPostModal(false);
          }}
          onClose={() => setIsShowPurchaseJobMachaFacebookPinPostModal(false)}
        />
      </>
    );
  };

  return (
    <>
      <div className="step-progress-container">
        {[...Array(5).keys()].map((index) => (
          <div
            className={`each-progress ${step >= index ? "fill" : ""}`}
            key={index}
          />
        ))}
      </div>
      <div className="d-flex flex-column mb-4">
        <h4 className="font-weight-bold">Purchase Summary</h4>
        <h5>
          Complete the ad description so we can match you with the right
          candidates!
        </h5>
      </div>
      {getStepComponent()}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.ProfileReducer.profile,
});

export default connect(mapStateToProps)(CreatePostForm);
