import React, { Component } from "react";
import { connect } from "react-redux";
import { Get, Put, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      voucherList: [],
      voucherPages: 1,
      voucherCount: 0,
      selectedSample: null,
      showEditSampleModal: false,
      showFilterModal: false,
      showConfirmModal: false,
      showDisableModal: false,
      showSuspendModal: false,
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getVoucher = (querystring = "") => {
      Get(
        "/vouchers?limit=10" + querystring,
        this.getVoucherSuccess,
        this.getVoucherError,
        this.load
      );
    };
    getVoucherSuccess = (payload) => {
      this.setState({
        voucherList: payload.data.rows,
        voucherCount: payload.data.count,
      });
    };
    getVoucherError = (error) => requestError(error);

    updateVoucher = (dataToSubmit) => {
      Put(
        `/vouchers/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateVoucherSuccess,
        this.updateVoucherError,
        this.load
      );
    };
    updateVoucherSuccess = () => {
      this.getVoucher();
      this.setState({
        showSuspendModal: false,
      });
      requestSuccess("Voucher updated successfully.");
    };
    updateVoucherError = (error) => requestError(error);

    // function to toggle display filter modal
    toggleShowFilterModal = () => {
      this.setState((prevState) => ({
        showFilterModal: !prevState.showFilterModal,
      }));
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          voucherList={this.state.voucherList}
          voucherPages={this.state.voucherPages}
          voucherCount={this.state.voucherCount}
          showEditSampleModal={this.state.showEditSampleModal}
          selectedSample={this.state.selectedSample}
          onLoadVoucher={this.state.loading}
          getVoucher={this.getVoucher}
          updateVoucher={this.updateVoucher}
          onChangeHOC={this.onChangeHOC}
          showFilterModal={this.state.showFilterModal}
          showConfirmModal={this.state.showConfirmModal}
          showDisableModal={this.state.showDisableModal}
          showSuspendModal={this.state.showSuspendModal}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
