import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({
  onSubmit,
  onChangeHOC,
  handleState,
  roleList,
  selectedRoleType
}) {
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userAgentCode, setUserAgentCode] = useState("");
  const [userAgentLeadId, setUserAgentLeadId] = useState("");
  const [userManagerId, setUserManagerId] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [userCreatedDateStart, setUserCreatedDateStart] = useState("");
  const [userCreatedDateEnd, setUserCreatedDateEnd] = useState("");
  const [userType, setUserType] = useState("");
  const [userManagerName, setUserManagerName] = useState("");
  const [userAgentName, setUserAgentName] = useState("");
  const [userAgentLeadName, setUserAgentLeadName] = useState("");

  const handleClear = () => {
    setUserId("");
    setUsername("");
    setUserEmail("");
    setUserRole("");
    setUserAgentCode("");
    setUserAgentLeadId("");
    setUserManagerId("");
    setUserStatus("");
    setUserType("");
    setUserManagerName("");
    setUserAgentName("");
    setUserAgentLeadName("");
    setUserCreatedDateStart("");
    setUserCreatedDateEnd("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (userId) {
      queryString += `&id=${userId}`;
    }
    if (username) {
      queryString += `&name=${username}`;
    }
    if (userEmail) {
      queryString += `&email=${userEmail}`;
    }
    if (userRole) {
      queryString += `&role_id=${userRole}`;
    }
    if (userAgentCode) {
      queryString += `&code=${userAgentCode}`;
    }
    if (userManagerId) {
      queryString += `&manager_id=${userManagerId}`;
    }
    if (userStatus) {
      queryString += `&status=${userStatus}`;
    }
    if (userCreatedDateStart) {
      queryString += `&created_at_start=${userCreatedDateStart}`;
    }
    if (userCreatedDateEnd) {
      queryString += `&created_at_end=${userCreatedDateEnd}`;
    }
    if (userType) {
      queryString += `&platform=${userType}`;
    }
    if (userManagerName) {
      queryString += `&manager_name=${userManagerName}`;
    }
    if (userAgentName) {
      queryString += `&agent_name=${userAgentName}`;
    }
    if (userAgentLeadName) {
      queryString += `&agent_lead_name=${userAgentLeadName}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-user-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="userType">User Type</Label>
                <Input
                  placeholder="Enter User Type"
                  name="userType"
                  id="userType"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                />
              </FormGroup>
            </Col>
            { selectedRoleType === 0 && <Col lg={3}>
              <FormGroup>
                <Label for="userRole">User Role</Label>
                <Input
                  type="select"
                  name="userRole"
                  id="userRole"
                  value={roleList.find(e => e.id === userRole)?.display_name}
                  onChange={(e) => setUserRole(e.target.value)}
                >
                  <option selected disabled value="">
                    Select Role
                  </option>
                  {roleList.map((role) => (
                    <option value={role.id}>{role.display_name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col> }
            <Col lg={3}>
              <FormGroup>
                <Label for="userStatus">User Status</Label>
                <Input
                  type="select"
                  name="userStatus"
                  id="userStatus"
                  value={userStatus}
                  onChange={(e) => setUserStatus(e.target.value)}
                >
                  <option disabled value="">
                    Select Status
                  </option>
                  <option>active</option>
                  <option>inactive</option>
                  <option>suspended</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userId">User ID</Label>
                <Input
                  placeholder="Enter User ID"
                  name="userId"
                  id="userId"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="username">Username</Label>
                <Input
                  placeholder="Enter Username"
                  name="username"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userEmail">Email</Label>
                <Input
                  placeholder="Enter Email"
                  name="userEmail"
                  id="userEmail"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userCreatedDateStart">Created Date (From)</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="userCreatedDateStart"
                  id="userCreatedDateStart"
                  value={userCreatedDateStart}
                  onChange={(e) => setUserCreatedDateStart(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userCreatedDateEnd">Created Date (To)</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="userCreatedDateEnd"
                  id="userCreatedDateEnd"
                  value={userCreatedDateEnd}
                  onChange={(e) => setUserCreatedDateEnd(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userAgentCode">Agent ID</Label>
                <Input
                  placeholder="Enter Agent ID"
                  name="userAgentCode"
                  id="userAgentCode"
                  value={userAgentCode}
                  onChange={(e) => setUserAgentCode(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userAgentName">Agent Name</Label>
                <Input
                  placeholder="Enter Agent Name"
                  name="userAgentName"
                  id="userAgentName"
                  value={userAgentName}
                  onChange={(e) => setUserAgentName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userAgentLeadId">Agent Lead ID</Label>
                <Input
                  placeholder="Enter Agent Lead ID"
                  name="userAgentLeadId"
                  id="userAgentLeadId"
                  value={userAgentLeadId}
                  onChange={(e) => setUserAgentLeadId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userAgentLeadName">Agent Lead Name</Label>
                <Input
                  placeholder="Enter Agent Lead Name"
                  name="userAgentLeadName"
                  id="userAgentLeadName"
                  value={userAgentLeadName}
                  onChange={(e) => setUserAgentLeadName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userManagerId">Manager ID</Label>
                <Input
                  placeholder="Enter Manager ID"
                  name="userManagerId"
                  id="userManagerId"
                  value={userManagerId}
                  onChange={(e) => setUserManagerId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userManagerName">Manager Name</Label>
                <Input
                  placeholder="Enter Manager Name"
                  name="userManagerName"
                  id="userManagerName"
                  value={userManagerName}
                  onChange={(e) => setUserManagerName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Search",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("userListPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
