"use client"
import { React, useState } from "react"
import { Button } from "reactstrap"
import { Modal, Row, Col } from "antd"
import cx from "classnames"
import { useHistory  } from "react-router-dom";
import IndividualIcon from "assets/images/individual.png";
import CompanyIcon from "assets/images/company.png";
import "./PreCreateModal.scss"
// import Trans from "components/i18next"

const PreCreateModal = (props) => {
  let { showPreCreateModal, onChangeCompaniesHOC } = props
  const history = useHistory();
  const [advertiserType, setAdvertiserType] = useState('')

  return (
    <Modal
      width="832px"
      open={showPreCreateModal}
      onCancel={() => onChangeCompaniesHOC("showCreateAdvertiser", false)}
      footer={null}
    >
      <div className="pre-create-modal">
        <h5 className="title">
        Create Advertiser Profile
        </h5>
        <p className="subtitle" style={{ paddingBottom: '6.125rem' }}>
        What is the main identity you want to use for posting an ad?
        </p>
        <Row gutter={24}>
          <Col span={12}>
            <div
              onClick={() =>
                setAdvertiserType('individual')
              }
              className={cx("options-picker", {
                "options-picker-active": advertiserType === "individual",
              })}
            >
              <div className="e-classified-sign-up__identity-card-img">
                <img src={IndividualIcon} alt="individual" />
              </div>
              <h6>
              Individual
              </h6>
              <p>
              I want to post an ad for myself.
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div
              onClick={() =>
                setAdvertiserType('company')
              }
              className={cx("options-picker", {
                "options-picker-active": advertiserType === "company",
              })}
            >
              <div className="e-classified-sign-up__identity-card-img">
                <img src={CompanyIcon} alt="company" />
              </div>
              <h6>
              Company
              </h6>
              <p>
              I want to post an ad as a company.
              </p>
            </div>
          </Col>
          <Col span={24}>
            <Button
              size="lg"
              disabled={!advertiserType}
              color="primary"
              className="w-100"
              style={{ padding: '1rem 1.5rem' }}
              onClick={() => history.push(`/advertiser-profile/create-${advertiserType}-profile`)}
            >
              Create Advertiser Profile
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
export default PreCreateModal
