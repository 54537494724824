import CKEditor from "components/Input/RTE.js";
import CustomModal from "components/Modal";
import _ from "lodash";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
	Alert,
	Button,
	Card,
	CardBody,
	FormFeedback,
	FormGroup,
	Input,
	Label,
} from "reactstrap";
import { debounce } from "utils/helper";
import PreviewInbox from "../Preview";

function CreateNewInboxForm({
	user,
	onSubmit,
	roleList,
	searchUser,
	selectedInbox,
    showPreviewModal,
    onChangeHOC
}) {
	const [userRoleList, setUserRoleList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState({
		userIdText: "",
		usernameText: "",
	});
	const [selectedDate, setSelectedDate] = useState("");
	const [title, setTitle] = useState("");
	const [tag, setTag] = useState("");
	const [message, setMessage] = useState("");
	const [alertVisible, setAlertVisible] = useState(false);

	// validation state variables
	const [titleError, setTitleError] = useState("");
	const [messageError, setMessageError] = useState("");

	useEffect(() => {
		if (selectedInbox) {
			setUserRoleList(
				_.map(selectedInbox.inbox_roles, (roleChild) => ({
					id: roleChild.id,
					roleName: roleChild.role_name,
					roleId: roleChild.role_id,
				}))
			);
			setTag(selectedInbox.tags);
			setMessage(selectedInbox.message);
			setSelectedDate(new Date(selectedInbox.published_at));
			setTitle(selectedInbox.title);

			setUserList(
				_.map(selectedInbox.inbox_recipients, (userChild) => ({
					id: userChild.id,
					username: userChild.user_name,
					userId: userChild.user_id,
				}))
			);
		}
	}, [selectedInbox]);

	const onDismissAlert = () => setAlertVisible(false);

	const setUserRoleInput = (selectedRole, selectedRoleId) => {
		if (!userRoleList.some((userRole) => userRole.roleId === selectedRoleId)) {
			setUserRoleList([
				...userRoleList,
				{ roleName: selectedRole, roleId: selectedRoleId },
			]);
		}
	};

	const deleteUserRoleInput = (indexToDelete) => {
		const updatedUserRoleList = userRoleList.filter(
			(_, index) => index !== indexToDelete
		);
		setUserRoleList(updatedUserRoleList);
	};

	const addUserList = (userInput) => {
		if (userInput.userIdText && userInput.usernameText) {
			if (
				!userList.some(
					(user) =>
						user.userId === userInput.userIdText &&
						user.username === userInput.usernameText
				)
			) {
				setUserList([
					...userList,
					{ userId: userInput.userIdText, username: userInput.usernameText },
				]);
			}
		}
	};

	const deleteUserList = (indexToDelete) => {
		const updatedUserList = userList.filter(
			(_, index) => index !== indexToDelete
		);
		setUserList(updatedUserList);
	};

	const debouncedSearchUser = debounce(searchUser, 300); // Adjust the debounce delay as needed

	const promiseOptions = async (inputValue) => {
		try {
			const results = await new Promise((resolve, reject) => {
				debouncedSearchUser(inputValue, resolve, reject);
			});

			return results.map((result) => ({
				label: result.name,
				value: { id: result.id, name: result.name },
			}));
		} catch (error) {
			// Handle error appropriately
			throw error;
		}
	};

	const validateForm = () => {
		// Reset all error states
		setTitleError("");
		setMessageError("");

		let isValid = true;

		// Validate title
		if (!title) {
			setTitleError("Title is required.");
			isValid = false;
		}
		if (!message) {
			setMessageError("Description is required.");
			setAlertVisible(true);
		}

		// Add more validation rules for other fields as needed

		return isValid;
	};

	const handleSubmit = () => {
		// Check form validation
		if (validateForm()) {
			// Call the onSubmit callback if the form is valid
			onSubmit({
				id: selectedInbox.id,
				published_at: !!selectedDate ? selectedDate.toISOString() : null,
				title: title,
				tags: !tag ? null : tag,
				user_ids: userList.map((user) => user.userId),
				user_roles: userRoleList.map((role) => role.roleId),
				status: "sent",
				message: message,
				open_count: 0,
				click_count: 0,
				created_by: user.id,
			});
		}
	};

    const handlePreview = () => {
        const inbox = selectedInbox;
        inbox.message = message;

        onChangeHOC("selectedInbox", inbox);
        onChangeHOC("showPreviewModal", true);
    }

	return (
		<>
			<Card className="main-card mb-3">
				<CardBody>
					<span style={{ fontWeight: "bold" }}>Message ID: M00001</span>
					<br></br>
					<br></br>
					<FormGroup>
						<Label style={{ fontWeight: "bold" }} for="userRole">
							Recipients (By User Role)
						</Label>
						<Select
							name="userRoleSelect"
							placeholder="Select..."
							isClearable
							isSearchable
							onChange={(newValue) => {
								if (!!newValue) {
									setUserRoleInput(newValue.label, newValue.value);
								}
							}}
							options={roleList.map((role) => ({
								label: role.display_name,
								value: role.id,
							}))}
						/>
					</FormGroup>
					<span>
						<ul style={{ paddingLeft: "1%" }}>
							{userRoleList.map((userRole, index) => {
								return (
									<li key={index}>
										<div
											style={{
												margin: "0.2%",
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
											}}>
											<span style={{ color: "blue" }}>{userRole.roleName}</span>
											<Button
												id={index}
												onClick={() => deleteUserRoleInput(index)}
												style={{ backgroundColor: "none" }}
												className="pe-7s-close-circle"
											/>
										</div>
									</li>
								);
							})}
						</ul>
					</span>

					<FormGroup>
						<Label style={{ fontWeight: "bold" }}>
							Recipients (By Specific Users)
						</Label>
						{userList.map((user, index) => {
							return (
								<div
									key={index}
									style={{
										margin: "0.2%",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Input
										type={"text"}
										style={{ width: "45%" }}
										value={user.userId}
										readOnly
									/>
									<Input
										type={"text"}
										style={{ width: "45%" }}
										value={user.username}
										readOnly
									/>
									<Button
										onClick={() => deleteUserList(index)}
										style={{ backgroundColor: "none" }}
										className="pe-7s-close-circle"
									/>
								</div>
							);
						})}
					</FormGroup>

					<FormGroup>
						<AsyncSelect
							cacheOptions
							defaultOptions
							loadOptions={promiseOptions}
							styles={{ input: (styles) => ({ ...styles, width: "100%" }) }}
							onChange={(newValue) => {
								if (!!newValue) {
									setSelectedUser({
										userIdText: newValue.value.id,
										usernameText: newValue.value.name,
									});
								} else {
									setSelectedUser({
										userIdText: "",
										usernameText: "",
									});
								}
							}}
							isClearable
						/>
					</FormGroup>
					<button
						type="button"
						className="btn btn-lg btn-block"
						style={{ border: "2px dotted #000" }}
						onClick={() => addUserList(selectedUser)}>
						<div className="pe-lg pe-7s-plus"></div> Add Specific User
					</button>
					<br></br>
					<FormGroup>
						<Label style={{ fontWeight: "bold" }}>Schedule Date & Time</Label>
						<div
							style={{
								position: "relative",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<div style={{ width: "96%" }}>
								<DatePicker
									showTimeSelect
									dateFormat="dd-MM-yyyy HH:mm"
									showYearDropdown
									className="form-control"
									selected={selectedDate}
									placeholderText={"Select Date to Send Message"}
									onChange={(val) => {
										setSelectedDate(val);
									}}
								/>
							</div>

							<div className="pe-2x pe-7s-date"></div>
						</div>
					</FormGroup>

					<FormGroup>
						<Label style={{ fontWeight: "bold" }}>Title</Label>
						<Input
							type={"text"}
							value={title}
							placeholder={"Enter Message Title"}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
							invalid={!!titleError}
						/>
						<FormFeedback>{titleError}</FormFeedback>
					</FormGroup>

					<FormGroup>
						<Label style={{ fontWeight: "bold" }} for="tag">
							Tag
						</Label>
						<Input
							type={"select"}
							name="tag"
							value={tag}
							selected={tag}
							placeholder="Select..."
							onChange={(e) => {
								setTag(e.target.value);
							}}>
							<option value=""></option>
							<option value="candidate">Candidate</option>
							<option value="advertiser">Advertiser</option>
						</Input>
					</FormGroup>

					<FormGroup>
						<Label style={{ fontWeight: "bold" }}>Descriptions</Label>
						<CKEditor
							data={message}
							onChange={(event, editor) => {
								const data = editor.getData();
								setMessage(data);
							}}
						/>
					</FormGroup>
					<Alert color="danger" isOpen={alertVisible} toggle={onDismissAlert}>
						{messageError}
					</Alert>
				</CardBody>
			</Card>
			<div className="container-fluid">
				<div className="row justify-content-end">
					<div className="col-auto" style={{ padding: "6px" }}>
						<button type="button" className="btn btn-outline-primary">
							Save as Draft
						</button>
					</div>
					<div className="col-auto" style={{ padding: "6px" }}>
						<button type="button" className="btn btn-outline-primary" onClick={handlePreview}>
							Preview
						</button>
					</div>
					<div className="col-auto" style={{ padding: "6px" }}>
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleSubmit}>
							<i className="pe-lg pe-7s-paper-plane"></i> Send
						</button>
					</div>
				</div>
			</div>
            
			<CustomModal
				size={"xl"}
				title={"Message Preview"}
				onClose={() => onChangeHOC("showPreviewModal", false)}
				isOpen={showPreviewModal}>
				<PreviewInbox selectedInbox={selectedInbox} />
			</CustomModal>
		</>
	);
}

const mapStateToProps = (state) => ({
	user: state.ProfileReducer.profile,
});

export default connect(mapStateToProps)(CreateNewInboxForm);
