import { CopyOutlined } from "@ant-design/icons";
import { Input, Modal, Tooltip } from "antd";
import { Fragment } from "react";

const socialSharingMap = [
	{
		label: "Facebook",
		icon: `/facebook.png`,
		url: (location) => {
			return `http://www.facebook.com/sharer/sharer.php?u=${location}`;
		},
	},
	{
		label: "Whatsapp",
		icon: `/whatsapp.png`,
		url: (location) => {
			return `https://api.whatsapp.com/send?text=${location}`;
		},
	},
	{
		label: "Telegram",
		icon: `/telegram.png`,
		url: (location) => {
			return `https://t.me/share/url?url=${location}`;
		},
	},
];

export default function ShareComponentDesktop({ title, open, shareLink, onCancel }) {
	return (
		<Modal
			centered
			open={open}
			footer={null}
			onCancel={() => onCancel()}
			title={title}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					gap: "9%",
				}}>
				{socialSharingMap.map((socialChild, socialIndex) => (
					<Fragment key={socialIndex}>
						<a
							key={socialIndex}
							href={ `${socialChild.url( shareLink || window.location)}`}
							rel="noreferrer"
							style={{ textDecoration: "unset" }}
							target="_blank">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									color: "#000000A6",
								}}>
								<img width={48} height={48} src={socialChild.icon} />
								<div style={{ marginTop: "7px" }}>{socialChild.label}</div>
							</div>
						</a>
					</Fragment>
				))}
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "2vh",
				}}>
				<Input
					value={ shareLink || decodeURI(window.location)}
					suffix={
						<Tooltip trigger={["click"]} title={"已复制!"}>
							<CopyOutlined
								style={{
									fontSize: "22px",
									cursor: "pointer",
								}}
								onClick={() => navigator.clipboard.writeText(window.location)}
							/>
						</Tooltip>
					}
				/>
			</div>
		</Modal>
	);
}
