import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import HOC from "../action";
import CreateHOC from "../CreateInbox/action";
import Form from "./Form.js";

class EditInbox extends Component {
	componentDidMount = () => {
		this.props.getRole();
		const currentURL = window.location.href;
		const urlParts = currentURL.split("/");
		const id = urlParts[urlParts.length - 1];

		id && this.props.getSelectedInbox(id);
	};

	render = () => {
		const { roleList, updateInbox, searchUser, selectedInbox, showPreviewModal, onChangeHOC } = this.props;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<PageTitle heading="Manage Inbox / <b>Edit Inbox</b>"></PageTitle>
								<h5>Edit Inbox</h5> <br></br>
								<Form
									selectedInbox={selectedInbox}
									onSubmit={updateInbox}
									roleList={roleList}
									searchUser={searchUser}
                                    showPreviewModal={showPreviewModal}
                                    onChangeHOC={onChangeHOC}
								/>
							</div>
						</div>
					</div>
					<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(CreateHOC, HOC)(EditInbox);
