import React, { useState } from "react";
import NoDataImg from "assets/images/no-file.jpg";
import { BiZoomIn } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";

import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModal from "components/Modal";
import ChinaPressModalFooter from "components/Modal/footer";
import InputFile from "components/Input/File";
import "./index.scss";

function Image({ imageData, onRemove, onAddFile, returnBase64 = false }) {
  const [selectedView, setSelectedView] = useState("");
  const [showAddImg, setShowAddImg] = useState(false);
  return (
    <>
      <Card className="mt-4">
        <CardHeader className="d-flex">
          Gallery
          <Button
            className="ml-auto"
            color="primary"
            onClick={() => setShowAddImg(true)}
          >
            Add Image
          </Button>
        </CardHeader>
        <CardBody>
          {imageData && imageData.length < 1 && (
            <div className={"text-center"} style={{ width: "max-content" }}>
              <small className="d-block">No Data Available</small>
            </div>
          )}
          {imageData?.[0] && (
            <div className="mywheels-model_img_cont">
              {imageData.map((imageChild, imageIndex) => (
                <div
                  key={`imageChild${imageIndex}`}
                  className="mywheels-model_img-item"
                >
                  <div className="mywheels-model_img-item-content_cont">
                    <img
                      style={{ objectFit: "cover" }}
                      src={imageChild.url}
                      alt={`model-image-${imageIndex}`}
                    />
                    <div className="mywheels-model_img_actions-cont">
                      <div
                        className="mywheels-model_img_action mywheels-model_img_action-icon bg-danger"
                        id="delete"
                        onClick={() => onRemove(imageChild, imageIndex)}
                      >
                        <FaTrash />
                        <UncontrolledTooltip target="delete">
                          Delete
                        </UncontrolledTooltip>
                      </div>
                      <div
                        className="mywheels-model_img_action bg-warning mywheels  mywheels-model_img_action-icon-hover"
                        onClick={() => setSelectedView(imageChild.url)}
                      >
                        <BiZoomIn />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardBody>
      </Card>
      <ChinaPressModal
        isOpen={selectedView}
        onClose={() => setSelectedView("")}
      >
        {selectedView && (
          <ChinaPressModalBody>
            <img src={selectedView} alt={"view_model_img"} className="w-100" />
          </ChinaPressModalBody>
        )}
      </ChinaPressModal>
      <ChinaPressModal isOpen={showAddImg} onClose={() => setShowAddImg(false)}>
        <InputFile
          returnBase64={returnBase64}
          onLoadAsset={true}
          setBase64Callback={param => {
            onAddFile(param)
            setShowAddImg(false)  
          }}
          onSelectImage={param => {
            onAddFile(param)
            setShowAddImg(false)
          }} />
        <ChinaPressModalFooter
          rightButton={[
            {
              color: "primary",
              content: "Submit",
              onClick: () => {},
            },
          ]}
        />
      </ChinaPressModal>
    </>
  );
}

export default Image;
