
import { useEffect } from "react";
import JobPrefForm from "./JobPrefForm";
import ResetPW from "./ResetPW";
import HOC from "./action";

function Settings(props) {

	useEffect(() => {
		props.getSpecializations();
	}, []);

	return (
		<>
			<h5 className="mb-4 e-classified-cont-header">
				Account Settings
			</h5>
			<JobPrefForm {...props} />
			<ResetPW {...props} />
		</>
	);
}

export default HOC(Settings);
