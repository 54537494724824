import React, { Component } from "react";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Card, CardBody } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import _ from "lodash";
import { RolesPermission } from "Config";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import Pagination from "components/Pagination";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import FilterForm from "./Form";
import ApplicantRecommendationCandidateAPI from "./action.js";
import { CheckUserRolesPermissons } from "actions/roles";

class ApplicantRecommendationCandidate extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(12)) {
			return this.props.history.push("/");
		}
		// Malaysia code = 136
		this.props.getCandidate();
		this.props.getStates(136);
	};

	componentDidUpdate = (pp) => {
		if (pp.query !== this.props.query) {
			this.props.getCandidate(this.props.query);
		}

		if (pp.currentPage !== this.props.currentPage) {
			this.props.getCandidate(this.props.query);
		}
	};

	renderCandidateTable = () => {
		return (
			<>
				<CustomTable
					hidePagination={true}
					data={this.props.candidateList}
					columnsContent={[
						{
							Header: "User ID",
							accessorKey: "userId",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.user_id}</>,
						},
						{
							Header: "Name",
							accessorKey: "candidateName",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val?.user?.name ?? "-"}</>,
						},
						{
							Header: "Contact Number",
							accessorKey: "candidateContactNumber",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.user?.mobile_number ?? "-"}</>,
						},
						{
							Header: "Whatsapp",
							accessorKey: "candidateWhatsappNumber",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.user?.whatsapp_number ?? "-"}</>,
						},
						{
							Header: "Email",
							accessorKey: "candidateEmail",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.user?.email ?? "-"}</>,
						},
						{
							Header: "Location",
							accessorKey: "location",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => (
								<>{`${val.city} - ${val.state}, ${val.country}`}</>
							),
						},
						{
							Header: "Recommended Count",
							accessorKey: "candidateRecommendedJobCount",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.jobads_recommend_count}</>,
						},
					]}
					menuContent={[
						{
							menuID: "RecommendJob",
							menuTooltip: "Recommend Job",
							onClick: (val) => {
								this.props.onChangeHOC("showRecommendModal", true);
								this.props.onChangeHOC("selectedCandidate", val);
							},
						},
					]}
				/>
				<Pagination
					pageIndex={this.props.currentPage}
					totalCount={this.props.candidateCount}
					onChangePage={(val) => this.props.onChangeHOC("currentPage", val)}
				/>
			</>
		);
	};

	renderFilterModal = () => {
		return (
			<CustomModal
				size={"lg"}
				title="Candidate List Filter"
				isOpen={this.props.showFilterModal}
				onClose={() => this.props.onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={(val) => {
						this.props.onChangeHOC("showFilterModal", false);
						this.props.onChangeHOC("query", val);
					}}
					{...this.props}
				/>
			</CustomModal>
		);
	};

	renderRecommendModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showRecommendModal}
				onClose={() => this.props.onChangeHOC("showRecommendModal", false)}
				onClickConfirm={() => {
					this.props.onChangeHOC("showRecommendModal", false);
					this.props.history.push(
						`/applicant-recommendation-candidate/post/${this.props.selectedCandidate.id}`
					);
				}}
				title={`Are you sure you want to provide recommendation to this Candidate? Name: ${this.props.selectedCandidate?.user?.name}`}
				message="Please confirm this action."
				confirmContent="Yes, Recommend"
				confirmColor="success"
			/>
		);
	};

	render = () => {
		const { onChangeHOC } = this.props;
		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="求职者推荐 - For Candidate"
										buttons={[
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>{this.renderCandidateTable()}</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderRecommendModal()}
									{this.props.onLoadCandidate && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ApplicantRecommendationCandidateAPI)(
	ApplicantRecommendationCandidate
);
