import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

const UploadImage = ({ uploadPdf, oAddCert }) => {
	let filePond = React.useRef();

	const onUploadDocs = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			let files = e.target.files;
			Object.keys(e.target.files).map((key) => {
				let reader = new FileReader();
				reader.onload = (e) => {
					uploadPdf(
						{
							base64: e.target.result,
							filename: files[key].name,
							title: files[key].name,
							alt: files[key].name,
							platforms: ["jobmacha"],
						},
						oAddCert
					);
				};
				reader.readAsDataURL(e.target.files[key]);
			});
		}
	};

	return (
		<>
			<div
				className="mb-4"
				style={{
					display: "grid",
					placeItems: "center",
					border: "1px solid #D9D9D9",
					borderRadius: "0.625rem",
					borderStyle: "dashed",
					padding: "1rem",
				}}
				onClick={() => filePond.click()}>
				<AiOutlineCloudUpload style={{ width: "1.5rem", height: "1.5rem " }} />
				<input
					ref={(ref) => (filePond = ref)}
					style={{ display: "none" }}
					type="file"
					accept="application/pdf"
					multiple={false}
					onChange={(e) => onUploadDocs(e)}
				/>
				<p className="e-classified-cont-header mb-2">
					Upload File
				</p>
				<p style={{ color: "#00000073" }} className="text-center">
					File Format: PDF
					<br />
					Max Size: 20MB
				</p>
			</div>
		</>
	);
};

export default UploadImage;
