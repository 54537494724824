import React, { useState } from "react";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
  const [voucherId, setVoucherId] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherValue, setVoucherValue] = useState("");
  const [voucherType, setVoucherType] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");

  const handleClear = () => {
    setVoucherId("");
    setVoucherCode("");
    setVoucherValue("");
    setVoucherType("");
    setCreatedDate("");
    setStartDate("");
    setEndDate("");
    setStatus("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (!!voucherId) {
      queryString += `&id=${voucherId}`;
    }
    if (!!voucherCode) {
      queryString += `&code=${voucherCode}`;
    }
    if (!!voucherValue) {
      queryString += `&value=${voucherValue}`;
    }
    if (!!voucherType) {
      queryString += `&type=${voucherType}`;
    }
    if (!!createdDate) {
      queryString += `&created_at=${createdDate}`;
    }
    if (!!startDate) {
      queryString += `&start_date=${startDate}`;
    }
    if (!!endDate) {
      queryString += `&end_date=${endDate}`;
    }
    if (!!status) {
      queryString += `&status=${status}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-promo-code-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="messageId">Promo Code ID</Label>
                <Input
                  placeholder="Enter Promo Code ID"
                  name="voucherId"
                  id="voucherId"
                  value={voucherId}
                  onChange={(e) => setVoucherId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="message">Promo Code</Label>
                <Input
                  placeholder="Enter Promo Code"
                  name="voucherCode"
                  id="voucherCode"
                  value={voucherCode}
                  onChange={(e) => setVoucherCode(e.target.value)}
                />
              </FormGroup>
            </Col>
            {/* <Col lg={3}>
              <FormGroup>
                <Label for="recipient">Voucher Value</Label>
                <Input
                  placeholder="Enter Voucher Value"
                  name="voucherValue"
                  id="voucherValue"
                  value={voucherValue}
                  onChange={(e) => setVoucherValue(e.target.value)}
                />
              </FormGroup>
            </Col> */}
            <Col lg={3}>
              <FormGroup>
                <Label for="status">Promo Type</Label>
                <Input
                  type="select"
                  name="voucherType"
                  id="voucherType"
                  value={voucherType}
                  onChange={(e) => setVoucherType(e.target.value)}
                >
                  <option disabled value="">
                    Select Promo Type
                  </option>
                  <option>amount</option>
                  <option>percentage</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option disabled value="">
                    Select Status
                  </option>
                  <option>draft</option>
                  <option>publish</option>
                  <option>suspend</option>
                  <option>disable</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="createdDate">Created Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="createdDate"
                  id="createdDate"
                  value={createdDate}
                  onChange={(e) => setCreatedDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="publishedDate">Start Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="publishedDate">End Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Search",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("voucherPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
