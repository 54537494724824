import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Post, Get, Delete, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {};

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    createUser = (dataToSubmit, postCompany = false) => {
      const postUserData = {
        email: dataToSubmit.email,
        name: dataToSubmit.name,
        password: dataToSubmit.password,
        confirm_password: dataToSubmit.confirm_password,
        platform: dataToSubmit.platform,
        role_id: dataToSubmit.role_id,
        mobile_number: dataToSubmit.mobile_number,
        code: dataToSubmit.code,
        agent_lead_id: dataToSubmit.agent_lead_id,
        manager_id: dataToSubmit.manager_id,
      };

      const postCompanyData = {
        is_house_advertiser: dataToSubmit?.is_house_advertiser,
        sst_registration_number: dataToSubmit?.sst_registration_number,
        is_exclude_sst: dataToSubmit?.is_exclude_sst,
        advertiser_address: dataToSubmit?.advertiser_address,
        postcode: dataToSubmit?.postcode,
        city: dataToSubmit?.city,
        billing_address: dataToSubmit?.billing_address,
        billing_postcode: dataToSubmit?.billing_postcode,
        billing_city: dataToSubmit?.billing_city,
        mobile_number: dataToSubmit?.mobile_number,
        description: dataToSubmit?.description,
        website_url: dataToSubmit?.website_url,
        registration_number: dataToSubmit?.registration_number,
        agent_id: dataToSubmit?.agent_id,
      };

      Post(
        `/users`,
        postUserData,
        (response) => {
          postCompany
            ? this.createUserAdvertiserSuccess(response, postCompanyData)
            : this.createUserSuccess(response);
        },
        this.createUserError,
        this.load
      );
    };
    createUserSuccess = (response) => {
      requestSuccess("User created successfully.");

      setTimeout(() => {
        this.props.history.push("/manage-user");
      }, 2000);
    };

    createUserAdvertiserSuccess = (response, postCompanyData) => {
      const dataToSubmit = {
        email: response.data.email,
        type: "individual",
        name: response.data.name,
        registration_number: postCompanyData.registration_number,
        contact_number: response.data.mobile_number,
        company_contact: response.data.mobile_number,
        advertiser_address: postCompanyData.advertiser_address,
        postcode: postCompanyData.postcode,
        city: postCompanyData.city,
        billing_address: postCompanyData.billing_address,
        billing_postcode: postCompanyData.billing_postcode,
        billing_city: postCompanyData.billing_city,
        status: response.data.status,
        user_id: response.data.id,
        is_house_advertiser: postCompanyData.is_house_advertiser,
        sst_registration_number: postCompanyData.sst_registration_number,
        is_exclude_sst: postCompanyData.is_exclude_sst,
        whatsapp_number: response.data.whatsapp_number,
        description: postCompanyData.description,
        website_url: postCompanyData.website_url,
      };

      this.createCompany(dataToSubmit);
    };

    createUserError = (error) => requestError(error);

    createCompany = (dataToSubmit) =>
      Post(
        "/companies",
        dataToSubmit,
        this.createCompanySuccess,
        this.createCompanyError,
        this.load
      );
    createCompanySuccess = (response) => {
      requestSuccess("User created successfully.");
      setTimeout(() => {
        this.props.history.push("/manage-user");
      }, 2000);
    };
    createCompanyError = (error) => requestError(error);

    updateCompany = (id, dataToSubmit) =>
      Put(
        `/companies/${id}`,
        dataToSubmit,
        this.updateCompanySuccess,
        this.updateCompanyError,
        this.load
      );
    updateCompanySuccess = (response) => {
      requestSuccess("User updated successfully.");
      setTimeout(() => {
        this.props.history.push("/manage-user");
      }, 2000);
    };
    updateCompanyError = (error) => requestError(error);

    updateUser = (dataToSubmit, updateCompany = false) => {
      const updateUserData = {
        email: dataToSubmit.email,
        name: dataToSubmit.name,
        mobile_number: dataToSubmit.mobile_number,
        is_first_time: dataToSubmit.is_first_time,
        status: dataToSubmit.status,
        agent_lead_id: dataToSubmit.agent_lead_id,
        manager_id: dataToSubmit.manager_id,
        whatsapp_number: dataToSubmit.whatsapp_number,
        code: dataToSubmit.code,
        agent_id: dataToSubmit.agent_id
      };

      const updateCompanyData = {
        email: dataToSubmit.email,
        type: "individual",
        name: dataToSubmit.name,
        registration_number: dataToSubmit.registration_number,
        contact_number: dataToSubmit.mobile_number,
        company_contact: dataToSubmit.mobile_number,
        advertiser_address: dataToSubmit.advertiser_address,
        postcode: dataToSubmit.postcode,
        city: dataToSubmit.city,
        billing_address: dataToSubmit.billing_address,
        billing_postcode: dataToSubmit.billing_postcode,
        billing_city: dataToSubmit.billing_city,
        description: dataToSubmit.description,
        website_url: dataToSubmit.website_url,
        status: dataToSubmit.status,
        is_house_advertiser: dataToSubmit.is_house_advertiser,
        sst_registration_number: dataToSubmit.sst_registration_number,
        is_exclude_sst: dataToSubmit.is_exclude_sst,
        whatsapp_number: dataToSubmit.whatsapp_number,
        user_id: parseInt(dataToSubmit.id),
      };

      Put(
        `/users/${dataToSubmit.id}`,
        updateUserData,
        (response) => {
          updateCompany
            ? this.updateUserAdvertiserSuccess(updateCompanyData)
            : this.updateUserSuccess();
        },
        this.updateUserError,
        this.load
      );
    };
    updateUserSuccess = () => {
      requestSuccess("User updated successfully.");
      setTimeout(() => {
        this.props.history.push("/manage-user");
      }, 2000);
    };
    updateUserAdvertiserSuccess = (updateCompanyData) => {
      Get(
        `/companies?user_id=${updateCompanyData.user_id}`,
        (response) => {
          response.data.count < 1
            ? this.createCompany(updateCompanyData)
            : this.updateCompany(response.data.rows[0].id, updateCompanyData);
        },
        (error) => {
          requestError(error);
        },
        this.load
      );
    };
    updateUserError = (error) => requestError(error);

    getRole = () => {
      return new Promise((resolve, reject) => {
        Get(
          `/roles?platform=jobmacha`,
          (response) => {
            resolve(response.data);
          },
          (error) => {
            // Handle error without calling reject, reject has issues
            console.error("Error in getRole:", error);
            // Optionally, you can resolve with a default value or an empty array
            resolve([]); // or resolve(defaultValue);
          },
          this.load
        );
      });
    };

    searchUser = (code, resolve, reject) =>
      Get(
        `/users?platform=jobmacha${code ? `&code=${code}` : ""}`,
        (response) => {
          resolve(response.data); // Resolve the promise with options
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    getUser = (id = "", resolve, reject) =>
      Get(
        `/single-user?id=${id}`,
        (response) => {
          resolve(response.data);
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    createRole = (dataToSubmit, resolve, reject) =>
      Post(
        `/user/${dataToSubmit.user_id}/roles`,
        dataToSubmit,
        (response) => {
          requestSuccess("User role created successfully.");
          resolve(response.data);
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    deleteRole = (dataToSubmit, resolve, reject) =>
      Delete(
        `/user/${dataToSubmit.user_id}/roles/${dataToSubmit.role_id}`,
        (response) => {
          requestSuccess("User role deleted successfully.");
          resolve(response.data);
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    load = (param) => this.setState({ loading: param });

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onChangeHOC={this.onChangeHOC}
          getRole={this.getRole}
          searchUser={this.searchUser}
          createUser={this.createUser}
          getUser={this.getUser}
          createRole={this.createRole}
          deleteRole={this.deleteRole}
          updateUser={this.updateUser}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setProfile,
  })(WithHOC);
};
export default HOC;
