import { SET_PROFILE } from "./type";

export const setProfile = (payload) => (dispatch) => {
	dispatch(setProfileSuccess(payload));
};

export const setProfileSuccess = (payload) => ({
	type: SET_PROFILE,
	payload,
});
