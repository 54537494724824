import React from "react"
import { Input, Label } from "reactstrap"
import _ from "lodash"

import './index.scss'

const CustomCheckBox = ({
  id,
  index,
  formGroupStyle,
  formGroupClass,
  labelClass,
  checkboxClass,
  disabled,
  checked,
  label,
  onChangeData,
  style
}) => {

  return (
    <div key={index} className={formGroupClass || ""} style={{...formGroupStyle || ''}}>
      <Input
        id={ id }
        type={"checkbox"}
        disabled={disabled}
        className={`custom-form-check-input ${checkboxClass || ""}`}
        checked={checked}
        onChange={(e) => onChangeData(e.target.checked)}
        style={style} />
      <Label className={`mb-0 ${labelClass}`} for={id}>{label}</Label>
    </div>
  )
}

export default CustomCheckBox