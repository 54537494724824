import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import CertItem from "./components/CertItem";
import UploadField from "./components/UploadField";

import {
	HardSkills,
	Lang,
	Licenses,
	Personalities,
	SoftSkills,
	SoftwareSkills,
} from "Config";

const defaultSkillVal = {
	personalities: [],
	soft_skills: [],
	hard_skills: [],
	software_skills: [],
};

const defaultCustomOption = {
	label_en: "",
	label_zh: "",
	key: "",
	custom: true,
};

function Skill({ candidateForm, onChangeForm, ...props }) {
	const [tempMiscSkills, setTempMiscSkills] = useState(null);
	const [certs, setCerts] = useState([]);

	useEffect(() => {
		setCerts(props.jobSeekerProfile.certifications);
		setTempMiscSkills(props.jobSeekerProfile.misc_skills || defaultSkillVal);
	}, [props.jobSeekerProfile]);

	const onHandleCertRemove = (index) => {
		let temp = _.cloneDeep(certs);
		temp[index].is_deleted = true;

		setCerts(temp);
	};

	const onSubmit = () => {
		let tempDeleted = [];
		let tempNonDeletedCert = [];

		certs.map((certChild) => {
			if (certChild.is_deleted) {
				tempDeleted.push(certChild);
			} else {
				tempNonDeletedCert.push(certChild);
			}
		});

		tempDeleted.map((deletedChild) => {
			props.deleteAsset(deletedChild.id);
		});

		props.updateJobSeekerProfile({
			...candidateForm,
			misc_skills: tempMiscSkills,
			certifications: tempNonDeletedCert,
		});
	};

	const oAddCert = (val) => {
		let temp = _.cloneDeep(certs);

		temp.push({
			id: `${val.id}`,
			title: val.title,
			url: val.url,
		});
		setCerts(temp);
	};

	const onHandleChoose = (val, context, selected) => {
		let temp = _.cloneDeep(candidateForm[context]);
		if (!temp) {
			temp = [];
		}
		let tempIndex = _.findIndex(temp, { key: val.key });

		if (temp > -1) {
			temp.splice(tempIndex, 1);
		} else {
			if (selected.length > 4) {
				return;
			}
			temp.push(val);
		}
		onChangeForm(context, temp);
	};

	const onHandleMiscChoose = (val, context, selected) => {
		let temp = _.cloneDeep(tempMiscSkills);
		if (!temp[context]) {
			temp[context] = [];
		}
		let tempIndex = _.findIndex(temp[context], { key: val.key });

		if (temp > -1) {
			temp[context].splice(tempIndex, 1);
		} else {
			if (selected.length > 4) {
				return;
			}

			temp[context].push(val);
		}

		setTempMiscSkills(temp);
	};

	return (
		<>
			<div className="d-flex">
				<h5 className="mb-3 e-classified-cont-header">Skill</h5>
			</div>
			<Card
				style={{ padding: "2rem", borderRadius: "0.625rem" }}
				className="mb-4">
				<h5 className="e-classified-cont-edit-label mb-3">
					Pick Your
					{` `}
					Language
					{` `}
					<i style={{ color: "#BFBFBF" }}>(Select up to 5)</i>
				</h5>
				<MultiSelect
					options={Lang}
					selected={candidateForm?.language_skill}
					onChoose={(val) =>
						onHandleChoose(val, "language_skill", candidateForm?.language_skill)
					}
				/>
			</Card>
			<Card
				style={{ padding: "2rem", borderRadius: "0.625rem" }}
				className="mb-4">
				<h5 className="e-classified-cont-edit-label mb-3">
					Pick Your
					{` `}
					Driving License
					{` `}
					<i style={{ color: "#BFBFBF" }}>(Select up to 5)</i>
				</h5>
				<MultiSelect
					options={Licenses}
					selected={candidateForm?.license_type}
					onChoose={(val) =>
						onHandleChoose(val, "license_type", candidateForm?.license_type)
					}
				/>
			</Card>{" "}
			<Card
				style={{ padding: "2rem", borderRadius: "0.625rem" }}
				className="mb-4">
				<h5 className="e-classified-cont-edit-label mb-3">
					Pick Your
					{` `}
					Personality
					{` `}
					<i style={{ color: "#BFBFBF" }}>(Select up to 5)</i>
				</h5>
				<MultiSelect
					options={Personalities}
					selected={tempMiscSkills?.["personalities"] ?? []}
					onChoose={(val) =>
						onHandleMiscChoose(
							val,
							"personalities",
							tempMiscSkills?.["personalities"] ?? []
						)
					}
				/>
			</Card>
			<Card
				style={{ padding: "2rem", borderRadius: "0.625rem" }}
				className="mb-4">
				<h5 className="e-classified-cont-edit-label mb-3">
					Pick Your
					{` `}
					Soft Skills
					{` `}
					<i style={{ color: "#BFBFBF" }}>(Select up to 5)</i>
				</h5>
				<MultiSelect
					options={SoftSkills}
					selected={tempMiscSkills?.["soft_skills"] ?? []}
					onChoose={(val) =>
						onHandleMiscChoose(
							val,
							"soft_skills",
							tempMiscSkills?.["soft_skills"] ?? []
						)
					}
				/>
			</Card>
			<Card
				style={{ padding: "2rem", borderRadius: "0.625rem" }}
				className="mb-4">
				<h5 className="e-classified-cont-edit-label mb-3">
					Pick Your
					{` `}
					Hard Skills
					{` `}
					<i style={{ color: "#BFBFBF" }}>(Select up to 5)</i>
				</h5>
				<MultiSelect
					options={HardSkills}
					selected={tempMiscSkills?.["hard_skills"] ?? []}
					onChoose={(val) =>
						onHandleMiscChoose(
							val,
							"hard_skills",
							tempMiscSkills?.["hard_skills"] ?? []
						)
					}
				/>
			</Card>
			<Card
				style={{ padding: "2rem", borderRadius: "0.625rem" }}
				className="mb-4">
				<h5 className="e-classified-cont-edit-label mb-3">
					Pick Your
					{` `}
					Software Skills
					{` `}
					<i style={{ color: "#BFBFBF" }}>(Select up to 5)</i>
				</h5>
				<MultiSelect
					options={SoftwareSkills}
					selected={tempMiscSkills?.["software_skills"] ?? []}
					onChoose={(val) =>
						onHandleMiscChoose(
							val,
							"software_skills",
							tempMiscSkills?.["software_skills"] ?? []
						)
					}
				/>
			</Card>
			<Card style={{ padding: "2rem" }}>
				<h5 className="e-classified-cont-header mb-4">Certificates</h5>
				<UploadField uploadPdf={props.uploadPdf} oAddCert={oAddCert} />
				{certs.map((certChild, certIndex) => (
					<>
						{!certChild.is_deleted && (
							<CertItem
								certChild={certChild}
								context={"edit"}
								onPreview={(val) => props.setPreview(val)}
								onRemove={() => onHandleCertRemove(certIndex)}
							/>
						)}
					</>
				))}
			</Card>
			<div
				className="d-flex align-items-center"
				style={{ marginTop: "2.5rem" }}>
				<Button
					size={"lg"}
					className="btn-outline-primary bg-white ml-auto mr-3"
					onClick={() => onSubmit()}>
					Store
				</Button>
				<Button
					size={"lg"}
					color="primary"
					onClick={() => {
						onSubmit();
					}}>
					Next
				</Button>
			</div>
		</>
	);
}

const MultiSelect = ({ options = [], selected = [], onChoose }) => {
	const [onShowOther, setShowOther] = useState(false);
	const [tempCustomOption, setTempCustomOption] = useState(defaultCustomOption);

	useEffect(() => {
		!onShowOther && setTempCustomOption(defaultCustomOption);
	}, [onShowOther]);
	return (
		<>
			<div
				className="d-flex flex-wrap align-items-center"
				style={{ gap: "1rem" }}>
				{selected.map((selectedOption) => (
					<>
						{selectedOption.custom && (
							<PillButton
								item={selectedOption}
								selected={selected}
								onHandleClick={onChoose}
							/>
						)}
					</>
				))}
				{options.map((optionChild) => (
					<PillButton
						item={optionChild}
						selected={selected}
						onHandleClick={onChoose}
					/>
				))}
				<PillButton
					item={{
						label_en: "Others",
						label_zh: "其他",
					}}
					selected={selected}
					onHandleClick={() => {
						selected.length < 5 && setShowOther(true);
					}}
				/>
			</div>
			{onShowOther && (
				<>
					<Row className="mt-3 mb-3">
						<Col>
							<FormGroup>
								<Label>English</Label>
								<Input
									value={tempCustomOption?.label_en ?? ""}
									onChange={(e) =>
										setTempCustomOption({
											...tempCustomOption,
											label_en: e.target.value,
											key: e.target.value.replace(/\s+/g, "-").toLowerCase(),
										})
									}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Chinese</Label>
								<Input
									value={tempCustomOption?.label_zh ?? ""}
									onChange={(e) =>
										setTempCustomOption({
											...tempCustomOption,
											label_zh: e.target.value,
										})
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<div className="d-flex">
						<Button
							size={"lg"}
							className="mr-3 ml-auto"
							color="primary"
							disabled={_.values(tempCustomOption).some((val) => !val)}
							onClick={() => {
								onChoose(tempCustomOption);
								setShowOther(false);
							}}>
							Save
						</Button>
						<Button
							size={"lg"}
							className={"btn btn-outline-primary bg-white"}
							onClick={() => setShowOther(false)}>
							Cancel
						</Button>
					</div>
				</>
			)}
		</>
	);
};

const PillButton = ({ item, onHandleClick, selected = [] }) => {
	const isSelected =
		_.findIndex(selected, (selectedChild) => selectedChild.key === item.key) >
		-1;
	return (
		<div
			className={`${isSelected ? "bg-primary text-white" : ""}`}
			style={{
				borderRadius: "3.125rem",
				padding: "0.5rem 1.5rem",
				cursor: "pointer",
				border: !isSelected ? "solid 1px #D9D9D9" : "none",
			}}
			onClick={() => {
				onHandleClick(item);
			}}>
			{item[`label_en`]}
		</div>
	);
};

export default Skill;
