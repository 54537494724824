import React, { Component } from "react";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CandidateProfile from "../../CandidateList/CandidateProfile";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  ModalBody,
} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { requestError } from "utils/requestHandler";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import ApplicantRecommendationAdvertiserCandidateAPI from "./action.js";

const ApplicantRecommendationAdvertiserCandidateWrapper = (props) => {
  const { specialization_id, id } = useParams();

  return (
    <ApplicantRecommendationAdvertiserCandidate
      specialization_id={specialization_id ?? null}
      jobad_id={id ?? null}
      {...props}
    />
  );
};
class ApplicantRecommendationAdvertiserCandidate extends Component {
  componentDidMount = () => {
    this.props.getCandidate(
      `&specialization_id=${this.props.specialization_id}&job_ads_id=${this.props.jobad_id}`
    );
    this.props.getState();
  };

  componentDidUpdate = (pp) => {
    const { candidatePages, getCandidate, specialization_id, jobad_id } = this.props;
    const { filterQuery } = this.state;

    if (pp.candidatePages !== candidatePages) {
      getCandidate(
        `&specialization_id=${specialization_id}&job_ads_id=${jobad_id}${
          candidatePages > 1 ? `&offset=${(candidatePages - 1) * 10}` : ""
        }${!!filterQuery ? filterQuery : ""}`
      );
    }
  };

  View = (val) => {};

  state = {
    selectedRowData: null,
    filterQuery: "",
  };

  renderSampleTable = () => {
    const { candidateList, loading, selectedRows, selectedCandidates, onChangeHOC } = this.props;

    return (
      <CustomTable
        data={candidateList}
        showPagination={false}
        loading={loading}
        columnsContent={[
          {
            Header: "User ID",
            accessorKey: "user_id",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.user_id}</>,
          },
          {
            Header: "Name",
            accessorKey: "user",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.users.name}</>,
          },
          {
            Header: "Contact Number",
            accessorKey: "user",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.users.mobile_number}</>,
          },
          {
            Header: "Email",
            accessorKey: "user",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.users.email}</>,
          },
          {
            Header: "Location",
            accessorKey: "state",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{`${val.state} - ${val.city}`}</>,
          },
          {
            Header: "Education",
            accessorKey: "education",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.education?.name}</>,
          },
          {
            Header: "Job Preferences",
            accessorKey: "candidateJobPreferences",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.candidateJobPreferences}</>,
          },
          {
            Header: "Expected Salary",
            accessorKey: "expected_salary",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.expected_salary}</>,
          },
        ]}
        actionsContent={[
          {
            content: <i className="pe-7s-look btn-icon-wrapper"> </i>,
            actionID: "PreviewCandidate",
            color: "primary",
            tooltipContent: "Preview",
            onClick: (val) => {
              this.props.getSelectedJobSeekerProfile(val.user_id, "view");
              // this.props.onChangeSampleHOC("selectedSample", val);
              // this.props.onChangeSampleHOC("showEditSampleModal", true);
            },
          },
          {
            content: (val) => (
              <FormGroup check>
                {" "}
                <Input
                  type="checkbox"
                  checked={
                    selectedRows.findIndex((item) => item === val.id) !== -1
                  }
                  onClick={() => {
                    const rowIndex = selectedRows.findIndex(
                      (item) => item === val.id
                    );
                    if (rowIndex === -1) {
                      onChangeHOC("selectedRows", [...selectedRows, val.id]);
                      onChangeHOC("selectedCandidates", [...selectedCandidates, val])
                    } else {
                      const newSelectedRows = [...selectedRows];
                      newSelectedRows.splice(rowIndex, 1);
                      onChangeHOC("selectedRows", newSelectedRows);

                      const newSelectedCandidates = [...selectedCandidates];
                      newSelectedCandidates.splice(rowIndex, 1);
                      onChangeHOC("selectedCandidates", [...selectedCandidates, val])
                    }
                  }}
                />
              </FormGroup>
            ),
            actionID: "SelectCandidate",
            color: "none",
            tooltipContent: "Select",
            onClick: (val) => {},
          },
        ]}
      />
    );
  };

  renderFilterModal = () => {
    const { showFilterModal, onChangeHOC, statesList, getCandidate } =
      this.props;
    const customSetState = (newState) => {
      this.setState(newState);
    };

    return (
      <CustomModal
        size={"lg"}
        title="Candidate List Filter"
        isOpen={showFilterModal}
        onClose={() => onChangeHOC("showFilterModal", false)}
      >
        <FilterForm
          onSubmit={getCandidate}
          onChangeHOC={onChangeHOC}
          statesList={statesList}
          handleState={customSetState}
        />
      </CustomModal>
    );
  };

  renderViewModal = () => {
		return (
			<CustomModal
				isOpen={this.props.showViewModal}
				fullScreen={true}
				onClose={() => this.props.onChangeHOC("showViewModal", false)}>
				<ModalBody>
					<CandidateProfile
						{...this.props}
						context={"view"}
						jobSeekerProfile={this.props.selectedCandiate}
					/>
				</ModalBody>
			</CustomModal>
		);
	};

  renderRecommendModal = () => {
    const { 
      selectedRows, 
      selectedCandidates, 
      recommendCandidate, 
      applyJob, 
      getCandidate, 
      specialization_id, 
      jobad_id 
    } = this.props;

    return (
      <ConfirmationModal
        open={this.props.showRecommendModal}
        onClose={() => this.props.onChangeHOC("showRecommendModal", false)}
        onClickConfirm={async () => {
          this.props.onChangeHOC("showRecommendModal", false);
          await Promise.all(selectedCandidates.map((candidate) => applyJob({
            fullname: candidate?.user?.name ?? "",
            contact_number: candidate?.user?.mobile_number ?? "",
            whatsapp: candidate?.user?.whatsapp_number ?? "",
            email: candidate?.user?.email ?? "",
            status: 'shortlisted',
            user_id: candidate.user_id,
            job_ads_id: parseInt(jobad_id, 10),
          })))
          // recommendCandidate({
          //   jobad_id: parseInt(jobad_id),
          //   create_jobseeker_profile_ids: selectedRows,
          //   delete_jobseeker_profile_ids: [],
          // })
          getCandidate(
            `&specialization_id=${specialization_id}&job_ads_id=${jobad_id}`
          );
        }}
        title={`Are you sure you want to provide recommendation to these candidates? Candidate ID: ${selectedRows.join(
          ", "
        )}`}
        message="Please confirm this action."
        confirmContent="Yes, Recommend"
        confirmColor="success"
      />
    );
  };

  render = () => {
    const { candidatePages, candidateCount, selectedRows, onChangeHOC } =
      this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <>
                  <PageTitle
                    heading="求职者推荐 - For Advertiser / <b>Candidate List</b>"
                    buttons={[
                      {
                        color: "",
                        className: "btn-outline-primary",
                        onClick: () => onChangeHOC("showFilterModal", true),
                        content: (
                          <>
                            <span>Filter</span>
                          </>
                        ),
                      },
                    ]}
                  />
                  <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                  >
                    <h5>Candidate List</h5>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        marginBottom: "2rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {/* <Button
                          style={{ marginRight: "0.8rem" }}
                          color="primary"
                          size="lg"
                        >
                          List View
                        </Button>
                        <Button color="secondary" size="lg">
                          Card View
                        </Button> */}
                      </div>
                      <div>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={() => {
                            if (selectedRows.length > 0) {
                              onChangeHOC("showRecommendModal", true);
                            } else {
                              requestError("Candidates are required.");
                            }
                          }}
                        >
                          Recommend to Advertiser
                        </Button>
                      </div>
                    </div>
                    <Row>
                      <Col md={12} style={{minWidth: 'max-content'}}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            {this.renderSampleTable()}
                            <Pagination
                              pageIndex={candidatePages}
                              totalCount={candidateCount}
                              onChangePage={(val) =>
                                onChangeHOC("candidatePages", val)
                              }
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </ReactCSSTransitionGroup>
                  {this.renderFilterModal()}
                  {this.renderRecommendModal()}
                  {this.renderViewModal()}
                  {this.props.loading && <LoadingOverlay />}
                </>
              </div>
            </div>
          </div>
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(ApplicantRecommendationAdvertiserCandidateAPI)(
  ApplicantRecommendationAdvertiserCandidateWrapper
);
