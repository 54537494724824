import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import _ from "lodash";

export default function FilterForm({
	onSubmit,
	onChangeHOC,
	cities,
	states,
	getCities,
}) {
	const [candidateName, setCandidateName] = useState("");
	const [candidateContactNumber, setCandidateContactNumber] = useState("");
	const [candidateWhatsappNumber, setCandidateWhatsappNumber] = useState("");
	const [candidateEmail, setCandidateEmail] = useState("");
	const [candidateCity, setCandidateCity] = useState("");
	const [candidateState, setCandidateState] = useState("");

	const handleClear = () => {
		setCandidateContactNumber("");
		setCandidateWhatsappNumber("");
		setCandidateEmail("");
		setCandidateCity("");
		setCandidateState("");
		onChangeHOC("cities", []);
	};

	const generateQuery = () => {
		let queryString = "";

		if (candidateName) {
			queryString += `&name=${candidateName}`;
		}
		if (candidateContactNumber) {
			queryString += `&mobile_number=${candidateContactNumber}`;
		}
		if (candidateWhatsappNumber) {
			queryString += `&whatsapp_number=${candidateWhatsappNumber}`;
		}

		if (candidateEmail) {
			queryString += `&email=${candidateEmail}`;
		}

		if (candidateState) {
			let tempState = _.find(states, { value: +candidateState }).label;

			if (tempState) {
				queryString += `&state=${tempState}`;
			}
		}

		if (candidateCity) {
			let tempCity = _.find(cities, { value: +candidateCity }).label;
			if (tempCity) {
				queryString += `&city=${tempCity}`;
			}
		}

		return queryString;
	};

	useEffect(() => {
		candidateState && getCities(candidateState);
	}, [candidateState]);

	return (
		<>
			<CustomModalBody>
				<Form id="manage-user-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="candidateName">Name</Label>
								<Input
									placeholder="Enter Name"
									name="candidateName"
									id="candidateName"
									value={candidateName}
									onChange={(e) => setCandidateName(e.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="candidateContactNumber">Contact Number</Label>
								<Input
									placeholder="Enter Contact Number"
									name="candidateContactNumber"
									id="candidateContactNumber"
									value={candidateContactNumber}
									onChange={(e) => setCandidateContactNumber(e.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="candidateWhatsappNumber">Whatsapp</Label>
								<Input
									placeholder="Enter Whatsapp Number"
									name="candidateWhatsappNumber"
									id="candidateWhatsappNumber"
									value={candidateWhatsappNumber}
									onChange={(e) => setCandidateWhatsappNumber(e.target.value)}
								/>
							</FormGroup>
						</Col>

						<Col lg={3}>
							<FormGroup>
								<Label for="candidateEmail">Email</Label>
								<Input
									placeholder="Enter Email"
									name="candidateEmail"
									id="candidateEmail"
									value={candidateEmail}
									onChange={(e) => setCandidateEmail(e.target.value)}
								/>
							</FormGroup>
						</Col>

						<Col lg={3}>
							<FormGroup>
								<Label for="candidateCity">City</Label>
								<Input
									type="select"
									name="candidateCity"
									id="candidateCity"
									value={candidateCity}
									onChange={(e) => setCandidateCity(e.target.value)}>
									<option value={""}>All</option>
									{cities.map((city) => (
										<option value={city.value} key={city.value}>
											{city.name}
										</option>
									))}
								</Input>
							</FormGroup>
						</Col>

						<Col lg={3}>
							<FormGroup>
								<Label for="candidateState">State</Label>
								<Input
									type="select"
									name="candidateState"
									id="candidateState"
									value={candidateState}
									onChange={(e) => setCandidateState(e.target.value)}>
									<option value={""}>All</option>
									{states.map((state) => (
										<option value={state.value} key={state.value}>
											{state.label}
										</option>
									))}
								</Input>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Apply",
						onClick: () => {
							onSubmit(generateQuery());
						},
					},
				]}
			/>
		</>
	);
}
