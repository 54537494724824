import React, { Component } from "react";
import _ from 'lodash'
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Post, Delete, Get } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false
    };

    load = (param) => this.setState({ loading: param });

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    createAsset = async (title, val, context) => {
      let tempTitle = title.replace(/ /g, "_"); 

      let tempResult = null
      await Post(
        `/assets`,
        {
          base64: val,
          filename: `${tempTitle}_${ context }`,
          alt: `${tempTitle}_${ context }`,
          title: `${tempTitle}_${ context }`,
          platforms: ['jobmacha'],
        },
        payload => tempResult = payload,
        (error) => requestError(error),
        this.load
      );

      return tempResult
    };

    deleteAsset = async (url) => {
      let tempID = null;
      const encodedUrl = url.split(' ').map(encodeURIComponent).join('%20');
    
      await Get(
        `/assets?platform=jobmacha&url=${encodedUrl}`,
        payload => {
          if (payload?.data?.rows?.[0]) {
            tempID = payload.data.rows[0].id;
          }
        },
        () => {},
        this.load
      );
    
      if (tempID !== null) {
        return new Promise((resolve, reject) => {
          Delete(
            `/assets/${tempID}`,
            () => resolve(tempID),
            error => reject(error),
            this.load,
            true
          );
        });
      } else {
        return false;
      }
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadImage={this.state.loading}
          createAsset={this.createAsset}
          deleteAsset={this.deleteAsset}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setProfile,
  })(WithHOC);
};
export default HOC;
