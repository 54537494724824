import Moment from "moment";
import DatePicker from "react-datepicker";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Button, Form, FormGroup, Input, InputGroup, Label } from "reactstrap";
import ECFormInput from "components/Input/FormInput"

import {
	CountryOption,
	Gender,
	MaritalStatus,
	MobileCountryCode,
} from "Config";
import { useEffect, useState } from "react";
import _ from "lodash";

const Months = Array.from({ length: 12 }, (_, index) => index + 1);
const Date = Array.from({ length: 31 }, (_, index) => index + 1);

function Edit({
	states,
	tempUser,
	candidateForm,
	tempDay,
	tempMonth,
	tempYear,
	setTempDay,
	setTempMonth,
	setTempYear,
	onChangeField,
	onChangeJobSeekerField,
	onChangeUserField,
	updateUser,
	getStates,
	setTempJobSeeker,
	updateJobSeekerProfile,
}) {
	const [countryCode, setCountryCode] = useState("+60");
	const [mobileInput, setMobileInput] = useState("");

	useEffect(() => {
		if (tempUser) {
			let temp = _.find(MobileCountryCode, (codeChild) => {
				return `+${tempUser.mobile_number}`.includes(codeChild.dial_code);
			});

			setCountryCode(temp.dial_code);

			let tempMobile = tempUser.mobile_number.includes("+")
				? tempUser.mobile_number.split(temp.dial_code)
				: `+${tempUser.mobile_number}`.split(temp.dial_code);

			if (tempMobile?.[1]) {
				setMobileInput(tempMobile?.[1]);
			}
		}
	}, [tempUser]);

	const onHandleSubmit = () => {
		updateUser(candidateForm.user_id, {
			...tempUser,
			mobile_number: `${countryCode}${mobileInput}`,
		});
		updateJobSeekerProfile({
			...candidateForm,
			birth_date: `${tempDay}-${tempMonth}-${Moment(tempYear).format("YYYY")}`,
		});
	};

	return (
		<>
			<h5 className="mb-3 e-classified-cont-header">Personal Details</h5>
			<div className="e-classified-profile-view-card e-classfied-me-menu__form-cont mb-4">
				<Form>
					<div className="row">
						{tempUser && (
							<>
								<div className="col-lg-12">
									<FormGroup>
										<Label>Name</Label>
										<Input
											value={tempUser.name}
											onChange={(e) =>
												onChangeUserField("name", e.target.value)
											}
										/>
									</FormGroup>
								</div>
								<div className="col-lg-12">
									<FormGroup>
										<Label>Email</Label>
										<Input
											value={tempUser.email}
											onChange={(e) => {
												onChangeUserField("email", e.target.value);
											}}
										/>
									</FormGroup>
								</div>
								<div className="col-lg-6">
									<ECFormInput
										context="contact_number"
										label='Contact No.'
										required={true}
										countryCode={true}
										value={mobileInput}
										placeholder="Eg: 11-23456789"
										onChangeData={(val) => {
											setMobileInput(val)
										}}
									/>
								</div>
								<div className="col-lg-6">
									<ECFormInput
										context="whatsapp_number"
										label='Whatsapp No.'
										value={tempUser.whatsapp_number}
										placeholder="Eg: 601123456789"
										onChangeData={(val) => {
											onChangeUserField("whatsapp_number", val)
										}}
										prefix={"wa.me/ "}
									/>
								</div>
							</>
						)}
						<div className="col-lg-6">
							<FormGroup>
								<Label>Gender</Label>
								<Input
									type={"select"}
									value={candidateForm.gender}
									onChange={(e) => onChangeField("gender", e.target.value)}>
									{Gender.map((child) => (
										<option key={`gender_${child.value}`} value={child.value}>
											{child["en"]}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>Marital Status</Label>
								<Input
									type={"select"}
									value={candidateForm.marital_status}
									onChange={(e) =>
										onChangeJobSeekerField("marital_status", e.target.value)
									}>
									{MaritalStatus.map((child) => (
										<option key={`marital_${child.value}`} value={child.value}>
											{child["en"]}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-lg-12">
							<FormGroup className="mb-0">
								<Label>
									Date Of Birth
									<span className="text-danger">{` *`}</span>
								</Label>
								<div className="row">
									<div className="col-lg-4">
										<FormGroup>
											<DatePicker
												className="form-control"
												selected={tempYear}
												placeholderText={"Year"}
												min={Moment().subtract(120, "years").toDate()}
												maxDate={Moment().subtract(16, "years").toDate()}
												onChange={(date) => {
													setTempYear(date);
												}}
												showYearPicker
												dateFormat="yyyy"
											/>
										</FormGroup>
									</div>
									<div className="col-lg-4">
										<FormGroup>
											<Input
												type={"select"}
												value={tempMonth}
												onChange={(e) => setTempMonth(e.target.value)}>
												<option value="">Month</option>
												{Months.map((child) => (
													<option key={child} value={child}>
														{child}
													</option>
												))}
											</Input>
										</FormGroup>
									</div>
									<div className="col-lg-4">
										<FormGroup>
											<Input
												type={"select"}
												value={tempDay}
												onChange={(e) => setTempDay(e.target.value)}>
												<option value="">Day</option>
												{Date.map((child) => (
													<option key={child} value={child}>
														{child}
													</option>
												))}
											</Input>
										</FormGroup>
									</div>
								</div>
								<small
									className="text-secondary d-flex align-items-center"
									style={{ fontSize: "0.8725rem" }}>
									<AiOutlineInfoCircle className="mr-2" />
									The legal age for full time employment is 15 years old and
									above
								</small>
							</FormGroup>
						</div>
						<div className="col-lg-12">
							<FormGroup>
								<Label>Address</Label>
								<Input
									value={candidateForm.address}
									onChange={(e) =>
										onChangeJobSeekerField("address", e.target.value)
									}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>Postcode</Label>
								<Input
									value={candidateForm.postcode}
									onChange={(e) =>
										onChangeJobSeekerField("postcode", e.target.value)
									}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									Country
									<span className="text-danger">{` *`}</span>
								</Label>
								<Input
									type="select"
									value={candidateForm["country"]}
									onChange={(e) => {
										let tempIndex = _.findIndex(CountryOption, {
											name: e.target.value,
										});
										getStates(CountryOption[tempIndex].id);
										let temp = _.cloneDeep(candidateForm);
										temp.country = e.target.value;
										temp.state = "";
										temp.city = "";
										setTempJobSeeker(temp);
									}}>
									<option value="">Select Country</option>
									{CountryOption.map((child, childIndex) => (
										<option key={`country_${childIndex}`} value={child.name}>
											{child.name}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									State
									<span className="text-danger">{` *`}</span>
								</Label>
								<Input
									type="select"
									value={candidateForm["state"]}
									onChange={(e) => {
										let temp = _.cloneDeep(candidateForm);
										temp.state = e.target.value;
										temp.city = "";
										setTempJobSeeker(temp);
									}}>
									<option value="">Select State</option>
									{states.map((child, childIndex) => (
										<option key={`state_${childIndex}`} value={child.name}>
											{child.name}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									City
									<span className="text-danger">{` *`}</span>
								</Label>
								<Input
									value={candidateForm["city"]}
									placeholder={`Enter City`}
									onChange={(e) => onChangeField("city", e.target.value)}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-12">
							<FormGroup>
								<Label>Expected Salary</Label>
								<Input
									value={candidateForm.expected_salary}
									onChange={(e) =>
										onChangeJobSeekerField("expected_salary", e.target.value)
									}
								/>
							</FormGroup>
							<FormGroup>
								<Label
									className="d-flex"
									style={{ fontWeight: 500, fontSize: "1rem" }}>
									About Me
									<span
										style={{ fontWeight: 300 }}
										className="text-secondary ml-auto">
										{`${"0"}/2000 Characters`}
									</span>
								</Label>
								<Input
									type="textarea"
									value={candidateForm.about_me}
									onChange={(e) =>
										onChangeJobSeekerField("about_me", e.target.value)
									}
								/>
							</FormGroup>
						</div>
					</div>
				</Form>
				<div className="d-flex mt-4">
					<Button
						className="ml-auto"
						size={"lg"}
						color="primary"
						onClick={() => onHandleSubmit()}>
						Save
					</Button>
				</div>
			</div>
		</>
	);
}

export default Edit;
