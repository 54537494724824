import React, { Component } from "react";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import ManageBannerInhouseAPI from "./action.js";
import logo from "assets/images/logo-trans.png";
import homePageImage from "assets/images/home-page-img.png";
import agmo from "assets/images/agmo.png";
import homeBgOverlay from "assets/images/home-bg-overlay.png";
import homeMenu1 from "assets/images/home-menu-1.png";
import homeMenu2 from "assets/images/home-menu-2.png";
import homeMenu3 from "assets/images/home-menu-3.png";
import chinaNews from "assets/images/china-news.png";
import chinaNews2 from "assets/images/china-news-2.png";
import redsquare from "assets/images/redsquare.png";
import { BsGlobe } from "react-icons/bs";
import { AiOutlineUser, AiOutlineSearch } from "react-icons/ai";
import { GrLocation, GrMailOption, GrPhone } from "react-icons/gr";
import { Button, Input } from "reactstrap";
import "./index.scss";

const FooterRouteData2 = [
  {
    route: "/",
    label: "Jobs",
  },
  {
    route: "/",
    label: "Candidate",
  },
  {
    route: "/",
    label: "Fresh Graduate",
  },
  {
    route: "/",
    label: "Apply Job Q&A",
  },
];
const FooterRouter3 = [
  {
    route: "/",
    label: "Create Job Post",
  },
  {
    route: "/",
    label: "Our Service",
  },
  {
    route: "/",
    label: "Pricing",
  },
  {
    route: "/",
    label: "Post Ad Q&A",
  },
];
const FooterRouteData = [
  {
    route: "/about-us",
    label: `About JobMacha`,
  },
  {
    route: "/contact-us",
    label: "Contact Us",
  },
  {
    route: "/disclaimer",
    label: "Disclaimer",
  },
  {
    route: "/privacy-policy ",
    label: "Privacy Policy",
  },
  {
    route: "/terms-of-service",
    label: "Term of Service",
  },
];

const PreviewBannerInhouseWrapper = (props) => {
  const { id } = useParams();

  return <PreviewBannerInhouse id={!!id ? id : null} {...props} />;
};
class PreviewBannerInhouse extends Component {
  componentDidMount = () => {
    if (!!this.props.id) {
      this.props.getBannerById(`&id=${this.props.id}`);
    }
  };

  render = () => {
    const { previewData } = this.props;

    return (
      <div style={{ minHeight: "100vh", width: "100%" }}>
        <div className="e-classified-header-cont">
          <div
            className="d-flex flex-wrap align-items-center"
            style={{ maxWidth: 1400, margin: "auto" }}
          >
            <img
              src={logo}
              alt="eclassifiedLogo"
              width={150}
              height={50}
              style={{ marginRight: "2.5rem", cursor: "pointer" }}
              priority
            />
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "auto" }}
            >
              <>
                <BsGlobe
                  className="text-secondary"
                  style={{ width: "1.5rem", height: "1.5rem" }}
                />
                <div
                  style={{
                    marginLeft: "0.5rem",
                    marginRight: "1rem",
                    textDecoration: "none",
                  }}
                >
                  <span style={{ fontWeight: 500 }} className="text-black">
                    English
                  </span>
                </div>
              </>

              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <AiOutlineUser
                  className="text-secondary"
                  style={{
                    marginRight: "0.5rem",
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                />
                <span className="text-secondary" style={{ pointer: "cursor" }}>
                  Login
                </span>
                <span className="ml-2 mr-2">{`  |  `}</span>
                <span className="text-secondary" style={{ pointer: "cursor" }}>
                  Register
                </span>
              </div>
            </div>
          </div>

          <div className="e-classified-home__header-cont">
            <div className="e-classified-home__header-text-mobile-cont">
              <h3>Find your dream job with us!</h3>
              <p>A trustable platform for you to find / offer a job!</p>
            </div>
            <div className="e-classified-home__header-text-desktop-cont">
              <h3 className="text-secondary">Find your next</h3>
              <h2 className="text-primary">dream Job</h2>
              <h3 className="text-secondary">with us!</h3>
              <p className="text-primary">
                A trustable platform for you to find / offer a job!
              </p>
            </div>
            <div className="e-classified-home__header-img-cont">
              <img src={homePageImage} alt="home-page-img" />
            </div>
          </div>
          <div
            className="bg-white d-flex align-items-center"
            style={{
              marginTop: "4rem",
              padding: "0.5rem 0.8rem",
              borderRadius: "0.5rem",
              border: "1px solid #D9D9D9",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: 1400,
            }}
          >
            <div
              className="d-flex"
              style={{ flex: 1, borderRight: "1px solid #D9D9D9" }}
            >
              <Input
                type={"select"}
                className="text-center"
                style={{
                  border: "none",
                  background: "transparent",
                  margin: "auto",
                  padding: 0,
                }}
              >
                <option value="">Area / Distance</option>
              </Input>
            </div>
            <div
              className="d-flex"
              style={{ flex: 1, borderRight: "1px solid #D9D9D9" }}
            >
              <Input
                type={"select"}
                className="text-center"
                style={{
                  border: "none",
                  background: "transparent",
                  margin: "auto",
                  padding: 0,
                }}
              >
                <option value="">Job Specification</option>
              </Input>
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                flex: 2,
                marginLeft: "1.5rem",
              }}
            >
              <AiOutlineSearch fontSize={24} className="text-primary" />
              <Input
                style={{
                  border: "none",
                  marginLeft: "0.55rem",
                  marginRight: "1rem",
                  padding: 0,
                }}
                placeholder="Search by entering job, service, company or area"
                type="text"
              />
            </div>
            <Button
              color={"primary"}
              className="text-white"
              style={{
                minWidth: "7.5rem",
                padding: "0.75rem  2.5rem",
                fontSize: "1rem",
                fontWeight: 600,
              }}
            >
              <span style={{ fontSize: "1rem" }}>Search</span>
            </Button>
          </div>
        </div>
        <div className="w-100">
          <div className="e-classified-home__header-nav-cont">
            <div className="e-classified-home__header-nav">
              <div className="e-classified-home__header-nav-card">
                <div className="e-classified-home__header-nav-card-img">
                  <img src={homeMenu1} alt="home-menu-job" />
                </div>
                <div className="e-classified-home__header-nav-card-title text-primary">
                  Job Seeker
                </div>
                <div className="e-classified-home__header-nav-card-desc text-primary">
                  抄黄汁流对们写借澡娘
                  <br />
                  扫候游，禾视就
                </div>
              </div>
              <div className="e-classified-home__header-nav-card">
                <div className="e-classified-home__header-nav-card-img">
                  <img src={homeMenu2} alt="home-menu-advertise" />
                </div>
                <div className="e-classified-home__header-nav-card-title text-primary">
                  Advertise With Us
                </div>
                <div className="e-classified-home__header-nav-card-desc text-primary">
                  抄黄汁流对们写借澡娘
                  <br />
                  扫候游，禾视就
                </div>
              </div>
              <div className="e-classified-home__header-nav-card">
                <div className="e-classified-home__header-nav-card-img">
                  <img src={homeMenu3} alt="home-menu-blog" />
                </div>
                <div className="e-classified-home__header-nav-card-title text-primary">
                  Blog
                </div>
                <div className="e-classified-home__header-nav-card-desc text-primary">
                  抄黄汁流对们写借澡娘
                  <br />
                  扫候游，禾视就
                </div>
              </div>
            </div>
          </div>
          <div className="e-classified-home__recomended_job-cont">
            <h3 className="e-classified-home__recomended_job_title text-center text-primary">
              Find Good job
            </h3>
            <p className="e-classified-home__recomended_job_desc text-center text-primary">
              Find the Best Job together with us.
            </p>
            <img
              src={homeBgOverlay}
              alt="home-bg-overlay"
              className="e-classified-home__recomended_job-cont-overlay-img"
            />
          </div>

          <div className="e-classified-banner-cont">
            <h3 className="e-classified-banner-title">{previewData?.title}</h3>
            <img
              src={previewData?.popup_image_en_url}
              alt="banner-desktop-image_en_url"
              className="e-classified-banner-desktop-img"
            />
            <img
              src={previewData?.banner_image_mobile_en_url}
              alt="banner-mobile-image_en_url"
              className="e-classified-banner-mobile-img"
            />

            <img
              src={previewData?.popup_image_cn_url}
              alt="banner-desktop-image_cn_url"
              className="e-classified-banner-desktop-img"
            />
            <img
              src={previewData?.banner_image_mobile_cn_url}
              alt="banner-mobile-image_cn_url"
              className="e-classified-banner-mobile-img"
            />
          </div>

          <div className="e-classified-home__blog-parent-cont">
            <h3 className="e-classified-home__blog-title text-center">
              Featured Job Search Guide
            </h3>
            <span className="d-block e-classified-home__blog-desc text-center">
              Provide the best service for user.
            </span>

            <div style={{ display: "grid", placeItems: "center" }}>
              <div className="e-classified-home__blog-find-more-btn">
                <span>Find More</span>
              </div>
            </div>
          </div>
          <h3 className="e-classified-home__best-company-title text-center">
            Best Employer & Company
          </h3>
          <span className="e-classified-home__best-company-desc d-block text-center">
            Provide the best service for user.
          </span>
          <div className="e-classified-home__best-company-cont">
            <img src={agmo} alt="agmo-1" />
            <img src={agmo} alt="agmo-1" />
            <img src={agmo} alt="agmo-1" />
            <img src={agmo} alt="agmo-1" />
            <img src={agmo} alt="logo-1" />
          </div>
        </div>
        <div
          style={{
            background: "#F0F4FF",
            display: "unset",
          }}
        >
          <div className="e-classified-footer-cont">
            <div className="d-flex flex-column">
              <p
                className="fw-bold text-primary mb-3"
                style={{ textTransform: "uppercase" }}
              >
                eClassified 分类在线
              </p>
              <div className="e-classified-footer-cont-menu">
                {FooterRouteData.map((route, routeIndex) => (
                  <div
                    key={`route_${route.route}_2_${routeIndex}`}
                    className="text-black"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    {route.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex flex-column">
              <p
                className="fw-bold text-primary mb-3"
                style={{ textTransform: "uppercase" }}
              >
                For Candidate
              </p>
              <div className="e-classified-footer-cont-menu">
                {FooterRouteData2.map((route, routeIndex) => (
                  <div
                    key={`route_${route.route}_2_${routeIndex}`}
                    className="text-black"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    {route.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex flex-column">
              <p
                className="fw-bold text-primary mb-3"
                style={{ textTransform: "uppercase" }}
              >
                For Employer
              </p>
              <div className="e-classified-footer-cont-menu">
                {FooterRouter3.map((route, routeIndex) => (
                  <div
                    key={`route_${route.route}_2_${routeIndex}`}
                    className="text-black"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    {route.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex flex-column">
              <p
                className="fw-bold text-primary mb-3"
                style={{ textTransform: "uppercase", cursor: "pointer" }}
              >
                Help Centre
              </p>
              <p
                className="fw-bold text-primary"
                style={{ textTransform: "uppercase", cursor: "pointer" }}
              >
                Blog
              </p>
            </div>
            <div>
              <div className="mb-3" style={{ width: "18rem" }}>
                <GrLocation
                  style={{
                    marginRight: "0.5rem",
                    cursor: "pointer",
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                  onClick={() =>
                    window
                      .open(
                        `https://www.google.com/maps/search/?api=1&query=No. 80, Jalan Riong Off Jalan Bangsar, 59100 Kuala Lumpur.`,
                        "_blank"
                      )
                      .focus()
                  }
                />
                <span>
                  No. 80, Jalan Riong Off Jalan Bangsar, 59100 Kuala Lumpur.
                </span>
              </div>
              <div className="mb-3">
                <GrPhone
                  style={{
                    marginRight: "0.5rem",
                    cursor: "pointer",
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                  onClick={() => window.open("tel:+03-22896188", "_blank")}
                />
                <span>03-2289 6188 / 03-22896188</span>
              </div>
              <div className="mb-3">
                <GrMailOption
                  style={{
                    marginRight: "0.5rem",
                    cursor: "pointer",
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                  onClick={() =>
                    window.open(
                      "mailto:eclassified@chinapress.com.my",
                      "_blank"
                    )
                  }
                />
                <span>eclassified@chinapress.com.my</span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap w-100">
              <div
                className="d-flex align-items-end flex-wrap"
                style={{ marginBottom: "2.5rem" }}
              >
                <img
                  src={chinaNews2}
                  alt="china-news-logo-2"
                  width={68}
                  height={88}
                  style={{ marginRight: "2.5rem", marginBottom: -12 }}
                  priority
                />
                <img
                  src={chinaNews}
                  alt="china-news-logo"
                  width={135}
                  height={56}
                  priority
                />
              </div>
              <div className="ml-auto">
                <p
                  className="fw-bold text-primary mb-3"
                  style={{ textTransform: "uppercase" }}
                >
                  Tech Partner
                </p>
                <div className="d-flex align-items-center">
                  <img
                    src={agmo}
                    alt="china-news-logo"
                    className="mb-4"
                    width={90}
                    height={43}
                    priority
                  />
                  <img
                    src={redsquare}
                    alt="china-news-logo"
                    width={136}
                    height={18}
                    priority
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(`https://www.redsquare.software`, "_blank")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="py-3 text-center" style={{ fontSize: "0.8rem" }}>
            <span style={{ alignSelf: "center" }}>
              <span style={{ color: "#8C8C8C" }}>
                Copyright © The China Press Berhad | Powered by Agmo
              </span>
              <span className="span text-primary">{` RedSquare`}</span>
            </span>
            <div
              style={{
                marginLeft: "9.5rem",
                color: "#8C8C8C",
                textDecoration: "none",
              }}
            >
              Disclaimer
            </div>
            <div
              style={{
                marginLeft: "2.5rem",
                color: "#8C8C8C",
                textDecoration: "none",
              }}
            >
              Privacy Policy
            </div>
            <div
              style={{
                marginLeft: "2.5rem",
                color: "#8C8C8C",
                textDecoration: "none",
              }}
            >
              Terms and Condition
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default compose(ManageBannerInhouseAPI)(PreviewBannerInhouseWrapper);
