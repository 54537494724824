export const employmentTypeOptions = [
  { label: "Full Time", value: "full_time" },
  { label: "Part Time", value: "part_time" },
  { label: "Internship", value: "internship" },
];

export const shiftTypeOptions = ["Morning Shift", "Night Shift"];

export const benefitOptions = [
  "5 Working Days",
  "Meal Allowance",
  "Transport Allowance",
  "Yearly Bonus",
  "Others",
];

export const planOptions = ["Ads Plan", "Ads Slot"];

export const categoryMappings = {
    'General': 'JobMacha Website',
    'Featured': 'News Website Feature',
    'Facebook': 'Facebook Post'
}

function mapAndFlattenFields(fields) {
    const flattenFields = [];

    const formatValue = (str) => str.toLowerCase()
        .replace(/[\s&]+/g, '_')
        .replace(/[^\w_]+/g, '');

    const addField = (field) => {
        flattenFields.push({
            value: formatValue(field.name.en),
            en: field.name.en,
            zh: field.name.zh
        });

        if (field.children) {
            field.children.forEach(child => addField(child));
        }
    };

    fields.forEach(field => addField(field));
    return flattenFields;
}

export const FieldOfStudies = [
    {
        "id": 1,
        "name": {
            "en": "Agriculture, Forestry, Fisheries, and Environmental Sciences",
            "zh": "农林渔牧、地理环境科学"
        },
        "children": [
            {
                "id": 2,
                "name": {
                    "en": "Agricultural Studies",
                    "zh": "农学"
                }
            },
            {
                "id": 3,
                "name": {
                    "en": "Agribusiness",
                    "zh": "农商"
                }
            },
            {
                "id": 4,
                "name": {
                    "en": "Agricultural Sciences",
                    "zh": "农业科学"
                }
            },
            {
                "id": 5,
                "name": {
                    "en": "Horticulture",
                    "zh": "园艺学"
                }
            },
            {
                "id": 6,
                "name": {
                    "en": "Forestry Studies",
                    "zh": "森林学"
                }
            },
            {
                "id": 7,
                "name": {
                    "en": "Pulp and Paper Technology",
                    "zh": "造纸工艺"
                }
            },
            {
                "id": 8,
                "name": {
                    "en": "Wood Science and Technology",
                    "zh": "木料工艺"
                }
            },
            {
                "id": 9,
                "name": {
                    "en": "Earth and Environment",
                    "zh": "地球与环境系"
                }
            },
            {
                "id": 10,
                "name": {
                    "en": "Ecology",
                    "zh": "生态学"
                }
            },
            {
                "id": 11,
                "name": {
                    "en": "Environmental Science",
                    "zh": "环境科学"
                }
            },
            {
                "id": 12,
                "name": {
                    "en": "Geography",
                    "zh": "地理学"
                }
            },
            {
                "id": 13,
                "name": {
                    "en": "Geology",
                    "zh": "地质学"
                }
            },
            {
                "id": 14,
                "name": {
                    "en": "Oceanography",
                    "zh": "海洋学"
                }
            },
            {
                "id": 15,
                "name": {
                    "en": "Fishery and Animal Husbandry Studies",
                    "zh": "渔牧系"
                }
            },
            {
                "id": 16,
                "name": {
                    "en": "Animal Husbandry",
                    "zh": "畜牧学"
                }
            },
            {
                "id": 17,
                "name": {
                    "en": "Aquaculture",
                    "zh": "水产生物学"
                }
            }
        ]
    },
    {
        "id": 18,
        "name": {
            "en": "Architecture and Urban Planning",
            "zh": "建筑与城市规划类"
        },
        "children": [
            {
                "id": 19,
                "name": {
                    "en": "Architecture and Design",
                    "zh": "建筑与设计"
                }
            },
			{
                "id": 20,
                "name": {
                    "en": "Architecture",
                    "zh": "建筑设计"
                }
            },
            {
                "id": 21,
                "name": {
                    "en": "Landscape Architecture",
                    "zh": "景观设计"
                }
            },
            {
                "id": 22,
                "name": {
                    "en": "Urban and Township Planning Studies",
                    "zh": "城市与区域规划"
                }
            },
            {
                "id": 23,
                "name": {
                    "en": "Built Environment",
                    "zh": "建成环境"
                }
            },
            {
                "id": 24,
                "name": {
                    "en": "Building Surveying",
                    "zh": "建筑测量"
                }
            },
            {
                "id": 25,
                "name": {
                    "en": "Construction Management",
                    "zh": "建筑管理"
                }
            },
            {
                "id": 26,
                "name": {
                    "en": "Quantity Surveying",
                    "zh": "材料测量"
                }
            }
        ]
    },
    {
        "id": 27,
        "name": {
            "en": "Beauty and Styling",
            "zh": "美容与造型类"
        },
        "children": [
            {
                "id": 28,
                "name": {
                    "en": "Beauty",
                    "zh": "美容"
                }
            },
            {
                "id": 29,
                "name": {
                    "en": "Body Care",
                    "zh": "身体护理"
                }
            },
            {
                "id": 30,
                "name": {
                    "en": "Hair Styling",
                    "zh": "美发"
                }
            },
            {
                "id": 31,
                "name": {
                    "en": "Make up",
                    "zh": "化妆"
                }
            },
            {
                "id": 32,
                "name": {
                    "en": "Pet Grooming",
                    "zh": "宠物美容"
                }
            }
        ]
    },
    {
        "id": 33,
        "name": {
            "en": "Business and Management",
            "zh": "商业管理类"
        },
        "children": [
            {
                "id": 34,
                "name": {
                    "en": "Aviation Management",
                    "zh": "航空管理"
                }
            },
            {
                "id": 35,
                "name": {
                    "en": "Business Management / Administration",
                    "zh": "工商管理"
                }
            },
            {
                "id": 36,
                "name": {
                    "en": "Human Resource Management",
                    "zh": "人力资源管理"
                }
            },
            {
                "id": 37,
                "name": {
                    "en": "International Business",
                    "zh": "国际商务"
                }
            },
            {
                "id": 38,
                "name": {
                    "en": "Logistics Management",
                    "zh": "物流管理"
                }
            },
            {
                "id": 39,
                "name": {
                    "en": "Maritime Management",
                    "zh": "海运管理"
                }
            },
            {
                "id": 40,
                "name": {
                    "en": "Marketing",
                    "zh": "行销管理"
                }
            },
            {
                "id": 41,
                "name": {
                    "en": "Property Management",
                    "zh": "产业管理"
                }
            },
            {
                "id": 42,
                "name": {
                    "en": "Public Administration",
                    "zh": "公共管理"
                }
            },
            {
                "id": 43,
                "name": {
                    "en": "Technology Process Management",
                    "zh": "工艺管理"
                }
            }
        ]
    },
    {
        "id": 44,
        "name": {
            "en": "Communication Studies",
            "zh": "传播类"
        },
        "children": [
            {
                "id": 45,
                "name": {
                    "en": "Advertising",
                    "zh": "广告学"
                }
            },
            {
                "id": 46,
                "name": {
                    "en": "Broadcasting, TV and Film",
                    "zh": "广播电视电影"
                }
            },
            {
                "id": 47,
                "name": {
                    "en": "Communication Management",
                    "zh": "传播管理"
                }
            },
            {
                "id": 48,
                "name": {
                    "en": "Graphic Communication",
                    "zh": "图文传播"
                }
            },
            {
                "id": 49,
                "name": {
                    "en": "Information and Communication",
                    "zh": "资讯传播"
                }
            },
            {
                "id": 50,
                "name": {
                    "en": "Journalism",
                    "zh": "新闻学"
                }
            },
            {
                "id": 51,
                "name": {
                    "en": "Mass Communication",
                    "zh": "大众传播"
                }
            },
            {
                "id": 52,
                "name": {
                    "en": "Media Studies",
                    "zh": "媒体研究"
                }
            },
            {
                "id": 53,
                "name": {
                    "en": "Public Relations",
                    "zh": "公共关系"
                }
            },
            {
                "id": 54,
                "name": {
                    "en": "Speech Communication",
                    "zh": "口语传播"
                }
            },
            {
                "id": 55,
                "name": {
                    "en": "Visual Communication",
                    "zh": "视觉传播"
                }
            }
        ]
    },
    {
        "id": 56,
        "name": {
            "en": "Computer and Information Technology",
            "zh": "电脑与资讯科技类"
        },
        "children": [
            {
                "id": 57,
                "name": {
                    "en": "Computer Science",
                    "zh": "电脑科学"
                }
            },
            {
                "id": 58,
                "name": {
                    "en": "Computer Security System",
                    "zh": "电脑保安系统"
                }
            },
            {
                "id": 59,
                "name": {
                    "en": "Information Technology",
                    "zh": "资讯工艺"
                }
            },
            {
                "id": 60,
                "name": {
                    "en": "Intelligence System Technology",
                    "zh": "智能电脑系统科技"
                }
            },
            {
                "id": 61,
                "name": {
                    "en": "Multimedia Technology",
                    "zh": "多媒体科技"
                }
            }
        ]
    },
    {
        "id": 62,
        "name": {
            "en": "Design and Arts",
            "zh": "设计与艺术类"
        },
        "children": [
            {
                "id": 63,
                "name": {
                    "en": "Applied Design",
                    "zh": "应用设计"
                }
            },
            {
                "id": 64,
                "name": {
                    "en": "Fashion Design",
                    "zh": "服装设计"
                }
            },
            {
                "id": 65,
                "name": {
                    "en": "Graphic / Visual Design",
                    "zh": "平面/视觉设计"
                }
            },
            {
                "id": 66,
                "name": {
                    "en": "Industrial / Product Design",
                    "zh": "工业/产品设计"
                }
            },
            {
                "id": 67,
                "name": {
                    "en": "Interior Design",
                    "zh": "室内设计"
                }
            },
            {
                "id": 68,
                "name": {
                    "en": "Jewellery Design",
                    "zh": "珠宝设计"
                }
            },
            {
                "id": 69,
                "name": {
                    "en": "Multimedia / Animation Design",
                    "zh": "多媒体/动画设计"
                }
            },
            {
                "id": 70,
                "name": {
                    "en": "Transport Design",
                    "zh": "交通工具造型设计"
                }
            },
            {
                "id": 71,
                "name": {
                    "en": "Art",
                    "zh": "艺术"
                }
            },
            {
                "id": 72,
                "name": {
                    "en": "Dance",
                    "zh": "舞蹈"
                }
            },
            {
                "id": 73,
                "name": {
                    "en": "Drama",
                    "zh": "演艺与戏剧"
                }
            },
            {
                "id": 74,
                "name": {
                    "en": "Fine Art",
                    "zh": "美术"
                }
            },
            {
                "id": 75,
                "name": {
                    "en": "Music",
                    "zh": "音乐"
                }
            }
        ]
    },
    {
        "id": 76,
        "name": {
            "en": "Engineering and Technology",
            "zh": "工程科技类"
        },
        "children": [
            {
                "id": 77,
                "name": {
                    "en": "Engineering",
                    "zh": "工程学系"
                }
            },
            {
                "id": 78,
                "name": {
                    "en": "Aerospace Engineering",
                    "zh": "宇航工程"
                }
            },
            {
                "id": 79,
                "name": {
                    "en": "Automobile Engineering",
                    "zh": "汽车工程"
                }
            },
            {
                "id": 80,
                "name": {
                    "en": "Bio Agricultural Engineering",
                    "zh": "生物农业工程"
                }
            },
            {
                "id": 81,
                "name": {
                    "en": "Biochemical Engineering",
                    "zh": "生活化学工程"
                }
            },
            {
                "id": 82,
                "name": {
                    "en": "Biomedical Engineering",
                    "zh": "生物医学工程"
                }
            },
            {
                "id": 83,
                "name": {
                    "en": "Chemical Engineering",
                    "zh": "化学工程"
                }
            },
            {
                "id": 84,
                "name": {
                    "en": "Civil Engineering",
                    "zh": "土木工程"
                }
            },
            {
                "id": 85,
                "name": {
                    "en": "Electrical and Electronic Engineering",
                    "zh": "电机与电子工程"
                }
            },
            {
                "id": 86,
                "name": {
                    "en": "Environment Engineering",
                    "zh": "环境工程"
                }
            },
            {
                "id": 87,
                "name": {
                    "en": "Geomatics Engineering",
                    "zh": "地理信息工程学"
                }
            },
            {
                "id": 88,
                "name": {
                    "en": "Food Engineering",
                    "zh": "食品工程"
                }
            },
            {
                "id": 89,
                "name": {
                    "en": "Manufacturing Engineering",
                    "zh": "制造工程"
                }
            },
            {
                "id": 90,
                "name": {
                    "en": "Marine Engineering",
                    "zh": "海洋工程"
                }
            },
            {
                "id": 91,
                "name": {
                    "en": "Material Engineering",
                    "zh": "材料工程"
                }
            },
            {
                "id": 92,
                "name": {
                    "en": "Mechanical Engineering",
                    "zh": "机械工程"
                }
            },
            {
                "id": 93,
                "name": {
                    "en": "Mechatronic Engineering",
                    "zh": "电子机械工程"
                }
            },
            {
                "id": 94,
                "name": {
                    "en": "Microelectronic Engineering",
                    "zh": "微电子工程"
                }
            },
            {
                "id": 95,
                "name": {
                    "en": "Nuclear Engineering",
                    "zh": "核子工程"
                }
            },
            {
                "id": 96,
                "name": {
                    "en": "Telecommunications Engineering",
                    "zh": "电讯工程"
                }
            }
        ]
    },
    {
        "id": 97,
        "name": {
            "en": "Education",
            "zh": "教育类"
        },
        "children": [
            {
                "id": 98,
                "name": {
                    "en": "Counselor Education",
                    "zh": "辅导教育"
                }
            },
            {
                "id": 99,
                "name": {
                    "en": "Early Childhood Education",
                    "zh": "学前教育"
                }
            },
            {
                "id": 100,
                "name": {
                    "en": "Educational Administration and Management",
                    "zh": "教育行政与管理"
                }
            },
            {
                "id": 101,
                "name": {
                    "en": "Literature, Science and Business Education",
                    "zh": "文/理/商科教育"
                }
            },
            {
                "id": 102,
                "name": {
                    "en": "Physical Education",
                    "zh": "体育教育"
                }
            },
            {
                "id": 103,
                "name": {
                    "en": "Special Education",
                    "zh": "特殊教育"
                }
            },
            {
                "id": 104,
                "name": {
                    "en": "Technical and Vocational Education",
                    "zh": "技职教育"
                }
            }
        ]
    },
    {
        "id": 105,
        "name": {
            "en": "Finance and Economics",
            "zh": "财经类"
        },
        "children": [
            {
                "id": 106,
                "name": {
                    "en": "Accounting",
                    "zh": "会计学"
                }
            },
            {
                "id": 107,
                "name": {
                    "en": "Banking",
                    "zh": "银行学"
                }
            },
            {
                "id": 108,
                "name": {
                    "en": "Commerce",
                    "zh": "商务学"
                }
            },
            {
                "id": 109,
                "name": {
                    "en": "Crisis Management and Insurance",
                    "zh": "风险管理和保险学"
                }
            },
            {
                "id": 110,
                "name": {
                    "en": "Economics",
                    "zh": "经济学"
                }
            },
            {
                "id": 111,
                "name": {
                    "en": "Finance",
                    "zh": "金融学"
                }
            },
            {
                "id": 112,
                "name": {
                    "en": "Finance and Banking",
                    "zh": "金融和银行学"
                }
            },
            {
                "id": 113,
                "name": {
                    "en": "Islamic Finance and Banking",
                    "zh": "回教金融与银行学"
                }
            }
        ]
    },
    {
        "id": 114,
        "name": {
            "en": "Hospitality, Recreation, and Tourism",
            "zh": "餐饮、休闲与旅游类"
        },
        "children": [
            {
                "id": 115,
                "name": {
                    "en": "Culinary Arts",
                    "zh": "烹饪艺术"
                }
            },
            {
                "id": 116,
                "name": {
                    "en": "Event Management",
                    "zh": "活动策划"
                }
            },
            {
                "id": 117,
                "name": {
                    "en": "Food and Beverage Management",
                    "zh": "餐饮管理"
                }
            },
            {
                "id": 118,
                "name": {
                    "en": "Hotel Management",
                    "zh": "酒店管理"
                }
            },
            {
                "id": 119,
                "name": {
                    "en": "Tourism Management",
                    "zh": "旅游管理"
                }
            }
        ]
    },
    {
        "id": 120,
        "name": {
            "en": "Humanities, Language, and Literature",
            "zh": "文史与语言类"
        },
        "children": [
            {
                "id": 121,
                "name": {
                    "en": "Chinese Studies",
                    "zh": "中文"
                }
            },
            {
                "id": 122,
                "name": {
                    "en": "English Studies",
                    "zh": "英文"
                }
            },
            {
                "id": 123,
                "name": {
                    "en": "Foreign Language",
                    "zh": "外语"
                }
            },
            {
                "id": 124,
                "name": {
                    "en": "History",
                    "zh": "历史"
                }
            },
            {
                "id": 125,
                "name": {
                    "en": "Indian Studies",
                    "zh": "印度研究"
                }
            },
            {
                "id": 126,
                "name": {
                    "en": "Islamic Studies",
                    "zh": "回教研究"
                }
            },
            {
                "id": 127,
                "name": {
                    "en": "Malay Studies",
                    "zh": "马来研究"
                }
            },
            {
                "id": 128,
                "name": {
                    "en": "Southeast Asian Studies",
                    "zh": "东南亚/东亚研究"
                }
            },
            {
                "id": 129,
                "name": {
                    "en": "Translation",
                    "zh": "翻译"
                }
            }
        ]
    },
    {
        "id": 130,
        "name": {
            "en": "Life Sciences",
            "zh": "生命科学类"
        },
        "children": [
            {
                "id": 131,
                "name": {
                    "en": "Biochemistry",
                    "zh": "生物化学"
                }
            },
            {
                "id": 132,
                "name": {
                    "en": "Bioinfomatics",
                    "zh": "生物资讯学"
                }
            },
            {
                "id": 133,
                "name": {
                    "en": "Biology",
                    "zh": "生物学"
                }
            },
            {
                "id": 134,
                "name": {
                    "en": "Biotechnology",
                    "zh": "生物工艺"
                }
            },
            {
                "id": 135,
                "name": {
                    "en": "Botany",
                    "zh": "植物学"
                }
            },
            {
                "id": 136,
                "name": {
                    "en": "Genetics",
                    "zh": "遗传学"
                }
            },
            {
                "id": 137,
                "name": {
                    "en": "Microbiology",
                    "zh": "微生物学"
                }
            },
            {
                "id": 138,
                "name": {
                    "en": "Sports Science",
                    "zh": "体育科学"
                }
            },
            {
                "id": 139,
                "name": {
                    "en": "Zoology",
                    "zh": "动物学"
                }
            },
			{
                "id": 140,
                "name": {
                    "en": "Food Science",
                    "zh": "食品科学"
                }
            },
            {
                "id": 141,
                "name": {
                    "en": "Food Technology",
                    "zh": "食品工艺"
                }
            }
        ]
    },
    {
        "id": 142,
        "name": {
            "en": "Medical and Health Sciences",
            "zh": "医疗与保健类"
        },
        "children": [
            {
                "id": 143,
                "name": {
                    "en": "Food Nutrition",
                    "zh": "食物营养学"
                }
            },
            {
                "id": 144,
                "name": {
                    "en": "Diet Therapy",
                    "zh": "饮食治疗学"
                }
            },
            {
                "id": 145,
                "name": {
                    "en": "Nutriology",
                    "zh": "营养学"
                }
            },
            {
                "id": 146,
                "name": {
                    "en": "Medical Studies",
                    "zh": "医学"
                }
            },
            {
                "id": 147,
                "name": {
                    "en": "Biomedical Science",
                    "zh": "生物医学"
                }
            },
            {
                "id": 148,
                "name": {
                    "en": "Dentistry",
                    "zh": "牙医"
                }
            },
            {
                "id": 149,
                "name": {
                    "en": "Diagnostic Radiography and Radiation Therapy",
                    "zh": "影像诊断与放射性治疗"
                }
            },
            {
                "id": 150,
                "name": {
                    "en": "Environmental and Occupational Health",
                    "zh": "环境与职业健康学"
                }
            },
            {
                "id": 151,
                "name": {
                    "en": "Medicine",
                    "zh": "医科"
                }
            },
            {
                "id": 152,
                "name": {
                    "en": "Traditional Chinese Medicine",
                    "zh": "中医"
                }
            },
            {
                "id": 153,
                "name": {
                    "en": "Veterinary",
                    "zh": "兽医"
                }
            },
            {
                "id": 154,
                "name": {
                    "en": "Rehabilitation Therapy",
                    "zh": "复健治疗学"
                }
            },
            {
                "id": 155,
                "name": {
                    "en": "Audiology",
                    "zh": "听力学"
                }
            },
            {
                "id": 156,
                "name": {
                    "en": "Optometry",
                    "zh": "光学"
                }
            },
            {
                "id": 157,
                "name": {
                    "en": "Occuoational Therapy",
                    "zh": "职能治疗"
                }
            },
            {
                "id": 158,
                "name": {
                    "en": "Physiotherapy",
                    "zh": "物理治疗学"
                }
            },
            {
                "id": 159,
                "name": {
                    "en": "Speech Therapy",
                    "zh": "语言治疗学"
                }
            },
            {
                "id": 160,
                "name": {
                    "en": "Pharmacy and Nursing",
                    "zh": "药理"
                }
            },
            {
                "id": 161,
                "name": {
                    "en": "Nursing",
                    "zh": "护理"
                }
            },
            {
                "id": 162,
                "name": {
                    "en": "Pharmacy",
                    "zh": "药剂学"
                }
            }
        ]
    },
    {
        "id": 163,
        "name": {
            "en": "Physical and Mathematical Sciences",
            "zh": "数理类"
        },
        "children": [
            {
                "id": 164,
                "name": {
                    "en": "Applied Science",
                    "zh": "应用科学"
                }
            },
            {
                "id": 165,
                "name": {
                    "en": "Applied Physics",
                    "zh": "应用物理"
                }
            },
            {
                "id": 166,
                "name": {
                    "en": "Forensic Science",
                    "zh": "鉴证科学"
                }
            },
            {
                "id": 167,
                "name": {
                    "en": "Industrial Chemistry",
                    "zh": "工业化学"
                }
            },
            {
                "id": 168,
                "name": {
                    "en": "Material Science",
                    "zh": "材料科学"
                }
            },
            {
                "id": 169,
                "name": {
                    "en": "Nuclear Science",
                    "zh": "核子科学"
                }
            },
            {
                "id": 170,
                "name": {
                    "en": "Palm Oil Chemistry",
                    "zh": "棕油化学"
                }
            },
            {
                "id": 171,
                "name": {
                    "en": "Petroleum Chemistry",
                    "zh": "石油化学"
                }
            },
            {
                "id": 172,
                "name": {
                    "en": "Mathematics and Sciences",
                    "zh": "数理化学"
                }
            },
            {
                "id": 173,
                "name": {
                    "en": "Actuarial Sciences",
                    "zh": "精算学"
                }
            },
            {
                "id": 174,
                "name": {
                    "en": "Chemistry",
                    "zh": "化学"
                }
            },
            {
                "id": 175,
                "name": {
                    "en": "Mathematics",
                    "zh": "数学"
                }
            },
            {
                "id": 176,
                "name": {
                    "en": "Physics",
                    "zh": "物理学"
                }
            },
            {
                "id": 177,
                "name": {
                    "en": "Statistics",
                    "zh": "统计学"
                }
            }
        ]
    },
    {
        "id": 178,
        "name": {
            "en": "Social Sciences, Law, and Humanities",
            "zh": "人文、法律与社会科学类"
        },
        "children": [
            {
                "id": 179,
                "name": {
                    "en": "Law",
                    "zh": "法律"
                }
            },
            {
                "id": 180,
                "name": {
                    "en": "Islamic Law",
                    "zh": "回教法律"
                }
            },
            {
                "id": 181,
                "name": {
                    "en": "Political Science",
                    "zh": "政治"
                }
            },
            {
                "id": 182,
                "name": {
                    "en": "International Relations",
                    "zh": "国际关系研究"
                }
            },
            {
                "id": 183,
                "name": {
                    "en": "Psychology",
                    "zh": "心理学"
                }
            },
            {
                "id": 184,
                "name": {
                    "en": "Cognitive Science",
                    "zh": "认知科学"
                }
            },
            {
                "id": 185,
                "name": {
                    "en": "Counseling and Guidance",
                    "zh": "咨商与辅导"
                }
            },
            {
                "id": 186,
                "name": {
                    "en": "Sociology",
                    "zh": "社会学"
                }
            },
            {
                "id": 187,
                "name": {
                    "en": "Consumption Studies",
                    "zh": "消费学"
                }
            },
            {
                "id": 188,
                "name": {
                    "en": "Development Anthropology",
                    "zh": "人类发展学"
                }
            },
            {
                "id": 189,
                "name": {
                    "en": "Population Studies",
                    "zh": "人口研究"
                }
            },
            {
                "id": 190,
                "name": {
                    "en": "Social Science",
                    "zh": "社会科学"
                }
            },
            {
                "id": 191,
                "name": {
                    "en": "Social Worker",
                    "zh": "社工学"
                }
            }
        ]
    }
]

const mappedFieldOfStudies = mapAndFlattenFields(FieldOfStudies);

export const JobRequirements = [
	{
		value: 'field_of_study',
		en: 'Relevant Field of Study',
		zh: '相关领域学历',
		placeholder: 'Enter Relevant Field of Study',
		placeholder_zh: '输入相关领域学历',
		options: mappedFieldOfStudies
	},
	{
		value: 'experience',
		en: 'Relevant Experience',
		zh: '相关工作经验',
		placeholder: 'Enter Relevant Experience',
		placeholder_zh: '输入相关工作经验',
		options: [
			{ value: 'with_or_without_experience', en: 'With or Without Experience', zh: '有无经验皆可' },
			{ value: 'finance_accounting_experience', en: 'Finance/Accounting Experience', zh: '财务/会计经验' },
			{ value: 'sales_experience', en: 'Sales Experience', zh: '销售经验' },
			{ value: 'marketing_experience', en: 'Marketing Experience', zh: '市场营销经验' },
			{ value: 'customer_service_experience', en: 'Customer Service Experience', zh: '客户服务经验' },
			{ value: 'project_management_experience', en: 'Project Management Experience', zh: '项目管理经验' },
			{ value: 'technical_support_experience', en: 'Technical Support Experience', zh: '技术支援经验' },
			{ value: 'leadership_experience', en: 'Leadership Experience', zh: '领导经验' },
		]
	},
	{
		value: 'software_skills',
		en: 'Software Skills',
		zh: '软件技能',
		placeholder: 'Enter Software Skills',
		placeholder_zh: '输入软件技能',
		options: [
			{ value: 'microsoft_office', en: 'Microsoft Office', zh: 'Microsoft Office' },
			{ value: 'microsoft_word', en: 'Microsoft Word', zh: 'Microsoft Word' },
			{ value: 'microsoft_excel', en: 'Microsoft Excel', zh: 'Microsoft Excel' },
			{ value: 'microsoft_powerpoint', en: 'Microsoft Power Point', zh: 'Microsoft Power Point' },
			{ value: 'microsoft_outlook', en: 'Microsoft Outlook ', zh: 'Microsoft Outlook ' },
			{ value: 'microsoft_navision', en: 'Microsoft Navision', zh: 'Microsoft Navision' },
			{ value: 'content_management_system', en: 'Content Management System', zh: 'Content Management System' },
			{ value: 'erp_management_system', en: 'ERP Management System', zh: 'ERP Management System' },
			{ value: 'payroll_system', en: 'Payroll System', zh: 'Payroll System' },
			{ value: 'autocount_accounting_software', en: 'AutoCount Accounting Software', zh: 'AutoCount Accounting Software' },
			{ value: 'sql_accounting_software', en: 'SQL Accounting Software', zh: 'SQL Accounting Software' },
			{ value: 'ubs_accounting_software', en: 'UBS Accounting Software', zh: 'UBS Accounting Software' },
			{ value: 'qne_accounting_software', en: 'QNE Accounting Software', zh: 'QNE Accounting Software' },
			{ value: 'quickbooks_accounting_software', en: 'QuickBooks Accounting Software', zh: 'QuickBooks Accounting Software' },
			{ value: 'autocad_software', en: 'AutoCAD Software', zh: 'AutoCAD Software' },
			{ value: 'mastercam_software', en: 'MasterCAM Software', zh: 'MasterCAM Software' },
			{ value: 'adobe_photoshop', en: 'Adobe Photoshop', zh: 'Adobe Photoshop' },
			{ value: 'adobe_illustrator', en: 'Adobe Illustrator', zh: 'Adobe Illustrator' },
			{ value: 'adobe_indesign', en: 'Adobe InDesign', zh: 'Adobe InDesign' },
			{ value: 'adobe_premiere_pro', en: 'Adobe Premiere Pro', zh: 'Adobe Premiere Pro' },
			{ value: 'adobe_after_effects', en: 'Adobe After Effects', zh: 'Adobe After Effects' },
			{ value: 'adobe_animate', en: 'Adobe Animate ', zh: 'Adobe Animate ' },
			{ value: 'adobe_dreamweaver', en: 'Adobe Dreamweaver', zh: 'Adobe Dreamweaver' },
			{ value: 'adobe_xd', en: 'Adobe XD', zh: 'Adobe XD' },
			{ value: 'google_drive', en: 'Google Drive', zh: 'Google Drive' },
			{ value: 'google_docs', en: 'Google Docs', zh: 'Google Docs' },
			{ value: 'google_sheets', en: 'Google Sheets', zh: 'Google Sheets' },
			{ value: 'google_slides', en: 'Google Slides', zh: 'Google Slides' },
			{ value: 'google_forms', en: 'Google Forms', zh: 'Google Forms' },
			{ value: 'java', en: 'Java', zh: 'Java' },
			{ value: 'php', en: 'PHP', zh: 'PHP' },
			{ value: 'mysql', en: 'MySQL', zh: 'MySQL' },
			{ value: 'sql', en: 'SQL', zh: 'SQL' },
			{ value: 'c#', en: 'C#', zh: 'C#' },
			{ value: 'c++', en: 'C++', zh: 'C++' },
			{ value: 'javascript', en: 'JavaScript', zh: 'JavaScript' },
			{ value: 'python', en: 'Python', zh: 'Python' },
		]
	},
	{
		value: 'hard_skills',
		en: 'Hard Skills',
		zh: '硬技能',
		placeholder: 'Enter Hard Skills',
		placeholder_zh: '输入硬技能',
		options: [
			{ value: 'date_entry', en: 'Data Entry', zh: '数据输入' },
			{ value: 'project_mngmt', en: 'Project Management', zh: '项目管理' },
			{ value: 'human_resource_mngmt', en: 'Human Resource Management', zh: '人力资源管理' },
			{ value: 'customer_relation_mngmt', en: 'Customer Relationship Management', zh: '客户关系管理' },
			{ value: 'marketing_campaign_mngmt', en: 'Marketing Campaign Management', zh: '营销活动管理' },
			{ value: 'digital_marketing', en: 'Digital Marketing', zh: '数码营销' },
			{ value: 'market_research', en: 'Market Research', zh: '市场调查' },
			{ value: 'data_analysis', en: 'Data Analysis', zh: '数据分析' },
			{ value: 'financial_analysis', en: 'Financial Analysis', zh: '财务分析' },
			{ value: 'statiscal_analysis', en: 'Statistical Analysis', zh: '统计分析' },
			{ value: 'writing_editing', en: 'Writing and Editing', zh: '写作编辑' },
			{ value: 'language_translation', en: 'Language Translation', zh: '语言翻译' },
			{ value: 'video_editing', en: 'Video Editing', zh: '影片剪辑' },
			{ value: 'graphic', en: 'Graphic Design', zh: '平面设计' },
			{ value: 'ui/ux_design', en: 'UI / UX Design', zh: 'UI / UX 设计' },
			{ value: 'programming_lang', en: 'Programming Languages', zh: '程式语言' },
			{ value: 'server_maintainance', en: 'Server Maintenance', zh: '伺服器维护' },
		]
	},
	{
		value: 'soft_skills',
		en: 'Soft Skills',
		zh: '软技能',
		placeholder: 'Enter Soft Skills',
		placeholder_zh: '输入软技能',
		options: [
			{ value: 'communication_skills', en: 'Communication Skills', zh: '沟通能力' },
			{ value: 'interpersonal_skills', en: 'Interpersonal Skills', zh: '人际交往能力' },
			{ value: 'negotiation_skills', en: 'Negotiation Skills', zh: '谈判能力' },
			{ value: 'teamwork', en: 'Teamwork', zh: '团队合作' },
			{ value: 'leadership', en: 'Leadership', zh: '领导能力' },
			{ value: 'problem_solving_skills', en: 'Problem-Solving Skills', zh: '解决问题能力' },
			{ value: 'multitasking_skills', en: 'Multitasking Skills', zh: '多工处理能力' },
			{ value: 'mngmt_skills', en: 'Management Skills', zh: '管理能力' },
			{ value: 'presentation_skills', en: 'Presentation Skills', zh: '呈现能力' },
			{ value: 'prioritization_skills', en: 'Prioritization Skills', zh: '工作排序能力' },
			{ value: 'time_mngmt', en: 'Time-Management', zh: '时间管理' },
			{ value: 'self_mngmt', en: 'Self-Management', zh: '自我管理' },
			{ value: 'decision_making', en: 'Decision Making', zh: '决策能力' },
			{ value: 'critical_thinking', en: 'Critical Thinking', zh: '批判性思维' },
			{ value: 'logical_thinking', en: 'Logical Thinking', zh: '逻辑思维' },
			{ value: 'sales_skills', en: 'Sales Skills', zh: '销售技能' },
		]
	},
	{
		value: 'personality',
		en: 'Personality',
		zh: '个人特质',
		placeholder: 'Enter Personality',
		placeholder_zh: '输入个人特质',
		options: [
			{ value: 'responsibility', en: 'Responsibility', zh: '责任心' },
			{ value: 'self_motivation', en: 'Self-Motivation', zh: '上进心' },
			{ value: 'willing_to_learn', en: 'Willing to Learn', zh: '愿意学习' },
			{ value: 'attention_to_detal', en: 'Attention to Detal', zh: '注重细节' },
			{ value: 'adaptability', en: 'Adaptability', zh: '适应能力' },
			{ value: 'collaboration', en: 'Collaboration', zh: '合作能力' },
			{ value: 'independently', en: 'Independently', zh: '独立' },
			{ value: 'efficiency', en: 'Efficiency', zh: '效率' },
			{ value: 'harkworking', en: 'Harkworking', zh: '勤奋' },
			{ value: 'proactive', en: 'Proactive', zh: '积极' },
			{ value: 'initiative', en: 'Initiative', zh: '主动' },
			{ value: 'patience', en: 'Patience', zh: '耐心' },
			{ value: 'caring', en: 'Caring', zh: '爱心' },
			{ value: 'passion', en: 'Passion', zh: '热忱' },
			{ value: 'confidence', en: 'Confidence', zh: '自信' },
			{ value: 'punctuality', en: 'Punctuality', zh: '守时' },
			{ value: 'self_disciplie', en: 'Self-discipline', zh: '自律' },
			{ value: 'integrity', en: 'Integrity', zh: '诚信' },
			{ value: 'creativity', en: 'Creativity', zh: '创造力' },
			{ value: 'flexibility', en: 'Flexibility', zh: '灵活性' },
			{ value: 'goal_oriented', en: 'Goal-oriented', zh: '目标导向' },
		]
	},
	{
		value: 'driving_license',
		en: 'Driving License',
		zh: '驾驶执照',
		placeholder: 'Enter Driving License',
		placeholder_zh: '输入驾驶执照',
		options: [
			{ value: '', en: 'Malaysia License', zh: '马来西亚驾照', disabled: true},
			{ value: 'class_b_c', en: 'Class B/C', zh: 'B/C驾照' },
			{ value: 'class_d', en: 'Class D', zh: 'D驾照' },
			{ value: 'gdl', en: 'Goods Driving License (GDL)', zh: 'GDL驾照' },
			{ value: 'psv', en: 'Public Service Vehicle (PSV)', zh: 'PSV驾照' },
			{ value: '', en: 'Singapore Licence', zh: '新加坡驾照', disabled: true},
			{ value: 'class_1_2', en: 'Class 1/2', zh: '1/2号驾照' },
			{ value: 'class_3', en: 'Class 3', zh: '3号驾照' },
			{ value: 'class_4', en: 'Class 4', zh: '4号驾照' },
		]
	},
	{
		value: 'own_transportation',
		en: 'Own Transportation',
		zh: '自备交通',
	},
	{
		value: 'outstation',
		en: 'Willing to travel outstation',
		zh: '能够出差工作',
	},
	{
		value: 'google_map',
		en: 'Well versed in Google Map or Waze',
		zh: '熟悉使用Google Map或Waze导航',
	},
	{
		value: 'forklift',
		en: 'Able to drive forklift',
		zh: '能够驾驶叉车',
	},
	{
		value: 'heavy_goods',
		en: 'Able to carry heavy goods',
		zh: '能够搬运重物',
	},
	{
		value: 'immediate',
		en: 'Able to start work immediately',
		zh: '能即刻开始上班',
	},
	{
		value: 'overtime',
		en: 'Ability to work overtime',
		zh: '能够接受加班工作',
	},
	{
		value: 'minimum_supervision',
		en: 'Able to work with minimum supervision',
		zh: '能够在最少监督下工作',
	},
	{
		value: 'pressure',
		en: 'Ability to work under pressure',
		zh: '能够在压力环境下完成工作',
	},
	{
		value: 'fast_paced',
		en: 'Ability to work in a fast-paced environment',
		zh: '能够在快节奏的环境中工作',
	},
	{
		value: 'tight_deadlines',
		en: 'Ability to meet tight deadlines',
		zh: '能够在期限内完成工作',
	},
]