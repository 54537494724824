import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Card, CardBody } from "reactstrap";
import moment from "moment";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import InboxAPI from "./action.js";
import PreviewInbox from "./Preview";
import { CheckUserRolesPermissons } from "actions/roles";

class Inbox extends Component {
	componentDidMount = () => {
		this.props.getInbox();
	};

	componentDidUpdate = (pp) => {
		const { inboxPages, getInbox } = this.props;
		const { filterQuery } = this.state;

		if (pp.inboxPages !== inboxPages) {
			getInbox(
				`${inboxPages > 1 ? `&offset=${(inboxPages - 1) * 10}` : ""}${
					!!filterQuery ? filterQuery : ""
				}`
			);
		}
	};

	state = {
		selectedRowData: null,
		filterQuery: "",
	};

	Edit = (val) => {
		this.props.history.push(`/inbox/EditInbox/${val.id}`);
	};
	Preview = (val) => {
		this.props.onChangeHOC("selectedInbox", val);
		this.props.onChangeHOC("showPreviewModal", true);
	};
	Duplicate = (val) => {
		this.props.onChangeHOC("selectedInbox", val);
		this.props.onChangeHOC("showDuplicateModal", true);
	};
	Disable = (val) => {
		this.props.onChangeHOC("selectedMessageId", val.messageId);
		this.props.onChangeHOC("showDisableModal", true);
	};

	renderSampleTable = () => {
		const { inboxList, onLoadInbox, onChangeHOC } = this.props;

		return (
			<CustomTable
				data={inboxList}
				showPagination={false}
				loading={onLoadInbox}
				columnsContent={[
					{
						Header: "Message ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.id}</>,
					},
					{
						Header: "Message Title",
						accessorKey: "message",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>
								<div dangerouslySetInnerHTML={{ __html: val.title }} />
							</>
						),
					},
					{
						Header: "Recipient",
						accessorKey: "inbox_roles",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.inbox_recipients?.map((role) => role.user_email).join(", ")}</>
						),
					},
					{
						Header: "Open Count",
						accessorKey: "open_count",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.open_count}</>,
					},
					{
						Header: "Click Count",
						accessorKey: "click_count",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.click_count}</>,
					},
					{
						Header: "Created Date",
						accessorKey: "created_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.created_at).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
					{
						Header: "Published Date",
						accessorKey: "published_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.published_at ? moment(val.published_at).format("DD/MM/YYYY hh:mm A") : "-"}</>
						),
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => <>{val.status}</>,
					},
				]}
				menuContent={
					![3, 5, 6, 7, 8, 9].includes(
						CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id
					)
						? [
								{
									menuID: "EditInbox",
									menuTooltip: "Edit",
									onClick: (val) => {
										this.Edit(val);
									},
								},
								{
									menuID: "PreviewInbox",
									menuTooltip: "Preview",
									onClick: (val) => {
										this.Preview(val);
									},
								},
								{
									menuID: "DuplicateInbox",
									menuTooltip: "Duplicate",
									onClick: (val) => {
										this.Duplicate(val);
									},
								},
								{
									menuID: "DisableInbox",
									menuTooltip: "Disable",
									onClick: (val) => {
										this.setState({ selectedRowData: val });
										onChangeHOC("showDisableModal", true);
									},
								},
						  ]
						: [
								{
									menuID: "PreviewInbox",
									menuTooltip: "Preview",
									onClick: (val) => {
										this.Preview(val);
									},
								},
						  ]
				}
			/>
		);
	};

	renderFilterModal = () => {
		const { getInbox, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Inbox Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getInbox}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	renderEditModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showEditModal}
				onClose={() => this.props.onChangeHOC("showEditModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showEditModal", false)}
				title={`Are you sure you want to edit this message? ${this.props.selectedMessageId}`}
				message="Please confirm this action."
				confirmContent="Yes, edit"
				confirmColor="danger"
			/>
		);
	};
	renderPreviewModal = () => {
		return (
			<CustomModal
				size={"xl"}
				title={"Message Preview"}
				onClose={() => this.props.onChangeHOC("showPreviewModal", false)}
				isOpen={this.props.showPreviewModal}>
				<PreviewInbox selectedInbox={this.props.selectedInbox} />
			</CustomModal>
		);
	};
	renderDuplicateModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showDuplicateModal}
				onClose={() => this.props.onChangeHOC("showDuplicateModal", false)}
				onClickConfirm={() => {
					this.props.duplicateInbox({
						message: this.props.selectedInbox.message,
						open_count: 0,
						click_count: 0,
						published_at: null,
						status: "draft",
						title: this.props.selectedInbox.title,
						created_by: this.props.selectedInbox?.created_by?.id,
						user_ids: _.map(
							this.props.selectedInbox?.inbox_recipients ?? [],
							"id"
						),
						user_roles: _.map(
							this.props.selectedInbox?.inbox_roles ?? [],
							"role_id"
						),
						tags: this.props.selectedInbox.tags,
					});
					this.props.onChangeHOC("showDuplicateModal", false);
				}}
				title={`Are you sure you want to duplicate this message?`}
				message="Please confirm this action."
				confirmContent="Yes, duplicate"
				confirmColor="danger"
			/>
		);
	};
	renderDisableModal = () => {
		const { selectedRowData } = this.state;
		const { showDisableModal, onChangeHOC, updateInbox } = this.props;

		return (
			<ConfirmationModal
				open={showDisableModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					onChangeHOC("showDisableModal", false);
				}}
				onClickConfirm={() => {
					updateInbox({
						message: selectedRowData.message,
						open_count: selectedRowData.open_count,
						click_count: selectedRowData.click_count,
						status: "disabled",
						title: selectedRowData.title,
						created_by: selectedRowData.created_by,
						id: selectedRowData.id,
					});
					this.setState({
						selectedRowData: null,
					});
				}}
				title={`Are you sure you want to disable this inbox? ${selectedRowData?.id}`}
				message="Please confirm this action."
				confirmContent="Yes, disable"
				confirmColor="danger"
			/>
		);
	};

	render = () => {
		const { onChangeHOC, onLoadInbox, inboxPages, inboxCount, getInbox } =
			this.props;
		const { filterQuery } = this.state;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Manage Inbox"
										buttons={[
											...(![3, 5, 6, 7, 8, 9].includes(
												CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id
											)
												? [
														{
															color: "primary",
															className: "btn-icon mr-2",
															onClick: () => {
																this.props.history.push("/inbox/CreateInbox");
															},
															content: (
																<>
																	<span>Create New Message</span>
																</>
															),
														},
												  ]
												: []),
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderSampleTable()}
														<Pagination
															pageIndex={inboxPages}
															totalCount={inboxCount}
															onChangePage={(val) =>
																onChangeHOC("inboxPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderEditModal()}
									{this.renderPreviewModal()}
									{this.renderDuplicateModal()}
									{this.renderDisableModal()}
									{this.props.onLoadSample && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(InboxAPI)(Inbox);
