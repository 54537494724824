import React from "react";
import { AiOutlineCloseCircle, AiOutlineEye } from "react-icons/ai";

const CertItem = ({ context, certChild, onPreview, onRemove }) => {
	return (
		<>
			<div
				className="p-3 d-flex align-items-center"
				style={{ border: "1px solid #D9D9D9", borderRadius: "0.25rem" }}>
				<span className="text-black">{certChild.title}</span>
				<AiOutlineEye
					className="ml-auto text-black"
					style={{ cursor: "pointer", width: "1.5rem", height: "1.5rem" }}
					onClick={() => onPreview(certChild)}
				/>
				{context !== "view" && (
					<AiOutlineCloseCircle
						className="text-primary ml-2"
						style={{ cursor: "pointer", width: "1.5rem", height: "1.5rem" }}
						onClick={() => onRemove(certChild)}
					/>
				)}
			</div>
		</>
	);
};
export default CertItem;
