

import React from "react";
import { AiOutlineUpload } from "react-icons/ai";

const UploadImage = ({
	uploadPdf,
	updateJobSeekerProfile,
	jobSeekerProfile,
}) => {
	let filePond = React.useRef();

	const onUploadDocs = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			let files = e.target.files;
			Object.keys(e.target.files).map((key) => {
				let reader = new FileReader();
				reader.onload = (e) => {
					uploadPdf(
						{
							base64: e.target.result,
							filename: files[key].name,
							title: files[key].name,
							alt: files[key].name,
							platforms: ["jobmacha"],
						},
						(val) =>
							updateJobSeekerProfile({
								...jobSeekerProfile,
								resume_url: val.url,
							})
					);
				};
				reader.readAsDataURL(e.target.files[key]);
			});
		}
	};

	return (
		<>
			<>
				<AiOutlineUpload
					className="ml-auto"
					style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
					onClick={() => filePond.click()}
				/>
				<input
					ref={(ref) => (filePond = ref)}
					style={{ display: "none" }}
					type="file"
					accept="application/pdf"
					multiple={false}
					onChange={(e) => onUploadDocs(e)}
				/>
			</>
		</>
	);
};

export default UploadImage;
