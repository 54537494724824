import LoadingOverlay from "components/Indicator/LoadingOverlay";
import _ from "lodash";

import { useEffect, useState } from "react";
import { compose } from "redux";
import HOC from "../actions";
import EducationHOC from "../actions/education";
import ExpHOC from "../actions/exp";
import Education from "./Education";
import Profile from "./Profile";
import WorkingExp from "./WorkingExp";
import "../../index.scss";

function ProfileExp({ candidateForm, ...props }) {
	const [tempUser, setTempUser] = useState(null);
	const [tempEducation, setTempEducation] = useState([]);

	const [tempDay, setTempDay] = useState("");
	const [tempMonth, setTempMonth] = useState("");
	const [tempYear, setTempYear] = useState("");

	const onChangeUserField = (key, val) => {
		let temp = _.cloneDeep(tempUser);
		temp[key] = val;
		setTempUser(temp);
	};

	useEffect(() => {
		//15-3-2005
		if (candidateForm) {
			props.getEducationLevels();
			setTempUser(candidateForm.user);

			let temp = _.map(
				candidateForm.jobseeker_profile_education_levels,
				(eduChild) => ({
					...eduChild,
					enrollment_year: eduChild.enrollment_year
						? new Date(`${eduChild.enrollment_year}`)
						: null,
					graduation_year: eduChild.graduation_year
						? new Date(`${eduChild.graduation_year}`)
						: null,
				})
			);

			setTempEducation(temp || []);

			let tempSplit = candidateForm.birth_date.split("-");
			setTempDay(tempSplit[0]);
			setTempMonth(tempSplit[1]);
			setTempYear(new Date(tempSplit[2]));
			props.getUserExp(candidateForm.user_id);
			props.getStates(candidateForm.country === "Malaysia" ? 136 : 203);
		}
	}, [candidateForm]);

	return (
		<>
			{candidateForm && (
				<>
					<Profile
						{...props}
						tempUser={tempUser}
						candidateForm={candidateForm}
						tempDay={tempDay}
						tempMonth={tempMonth}
						tempYear={tempYear}
						onChangeForm={props.onChangeForm}
						onChangeUserField={onChangeUserField}
					/>
					<WorkingExp {...props} tempUser={tempUser} />
					<Education
						{...props}
						tempUser={tempUser}
						tempEducation={tempEducation}
					/>
				</>
			)}
			{(props.onLoadProfile || props.onLoadExp || props.onLoadEdu) && (
				<LoadingOverlay />
			)}
		</>
	);
}

export default compose(HOC, ExpHOC, EducationHOC)(ProfileExp);
