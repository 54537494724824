import Edit from "./edit";
import View from "./view";

function WorkingExp(props) {
	return (
		<>{props.mode === "edit" ? <Edit {...props} /> : <View {...props} />}</>
	);
}

export default WorkingExp;
