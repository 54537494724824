import React, { useState } from "react";
import {
    Modal,
    ModalBody,
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from "reactstrap";
import { AiFillRightCircle, AiFillLeftCircle, AiOutlineClose } from "react-icons/ai";
import "./index.scss";
import _ from "lodash";

const PreviewPlan = ({
    isOpen,
    onClose,
    isPackage,
    locale = 'en',
    packages,
    itemIndex = 0,
    planDescription,
    plan,
    planPrice,
    planDetail,
    planImage,
}) => {
    const [activeIndex, setActiveIndex] = useState(itemIndex);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (!animating) {
            const nextIndex =
                activeIndex === (packages?.length || 1) - 1
                    ? 0
                    : activeIndex + 1;
            setActiveIndex(nextIndex);
        }
    };

    const previous = () => {
        if (!animating) {
            const nextIndex =
                activeIndex === 0
                    ? (packages?.length || 1) - 1
                    : activeIndex - 1;
            setActiveIndex(nextIndex);
        }
    };

    const goToIndex = (newIndex) => {
        if (!animating) setActiveIndex(newIndex);
    };

    const slides =
        packages &&
        packages.length > 0 &&
        packages.map((item) => {
            let price1 = _.isArray(item.price)
                ? _.get(item, "price[0].amount")
                : item.price;
            let price2 = _.isArray(item.price)
                ? _.get(item, "price[1].amount")
                : null;

            if (isPackage && packages.length > 0) {
                return (
                    <CarouselItem
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                        key={item.id}
                    >
                        <div style={{ width: "100%", margin: "0" }}>
                            <img
                                src={item.img_url}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ padding: "2.25rem" }}>
                            <span style={{ fontSize: "1.5rem" }}>
                                {item.name[locale]}
                            </span>
                            <div className="e-classified-advertiser__price_detail">
                                <h1>
                                    RM {price1}{" "}
                                    {price2 && (
                                        <>~ RM {price2}</>
                                    )}{" "}
                                </h1>
                                <p className="text-secondary">
                                    {item.description[locale]}
                                </p>
                            </div>
                        </div>
                    </CarouselItem>
                );
            }

            return (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={item.id}
                >
                    <div style={{ width: "100%", margin: "0" }}>
                        <img
                            src={`/create-post-preview_sample.png`}
                            alt="image"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ padding: "2.25rem" }}>
                        <span style={{ fontSize: "1.5rem" }}>
                            {item.name[locale]}
                        </span>
                        <div className="e-classified-advertiser__price_detail">
                            <h1>
                                RM {price1}{" "}
                                {price2 && (
                                    <>~ RM {price2}</>
                                )}{" "}
                            </h1>
                            <p className="text-secondary">
                                {item.description[locale]}
                            </p>
                        </div>
                    </div>
                </CarouselItem>
            );
        });

    if (isPackage && slides.length > 0) {
        return (
            <Modal
                className="e-classified-advertiser__preview-modal"
                size="xl"
                isOpen={isOpen}
                toggle={() => {
                    onClose();
                    setActiveIndex(0);
                }}
                centered
            >
                <ModalBody className="e-classified-advertiser__preview-modal-body">
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                        interval={null}
                    >
                        <CarouselIndicators
                            items={packages}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                        />
                        {slides}
                        {slides?.length > 1 && (
                            <>
                                <a className="carousel-control-prev">
                                    <AiFillLeftCircle onClick={previous} />
                                </a>
                                <a className="carousel-control-next">
                                    <AiFillRightCircle onClick={next} />
                                </a>
                            </>
                        )}
                    </Carousel>
                    <AiOutlineClose 
                        className="e-classified-advertiser__close-button"
                        onClick={() => { onClose(); setActiveIndex(0) }}
                    />
                </ModalBody>
            </Modal>
        );
    } else {
        return (
            <Modal
                className="e-classified-advertiser__preview-modal"
                size="lg"
                isOpen={isOpen}
                toggle={() => {
                    onClose();
                    setActiveIndex(0);
                }}
                centered
            >
                <ModalBody className="e-classified-advertiser__preview-modal-body">
                    <div style={{ width: "100%", margin: "0" }}>
                        <img
                            src={planImage}
                            alt="image"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ padding: "2.25rem" }}>
                        <span style={{ fontSize: "1.5rem" }}>{plan}</span>
                        <div className="e-classified-advertiser__price_detail">
                            <h1>RM {planPrice}</h1>
                            <p className="text-secondary">{planDetail}</p>
                        </div>
                    </div>
                    <AiOutlineClose 
                        className="e-classified-advertiser__close-button"
                        onClick={() => { onClose(); setActiveIndex(0) }}
                    />
                </ModalBody>
            </Modal>
        );
    }
};

export default PreviewPlan;
