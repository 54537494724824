// import PdfViewer from "components/PDFViewer";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import HOC from "../actions";
import Edit from "./Edit";
import View from "./View";

function Skill(props) {
	const [preview, setPreview] = useState(null);

	return (
		<>
			{props.jobSeekerProfile && (
				<>
					{props.mode === "edit" ? (
						<Edit {...props} setPreview={setPreview} />
					) : (
						<View {...props} setPreview={setPreview} />
					)}
				</>
			)}
			{preview && (
				<>
					<Modal isOpen={true}>
						<ModalHeader toggle={() => setPreview(null)}>
							{preview?.title}
						</ModalHeader>
						<ModalBody>{/* <PdfViewer url={preview.url} /> */}</ModalBody>
					</Modal>
				</>
			)}
		</>
	);
}

export default HOC(Skill);
