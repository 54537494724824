import HOC from "../actions";
import Edit from "./Edit";
import View from "./View";
import "../index.scss";

function Other(props) {
	return (
		<>{props.mode === "edit" ? <Edit {...props} /> : <View {...props} />}</>
	);
}

export default HOC(Other);
