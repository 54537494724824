import Moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import _ from "lodash";

const Months = Array.from({ length: 12 }, (_, index) => index + 1);

const SampleEducation = {
	jobseeker_profile_id: null,
	education_level_id: null,
	institution: "",
	field_of_study: "",
	field_of_study_text: "",
	enrollment_month: null,
	enrollment_year: null,
	graduation_month: null,
	graduation_year: null,
};

function EduForm({
	jobSeekerProfile,
	tempEducation,
	educationLevels,
	deleteEdu,
	updateEdu,
	createEdu,
	getSelectedJobSeekerProfile,
}) {
	const [showCreate, setShowCreate] = useState(false);

	return (
		<>
			<div className="d-flex" style={{ marginTop: "2.5rem" }}>
				<h5 className="mb-3 e-classified-cont-header">Education</h5>
				<AiOutlinePlusCircle
					className="ml-auto text-primary"
					onClick={() => {
						setShowCreate(true);
					}}
					style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
				/>
			</div>
			{showCreate && (
				<FormItem
					getSelectedJobSeekerProfile={getSelectedJobSeekerProfile}
					educationLevels={educationLevels}
					child={SampleEducation}
					createEdu={createEdu}
					context={"create"}
					jobSeekerProfile={jobSeekerProfile}
					onRemoveItem={() => setShowCreate(false)}
				/>
			)}
			{tempEducation.map((child) => (
				<FormItem
					getSelectedJobSeekerProfile={getSelectedJobSeekerProfile}
					child={child}
					educationLevels={educationLevels}
					jobSeekerProfile={jobSeekerProfile}
					updateEdu={updateEdu}
					onRemoveItem={() => deleteEdu(child.id)}
				/>
			))}
		</>
	);
}

const FormItem = ({
	child,
	context,

	educationLevels,
	jobSeekerProfile,
	updateEdu,
	onRemoveItem,
	createEdu,
	getSelectedJobSeekerProfile,
}) => {
	const [tempForm, setTempForm] = useState(SampleEducation);

	const onChangeField = (key, val) => {
		let temp = _.cloneDeep(tempForm);
		temp[key] = val;

		setTempForm(temp);
	};

	useEffect(() => {
		if (child) {
			setTempForm({
				...child,
				jobseeker_profile_id: jobSeekerProfile.id,
			});
		}
	}, [child]);

	return (
		<>
			<div className="e-classified-profile-view-card e-classfied-me-menu__form-cont mb-4">
				<div
					className="d-flex align-items-center"
					style={{ marginBottom: "2.5rem" }}>
					<h5 className="e-classified-cont-header">Edit Education</h5>
					<AiOutlineCloseCircle
						style={{ width: "1.75rem", height: "1.75rem", cursor: "pointer" }}
						className="text-secondary ml-auto"
						onClick={() => onRemoveItem()}
					/>
				</div>
				<Form>
					<div className="row">
						<div className="col-lg-6">
							<FormGroup>
								<Label>Education</Label>
								<Input
									value={tempForm.education_level_id}
									type={"select"}
									onChange={(e) =>
										onChangeField("education_level_id", e.target.value)
									}>
									<option value=""></option>
									{educationLevels.map((levelChild) => (
										<option key={levelChild.id} value={levelChild.id}>
											{levelChild.name["en"]}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>Field Of Study</Label>
								<Input
									value={tempForm.field_of_study}
									onChange={(e) => {
										onChangeField("field_of_study", e.target.value);
									}}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-12">
							<FormGroup>
								<Label>Education Institution</Label>
								<Input
									value={tempForm.institution}
									onChange={(e) => onChangeField("institution", e.target.value)}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-3 col-md-6">
							<FormGroup>
								<Label>Enrolment Year</Label>
								<DatePicker
									className="form-control"
									selected={tempForm.enrollment_year}
									placeholderText={"Year"}
									onChange={(date) => onChangeField("enrollment_year", date)}
									showYearPicker
									dateFormat="yyyy"
								/>
							</FormGroup>
						</div>
						<div className="col-lg-3 col-md-6">
							<FormGroup>
								<Label style={{ opacity: 0 }}>{`Month`}</Label>
								<Input
									type={"select"}
									value={tempForm.enrollment_month}
									onChange={(e) =>
										onChangeField("enrollment_month", e.target.value)
									}>
									<option value="">Month</option>
									{Months.map((child) => (
										<option key={child} value={child}>
											{child}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-lg-3 col-md-6">
							<FormGroup>
								<Label>Graduation Year</Label>
								<DatePicker
									className="form-control"
									selected={tempForm.graduation_year}
									placeholderText={"Year"}
									onChange={(date) => onChangeField("graduation_year", date)}
									showYearPicker
									dateFormat="yyyy"
								/>
							</FormGroup>
						</div>
						<div className="col-lg-3 col-md-6">
							<FormGroup>
								<Label style={{ opacity: 0 }}>{`Month`}</Label>
								<Input
									type={"select"}
									value={tempForm.graduation_month}
									onChange={(e) =>
										onChangeField("graduation_month", e.target.value)
									}>
									<option value="">Month</option>
									{Months.map((child) => (
										<option key={child} value={child}>
											{child}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
					</div>
				</Form>
				<div className="d-flex" style={{ marginTop: "2.5rem" }}>
					<Button
						className="ml-auto"
						color="primary"
						size="lg"
						onClick={() => {
							let temp = {
								...tempForm,
								field_of_study_text: tempForm.field_of_study,
								education_level_id: +tempForm.education_level_id,
								enrollment_month: +tempForm.enrollment_month,
								graduation_month: +tempForm.graduation_month,
								enrollment_year: +Moment(tempForm.enrollment_year).format(
									"YYYY"
								),
								graduation_year: +Moment(tempForm.graduation_year).format(
									"YYYY"
								),
							};
							context === "create"
								? createEdu(temp, () =>
										getSelectedJobSeekerProfile(jobSeekerProfile.user_id)
								  )
								: updateEdu(temp, () =>
										getSelectedJobSeekerProfile(jobSeekerProfile.user_id)
								  );
						}}>
						Store
					</Button>
				</div>
			</div>
		</>
	);
};

export default EduForm;
