import { Switch } from "antd";
import { AiFillInfoCircle } from "react-icons/ai";
import { FiBriefcase } from "react-icons/fi";

function BoxContent({ jobSeekerProfile = {}, candidateForm, onChangeForm }) {
	let tempComplete =
		jobSeekerProfile?.user?.name &&
		jobSeekerProfile?.user?.email &&
		jobSeekerProfile?.user?.mobile_number &&
		jobSeekerProfile?.address &&
		jobSeekerProfile?.postcode &&
		jobSeekerProfile?.city &&
		jobSeekerProfile?.country &&
		jobSeekerProfile?.state;

	return (
		<>
			{tempComplete && (
				<div className="e-classfied-want-job__cont">
					<div className="e-classfied-want-job__cont-child-cont">
						<FiBriefcase />
						<strong>I Want Job</strong>
						<Switch
							className="ml-auto"
							checked={candidateForm.is_asking_for_job}
							onChange={(val) => onChangeForm("is_asking_for_job", val)}
						/>
					</div>
					<p>Improve the chances of employers finding you!</p>
				</div>
			)}
			{!tempComplete && (
				<div
					className="e-classfied-want-job__cont"
					style={{ background: "#FFF1F0", border: "1px solid red" }}>
					<div className="e-classfied-want-job__cont-child-cont">
						<AiFillInfoCircle className="text-danger" />
						<strong className="text-danger">Incomplete Profile Info</strong>
					</div>
					<p className="text-danger">
						You’re encourage to complete your profile for employers to know you
						better!
					</p>
				</div>
			)}
		</>
	);
}

export default BoxContent;
