// ECModalTrigger Component
'use client'

import React, { useEffect, useState } from 'react';
import { Input, Button, FormGroup, Label, Modal } from 'reactstrap';
import CategoryModal from '../CategoryModal/CategoryModal.js'; // Ensure this path matches the location of your CategoryModal component
// import { usePathname } from 'next/navigation';
import { useLocation } from "react-router-dom";
import _ from 'lodash'

import "./index.scss";

const ECModalTrigger = ({
  label,
  formGroupStyle,
  formGroupClass,
  labelClass,
  context,
  required,
  options,
  placeholder,
  placeholder_zh,
  value,
  onChangeData,
  locale,
  isProfilePage,
}) => {
  const { pathname } = useLocation();
  const [selectedValue, setSelectedValue] = useState(null);
  const [displayValue, setDisplayValue] = useState("");

  useEffect(() => {
      if (value) {
          setSelectedValue(value);

          if (pathname.includes("/me/profile/profile-exp")) {
              setDisplayValue(
                  locale === "en" ? value.split(";")[0] : value.split(";")[1]
              );
              return;
          }

          let selectedOption = _.chain(options)
              .cloneDeep()
              .map((item) => [item, ...item.children])
              .flatten()
              .find({ id: value })
              .value();
          setDisplayValue(
              locale == "zh"
                  ? selectedOption?.name?.zh
                  : selectedOption?.name?.en
          );
      }
  }, [value]);

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  const handleSelectionChange = (selectedValue) => {
    setSelectedValue(selectedValue);
    onChangeData(selectedValue); // Pass the selected value to the parent's handler
  };

  return (
    <FormGroup
      style={{ ...formGroupStyle }}
      className={`${formGroupClass || ""}`}
      required={required}
    >
      {label && (
        <Label
          style={{ fontWeight: "bold" }}
          for={context}
        >
          {label}
          {required ? <span className="text-danger">*</span> : ""}
        </Label>
      )}
      <div className={
          isProfilePage ? "modal-trigger-sm" : "modal-trigger"
        } 
        onClick={toggleModal}>
          {displayValue || ( locale == 'zh'? placeholder_zh : placeholder)}
      </div>
      
      <CategoryModal
	  	  jobSpecs={options}
        showFilterByCategoryModal={modalOpen}
        onPressCloseModal={toggleModal}
        selectedJobSpecs={[selectedValue]}
        setSelectedJobSpecs={handleSelectionChange}
        setDisplayValue={setDisplayValue}
      />
    </FormGroup>
  );
};

export default ECModalTrigger;