export const COUNTRY_MALAYSIA_ID = 136;
export const COUNTRY_SINGAPORE_ID = 203;

export const BENEFIT_OTHERS_ID = 53;

export const PACKAGE_CONST_PLAN_C_ID = 4;
export const PACKAGE_CONST_POSTING_PLAN_ID = 1;
export const PACKAGE_CONST_FACEBOOK_PLAN_ID = 9;
export const PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID = 10;

export const TAX_RATE = 0.08;

export const COOKIES_LOGGED_IN = "loggedIn";