import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import CreatePromoCodeAPI from "./action.js";
import { CreateNewVoucherForm } from "./Form.js";

class CreatePromoCode extends Component {
  componentDidMount = () => this.props.getRole();

  render = () => {
    const { roleList, createNewVoucher, searchUser } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Manage Voucher / <b>Create New Promo Code</b>"></PageTitle>
                <h5>Create New Promo Code</h5> <br></br>
                <CreateNewVoucherForm
                  onSubmit={createNewVoucher}
                  roleList={roleList}
                  searchUser={searchUser}
                />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(CreatePromoCodeAPI)(CreatePromoCode);
