import React, { useState, useEffect } from "react";
import { Post, Get, Delete, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";

const roleTitle = {
  5: "Agent Manager",
  6: "Agent Lead",
  7: "Agent",
};

export default function AgentCodeSearch({
  roleId,
  onSelectionChanged,
  defaultValue,
}) {
  const [inputCode, setInputCode] = useState("");
  const [defaultValueLocal, setDefaultValueLocal] = useState(null);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (typeof defaultValue === "number") {
      Get(
        `/list-companies?id=${defaultValue}`,
        (response) => {
          const res = response.data.map((e) => ({
            value: e.id,
            label: e.name,
            data: e,
          }))[0];
          setDefaultValueLocal(res);
          onSelectionChanged && onSelectionChanged(res);
        },
        (error) => {
          requestError(error);
        },
        () => {}
      );
    } else {
      setDefaultValueLocal(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <FormGroup>
        <Label htmlFor={"companyId"} style={{ fontWeight: "bold" }}>
          Advertiser Name <span className="text-danger">*</span>
        </Label>
        <Select
          name="agentLeadSelect"
          placeholder={`Select Company`}
          isClearable
          value={defaultValueLocal}
          //   isSearchable
          onInputChange={(newValue) => {
            if (!newValue) return;
            Get(
              `/list-companies?name=${newValue}`,
              (response) =>
                setOptions(
                  response.data.map((e) => ({
                    value: e.id,
                    label: e.name,
                    data: e,
                  }))
                ),
              (error) => {
                requestError(error);
              },
              () => {}
            );
          }}
          onChange={(value) => {
            onSelectionChanged && onSelectionChanged(value);
          }}
          options={options}
          //   isLoading={agentLeadLoading}
          //   value={agentLead}
        />
      </FormGroup>
    </>
  );
}
