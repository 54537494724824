import React, { useState } from "react";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  CardTitle,
  CardText,
  CardBody,
  Card,
  CardImg,
  Button,
} from "reactstrap";

export function CreateForm({ history, selectedTag, onSubmit }) {
  const sampleAdvertiserCreate = [
    {
      id: 1,
      avatar: "individual.png",
      title: "Individual",
      description: "I want to post an ad for myself.",
      buttonDescription: "Create Individual Profile",
      urlRedirect: "create-individual-profile",
    },
    {
      id: 2,
      avatar: "company.png",
      title: "Company",
      description: "I want to post an ad as a company.",
      buttonDescription: "I want to post an ad as a company.",
      urlRedirect: "create-company-profile",
    },
  ];

  const renderAdvertiserType = () => {
    return (
      <div
        style={{
          justifyContent: "center",
          margin: "5% 5%",
          display: "flex",
          textAlign: "center",
        }}
      >
        {sampleAdvertiserCreate.map((item) => {
          return (
            <Card
              style={{ margin: "0 2rem" }}
              key={item.id}
              className="card mb-3"
            >
              <div>
          
                <CardImg
                  alt="Card image cap"
                  src={require(`assets/images/${item.avatar}`).default}
                  style={{ width: "10rem" }} // Adjust the width and height as needed
                />
              </div>

              <CardBody>
                <CardTitle tag="h5">{item.title}</CardTitle>
                <CardText>{item.description}</CardText>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push(`/advertiser-profile/${item.urlRedirect}`);
                  }}
                >
                  {item.buttonDescription}
                </Button>
              </CardBody>
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-inbox-filter-form">
          <h6>What is the main identity you want to use for posting an ad?</h6>
          {renderAdvertiserType()}
        </Form>
      </CustomModalBody>
    </>
  );
}

export function FilterForm({ onSubmit, onChangeHOC, handleState, natureList }) {
  const [advertiserId, setAdvertiserId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [agentId, setAgentId] = useState("");
  const [agentName, setAgentName] = useState("");
  const [createdStartDate, setCreatedStartDate] = useState("");
  const [createdEndDate, setCreatedEndDate] = useState("");
  const [updatedStartDate, setUpdatedStartDate] = useState("");
  const [updatedEndDate, setUpdatedEndDate] = useState("");
  const [userRole, setUserRole] = useState("");

  const handleClear = () => {
    setAdvertiserId("");
    setCompanyName("");
    setCompanyType("");
    setBusinessNature("");
    setAgentName("");
    setCreatedStartDate("");
    setCreatedEndDate("");
    setUpdatedStartDate("");
    setUpdatedEndDate("");
    setUserRole("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (!!advertiserId) {
      queryString += `&id=${advertiserId}`;
    }
    if (!!companyName) {
      queryString += `&name=${companyName}`;
    }
    if (!!companyType) {
      queryString += `&type=${companyType.toLowerCase()}`;
    }
    if (!!businessNature) {
      queryString += `&industry_id=${businessNature}`;
    }
    if (!!agentId) {
      queryString += `&agent_id=${agentId}`;
    }
    if (!!agentName) {
      queryString += `&agent_name=${agentName}`;
    }
    if (!!createdStartDate) {
      queryString += `&created_at_start=${createdStartDate}`;
    }
    if (!!createdEndDate) {
      queryString += `&created_at_end=${createdEndDate}`;
    }
    if (!!updatedStartDate) {
      queryString += `&updated_at_start=${updatedStartDate}`;
    }
    if (!!updatedEndDate) {
      queryString += `&updated_at_end=${updatedEndDate}`;
    }
    if (!!userRole) {
      queryString += `&role_id=${userRole}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-inbox-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="advertiserId">Advertiser ID</Label>
                <Input
                  placeholder="Enter Advertiser ID"
                  name="advertiserId"
                  id="advertiserId"
                  value={advertiserId}
                  onChange={(e) => setAdvertiserId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="companyName">Advertiser Name</Label>
                <Input
                  placeholder="Enter Advertiser Name"
                  name="companyName"
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="companyType">Advertiser Type</Label>
                <Input
                  type="select"
                  name="companyType"
                  id="companyType"
                  value={companyType}
                  onChange={(e) => setCompanyType(e.target.value)}
                >
                  <option disabled value="">
                    Select Advertiser Type
                  </option>
                  <option>Individual</option>
                  <option>Company</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="businessNature">Industry</Label>
                <Input
                  type="select"
                  name="businessNature"
                  id="businessNature"
                  value={businessNature}
                  onChange={(e) => setBusinessNature(e.target.value)}
                >
                  <option disabled value="">
                    Select Industry
                  </option>
                  {natureList.map((nature) => (
                    <option value={nature.id}>{nature.name.en}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="agentId">Agent ID</Label>
                <Input
                  placeholder="Enter Agent ID"
                  name="agentId"
                  id="agentId"
                  value={agentId}
                  onChange={(e) => setAgentId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="agentName">Agent Name</Label>
                <Input
                  placeholder="Enter Agent Name"
                  name="agentName"
                  id="agentName"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="createdStartDate">Created Start Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="createdStartDate"
                  id="createdStartDate"
                  value={createdStartDate}
                  onChange={(e) => setCreatedStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="createdEndDate">Created End Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="createdEndDate"
                  id="createdEndDate"
                  value={createdEndDate}
                  onChange={(e) => setCreatedEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="updatedStartDate">Last Updated Start Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="updatedStartDate"
                  id="updatedStartDate"
                  value={updatedStartDate}
                  onChange={(e) => setUpdatedStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="updatedEndDate">Last Updated End Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="updatedEndDate"
                  id="updatedEndDate"
                  value={updatedEndDate}
                  onChange={(e) => setUpdatedEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Search",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("advertiserPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
