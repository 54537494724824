"use client"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./index.scss"
const responsive = {
  xxl: {
    breakpoint: { max: 9999, min: 1600 },
    items: 4,
  },
  xl: {
    breakpoint: { max: 1599, min: 1200 },
    items: 4,
  },
  lg: {
    breakpoint: { max: 1199, min: 991 },
    items: 4,
  },
  md: {
    breakpoint: { max: 991, min: 768 },
    items: 3,
  },
  sm: {
    breakpoint: { max: 767, min: 576 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
}
const CustomCarousel = (props) => {
  let { showDots, infinite, autoplay, renderDotsOutside, children } = props
  return (
    <div style={{ position: "relative" }}>
      <Carousel
        showDots={showDots}
        infinite={infinite || true}
        autoPlay={autoplay}
        renderDotsOutside={renderDotsOutside || true}
        responsive={responsive}
        containerClass="custom-carousel-container"
      >
        {children}
      </Carousel>
    </div>
  )
}

export default CustomCarousel
