import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Get, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      roleList: [],
    };

    load = (param) => this.setState({ loading: param });
    onChangeHOC = (key, val) => this.setState({ [key]: val });

    createNewVoucher = (dataToSubmit) => {
      Post(
        `/vouchers`,
        dataToSubmit,
        this.createVoucherSuccess,
        this.createVoucherError,
        this.load
      );
    };
    createVoucherSuccess = () => {
      requestSuccess("Voucher created successfully.");
      setTimeout(() => {
        this.props.history.push("/promo-code");
      }, 2000);
    };
    createVoucherError = (error) => requestError(error);

    getRole = () =>
      Get(
        `/roles?platform=jobmacha`,
        this.getRoleSuccess,
        this.getRoleError,
        this.load
      );
    getRoleSuccess = (payload) => {
      this.setState({
        roleList: payload.data,
      });
    };
    getRoleError = (error) => requestError(error);

    searchUser = (user, resolve, reject) =>
      Get(
        `/users?platform=jobmacha${user ? `&name=${user}` : ""}`,
        (response) => {
          resolve(response.data); // Resolve the promise with options
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          createNewVoucher={this.createNewVoucher}
          getRole={this.getRole}
          roleList={this.state.roleList}
          searchUser={this.searchUser}
          onChangeHOC={this.onChangeHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setProfile,
  })(WithHOC);
};
export default HOC;
