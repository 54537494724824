import React from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { Col, FormGroup, Label } from "reactstrap";

const SelectMulti = ({
  formGroupStyle,
	formGroupClass,
	value,
	row, // boolean
	labelCol,
	inputCol,
	label,
	options,
	errors, // [ { field: 'name', message: 'Name is required' }]
	labelClass,
	context,
	required,
	placeholder,
	onChangeData,
}) => {

  const InputContent = () => {
		return(
      <Select
        mode="multiple"
        value={value}
        style={{ width: '100%' }}
        fieldNames={{ label: 'en', value: 'value' }}
        className={'custom-form-select'}
        placeholder={placeholder}
        defaultValue={[]}
        onChange={onChangeData}
        options={options} />
		)
	}

  return (
    <FormGroup
			style={{ ...formGroupStyle }}
			className={`${formGroupClass || ""}`}
			row={row || false}
			required={required}>
			{ label && (
				<Label
					{...(row ? labelCol : {})}
					className={`custom-form-label ${labelClass || ""}`}
					for={context}>
					{label}
					{required ? <span className="text-danger">*</span> : ""}
				</Label>
			)}
			{row ? 
				<Col {...(row && inputCol)}>
					<InputContent />
				</Col>
				: <InputContent />
			}
			<span className="text-danger">
				{errors?.length > 0 &&
					_.find(errors, (obj) => obj.field === context)?.message}{" "}
			</span>
		</FormGroup>
  )
}

export default SelectMulti;