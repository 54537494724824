import React, { Component } from "react";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			userExp: [],
		};

		onChangeExpHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getUserExp = (userID) => {
			Get(
				`/user/${userID}/working-experiences`,
				this.getUserExpSuccess,
				(error) => requestError(error.message),
				this.load,
				true
			);
		};
		getUserExpSuccess = (payload) => {
			this.setState({
				userExp: payload.data,
			});
		};

		createExp = (dataToSubmit) =>
			Post(
				`/user/${dataToSubmit.user_id}/working-experiences`,
				dataToSubmit,
				() => this.getUserExp(dataToSubmit.user_id),
				(error) => requestError(error.message),
				this.load,
				true
			);

		deleteExp = (id, userID) =>
			Delete(
				`/user/${userID}/working-experiences/${id}`,
				() => this.getUserExp(userID),
				(error) => requestError(error.message),
				this.load,
				true
			);

		updateExp = (dataToSubmit) =>
			Put(
				`/user/${dataToSubmit.user_id}/working-experiences/${dataToSubmit.id}`,
				dataToSubmit,
				() => {},
				(error) => requestError(error.message),
				this.load,
				true
			);
		updateExpSucces = (payload) => {
			this.getExp();
			this.setState({ showUpdateExpModal: false });
			requestSuccess('Record updated successfully.');
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onLoadExp={this.state.loading}
					getUserExp={this.getUserExp}
					createExp={this.createExp}
					deleteExp={this.deleteExp}
					updateExp={this.updateExp}
				/>
			);
		};
	}

	return WithHOC;
};

export default HOC;
