import moment from "moment";
import React from "react";
import { Card, Row, Col } from "reactstrap";

function View({ selectedLog }) {
	return (
		<>
			<p>Log Entries</p>
			<Card style={{ border: "1px solid #D9D9D9" }}>
				<Row style={{ margin: 0 }}>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>Date/Time</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>{moment(selectedLog.created_at).format("DD-MM-YYYY h:mA")}</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>User</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>{selectedLog.email}</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>Action</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>{selectedLog.actions}</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>IP Address</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>{selectedLog.ip_address}</span>
					</Col>
				</Row>
			</Card>
		</>
	);
}

export default View;
