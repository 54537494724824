import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			logList: [],
			logPages: 1,
			logCount: 0,
			showFilterModal: false,
			showView: false,
			selectedLog: null,
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getLog = (querystring = "") => {
			Get(
				`/audits?limit=10&source=admin&offset=${(this.state.logPages - 1) * 10}` +
					querystring,
				this.getLogSuccess,
				this.getLogError,
				this.load
			);
		};
		getLogSuccess = (payload) => {
			this.setState({
				logList: payload.data,
				logCount: payload.count,
			});
		};
		getLogError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onLoad={this.state.loading}
					getLog={this.getLog}
					onChangeHOC={this.onChangeHOC}
					showFilterModal={this.state.showFilterModal}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
