import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({
  onSubmit,
  onChangeHOC,
  statesList,
  handleState,
}) {
  const [candidateName, setCandidateName] = useState("");
  const [candidateContactNumber, setCandidateContactNumber] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidateCity, setCandidateCity] = useState("");
  const [candidateState, setCandidateState] = useState("");
  const [candidateExpectedSalary, setCandidateExpectedSalary] = useState("");

  const handleClear = () => {
    setCandidateName("");
    setCandidateContactNumber("");
    setCandidateEmail("");
    setCandidateCity("");
    setCandidateState("");
    setCandidateExpectedSalary("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (!!candidateName) {
      queryString += `&name=${candidateName}`;
    }
    if (!!candidateContactNumber) {
      queryString += `&mobile_number=${candidateContactNumber}`;
    }
    if (!!candidateEmail) {
      queryString += `&email=${candidateEmail}`;
    }
    if (!!candidateCity) {
      queryString += `&city=${candidateCity}`;
    }
    if (!!candidateState) {
      queryString += `&state=${candidateState}`;
    }
    if (!!candidateExpectedSalary) {
      queryString += `&expected_salary=${candidateExpectedSalary}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-user-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="candidateName">Name</Label>
                <Input
                  placeholder="Enter Name"
                  name="candidateName"
                  id="candidateName"
                  value={candidateName}
                  onChange={(e) => setCandidateName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="candidateContactNumber">Contact Number</Label>
                <Input
                  placeholder="Enter Contact Number"
                  name="candidateContactNumber"
                  id="candidateContactNumber"
                  value={candidateContactNumber}
                  onChange={(e) => setCandidateContactNumber(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="candidateEmail">Email</Label>
                <Input
                  placeholder="Enter Email"
                  name="candidateEmail"
                  id="candidateEmail"
                  value={candidateEmail}
                  onChange={(e) => setCandidateEmail(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="candidateCity">City</Label>
                <Input
                  type="text"
                  name="candidateCity"
                  placeholder="Enter City"
                  id="candidateCity"
                  value={candidateCity}
                  onChange={(e) => setCandidateCity(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="candidateState">State</Label>
                <Input
                  type="select"
                  name="candidateState"
                  id="candidateState"
                  value={candidateState}
                  onChange={(e) => setCandidateState(e.target.value)}
                >
                  <option value="" disabled>
                    Select State
                  </option>
                  {statesList.map((state) => (
                    <option value={state.name} key={state.id}>
                      {state.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="candidateExpectedSalary">Expected Salary</Label>
                <Input
                  placeholder="Enter Expected Salary"
                  type="number"
                  name="candidateExpectedSalary"
                  id="candidateExpectedSalary"
                  value={candidateExpectedSalary}
                  onChange={(e) => setCandidateExpectedSalary(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Apply",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("candidatePages", 1);
            },
          },
        ]}
      />
    </>
  );
}
