import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";
import Select from "react-select";
import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

export default function FilterForm({ onSubmit, onChangeHOC, handleState, stateList }) {
  const [candidateName, setCandidateName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidateCity, setCandidateCity] = useState("");
  const [candidateState, setCandidateState] = useState("");
  const [userId, setUserId] = useState("");

  const [options, setOptions] = useState([]);

  const handleClear = () => {
    setCandidateName("");
    setContactNumber("");
    setWhatsappNumber("");
    setCandidateEmail("");
    setCandidateCity("");
    setCandidateState("");
    setUserId("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (candidateName) {
      queryString += `&name=${candidateName}`;
    }
    if (contactNumber) {
      queryString += `&mobile_number=${contactNumber}`;
    }
    if (whatsappNumber) {
      queryString += `&whatsapp_number=${whatsappNumber}`;
    }
    if (candidateEmail) {
      queryString += `&email=${candidateEmail}`;
    }
    if (candidateCity) {
      queryString += `&city=${candidateCity}`;
    }
    if (candidateState) {
      queryString += `&state=${candidateState}`;
    }
    if (userId) {
      queryString += `&user_id=${userId}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="candidate-list-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="candidateName">User ID</Label>
                <Input
                  placeholder="Enter User ID"
                  name="setUserId"
                  id="setUserId"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="candidateName">Name</Label>
                <Input
                  placeholder="Enter Name"
                  name="candidateName"
                  id="candidateName"
                  value={candidateName}
                  onChange={(e) => setCandidateName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="contactNumber">Contact Number</Label>
                <Input
                  placeholder="Enter Contact Number"
                  name="contactNumber"
                  id="contactNumber"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="whatsappNumber">Whatsapp</Label>
                <Input
                  placeholder="Enter Whatsapp Number"
                  name="whatsappNumber"
                  id="whatsappNumber"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="candidateEmail">Email</Label>
                <Input
                  placeholder="Enter Email"
                  name="candidateEmail"
                  id="candidateEmail"
                  value={candidateEmail}
                  onChange={(e) => setCandidateEmail(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="status">City</Label>
                <Select
                  isClearable
                  isSearchable
                  name="status"
                  id="status"
                  value={candidateCity}
                  placeholder={"Select City"}
                  onChange={(e) => setCandidateCity(e.target.value)}
                  options={options}
                  onInputChange={(newValue) => {
                    if (!newValue) return;

                    Get(
                        `/cities?search=${newValue}`,
                        (response) =>
                          setOptions(
                            response.data.map((e) => ({
                              value: e.id,
                              label: e.name,
                            }))
                          ),
                        (error) => {
                          requestError(error);
                        },
                        () => {}
                      );
                  }}
                />
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="candidateState">State</Label>
                <Input
                  type="select"
                  name="candidateState"
                  id="candidateState"
                  value={candidateState}
                  onChange={(e) => setCandidateState(e.target.value)}
                >
                  <option disabled value="">
                    Select State
                  </option>
                  {stateList?.map((state, index) => (
                  <option key={index} value={state.value}>
                    {state.label}
                  </option>
                ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Search",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("candidateListPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
