import TagButton from "components/TagButton";
import { useEffect, useState } from "react";

import { CategoryOptions } from "Config";
import BlogListing from "./BlogListing";
import HOC from "./action";
import "./index.scss";

function SavedArticles(props) {
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(() => {
    props.getBlog(props.jobSeekerProfile.user_id);
  }, [selectedTab])

	return (
		<>
			<h5 className="mb-4 e-classified-cont-header">
				Saved Articles
			</h5>
			<div className="e-classified-saved-article-tags-cont">
				{CategoryOptions.map((item) => (
					<TagButton
						key={`blog_cat_${item.id}`}
						selectedTab={selectedTab}
						item={item.id}
						label={ item.label }
						onSelect={(val) => setSelectedTab(val)}
					/>
				))}
			</div>
			<BlogListing
				selectedTab={selectedTab}
				blogPosts={props.savedBlogs}
			/>
		</>
	);
}

export default HOC(SavedArticles);
