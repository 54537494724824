import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
		};

		onChangeHOC = (val, context) => this.setState({ [context]: val });

		getBanner = (querystring = "", resolve, reject) => {
			Get(
				"/popup-banners?limit=10&type=inhouse&location=Jobmacha" + querystring,
				(response) => {
					resolve(response.data);
				},
				(error) => {
					requestError(error);
					reject(error);
				},
				this.load
			);
		}

		createNewBanner = async (dataToSubmit) => {

			const bannerImageCN = dataToSubmit.banner_image_mobile_cn_url 
				? await this.props.createAsset(dataToSubmit.title, dataToSubmit.banner_image_mobile_cn_url, 'banner_image_mobile_cn_url')
				: ''
			const bannerImage = dataToSubmit.banner_image_mobile_en_url ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.banner_image_mobile_en_url, 'banner_image_mobile_en_url')
				: ''
			const popUpImage = dataToSubmit.popup_image_en_url ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_en_url, 'popup_image_en_url')
				: ''
			const popUpImageCN = dataToSubmit.popup_image_cn_url ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_cn_url, 'popup_image_cn_url')
				: ''
			Post(
				"/popup-banners",
				{
					... dataToSubmit,
					banner_image_mobile_cn_url: bannerImageCN && typeof bannerImageCN !== 'string' ? bannerImageCN.data.url : bannerImageCN,
					banner_image_mobile_en_url: bannerImage && typeof bannerImage !== 'string' ? bannerImage.data.url : bannerImage,
					popup_image_en_url: popUpImage && typeof popUpImage !== 'string' ? popUpImage.data.url : popUpImage,
					popup_image_cn_url: popUpImageCN && typeof popUpImageCN !== 'string' ? popUpImageCN.data.url : popUpImageCN,
				},
				this.createNewBannerSuccess,
				this.createNewBannerError,
				this.load
			);
		};
		createNewBannerSuccess = () => {
			requestSuccess("Banner created successfully.");
			setTimeout(() => {
				this.props.history.push("/banner-inhouse");
			}, 2000);
		};
		createNewBannerError = (error) => requestError(error);

		updateBanner = async(dataToSubmit, deleteAssetsList = []) => {
			deleteAssetsList.map(async url => this.props.deleteAsset( url ))

			const bannerImageCN = ( dataToSubmit.banner_image_mobile_cn_url || '' ).indexOf( 'base64' ) > -1 ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.banner_image_mobile_cn_url, 'banner_image_mobile_cn_url')
				: (dataToSubmit.banner_image_mobile_cn_url || '')
			const bannerImage = ( dataToSubmit.banner_image_mobile_en_url || '' ).indexOf( 'base64' ) > -1 ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.banner_image_mobile_en_url, 'banner_image_mobile_en_url')
				: (dataToSubmit.banner_image_mobile_en_url || '')
			const popUpImage = ( dataToSubmit.popup_image_en_url || '' ).indexOf( 'base64' ) > -1 ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_en_url, 'popup_image_en_url')
				: (dataToSubmit.popup_image_en_url || '')
			const popUpImageCN = ( dataToSubmit.popup_image_cn_url || '' ).indexOf( 'base64' ) > -1 ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_cn_url, 'popup_image_cn_url')
				: (dataToSubmit.popup_image_cn_url || '')
			
			Put(
				`/popup-banners/${dataToSubmit.id}`,
				{
					... dataToSubmit,
					banner_image_mobile_cn_url:  typeof bannerImageCN === 'object' && bannerImageCN !== null ? bannerImageCN.data.url : bannerImageCN,
					banner_image_mobile_en_url:  typeof bannerImage === 'object' && bannerImage !== null ? bannerImage.data.url : bannerImage,
					popup_image_en_url:  typeof popUpImage === 'object' && popUpImage !== null ? popUpImage.data.url : popUpImage,
					popup_image_cn_url:  typeof popUpImageCN === 'object' && popUpImageCN !== null ? popUpImageCN.data.url : popUpImageCN,
				},
				this.updateBannerSuccess,
				this.updateBannerError,
				this.load
			);
		};
		updateBannerSuccess = () => {
			requestSuccess("Banner updated successfully.");
			setTimeout(() => {
				this.props.history.push("/banner-inhouse");
			}, 2000);
		};
		updateBannerError = (error) => requestError(error);

		load = (param) => this.setState({ loading: param });

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					onLoad={this.state.loading}
					onChangeHOC={this.onChangeHOC}
					createNewBanner={this.createNewBanner}
					getBanner={this.getBanner}
					updateBanner={this.updateBanner}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps, {
		setProfile,
	})(WithHOC);
};
export default HOC;
