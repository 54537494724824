import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			roleList: [],
		};

		onChangeHOC = (val, context) => this.setState({ [context]: val });

		getBanner = (querystring = "", resolve, reject) =>
			Get(
				"/popup-banners?limit=10&type=popup&location=Jobmacha" + querystring,
				(response) => {
					resolve(response.data);
				},
				(error) => {
					requestError(error);
					reject(error);
				},
				this.load
			);
		createNewBanner = async (dataToSubmit) => {
			const popUpImage = dataToSubmit.popup_image_en_url ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_en_url, 'popup_image_en_url')
				: ''
			const popUpImageCN = dataToSubmit.popup_image_cn_url ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_cn_url, 'popup_image_cn_url')
				: ''

			Post(
				"/popup-banners",
				{
					... dataToSubmit,
					popup_image_en_url: popUpImage && typeof popUpImage !== 'string' ? popUpImage.data.url : popUpImage,
					popup_image_cn_url: popUpImageCN && typeof popUpImageCN !== 'string' ? popUpImageCN.data.url : popUpImageCN,
				},
				this.createNewBannerSuccess,
				this.createNewBannerError,
				this.load
			);
		};
		createNewBannerSuccess = () => {
			requestSuccess("Banner created successfully.");
			setTimeout(() => {
				this.props.history.push("/banner-popup");
			}, 2000);
		};
		createNewBannerError = (error) => requestError(error);

		updateBanner = async (dataToSubmit, deleteAssetsList) => {
			deleteAssetsList.map(async url => this.props.deleteAsset( url ))

			const popUpImage = ( dataToSubmit.popup_image_en_url || '' ).indexOf( 'base64' ) > -1 ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_en_url, 'popup_image_en_url')
				: (dataToSubmit.popup_image_en_url || '')
			const popUpImageCN = ( dataToSubmit.popup_image_cn_url || '' ).indexOf( 'base64' ) > -1 ? 
				await this.props.createAsset(dataToSubmit.title, dataToSubmit.popup_image_cn_url, 'popup_image_cn_url')
				: (dataToSubmit.popup_image_cn_url || '')

			Put(
				`/popup-banners/${dataToSubmit.id}`,
				{
					... dataToSubmit,
					popup_image_en_url:  typeof popUpImage === 'object' && popUpImage !== null ? popUpImage.data.url : popUpImage,
					popup_image_cn_url:  typeof popUpImageCN === 'object' && popUpImageCN !== null ? popUpImageCN.data.url : popUpImageCN,
				},
				this.updateBannerSuccess,
				this.updateBannerError,
				this.load
			);
		};
		updateBannerSuccess = () => {
			requestSuccess("Banner updated successfully.");
			setTimeout(() => {
				this.props.history.push("/banner-popup");
			}, 2000);
		};
		updateBannerError = (error) => requestError(error);

		getRole = () =>
			Get(
				`/roles?platform=jobmacha`,
				this.getRoleSuccess,
				this.getRoleError,
				this.load
			);
		getRoleSuccess = (payload) => {
			this.setState({
				roleList: payload.data,
			});
		};
		getRoleError = (error) => requestError(error);

		searchUser = (user, resolve, reject) =>
			Get(
				`/users?platform=jobmacha${user ? `&name=${user}` : ""}`,
				(response) => {
					resolve(response.data); // Resolve the promise with options
				},
				(error) => {
					requestError(error);
					reject(error);
				},
				this.load
			);

		load = (param) => this.setState({ loading: param });

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					onLoad={this.state.loading}
					onChangeHOC={this.onChangeHOC}
					getRole={this.getRole}
					roleList={this.state.roleList}
					searchUser={this.searchUser}
					createNewBanner={this.createNewBanner}
					getBanner={this.getBanner}
					updateBanner={this.updateBanner}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps, {
		setProfile,
	})(WithHOC);
};
export default HOC;
