import React, { useEffect, useState } from "react";
import { useLocale } from "use-intl";
import { Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import _ from "lodash";
import { getCountries, getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import "./index.scss";

// // Sample
// <FormInput
//   row={true}
//   labelCol={{ xl: 2, lg: 2, md: 2 }}
//   inputCol={{ xl: 10, lg: 10, md: 10 }}
//   formGroupStyle={{ marginBottom: "6px" }}
//   type={"text"}
//   value={""}
//   label={"User ID"}
//   errors={""}
//   labelClass="text-xs font-weight-bold text-light-grey"
//   context={"user-id"}
//   required={false}
//   placeholder={"User ID"}
//   onChangeData={() => { }}
// />

const customStyles = {
	control: (provided, state) => ({
	  ...provided,
	  minHeight: 'calc(1.5em + 0.75rem + 2px)',
	  height: 'calc(1.5em + 0.75rem + 2px)',
	  fontSize: '0.875rem',
	  fontWeight: '300',
	  border: '1px solid #d0d5dd',
	  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
	  marginRight: '4px',
	  '&:hover': {
		borderColor: '#d0d5dd',
	  },
	  // focus state styling
	  border: state.isFocused ? '1px solid #337cd5' : provided.border,
	  boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : provided.boxShadow,
	}),
  };
const CountryCodeSelect = ({ onChange, value }) => {
	const options = getCountries().map(countryCode => ({
		value: `+${getCountryCallingCode(countryCode)}`,
		label: `+${getCountryCallingCode(countryCode)} ${countryCode}`,
	}));

	const selectedOption = options.find(option => option.value === value);
	
	return (
		<Select
			options={options}
			value={selectedOption}
			onChange={option => onChange(option ? option.value : "")}
			isSearchable={true}
			placeholder={"+60"}
			className="custom-select-component"
			classNamePrefix="custom-select"
			styles={customStyles}
		/>
	);
};

const FormInput = ({
	formGroupStyle,
	formGroupClass,
	type,
	row, // boolean
	labelCol, //customize column size for label
	inputCol, //customize column size for input
	style,
	value,
	label,
	prefix,
	onKeyUp,
	disabled, // boolean
	errors, // [ { field: 'name', message: 'Name is required' }]
	labelClass,
	labelHelptext,
	helptext,
	context,
	required,
	maxLength,
	placeholder,
	countryCode,
	placeholder_zh,
	onChangeData,
	labelChildren,
	min,
	className
}) => {

	const locale = useLocale()
	const [countryCodeState, setCountryCodeState] = useState('+60')
	const [cleanPhone, setCleanPhone] = useState(null)

	const handleChangeCountryCodeChange = (newCountryCode) => {
		setCountryCodeState(newCountryCode);
	};

	useEffect(() => {
		if(countryCode && value) {
			let clean = value.split('+').pop()
			let correctedPhone = '+'+clean
			if(value.split('+')?.length > 2) onChangeData(correctedPhone)
			setCleanPhone(value.replaceAll('+' + parsePhoneNumber(value)?.countryCallingCode, ''))
		}
	}, [value])

	const renderInput = () => (
			<InputGroup className="custom-input-group">
				{prefix && <span className="custom-input-prefix">{prefix}</span>}
				{countryCode && (
					<CountryCodeSelect
						value={countryCodeState}
						onChange={handleChangeCountryCodeChange}
					/>
				)}
				<Input
					type={type}
					disabled={disabled}
					value={cleanPhone ?? value}
					style={style}
					onKeyUp={onKeyUp}
					maxLength={maxLength}
					className={`custom-form-input ${className || ""}`} 
					placeholder={locale === 'zh'
						? (placeholder_zh || placeholder || '')
						: (placeholder || '')}
					onChange={(e) => {
						const caret = e.target.selectionStart
						const element = e.target
						window.requestAnimationFrame(() => {
							element.selectionStart = caret
							element.selectionEnd = caret
						})
						// For number inputs, ensure the value is not less than the specified min
						if (type === "number" && min !== undefined) {
							const newValue = Math.max(Number(e.target.value), min);
							onChangeData(newValue.toString());
						} else {
							onChangeData(e.target.value);
						}
						}}
					// Conditionally add the min attribute for number inputs
					{...(type === "number" && { min })}
				/>
			</InputGroup>
	)

	return (
		<FormGroup
			style={{ ...formGroupStyle }}
			className={`${formGroupClass || ""}`}
			row={row || false}
			required={required}>
			{
				label !== undefined && (
					<Label
						{...(row ? labelCol : {})}
						className={`custom-form-label ${labelClass}`}
						for={context}>
						{label}
						{labelHelptext && <span style={{ fontSize: '0.875rem', fontWeight: 300 }} className="text-secondary">{labelHelptext}</span>}
						{required ? <span className="text-danger">*</span> : ""}
						{labelChildren}
					</Label>
				)
			}
			{row ? (
				<Col {...(row && inputCol)}>
					{renderInput()}
				</Col>
			) : (renderInput())}
			{helptext && <span className="custom-helptext">{helptext}</span>}
			<span className="text-danger">
				{errors?.length > 0 &&
					_.find(errors, (obj) => obj.field === context)?.message}{" "}
			</span>
		</FormGroup>
	);
};

export default FormInput;
