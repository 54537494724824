import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
        state = {
            loading: false,
            reportList: [],
            reportPages: 1,
            reportCount: 0,
            showFilterModal: false,
            filterQuery: null,
        };

        onChangeHOC = (key, val) => this.setState({ [key]: val });

        load = (param) => this.setState({ loading: param });

        getReport = (querystring = "") =>
            Get(
                `/reports/sales?limit=10&offset=${
                    (this.state.reportPages - 1) * 10
                }` + querystring,
                this.getReportSuccess,
                this.getReportError,
                this.load
            );
        getReportSuccess = (payload) => {
            this.setState({
                reportList: payload.data.rows,
                reportCount: payload.data.count,
            });
        };
        getReportError = (error) => requestError(error);

        exportReport = () => {
            Get(
                `/report-generate/xlsx/sales?${this.state.filterQuery?.slice(1) || ""}`,
                this.exportReportSuccess,
                this.exportReportError,
                this.load
            );
        };
        exportReportSuccess = (payload) => {
            requestSuccess(payload.message);
            window.open(payload.ossUrl, "_blank");
        };
        exportReportError = (error) => requestError(error);

        render = () => {
            return (
                <WrappedComponent
                    {...this.props}
                    reportList={this.state.reportList}
                    reportPages={this.state.reportPages}
                    reportCount={this.state.reportCount}
                    onLoad={this.state.loading}
                    getReport={this.getReport}
                    onChangeHOC={this.onChangeHOC}
                    showFilterModal={this.state.showFilterModal}
                    exportReport={this.exportReport}
                />
            );
        };
    }
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
