import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
  const [jobPosition, setJobPosition] = useState("");
  const [jobProfession, setJobProfession] = useState("");

  const handleClear = () => {
    setJobPosition("");
    setJobProfession("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (!!jobPosition) {
      queryString += `&job_title_en=${jobPosition}&job_title_zh=${jobPosition}`;
    }
    if (!!jobProfession) {
      queryString += `&specialization_ids=${jobProfession}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-user-filter-form">
          <Row>
            <Col>
              <FormGroup>
                <Label for="jobPosition">职业名称</Label>
                <Input
                  placeholder="输入职业名称"
                  name="jobPosition"
                  id="jobPosition"
                  value={jobPosition}
                  onChange={(e) => setJobPosition(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="jobProfession">专业</Label>
                <Input
                  placeholder="输入专业"
                  name="jobProfession"
                  id="jobProfession"
                  value={jobProfession}
                  onChange={(e) => setJobProfession(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Apply",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("postPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
