import React, { useEffect } from "react";
import CustomModal from "./index";
import AssetDB from "components/Table/Asset";
import CustomModalBody from "components/Modal/body";
import AssetsHOC from "actions/hoc/assets";

const MediaDBModal = ({ isOpen, onSelect, onClose, ...props }) => {
	useEffect(() => {
		if (isOpen) {
			props.getAssets();
		}
	}, [isOpen]);
	return (
		<CustomModal isOpen={isOpen} size={"xl"} onClose={onClose}>
			<CustomModalBody>
				<AssetDB
					mode={"view"}
					{...props}
					onSelect={onSelect}
					onClickElement={(val) =>
						props.onChangeAssetsHOC("selectedAsset", val)
					}
					{...props}
				/>
			</CustomModalBody>
		</CustomModal>
	);
};

export default AssetsHOC(MediaDBModal);
