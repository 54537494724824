import { Component } from "react";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			savedBlogs: [],
		};

		onChangeBlogHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getBlog = (userID) => {
			Get(
				`/user-saved-posts?created_by=${userID}`,
				this.getBlogSuccess,
				this.getBlogError,
				this.load,
				true
			);
		};
		getBlogSuccess = (payload) => {
			let temp = payload.data.map((payloadChild) => ({
				...payloadChild,
				is_applied: true,
			}));

			this.setState({ savedBlogs: temp });
		};
		getBlogError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					getBlog={this.getBlog}
				/>
			);
		};
	}

	return WithHOC;
};

export default HOC;
