import CertItem from "./components/CertItem";

function Skill({ ...props }) {
	const { language_skill, license_type, misc_skills, certifications } = props.jobSeekerProfile;

	return (
		<>
			<div className="d-flex">
				<h5 className="mb-3 e-classified-cont-header">
					Skills 
				</h5>
			</div>
			<div className="e-classified-profile-view-card">
				<h5 className="e-classified-cont-header">
					Languages
				</h5>
				<div className="e-classified-profile-tag-cont mt-4 mb-4">
					{language_skill.map((skillChild) => (
						<div
							key={skillChild.key}
							className="e-classified-tag-cont-secondary">
							{skillChild[`label_en`]}
						</div>
					))}
				</div>
				<hr />
				<h5 className="e-classified-cont-header">
					Driving License
				</h5>
				<div className="e-classified-profile-tag-cont mt-4 mb-4">
					{license_type.map((licenseChild) => (
						<div
							key={licenseChild.key}
							className="e-classified-tag-cont-secondary">
							{licenseChild[`label_en`]}
						</div>
					))}
				</div>
				<hr />
				<h5 className="e-classified-cont-header">
					{" "}
					Personality
				</h5>
				<div className="e-classified-profile-tag-cont mt-4 mb-4">
					{(misc_skills?.personalities ?? []).map((child) => (
						<div key={child.key} className="e-classified-tag-cont-secondary">
							{child[`label_en`]}
						</div>
					))}
				</div>
				<hr />
				<h5 className="e-classified-cont-header">
					Soft Skils
				</h5>
				<div className="e-classified-profile-tag-cont mt-4 mb-4">
					{(misc_skills?.soft_skills ?? []).map((child) => (
						<div key={child.key} className="e-classified-tag-cont-secondary">
							{child[`label_en`]}
						</div>
					))}
				</div>
				<hr />
				<h5 className="e-classified-cont-header">
					Hard Skills
				</h5>
				<div className="e-classified-profile-tag-cont mt-4 mb-4">
					{(misc_skills?.hard_skills ?? []).map((child) => (
						<div key={child.key} className="e-classified-tag-cont-secondary">
							{child[`label_en`]}
						</div>
					))}
				</div>
				<hr />
				<h5 className="e-classified-cont-header">
					Software Skills
				</h5>
				<div className="e-classified-profile-tag-cont mt-4 mb-4">
					{(misc_skills?.software_skills ?? []).map((child) => (
						<div key={child.key} className="e-classified-tag-cont-secondary">
							{child[`label_en`]}
						</div>
					))}
				</div>
				<hr />
				<h5 className="e-classified-cont-header">
					Certificates
				</h5>
				{certifications.map((certChild) => (
					<CertItem
						certChild={certChild}
						context={"view"}
						onPreview={(val) => props.setPreview(val)}
					/>
				))}
			</div>
		</>
	);
}

export default Skill;
