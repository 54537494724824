import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [postID, setPostID] = useState("");
	const [orderDesc, setOrderDesc] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [advertiserId, setAdvertiserId] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [agentId, setAgentId] = useState("");
	const [agentName, setAgentName] = useState("");

	const handleClear = () => {
		setPostID("");
		setOrderDesc("");
		setStartDate("");
		setEndDate("");
		setAdvertiserId("");
		setAdvertiser("");
		setAgentId("")
		setAgentName("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (postID){ queryString += `&post_id=${postID}`}
		if (orderDesc){ queryString += `&order_description=${orderDesc}`}
		if (startDate){ queryString += `&start_date=${startDate}`}
		if (endDate){ queryString += `&end_date=${endDate}`}
		if (agentId){ queryString += `&agent_id=${agentId}`}
		if (agentName){ queryString += `&agent_name=${agentName}`}
		if (advertiserId){ queryString += `&advertiser_id=${advertiserId}`}
		if (advertiser){ queryString += `&advertiser_name=${advertiser}`}

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="postID">Post ID </Label>
								<Input
									placeholder="Enter Post ID"
									name="postID"
									id="postID"
									value={postID}
									onChange={(e) => {setPostID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="orderDesc">Order Description</Label>
								<Input
									placeholder="Enter Order Description"
									name="orderDesc"
									id="orderDesc"
									value={orderDesc}
									onChange={(e) => {setOrderDesc(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="startDate">Start Date </Label>
								<Input
									type="date"
									placeholder="Enter Start Date"
									name="startDate"
									id="startDate"
									value={startDate}
									onChange={(e) => {setStartDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="endDate">End Date </Label>
								<Input
									type="date"
									placeholder="Enter End Date"
									name="endDate"
									id="endDate"
									value={endDate}
									onChange={(e) => {setEndDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiserId">Advertiser ID </Label>
								<Input
									placeholder="Enter Advertiser ID"
									name="advertiserId"
									id="advertiserId"
									value={advertiserId}
									onChange={(e) => {setAdvertiserId(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {setAdvertiser(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentId">Agent ID </Label>
								<Input
									placeholder="Enter Agent ID"
									name="agentId"
									id="agentId"
									value={agentId}
									onChange={(e) => {setAgentId(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentNamee"
									value={agentName}
									onChange={(e) => {setAgentName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
