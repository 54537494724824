import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Container, ModalBody, Card, CardBody } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import ManageBannerPopupAPI from "./action.js";
import moment from "moment";
import { RolesPermission } from "Config";
import "./index.scss";
import { CheckUserRolesPermissons } from "actions/roles";

class ManageBannerPopup extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(6)) {
			return this.props.history.push("/");
		}

		this.props.getBanner();
	};

	componentDidUpdate = (pp) => {
		const { bannerPopupPages, getBanner } = this.props;
		const { filterQuery } = this.state;

		if (pp.bannerPopupPages !== bannerPopupPages) {
			getBanner(
				`${
					bannerPopupPages > 1 ? `&offset=${(bannerPopupPages - 1) * 10}` : ""
				}${!!filterQuery ? filterQuery : ""}`
			);
		}
	};

	state = {
		selectedRowData: null,
		filterQuery: "",
	};

	Publish = (val) => {
		this.props.onChangeHOC("selectedPopupId", val.popupId);
		this.props.onChangeHOC("showPublishModal", true);
	};

	renderSampleTable = () => {
		const { bannerPopupList, onLoadBanner, onChangeHOC } = this.props;

		return (
			<CustomTable
				data={bannerPopupList}
				showPagination={false}
				loading={onLoadBanner}
				columnsContent={[
					{
						Header: "Pop-Up ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.id}</>,
					},
					{
						Header: "Pop-Up Title",
						accessorKey: "title",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.title}</>,
					},
					{
						Header: "Recipient",
						accessorKey: "popup_banner_roles",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>
								{val.popup_banner_roles?.map((user) => user.name).join(", ")}
							</>
						),
					},
					{
						Header: "Redirect Link",
						accessorKey: "link_url",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>
								<a href={val.link_url}>{val.link_url}</a>
							</>
						),
					},
					{
						Header: "Redirect Count",
						accessorKey: "redirect_count",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.redirect_count}</>,
					},
					{
						Header: "Close Count",
						accessorKey: "close_count",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.close_count}</>,
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => <>{val.status}</>,
					},
					{
						Header: "Created Date",
						accessorKey: "created_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.created_at ? moment(val.created_at).format("DD/MM/YYYY hh:mm A") : "-"}</>
						),
					},
					{
						Header: "Start Date",
						accessorKey: "start_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.start_date ? moment(val.start_date).format("DD/MM/YYYY hh:mm A") : "-"}</>
						),
					},
					{
						Header: "End Date",
						accessorKey: "end_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.end_date ? moment(val.end_date).format("DD/MM/YYYY hh:mm A") : "-"}</>
						),
					},
				]}
				menuContent={[
					...([4].includes(CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id)
						? [
								{
									menuID: "PreviewPopup",
									menuTooltip: "Preview",
									onClick: (val) => {
										this.setState({ selectedRowData: val });
										onChangeHOC("showPreviewModal", true);
									},
								},
						  ]
						: [
								{
									menuID: "EditPopup",
									menuTooltip: "Edit",
									onClick: (val) => {
										this.props.history.push(`/banner-popup/edit/${val.id}`);
									},
								},
								{
									menuID: "PublishPopup",
									menuTooltip: "Publish",
									onClick: (val) => {
										this.Publish(val);
									},
								},
								{
									menuID: "SuspendPopup",
									menuTooltip: "Suspend",
									onClick: (val) => {
										this.setState({ selectedRowData: val });
										onChangeHOC("showSuspendModal", true);
									},
								},
								{
									menuID: "DisablePopup",
									menuTooltip: "Disable",
									onClick: (val) => {
										this.setState({ selectedRowData: val });
										onChangeHOC("showDisableModal", true);
									},
								},
						  ]),
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getBanner, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Pop-Up Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getBanner}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	renderEditModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showEditModal}
				onClose={() => this.props.onChangeHOC("showEditModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showEditModal", false)}
				title={`Are you sure you want to edit this popup? ID: ${this.props.selectedPopupId}`}
				message="Please confirm this action."
				confirmContent="Yes, edit"
				confirmColor="danger"
			/>
		);
	};
	renderPreviewModal = () => {
		const { selectedRowData } = this.state;
		const { showPreviewModal, onChangeHOC } = this.props;

		return (
			<CustomModal
				size={"lg"}
				title="Banner Preview"
				isOpen={showPreviewModal}
				onClose={() => onChangeHOC("showPreviewModal", false)}>
				<ModalBody>
					<Container>
						<h3>
							<b>{selectedRowData?.title}</b>
						</h3>
						<br />
						<Row className="banner-row">
							<Col xs={6}>
								<div className="each-banner-preview">
									<span>
										<b>Popup Image CN</b>
									</span>
									{!selectedRowData?.popup_image_cn_url ? (
										"No image available to preview"
									) : (
										<img
											src={selectedRowData.popup_image_cn_url}
											alt="Popup CN"
											className="image-container"
										/>
									)}
								</div>
							</Col>
							<Col xs={6}>
								<div className="each-banner-preview">
									<span>
										<b>Popup Image EN</b>
									</span>
									{!selectedRowData?.popup_image_en_url ? (
										"No image available to preview"
									) : (
										<img
											src={selectedRowData.popup_image_en_url}
											alt="Popup EN"
											className="image-container"
										/>
									)}
								</div>
							</Col>
						</Row>
					</Container>
				</ModalBody>
			</CustomModal>
		);
	};

	renderPublishModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showPublishModal}
				onClose={() => this.props.onChangeHOC("showPublishModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showPublishModal", false)}
				title={`Are you sure you want to publish this popup? ID: ${this.props.selectedPopupId}`}
				message="Please confirm this action."
				confirmContent="Yes, publish"
				confirmColor="danger"
			/>
		);
	};

	renderSuspendModal = () => {
		const { selectedRowData } = this.state;
		const { showSuspendModal, onChangeHOC, updateBanner } = this.props;

		return (
			<ConfirmationModal
				open={showSuspendModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					onChangeHOC("showSuspendModal", false);
				}}
				onClickConfirm={() => {
					updateBanner({
						title: selectedRowData.title,
						status: "expired",
						id: selectedRowData.id,
						type: selectedRowData.type,
					});
					this.setState({
						selectedRowData: null,
					});
				}}
				title={`Are you sure you want to suspend this popup? ID: ${selectedRowData?.id}`}
				message="Please confirm this action."
				confirmContent="Yes, suspend"
				confirmColor="danger"
			/>
		);
	};

	renderDisableModal = () => {
		const { selectedRowData } = this.state;
		const { showDisableModal, onChangeHOC, updateBanner } = this.props;

		return (
			<ConfirmationModal
				open={showDisableModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					onChangeHOC("showDisableModal", false);
				}}
				onClickConfirm={() => {
					updateBanner({
						title: selectedRowData.title,
						status: "disabled",
						id: selectedRowData.id,
						type: selectedRowData.type,
					});
					this.setState({
						selectedRowData: null,
					});
				}}
				title={`Are you sure you want to disable this banner? ID: ${selectedRowData?.id}`}
				message="Please confirm this action."
				confirmContent="Yes, disable"
				confirmColor="danger"
			/>
		);
	};

	render = () => {
		const {
			onChangeHOC,
			onLoadBanner,
			bannerPopupPages,
			bannerPopupCount,
			getBanner,
		} = this.props;

		const { filterQuery } = this.state;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Manage Banner / <b>Manage Pop-Up Banner</b>"
										buttons={[
											...(![4].includes(
												CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id
											)
												? [
														{
															color: "primary",
															className: "btn-icon mr-2",
															onClick: () => {
																this.props.history.push("/banner-popup/create");
															},
															content: (
																<>
																	<span>Create Pop-Up</span>
																</>
															),
														},
												  ]
												: []),
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<h5>Manage Pop-Up Banner</h5>
										<br></br>
										<Card className="main-card mb-3">
											<CardBody>
												{this.renderSampleTable()}
												<Pagination
													pageIndex={bannerPopupPages}
													totalCount={bannerPopupCount}
													onChangePage={(val) =>
														onChangeHOC("bannerPopupPages", val)
													}
												/>
											</CardBody>
										</Card>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderEditModal()}
									{this.renderPreviewModal()}
									{this.renderPublishModal()}
									{this.renderSuspendModal()}
									{this.renderDisableModal()}
									{this.props.onLoadBanner && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ManageBannerPopupAPI)(ManageBannerPopup);
