import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			postList: [],
			postPages: 1,
			postCount: 0,
			showFilterModal: false,
			showRecommendModal: false,
			selectedPost: {},
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getPost = (query = "") => {
			const route = `/job-ads?limit=10&type=job${!!query ? query : ""}`;

			Get(route, this.getPostSuccess, this.getPostError, this.load);
		};
		getPostSuccess = (payload) => {
			this.setState({
				postList: payload.data,
				postCount: payload.count,
			});
		};
		getPostError = (error) => requestError(error);

		// function to toggle display filter modal
		toggleShowFilterModal = () => {
			this.setState((prevState) => ({
				showFilterModal: !prevState.showFilterModal,
			}));
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					loading={this.state.loading}
					postList={this.state.postList}
					postPages={this.state.postPages}
					postCount={this.state.postCount}
					getPost={this.getPost}
					onChangeHOC={this.onChangeHOC}
					showFilterModal={this.state.showFilterModal}
					showRecommendModal={this.state.showRecommendModal}
					selectedPost={this.state.selectedPost}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
