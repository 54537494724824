import { Component } from "react";

import { Delete, Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			savedAdvertisers: [],
		};

		onChangeSavedHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getSaved = (userID) =>
			Get(
				`/user-saved-companies?user_id=${userID}`,
				this.getSavedSuccess,
				this.getSavedError,
				this.load,
				true
			);
		getSavedSuccess = (payload) =>
			this.setState({ savedAdvertisers: payload.data });
		getSavedError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					getSaved={this.getSaved}
				/>
			);
		};
	}

	return WithHOC;
};

export default HOC;
