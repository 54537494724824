import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [invoiceStartDate, setInvoiceStartDate] = useState(null);
	const [invoiceEndDate, setInvoiceEndDate] = useState(null);
	const [advertiserId, setAdvertiserId] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [billingName, setBillingName] = useState("");
	const [agentName, setAgentName] = useState("");
	const [sellingPrice, setSellingPrice] = useState("");
	const [orderDesc, setOrderDesc] = useState("");
	const [agentLeadID, setAgentLeadID] = useState("");
	const [promoCode, setPromoCode] = useState("");
	const [paymentRefNo, setPaymentRefNo] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");

	const handleClear = () => {
		setInvoiceStartDate("");
		setInvoiceEndDate("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (invoiceStartDate){ queryString += `&start_date=${invoiceStartDate}`}
		if (invoiceEndDate){ queryString += `&end_date=${invoiceEndDate}`}


		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceStartDate">Start Date </Label>
								<Input
									type="date"
									placeholder="Enter Start Date"
									name="invoiceStartDate"
									id="invoiceStartDate"
									value={invoiceStartDate}
									onChange={(e) => {setInvoiceStartDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceEndDate">End Date </Label>
								<Input
									type="date"
									placeholder="Enter "
									name="invoiceEndDate"
									id="invoiceEndDate"
									value={invoiceEndDate}
									onChange={(e) => {setInvoiceEndDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
