import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ specializations, selectedTag, onSubmit, onChangeHOC }) {
  const [id, setId] = useState("");
  const [ecId, setEcId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [advertiserName, setAdvertiserName] = useState("");
  const [agentName, setAgentName] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");

  const handleClear = () => {
    setId("");
    setEcId("");
    setJobTitle("");
    setAdvertiserName("");
    setSpecialization("");
    setPublishedDate("");
    setLastModifiedDate("");
    setAgentName("");
    
    onSubmit();
    onChangeHOC("showFilterModal", false);
  };

  const handleSubmit = () => {
    const urlParams = new URLSearchParams();
    urlParams.append('platform_uuid', id);
    urlParams.append('ec_id', ecId);
    urlParams.append('job_title_en', jobTitle);
    urlParams.append('company_name', advertiserName);
    urlParams.append('specialization_ids', specialization);
    urlParams.append('published_date', publishedDate);
    urlParams.append('updated_at', lastModifiedDate);
    urlParams.append('agent_name', agentName);

    onSubmit(`&${urlParams}`);
    onChangeHOC("showFilterModal", false);
  }

  return (
    <>
      <CustomModalBody>
        <Form id="manage-post-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="id">JobMacha ID</Label>
                <Input
                  placeholder="JobMacha ID"
                  name="id"
                  id="id"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="ec_id">EC ID</Label>
                <Input
                  placeholder="EC ID"
                  name="ec_id"
                  id="ec_id"
                  value={ecId}
                  onChange={(e) => setEcId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="job_title">Job Title</Label>
                <Input
                  placeholder="Job Title"
                  name="job_title"
                  id="job_title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="agent_name">Agent Name</Label>
                <Input
                  placeholder="Agent Name"
                  name="agent_name"
                  id="agent_name"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="company_id">Advertiser Name</Label>
                <Input
                  placeholder="Advertiser Name"
                  name="company_id"
                  id="company_id"
                  value={advertiserName}
                  onChange={(e) => setAdvertiserName(e.target.value)}
                />
              </FormGroup>
            </Col>

            
            <Col lg={3}>
              <FormGroup>
                <Label for="specialization_ids">Industry</Label>
                <Input
                  type="select"
                  name="specialization_ids"
                  id="specialization_ids"
                  value={specialization}
                  onChange={(e) => setSpecialization([e.target.value])}
                >
                  <option disabled value="">
                    Select Specialization
                  </option>
                  {specializations.map((nature) => (
                    <option value={nature.value}>{nature.en}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="published_date">Published Date</Label>
                <Input
                  placeholder="Published Date"
                  type="date"
                  name="published_date"
                  id="published_date"
                  value={publishedDate}
                  onChange={(e) => setPublishedDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="last_modified_date">Last Modified Date</Label>
                <Input
                  placeholder="Last Modified Datee"
                  type="date"
                  name="last_modified_date"
                  id="last_modified_date"
                  value={lastModifiedDate}
                  onChange={(e) => setLastModifiedDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Submit",
            onClick: () => handleSubmit(),
          },
        ]}
      />
    </>
  );
}
