import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Post, Get } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showPassword: false,
      showErrorMessage: false,
      showCreateSampleModal: false,
      natureList: [],
      countries: [],
      states: [],

      selectedCountry: null,
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });
    onChangeSampleHOC = (key, val) => this.setState({ [key]: val });

    createAdvertiser = (dataToSubmit) => {
      Post(
        `/companies`,
        dataToSubmit,
        this.createAdvertiserSuccess,
        this.createAdvertiserError,
        this.load
      );
    };
    createAdvertiserSuccess = () => {
      requestSuccess("Advertiser created successfully.");
      setTimeout(() => {
        this.props.history.push("/advertiser-profile");
      }, 2000);
    };
    createAdvertiserError = (error) => requestError(error);

    load = (param) => this.setState({ loading: param });

    getNature = () => {
      this.setState({ loading: true });
      Get(
        "/industries",
        this.getNatureSuccess,
        this.getNatureError,
        this.load
      );
    };
    getNatureSuccess = (payload) => {
      this.setState({
        natureList: payload.data,
      });
    };
    getNatureError = (error) => requestError(error);

    getCountries = () => {
      Get(
        `/countries`,
        this.getCountriesSuccess,
        (error) => requestError(error),
        this.load
      )
    }
    getCountriesSuccess = response => {
      if (response?.data.length > 0) {
        let tempData = response.data.map(country => {
          return {
            label: country.name,
            value: country.id
          }
        })
        this.setState({
          countries: tempData.sort((a, b) => {
            // Prioritize Malaysia and Singapore
            if (a.label === 'Malaysia') return -1;
            if (b.label === 'Malaysia') return 1;
            if (a.label === 'Singapore') return b.label === 'Malaysia' ? 1 : -1;
            if (b.label === 'Singapore') return a.label === 'Malaysia' ? -1 : 1;

            // Alphabetical order for the rest
            return a.label.localeCompare(b.label);
          })
        })

        // Select Malaysia by default
        this.setState({ selectedCountry: tempData[0].value });
        // Immediately fetch states for the default country
        this.getStates(this.state.selectedCountry);
      }
    }

    getStates = (id) => {
      Get(
        `/countries/${id}/states`,
        this.getStatesSuccess,
        error => requestError(error),
        this.load
      )
    }
    getStatesSuccess = response => {
      if (response?.data.length > 0) {
        let tempData = response.data.map(state => {
          return {
            label: state.name,
            value: state.id
          }
        })
        this.setState({ states: tempData.sort((a, b) => a.label.localeCompare(b.label)) })
      }
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          showPassword={this.state.showPassword}
          showErrorMessage={this.state.showErrorMessage}
          onLoadLogin={this.state.loading}
          onChangeHOC={this.onChangeHOC}
          onClickLogin={this.onClickLogin}
          showCreateSampleModal={this.state.showCreateSampleModal}
          onChangeSampleHOC={this.onChangeSampleHOC}
          createAdvertiser={this.createAdvertiser}
          natureList={this.state.natureList}
          getNature={this.getNature}
          getCountries={this.getCountries}
          countries={this.state.countries}
          getStates={this.getStates}
          states={this.state.states}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setProfile,
  })(WithHOC);
};
export default HOC;
