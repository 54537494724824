import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import UpdateCreatePostAPI from "./CreatePost/action";
import UpdatePostForm from "./CreatePost/Form";

class UpdatePost extends Component {
  componentDidMount = () => {
    this.props.getBenefits()
    this.props.getAgentList()
    this.props.getAdvertiser();
    this.props.getNature();
    this.props.getEducationLevel();
    this.props.getPositionLevel();
    this.props.getPackage();
    this.props.getPlan();
    this.props.getCountry();
    this.props.getRegions();
    const urlParams = new URLSearchParams(this.props.history.location.search);
    const slug = urlParams.get('slug');
    this.props.getSelectedPost(slug);
  };

  render = () => {

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Manage Post / <b>Edit Post</b>"></PageTitle>
                <UpdatePostForm mode={'Update'} {...this.props} />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(UpdateCreatePostAPI)(UpdatePost);