import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CustomModal from "components/Modal";
import Pagination from "components/Pagination";
import CustomTable from "components/Table";
import TemplateContainerMain from "components/Template";
import PageTitle from "components/Title/PageTitle";
import moment from "moment";
import _ from "lodash";
import { RolesPermission } from "Config";
import { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Card, CardBody, Col, Row } from "reactstrap";
import { compose } from "redux";
import FilterForm from "./Form";
import ReportAPI from "./action.js";
import { CheckUserRolesPermissons } from "actions/roles";

class SalesReportByCategory extends Component {
	state = {
		filterQuery: "",
	};

	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(13)) {
			return this.props.history.push("/");
		}

		this.props.getReport();
		this.props.getSpecialization();
	};

	componentDidUpdate = (pp) => {
		const { reportPages, getReport } = this.props;
		const { filterQuery } = this.state;
		if (pp.reportPages !== reportPages) {
			getReport(`${!!filterQuery ? filterQuery : ""}`);
		}
	};

	renderReportTable = () => {
		const { reportList, onLoad } = this.props;
		return (
			<CustomTable
				data={reportList}
				showPagination={false}
				loading={onLoad}
				columnsContent={[
					{
						Header: "Invoice Date",
						accessorKey: "invoice_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return (
								<>{moment(val.invoice_date).format("DD/MM/YYYY hh:mm A")}</>
							);
						},
					},
					{
						Header: "Invoice No.",
						accessorKey: "invoice_no",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.invoice_no}</>;
						},
					},
					{
						Header: "Advertiser Name",
						accessorKey: "company_name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.company_name}</>;
						},
					},
					{
						Header: "Order No.",
						accessorKey: "order_number",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.order_number}</>;
						},
					},
					{
						Header: "Selling Price",
						accessorKey: "selling_price",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.selling_price}</>;
						},
					},
					{
						Header: "Price After Tax",
						accessorKey: "price_after_tax",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.price_after_tax}</>;
						},
					},
					// {
					// 	Header: "Category",
					// 	accessorKey: "specialization_name.en",
					// 	centerColumn: true,
					// 	isStatusButton: false,
					// 	Cell: (val) => {return <>{val.specialization_name.en}</>},
					// },
					{
						Header: "Specialization",
						accessorKey: "specialization_name.en",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val?.specialization_name?.en}</>;
						},
					},
					{
						Header: "Agent ID",
						accessorKey: "agent_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.agent_id}</>;
						},
					},
					{
						Header: "Agent Name",
						accessorKey: "agent_name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.agent_name}</>;
						},
					},
					{
						Header: "Agent Lead ID",
						accessorKey: "agent_lead_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.agent_lead_id}</>;
						},
					},
					{
						Header: "Agent Lead Name",
						accessorKey: "agent_lead_name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => {
							return <>{val.agent_lead_name}</>;
						},
					},
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getReport, onChangeHOC, showFilterModal, specializationList } =
			this.props;
		const customSetState = (newState) => {
			this.setState(newState);
		};

        const handleSubmit = (filterQuery) => {
            getReport(filterQuery);
            onChangeHOC("filterQuery", filterQuery);
        }

		return (
			<CustomModal
				size={"lg"}
				title="Sales Report By Category Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={handleSubmit}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
					specializationList={specializationList}
				/>
			</CustomModal>
		);
	};

	render = () => {
		const { onChangeHOC, reportPages, reportCount } = this.props;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Sales Report By Category"
										buttons={[
											{
												color: "",
												className: "btn-outline-primary mr-2",
												onClick: () => { this.props.exportReport() },
												content: (
													<>
														<span>Export</span>
													</>
												),
											},
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderReportTable()}
														<Pagination
															pageIndex={reportPages}
															totalCount={reportCount}
															onChangePage={(val) =>
																onChangeHOC("reportPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.props.onLoad && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ReportAPI)(SalesReportByCategory);
