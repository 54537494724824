import { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			currentPage: 1,
			candidateCount: 1,
			query: "",
			selectedCandidate: null,
			candidateList: [],
			showFilterModal: false,
			showConfirmModal: false,
			countries: [],
			states: [],
			cities: [],
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getCountries = () =>
			Get(
				`/countries`,
				this.getCountriesSuccess,
				(error) => requestError(error),
				this.load
			);
		getCountriesSuccess = (response) => {
			if (response?.data.length > 0) {
				let tempData = response.data.map((country) => {
					return {
						label: country.name,
						value: country.id,
					};
				});
				this.setState({
					countries: tempData.sort((a, b) => a.label.localeCompare(b.label)),
				});
			}
		};

		getStates = (id) => {
			this.setState({ selectedCountry: id });
			Get(
				`/countries/${id}/states`,
				this.getStatesSuccess,
				(error) => requestError(error),
				this.load
			);
		};
		getStatesSuccess = (response) => {
			if (response?.data.length > 0) {
				let tempData = response.data.map((state) => {
					return {
						label: state.name,
						value: state.id,
					};
				});
				this.setState({
					states: tempData.sort((a, b) => a.label.localeCompare(b.label)),
				});
			}
		};

		getStates = (id) => {
			this.setState({ selectedCountry: id });
			Get(
				`/countries/136/states`,
				this.getStatesSuccess,
				(error) => requestError(error),
				this.load
			);
		};
		getStatesSuccess = (response) => {
			if (response?.data.length > 0) {
				let tempData = response.data.map((state) => {
					return {
						label: state.name,
						value: state.id,
					};
				});
				this.setState({
					states: tempData.sort((a, b) => a.label.localeCompare(b.label)),
				});
			}
		};

		getCities = (id) =>
			Get(
				`/countries/${this.state.selectedCountry}/states/${id}/cities`,
				this.getCitiesSuccess,
				(error) => requestError(error),
				this.load
			);
		getCitiesSuccess = (response) => {
			if (response?.data.length > 0) {
				let tempData = response.data.map((city) => {
					return {
						label: city.street,
						value: city.id,
					};
				});
				this.setState({
					cities: tempData.sort((a, b) => a.label.localeCompare(b.label)),
				});
			}
		};

		getCandidate = (query) =>
			Get(
				`/jobseeker-profiles?limit=10&offset=${
					(this.state.currentPage - 1) * 10
				}${query ? query : ""}`,
				this.getCandidateSuccess,
				this.getCandidateError,
				this.load
			);
		getCandidateSuccess = (payload) => {
			this.setState({
				candidateList: payload.data,
				candidateCount: payload.count,
			});
		};
		getCandidateError = (error) => requestError(error);

		// function to toggle display filter modal
		toggleShowFilterModal = () => {
			this.setState((prevState) => ({
				showFilterModal: !prevState.showFilterModal,
			}));
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					query={this.state.query}
					onLoadCandiate={this.state.loading}
					getCandidate={this.getCandidate}
					getCountries={this.getCountries}
					getStates={this.getStates}
					getCities={this.getCities}
					onChangeHOC={this.onChangeHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
