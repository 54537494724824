import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      roleList: [],
      selectedRowData: [],
    };

    load = (param) => this.setState({ loading: param });
    onChangeHOC = (key, val) => this.setState({ [key]: val });

    updateVoucher = (dataToSubmit) => {
      Put(
        `/vouchers/${this.props.match.params.id}`,
        dataToSubmit,
        this.updateVoucherSuccess,
        this.updateVoucherError,
        this.load
      );
    };
    updateVoucherSuccess = () => {
      requestSuccess("Voucher updated successfully.");
      setTimeout(() => {
        this.props.history.push("/promo-code");
      }, 2000);
    };
    updateVoucherError = (error) => requestError(error);

    getVoucher = () =>
      Get(
        `/vouchers/${this.props.match.params.id}`,
        this.getVoucherSuccess,
        this.getVoucherError,
        this.load
      );
    getVoucherSuccess = (payload) => {
      this.setState({ selectedRowData: payload.data[0] });
    };
    getVoucherError = (error) => requestError(error);

    getRole = () =>
      Get(
        `/roles?platform=jobmacha`,
        this.getRoleSuccess,
        this.getRoleError,
        this.load
      );
    getRoleSuccess = (payload) => {
      this.setState({
        roleList: payload.data,
      });
    };
    getRoleError = (error) => requestError(error);

    searchUser = (user, resolve, reject) =>
      Get(
        `/users?platform=jobmacha${user ? `&name=${user}` : ""}`,
        (response) => {
          resolve(response.data); // Resolve the promise with options
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeHOC={this.onChangeHOC}
          updateVoucher={this.updateVoucher}
          getRole={this.getRole}
          searchUser={this.searchUser}
          getVoucher={this.getVoucher}
        />
      );
    }
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { setProfile })(WithHOC);
};

export default HOC;
