import Edit from "./edit";
import View from "./view";
import "../../index.scss";

function ProfileExp(props) {
	return (
		<>{props.mode === "edit" ? <Edit {...props} /> : <View {...props} />}</>
	);
}

export default ProfileExp;
