import {
	AiOutlineHeart,
	AiOutlineMail,
	AiOutlinePhone,
	AiOutlineWhatsApp,
} from "react-icons/ai";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { HiOutlineCake } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";

function profile({ jobSeekerProfile }) {
	return (
		<>
			<div className="e-classified-profile-view-card">
				<div className="d-flex mb-4 align-items-center">
					<div
						className="bg-secondary mr-4"
						style={{
							cursor: "pointer",
							width: "5rem",
							height: "5rem",
							borderRadius: "50%",
						}}
						onClick={() => {}}></div>
					<div>
						<h5 className="mb-2 e-classified-cont-header">
							{jobSeekerProfile.user.name || "N/A"}
						</h5>
						{(jobSeekerProfile.experiences || []).map((expChild) => (
							<div className="e-classified-profile-view-position-badge">
								{expChild}
							</div>
						))}
					</div>
				</div>
				<div className="e-classified-profile-view-info-subcont">
					<AiOutlineMail />
					<span>{jobSeekerProfile.user.email || "N/A"}</span>
				</div>
				<div className="d-flex align-items-center">
					<div className="e-classified-profile-view-info-subcont">
						<AiOutlinePhone />
						<span>{jobSeekerProfile.user.mobile_number || "N/A"}</span>
					</div>
					<div className="e-classified-profile-view-info-subcont">
						<AiOutlineWhatsApp />
						<span>{jobSeekerProfile.user.whatsapp_number || "N/A"}</span>
					</div>
				</div>
				<div className="d-flex align-items-center">
					<div className="e-classified-profile-view-info-subcont">
						<HiOutlineCake />
						<span>{jobSeekerProfile.birth_date || "N/A"}</span>
					</div>
					<div className="e-classified-profile-view-info-subcont">
						<span>
							{jobSeekerProfile.gender === "male" ? (
								<BsGenderMale />
							) : (
								<BsGenderFemale />
							)}
							<span style={{ textTransform: "capitalize" }}>
								{jobSeekerProfile.gender}
							</span>
						</span>
					</div>
					<div className="e-classified-profile-view-info-subcont">
						<AiOutlineHeart />
						<span style={{ textTransform: "capitalize" }}>
							{jobSeekerProfile.marital_status}
						</span>
					</div>
				</div>
				<div className="e-classified-profile-view-info-subcont">
					<IoLocationOutline />
					<span style={{ textTransform: "capitalize" }}>
						{jobSeekerProfile.address}
						{`, `}
						{jobSeekerProfile.state}
						{`, `}
						{jobSeekerProfile.city}
						{`, `}
						{jobSeekerProfile.country}
					</span>
				</div>
				<h5 className="mb-2 e-classified-cont-header">Expected Salary</h5>
				<span className="text-black">
					RM {jobSeekerProfile.expected_salary}
				</span>
				<div className="e-classified-profile-tag-cont mt-4 mb-4">
					<div className="e-classified-tag-cont-primary">Diploma</div>
					<div className="e-classified-tag-cont-primary">{`${jobSeekerProfile.state} - ${jobSeekerProfile.country}`}</div>
					<div className="e-classified-tag-cont-primary">
						{jobSeekerProfile.misc_skills.personalities?.[0]?.[`label_en`] ??
							"N/A"}
					</div>
				</div>
				<hr />
				<h5 className="mb-2 e-classified-cont-header">About Us</h5>
				<span className="text-secondary">{jobSeekerProfile.about_me}</span>
			</div>
		</>
	);
}

export default profile;
