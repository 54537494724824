import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import EditPromoCodeAPI from "./action.js";
import { EditNewVoucherForm } from "./Form.js";

class EditPromoCode extends Component {
  componentDidMount = () => this.props.getRole();

  render = () => {
    const { roleList, updateVoucher, searchUser, getVoucher, selectedRowData, getRole } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Manage Voucher / <b>Edit Promo Code</b>"></PageTitle>
                <h5>Edit Promo Code</h5> <br></br>
                <EditNewVoucherForm
                  onSubmit={updateVoucher}
                  roleList={roleList}
                  searchUser={searchUser}
                  getVoucher={getVoucher}
                  selectedRowData={selectedRowData}
                  getRole={getRole}
                />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}
export default compose(EditPromoCodeAPI)(EditPromoCode);