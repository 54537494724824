import React from "react";
import { BsBookmarkFill } from "react-icons/bs";
import SampleImg from 'assets/images/blog_sample1.png'

function SavedCard({ item, deleteBlog }) {
	return (
		<div className="e-classified-saved-article-card">
			<img
				src={item.cover_img_without_title_url || SampleImg }
				alt={item.cover_img_without_title_url}
				style={{ objectFit: "cover" }}
			/>
			<div className="e-classified-saved-article-card-action">
				<span>{item.title || "N/A"}</span>
				<BsBookmarkFill onClick={() => deleteBlog(item.user_saved_post.id)} />
			</div>
		</div>
	);
}

export default SavedCard;
