import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import CustomTable from "components/Table";
import TemplateContainerMain from "components/Template";
import PageTitle from "components/Title/PageTitle";
import moment from "moment";
import _ from "lodash";
import { RolesPermission } from "Config";
import { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Card, CardBody, Col, Row } from "reactstrap";
import { compose } from "redux";
import { CreateForm, FilterForm } from "./Form.js";
import AdvertiserAPI from "./action.js";
import PreCreateModal from "./PreCreateModal.js";
import PreviewModal from "./PreviewAdvertiser";
import { CheckUserRolesPermissons } from "actions/roles.js";


class Advertiser extends Component {
  componentDidMount = () => {
    let tempModule = _.find(RolesPermission, {
      id: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
    });

    if (!tempModule) {
      return this.props.history.push("/");
    }

    this.props.getNature();
    this.props.getAdvertiser();
  };

  componentDidUpdate = (pp) => {
    const { advertiserPages, getAdvertiser } = this.props;
    const { filterQuery } = this.state;

    if (pp.advertiserPages !== advertiserPages) {
      getAdvertiser(
        `${advertiserPages > 1 ? `&offset=${(advertiserPages - 1) * 10}` : ""}${
          !!filterQuery ? filterQuery : ""
        }`
      );
    }
  };

  state = {
    selectedRowData: null,
    filterQuery: "",
  };

  Edit = (val) => {
    if (val.type === 'company') {
      this.props.history.push(`/advertiser-profile-company/update/${val.id}`);
    } else if (val.type === 'individual') {
      this.props.history.push(`/advertiser-profile-individual/update/${val.id}`);
    }
  };

  Preview = (val) => {
    this.props.onChangeHOC("selectedCompanyName", val.companyName);
    this.props.onChangeHOC("showPreviewModal", true);
  };

  Inactive = (val) => {
    this.setState({ selectedRowData: val });
    this.props.onChangeHOC("showInactiveModal", true);
  };

  Activate = (val) => {
    this.setState({ selectedRowData: val });
    this.props.onChangeHOC("showActivateModal", true);
  };
  

  renderSampleTable = ({getSelectedCompany}) => {
    const { advertiserList, onLoadAdvertiser } = this.props;

    return (
      <CustomTable
        data={advertiserList}
        showPagination={false}
        loading={onLoadAdvertiser}
        columnsContent={[
          {
            Header: "Advertiser ID",
            accessorKey: "id",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.id}</>,
          },
          {
            Header: "Advertiser Name",
            accessorKey: "name",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.name}</>,
          },
          {
            Header: "Advertiser Type",
            accessorKey: "type",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.type}</>,
          },
          {
            Header: "Industry",
            accessorKey: "specialization",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val.industry?.name?.en}</>,
          },
          {
            Header: "Agent ID",
            accessorKey: "agent_id",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val?.agent?.code ?? '-'}</>,
          },
          {
            Header: "Agent Name",
            accessorKey: "agent",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => <>{val?.agent?.name ?? '-'}</>,
          },
          {
            Header: "Created Date",
            accessorKey: "created_at",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => (
              <>{moment(val.created_at).format("DD/MM/YYYY hh:mm A")}</>
            ),
          },
          {
            Header: "Last Updated Date",
            accessorKey: "updated_at",
            centerColumn: true,
            isStatusButton: true,
            Cell: (val) => (
              <>{moment(val.updated_at).format("DD/MM/YYYY hh:mm A")}</>
            ),
          },
        ]}
        menuContent={
          [3, 4, 5, 6].includes(CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id)
            ? [
                {
                  menuID: "PreviewAdvertiser",
                  menuTooltip: "Preview",
                  onClick: (val) => {
                    getSelectedCompany(val.id, "showPreviewModal")
                    this.Preview(val);
                  },
                },
              ]
              : [
                {
                  menuID: "EditAdvertiser",
                  menuTooltip: "Edit",
                  onClick: (val) => {
                    this.Edit(val);
                  },
                },
                {
                  menuID: "PreviewAdvertiser",
                  menuTooltip: "Preview",
                  onClick: (val) => {
                    getSelectedCompany(val.id, "showPreviewModal")
                    this.Preview(val);
                  },
                },
                {
                  menuID: "InactiveAdvertiser",
                  menuTooltip: "Inactive",
                  onClick: (val) => {
                    this.Inactive(val);
                  },
                },
                {
                  menuID: "ActivateAdvertiser",
                  menuTooltip: "Activate",
                  onClick: (val) => {
                    this.Activate(val);
                  },
                },
              ]
        }
      />
    );
  };

  renderCreateAdvertiser = () => {
    return (
      <PreCreateModal
        showPreCreateModal={this.props.showCreateAdvertiser}
        onChangeCompaniesHOC={this.props.onChangeHOC}
      />
      // <CustomModal
      // 	size={"lg"}
      // 	title="Create Advertiser Profile"
      // 	isOpen={this.props.showCreateAdvertiser}
      // 	onClose={() => this.props.onChangeHOC("showCreateAdvertiser", false)}>
      // 	<CreateForm
      // 		history={this.props.history}
      // 		onSubmit={this.props.createSample}
      // 		onLoadSample={this.props.onLoadSample}
      // 	/>
      // </CustomModal>
    );
  };

  renderFilterAdvertiser = () => {
    const { getAdvertiser, onChangeHOC, showFilterModal, natureList } =
      this.props;

    const customSetState = (newState) => {
      this.setState(newState);
    };

    const handleSubmit = (filterQuery) => {
      getAdvertiser(filterQuery);
      onChangeHOC("filterQuery", filterQuery);
    }

    return (
      <CustomModal
        size={"lg"}
        title="Manage Advertiser Profile Filter"
        isOpen={showFilterModal}
        onClose={() => onChangeHOC("showFilterModal", false)}
      >
        <FilterForm
          natureList={natureList}
          onSubmit={handleSubmit}
          onChangeHOC={onChangeHOC}
          handleState={customSetState}
        />
      </CustomModal>
    );
  };

  renderPreviewModal = ({loading, showPreviewModal, selectedCompany, getCompanyImage, onChangeHOC}) => {
    // const { selectedRowData } = this.state;
    
    return (
      <PreviewModal
        onLoad={loading}
        selectedCompany={selectedCompany}
        getCompanyImage={getCompanyImage}
        showPreviewModal={showPreviewModal}
        onChangeCompaniesHOC={onChangeHOC}
      />
    );
  };
  renderInactiveModal = () => {
    const { selectedRowData } = this.state;

    return (
      <ConfirmationModal
        open={this.props.showInactiveModal}
        onClose={() => this.props.onChangeHOC("showInactiveModal", false)}
        onClickConfirm={() => {
          this.props.updateAdvertiser({
            ...selectedRowData,
            status: "inactive",
          });
        }}
        title={`Confirm Inactive Advertiser`}
        message={`Are you sure you want to inactive ${selectedRowData?.name}?\n\nYou can click to activate the advertiser after this process if necessary.`}
        confirmContent="Inactive Advertiser"
        confirmColor="danger"
      />
    );
  };
  renderActivateModal = () => {
    const { selectedRowData } = this.state;

    return (
      <ConfirmationModal
        open={this.props.showActivateModal}
        onClose={() => this.props.onChangeHOC("showActivateModal", false)}
        onClickConfirm={() => {
          this.props.updateAdvertiser({
            ...selectedRowData,
            status: "active",
          });
        }}
        title={`Confirm Activate Advertiser`}
        message={`Are you sure you want to activate ${selectedRowData?.name}?\n\nYou can click to activate the advertiser after this process if necessary.`}
        confirmContent="Activate Advertiser"
        confirmColor="success"
      />
    );
  };

  render = () => {
    const {
      onChangeHOC,
      onLoadSample,
      advertiserPages,
      advertiserCount,
      getAdvertiser,
    } = this.props;

    const { filterQuery } = this.state;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <>

				{/* temp fix missing sideMenu from migration */}
				<div class="show-hide-menu-btn"/>
                  <PageTitle
                    heading="Manage Advertiser Profile"
                    buttons={[
                      ...(![3, 4, 5, 6].includes(
                        CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id
                      )
                        ? [
                            {
                              color: "primary",
                              className: "btn-icon mr-2",
                              onClick: () => {
                                onChangeHOC("showCreateAdvertiser", true);
                              },
                              content: (
                                <>
                                  <span>Create Advertiser</span>
                                </>
                              ),
                            },
                          ]
                        : []),
                      {
                        color: "",
                        className: "btn-outline-primary mr-2",
                        onClick: () => { this.props.exportReport() },
                        content: (
                          <>
                            <span>Export</span>
                          </>
                        ),
                      },
                      {
                        color: "",
                        className: "btn-outline-primary",
                        onClick: () => onChangeHOC("showFilterModal", true),
                        content: (
                          <>
                            <span>Filter</span>
                          </>
                        ),
                      },
                    ]}
                  />
                  <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                  >
                    <Row>
                      <Col md={12} style={{minWidth: 'max-content'}}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            {this.renderSampleTable(this.props)}
                            <Pagination
                              pageIndex={advertiserPages}
                              totalCount={advertiserCount}
                              onChangePage={(val) =>
                                onChangeHOC("advertiserPages", val)
                              }
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </ReactCSSTransitionGroup>
                  {this.renderCreateAdvertiser()}
                  {this.renderFilterAdvertiser()}
                  {this.renderPreviewModal(this.props)}
                  {this.renderInactiveModal()}
                  {this.renderActivateModal()}
                  {onLoadSample && <LoadingOverlay />}
                </>
              </div>
            </div>
          </div>
          {/* <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} /> */}
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(AdvertiserAPI)(Advertiser);
