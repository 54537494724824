import _ from "lodash";
import React, { Component } from "react";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Card, CardBody } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import PromoCodeAPI from "./action.js";
import { RolesPermission } from "Config";
import { CheckUserRolesPermissons } from "actions/roles";

class PromoCode extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(5)) {
			return this.props.history.push("/");
		}

		this.props.getVoucher();
	};

	componentDidUpdate = (pp) => {
		const { voucherPages, getVoucher } = this.props;
		const { filterQuery } = this.state;

		if (pp.voucherPages !== voucherPages) {
			getVoucher(
				`${voucherPages > 1 ? `&offset=${(voucherPages - 1) * 10}` : ""}${
					!!filterQuery ? filterQuery : ""
				}`
			);
		}
	};

	state = {
		selectedRowData: null,
		filterQuery: "",
	};

	Edit = (val) => {
		this.props.history.push("/promo-code/edit/" + val.id);
	};

	renderSampleTable = () => {
		const { voucherList, onLoadVoucher, onChangeHOC } = this.props;

		return (
			<CustomTable
				data={voucherList}
				showPagination={false}
				loading={onLoadVoucher}
				columnsContent={[
					{
						Header: "Promo ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.id}</>,
					},
					{
						Header: "Promo Code",
						accessorKey: "code",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.code}</>,
					},
					{
						Header: "Promo Value",
						accessorKey: "value",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.value}</>,
					},
					{
						Header: "Promo Type",
						accessorKey: "type",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.type}</>,
					},
					{
						Header: "Quantity",
						accessorKey: "quantity",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.quantity + val.used_quantity}</>,
					},
					{
						Header: "Available",
						accessorKey: "used_quantity",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.quantity}</>,
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => <>{val.status}</>,
					},
					{
						Header: "Created Date",
						accessorKey: "created_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.created_at).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
					{
						Header: "Start Date",
						accessorKey: "start_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.start_date).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
					{
						Header: "End Date",
						accessorKey: "end_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.end_date).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
				]}
				menuContent={[
					...(![3, 4].includes(CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id)
						? [
                            {
                                menuID: "EditPromoCode",
                                menuTooltip: "Edit",
                                onClick: (val) => {
                                    this.Edit(val);
                                },
                            },
                            {
                                menuID: "PublishPromoCode",
                                menuTooltip: "Publish",
                                onClick: (val) => {
                                    this.setState({ selectedRowData: val });
                                    onChangeHOC("showConfirmModal", true);
                                },
                            },
                            {
                                menuID: "SuspendPromoCode",
                                menuTooltip: "Suspend",
                                onClick: (val) => {
                                    this.setState({ selectedRowData: val });
                                    onChangeHOC("showSuspendModal", true);
                                },
                            },
                            {
                                menuID: "DisablePromoCode",
                                menuTooltip: "Disable",
                                onClick: (val) => {
                                    this.setState({ selectedRowData: val });
                                    onChangeHOC("showDisableModal", true);
                                },
                            }
						  ]
						: []),
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getVoucher, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Manage Promo Code Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getVoucher}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	renderConfirmModal = () => {
		const { selectedRowData } = this.state;

		return (
			<ConfirmationModal
				open={this.props.showConfirmModal}
				onClose={() => this.props.onChangeHOC("showConfirmModal", false)}
				onClickConfirm={() => {
					this.props.updateVoucher({
						...selectedRowData,
                        value: Number(selectedRowData.value),
						status: "publish",
						voucher_roles: !!selectedRowData?.voucher_roles
							? selectedRowData.voucher_roles.map((role) => role.id)
							: [],
						voucher_users: !!selectedRowData?.voucher_users
							? selectedRowData?.voucher_users.map((user) => user.id)
							: [],
					});
					this.setState({
						selectedRowData: null,
					});
					this.props.onChangeHOC("showConfirmModal", false);
                }}
				title="Are you sure you want to publish?"
				message="Please confirm this action."
				confirmContent="Yes, publish"
				confirmColor="success"
			/>
		);
	};

	renderSuspendModal = () => {
		const { selectedRowData } = this.state;

		return (
			<ConfirmationModal
				open={this.props.showSuspendModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					this.props.onChangeHOC("showSuspendModal", false);
				}}
				onClickConfirm={() => {
					this.props.updateVoucher({
						...selectedRowData,
                        value: Number(selectedRowData.value),
						status: "suspend",
						voucher_roles: !!selectedRowData?.voucher_roles
							? selectedRowData.voucher_roles.map((role) => role.id)
							: [],
						voucher_users: !!selectedRowData?.voucher_users
							? selectedRowData?.voucher_users.map((user) => user.id)
							: [],
					});
					this.setState({
						selectedRowData: null,
					});
					this.props.onChangeHOC("showSuspendModal", false);
				}}
				title="Are you sure you want to suspend?"
				message="Please confirm this action."
				confirmContent="Yes, suspend"
				confirmColor="danger"
			/>
		);
	};

	renderDisableModal = () => {
		const { selectedRowData } = this.state;

		return (
			<ConfirmationModal
				open={this.props.showDisableModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					this.props.onChangeHOC("showDisableModal", false);
				}}
				onClickConfirm={() => {
					this.props.updateVoucher({
						...selectedRowData,
                        value: Number(selectedRowData.value),
						status: "disable",
						voucher_roles: !!selectedRowData?.voucher_roles
							? selectedRowData.voucher_roles.map((role) => role.id)
							: [],
						voucher_users: !!selectedRowData?.voucher_users
							? selectedRowData?.voucher_users.map((user) => user.id)
							: [],
					});
					this.setState({
						selectedRowData: null,
					});
					this.props.onChangeHOC("showDisableModal", false);
				}}
				title="Are you sure you want to disable?"
				message="Please confirm this action."
				confirmContent="Yes, disable"
				confirmColor="danger"
			/>
		);
	};

	render = () => {
		const {
			onChangeHOC,
			onLoadVoucher,
			voucherPages,
			voucherCount,
			getVoucher,
		} = this.props;

		const { filterQuery } = this.state;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Manage Promo Code"
										buttons={[
											...(![3, 4].includes(
												CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id
											)
												? [
														{
															color: "primary",
															className: "btn-icon mr-2",
															onClick: () => {
																this.props.history.push("/promo-code/create");
															},
															content: (
																<>
																	<span>Create New Promo Code</span>
																</>
															),
														},
												  ]
												: []),
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderSampleTable()}
														<Pagination
															pageIndex={voucherPages}
															totalCount={voucherCount}
															onChangePage={(val) =>
																onChangeHOC("voucherPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderConfirmModal()}
									{this.renderSuspendModal()}
                                    {this.renderDisableModal()}
									{this.props.onLoadVoucher && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(PromoCodeAPI)(PromoCode);
