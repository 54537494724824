import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Card, CardTitle, CardText, CardBody, Row, Col } from "reactstrap";
import PageTitle from "components/Title/PageTitle";
import SampleHOC from "./action.js";
import UpdateAdvertiserForm from "./Form.js";

class UpdateAdvertiserIndividual extends Component {
  componentDidMount = () => {
    this.props.getNature();
    this.props.getCountries();
    this.props.getCompanyDetailsData(this.props.location.pathname.split("/").pop());
  };

  state = {
    email: "",
    password: "",
    statusModal: false,
    showFilterModal: false,
  };

  render = () => {
    const { updateAdvertiser, companyDetailsData, countries, states, getStates, onChangeHOC, natureList } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Manage Advertiser Profile / <b>Update Individual Advertiser Profile</b>"></PageTitle>
                <h5>
                  <b>Update Advertiser Profile</b>
                </h5>
                <h6>Please edit below.</h6>
                <Row>
                  <Col xs={10}>
                    <UpdateAdvertiserForm
                      onSubmit={updateAdvertiser}
                      initialFormData={companyDetailsData}
                      natureList={natureList}
                      countries={countries}
                      states={states}
                      getStates={getStates}
                      onChangeHOC={onChangeHOC}
                      user={this.props.user}
                    />
                  </Col>
                  <Col xs={2}>
                    <Card
                      style={{
                        background:
                          "linear-gradient(180deg, rgb(34, 84, 245) 0%, rgb(82.48, 120.84, 244.37) 45.32%, rgb(121.2, 150.89, 246.5) 74.21%, rgb(164.53, 182.95, 242.25) 100%, rgb(142.7, 166.04, 241.19) 100%)",
                        height: "10rem",
                      }}
                      className="main-card mb-3"
                    >
                      <CardBody>
                        <CardTitle style={{ color: "white" }}>
                        Reminder  🔔
                        </CardTitle>
                        <CardText style={{ color: "white" }}>
                        Completing your profile information helps candidates get to know you better.
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(SampleHOC)(UpdateAdvertiserIndividual);
