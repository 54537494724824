import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ selectedTag, onSubmit }) {
  const [jobPosition, setJobPosition] = useState("");
  const [jobProfession, setJobProfession] = useState("");

  const handleClear = () => {
    setJobPosition("");
    setJobProfession("");
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-user-filter-form">
          <Row>
            <Col >
              <FormGroup>
                <Label for="jobPosition">职业名称</Label>
                <Input
                  placeholder="输入职业名称"
                  name="jobPosition"
                  id="jobPosition"
                  value={jobPosition}
                  onChange={(e) => setJobPosition(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col >
              <FormGroup>
                <Label for="jobProfession">专业</Label>
                <Input
                  placeholder="输入专业"
                  name="jobProfession"
                  id="jobProfession"
                  value={jobProfession}
                  onChange={(e) => setJobProfession(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Apply",
            onClick: () => {
              // Reference the form element and trigger its submit event
              const form = document.getElementById("candidate-list-filter-form");
              if (form) {
                form.dispatchEvent(new Event("submit"));
              }
            },
          },
        ]}
      />
    </>
  );
}
