import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Label,
  FormGroup,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";
import Axios from "axios";
import { BsFillImageFill } from "react-icons/bs";
import MediaDB from "components/Modal/MediaDB";
import getDomainURL from "utils/api";
import LoadingIndicator from "components/Indicator/LoadingOverlay";
import "./rte.scss";

const CKEditor5 = (props) => {
  const editorRef = useRef();
  const testEditorRef = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, CustomEditor } = editorRef.current || {};
  const [isOpenMediaSelector, toggleMediaSelector] = useState(false);

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      CustomEditor: require("ckeditor5-custom-build/build/ckeditor"),
    };
    setEditorLoaded(true);
  }, []);

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            let reader = new FileReader();
            reader.onload = (e) => {
              let token = localStorage["CHINA_PRESS_TOKEN"];
              Axios.defaults.headers = {
                "Access-Control-Allow-Origin": "*",
                Authorization: `${token}`,
              };
              return Axios.post(`${getDomainURL()}/assets`, {
                base64: e.target.result,
                title: file.name,
                alt: file.name,
                filename: file.name,
              })
                .then((res) => {
                  resolve({
                    default: res.data.data.url,
                  });
                })
                .catch((err) => {
                  if (err && err.response && err.response.status === 422) {
                    reject(err);
                  } else {
                    reject(
                      "You are disconnnected from the internet, please reconnect to use the system. If problem persists, please contact the system admin."
                    );
                  }
                });
            };
            reader.readAsDataURL(file);
          });
        });
      },
    };
  }

  return editorLoaded ? (
    <>
      <FormGroup className="ckrte">
        <Label className="d-flex align-items-end">
          {props.formLabel}
        </Label>
        <CKEditor
          ref={testEditorRef}
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                {
                  height: props.height || "400px",
                  backgroundColor: props.backgroundColor,
                },
                editor.editing.view.document.getRoot()
              );
            });
          }}
          config={{
            extraPlugins: [uploadPlugin],
            toolbar: {
              items: props.items
                ? props.items
                : [
                    "bold",
                    "italic",
                    "heading",
                    "bulletedList",
                    "fontSize",
                    "|",
                    "undo",
                    "redo",
                  ],
              shouldNotGroupWhenFull:
                props.shouldNotGroupWhenFull !== undefined
                  ? props.shouldNotGroupWhenFull
                  : true,
            },
            image: {
              toolbar: [
                "imageStyle:inline",
                "imageStyle:block",
                "imageStyle:side",
                "|",
                "toggleImageCaption",
                "imageTextAlternative",
                "resizeImage",
              ],
            },
            mediaEmbed: { previewsInData: true },
            link: {
              decorators: {
                openInNewTab: {
                  mode: "manual",
                  label: "Open in a new tab",
                  attributes: {
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                },
              },
            },
          }}
          editor={CustomEditor.ClassicEditor}
          data={props.data}
          onFocus={props.onFocus}
          onChange={props.onChange}
        />
        <MediaDB
          isOpen={isOpenMediaSelector}
          onSelect={(val) => {
            testEditorRef.current.editor.execute("insertImage", {
              source: val,
            });
            toggleMediaSelector(false);
            props.onChange(null, testEditorRef.current.editor);
          }}
          onClose={() => toggleMediaSelector(false)}
        />
        <FormFeedback className={props.invalid ? "d-block" : ""}>
          {props.invalid}
        </FormFeedback>
      </FormGroup>
    </>
  ) : (
    <LoadingIndicator />
  );
};

export default CKEditor5;
