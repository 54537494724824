import _ from 'lodash'
import React, { useEffect } from 'react'
import { Modal, ModalBody, Button } from 'reactstrap'
import { AiOutlineEye, AiOutlineClose, AiFillCheckCircle } from 'react-icons/ai'

import ECCheckbox from "components/Input/Checkbox"
import ECFormInput from "components/Input/FormInput"
import ECCartButton from "components/Input/CartButton"
import PaymentGatewayForm from "components/PaymentGatewayForm"

import ConfirmationModal from "components/Modal/confirmation";
import UpgradeHOC from './actions/upgrade'
import Select from "react-select";

import {
  PACKAGE_CONST_FACEBOOK_PLAN_ID,
  PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID,
  TAX_RATE
} from "constants/Constants";

const UpgradeModal = ({
  data,
  isOpen,
  plans,
  postPayment,
  selectedPost,
  payment_key,
  plansRaw,
  showConfirmModal,

  onClose,
  getPlans,
  createOrder,
  getPaymentKey,
  validateVoucher,
  onChangeUpgradeHOC,
  onChangeHOC,
  getPosts
}) => {

  let subTotal = postPayment.addOns.reduce((acc, item) => { return acc + (item.price_amount * item.quantity) }, 0)
  let discount = postPayment.voucher?.type 
    ? (postPayment.voucher?.type === 'percentage'
      ? (subTotal * postPayment.voucher?.value) / 100
      : postPayment.voucher?.value)
    : 0
  let tax = (subTotal - discount) * TAX_RATE
  let ec_total = subTotal + tax
  let grand_total = ec_total + postPayment.print_price

  useEffect(() => {
    getPlans()
  }, [])

  const onChangePostPayment = (field, val) => {
    let temp = _.cloneDeep(postPayment)
    temp[field] = val
    onChangeUpgradeHOC('postPayment', temp)
  }

  const onToggleAddOn = (addOn) => {
    let temp = _.cloneDeep(postPayment.addOns)
    const index = _.findIndex(temp, { id: addOn.id })
    if (index > -1) {
      const hasFacebookPin = _.find(temp, { id: PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID}); 
      temp.splice(index, 1)
      if (addOn.id === PACKAGE_CONST_FACEBOOK_PLAN_ID && hasFacebookPin) {
        const indexFacebookPin = _.findIndex(temp, { id: PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID })
        temp.splice(indexFacebookPin, 1)
      }
    } else {
      const hasFacebook = _.find(temp, { id: PACKAGE_CONST_FACEBOOK_PLAN_ID}); 
      if (addOn.id === PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID && !hasFacebook) {
        onChangeUpgradeHOC("showConfirmModal", true)
        return;
      }
      temp.push({ ...addOn, quantity: 1 })
    }
    onChangePostPayment('addOns', temp)
  }

  const onChangeQuantity = (val, addOn) => {
    let temp = _.cloneDeep(postPayment.addOns)
    const index = _.findIndex(temp, { id: addOn.id })
    if (index > -1) {
      temp[index].quantity = val
    }
    onChangePostPayment('addOns', temp)
  }

  const onChangeSelectedRegion = (val, addOn) => {
    let temp = _.cloneDeep(postPayment.addOns)
    const index = _.findIndex(temp, { id: addOn.id })
    if (index > -1) {
      temp[index] = {
        ...temp[index],
        ...val
      }
      if(temp[index].quantity <= 0) temp.splice(index, 1)
    } else {
      let target = _.find(plansRaw, { id: addOn.id })
      temp.push({
        ...target,
        price_amount: target.price.length > 0 ? target.price[0].amount : 0,
        ...val
      })
    }
    onChangePostPayment('addOns', temp)
  }

  const onChangeRegion  = (val, addOn) => {
    let temp = _.cloneDeep(postPayment.addOns)
    const index = _.findIndex(temp, { id: addOn.id })
    if (index > -1) {
      temp[index].region = val
    }
    onChangePostPayment('addOns', temp)
  }

  const onToggleUpgrade = () => {
    let temp = _.cloneDeep(postPayment)
    temp.status = 'backoffice-order'
    temp.user_id = data.ProfileReducer.profile.id
    temp.job_ads_id = selectedPost.id
    temp.company_id = selectedPost.company_id
    temp.tax = tax
    temp.subtotal = subTotal
    temp.discount = discount
    temp.order_plans = temp.addOns?.map(item => { return item.id } )
    temp.grand_total = grand_total
    temp.post_payment_url = `${window.location.origin}/advertiser/payment/complete`
    createOrder(temp, () => {
        getPosts();
        onChangeHOC("showUpgradeModal", false)
    });
  }
  
  const renderAddOns = () => {
    const renderAddOn = (addOn, index) => {
      const name = addOn?.name.en
      const subtitle = addOn?.detail.en

      return (
        <div className="mb-3">
          <div className='d-flex justify-content-between align-items-center' style={{
            ...[11,12].includes(addOn.id) && {
              pointerEvents: 'none',
              tabIndex: -1
            }
          }}>
            <ECCheckbox
              key={index}
              formGroupClass={'d-flex align-items-center'}
              formGroupStyle={{ padding: '0rem 1.25rem' }}
              label={
                <div className="e-classified-advertiser__add-on">
                  <div className="e-classified-advertiser__add-on-label">
                    <label>{name}</label>
                    <span className="text-secondary">({subtitle})</span>
                    <AiOutlineEye />
                  </div>
                  <p>RM {addOn.price_amount.toFixed(2)}</p>
                </div>
              }
              checked={_.find(postPayment.addOns, { id: addOn.id })}
              onChangeData={() => {onToggleAddOn(addOn)}} />
            <ECCartButton
              value={_.find(postPayment.addOns, { id: addOn.id })?.quantity ?? 0}
              disabledMinus={_.findIndex(postPayment.addOns, { id: addOn.id }) === -1 || _.find(postPayment.addOns, { id: addOn.id })?.quantity === 1}
              disabledPlus={_.findIndex(postPayment.addOns, { id: addOn.id }) === -1}
              onChangeData={val => onChangeQuantity(val, addOn)} />
          </div>
          {addOn.region?.length > 0 && 
            <Select
              className='pt-2'
              isMulti
              id="region"
              placeholder="Select"
              options={addOn.region.map((e) => ({
                label: e.name.en,
                value: e.id,
              }))}
              onChange={(selectedOption) => {
                onChangeSelectedRegion(
                  {
                    selected_region_ids: selectedOption.map(e => e.value),
                    selected_region_ids_raw: selectedOption,
                    quantity: selectedOption.length
                  }, addOn)
              }}
            />
          }
        </div>
      )
    }

    return (
      <>
        {
          plans.map((plan, index) => (
            <section key={index}>
              <label className="custom-form-label">{plan.title}</label>
              { plan.data.map((addOn, index) => (
                renderAddOn(addOn, index)
              )) }
            </section>
          ))
        }
      </>
    )
  }

  return(
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalBody style={{ padding: "2rem" }}>
          <AiOutlineClose
            style={{
              width: "1.5rem",
              height: "1.5rem",
              cursor: "pointer",
              position: "absolute",
              top: 20,
              right: 20,
            }}
            onClick={() => {
              onChangePostPayment("addOns", [])
              onClose()
            }}
          />
          <div>
            <label className="custom-form-label" style={{ fontSize: '1.125rem' }}>
              Upgrade Post
              <span className="text-secondary">(Select Multiple)</span>
            </label>
            { renderAddOns() }
          </div>
          <hr />
          <div className="e-classified-advertiser__manage-post-cont">
            <label className="custom-form-label">Your Charges</label>
            <div className="w-100">
              { postPayment.addOns?.map((addOn, index) => (
                <>
                  <div className="e-classified-advertiser__charges-item">
                    <div className="d-flex align-items-center">
                      <AiFillCheckCircle onClick={() => onChangePostPayment('package', {})} />
                      <span>{addOn?.name.en} x{addOn.quantity}</span>
                    </div>
                    <span className="fw-600">RM {(addOn.price_amount * addOn.quantity).toFixed(2)}</span>
                  </div>
                  <div key={index} className="e-classified-advertiser__charges-subitem">
                    <span>{addOn?.detail.en}</span>
                  </div>
                  { addOn.selected_region_ids_raw && <div className="e-classified-advertiser__charges-subitem d-flex justify-content-between">
                      <span className="text-secondary">
                        Region
                      </span>
                      <span className="text-secondary">
                      {addOn.selected_region_ids_raw.map(e => e.label).join()}
                      </span>
                    </div>
                  }
                </>
              )) }
            </div>
            <hr />
            <div className="w-100">
              <label className="fw-500">Do you have voucher?</label>
              <div className="d-flex">
                <ECFormInput
                  type={'text'}
                  formGroupClass={'w-100'}
                  value={postPayment.voucherCode}
                  placeholder={'Enter your voucher code here'} 
                  onChangeData={val => onChangeUpgradeHOC('postPayment', {...postPayment, voucherCode: val })} />
                <Button
                  size="sm"
                  className="mb-3 ms-1"
                  color="primary"
                  onClick={() => validateVoucher()}>
                  Apply
                </Button>
              </div>
            </div>
            <div className="w-100">
              <div className="e-classified-advertiser__charges-item">
                <span>Sub Total</span>
                <span className="fw-600">RM {subTotal}</span>
              </div>
              <div className="e-classified-advertiser__charges-subitem">
                <span className="ml-0">Discount</span>
                <span>-RM {discount.toFixed(2)}</span>
              </div>
              <div className="e-classified-advertiser__charges-item">
                <span>Tax</span>
                <span className="fw-600">RM {tax.toFixed(2)}</span>
              </div>
              <hr />
              <div className="e-classified-advertiser__charges-item">
                <span>Grand Total:</span>
                <h1>RM {grand_total.toFixed(2)}</h1>
              </div>
            </div>
          </div>
          <div className="e-classified-advertiser__summary-actions">
            <Button
              size="lg"
              style={{ width: '100%' }}
              color="primary"
              onClick={onToggleUpgrade}>
              Upgrade
            </Button>
          </div>
          <PaymentGatewayForm 
            id='jobads-payment-gateway' 
            value={payment_key}/>
        </ModalBody>
      </Modal>
      
      <ConfirmationModal
        open={showConfirmModal}
        onClose={() => {
          const availablePlans = _.chain(plans)
            .cloneDeep()
            .map('data')
            .flatten()
            .value()
            
          const jobmachaFacebookGeneralPostAddon = _.find(availablePlans, { id: PACKAGE_CONST_FACEBOOK_PLAN_ID});
          const jobmachaFacebookPinPostAddon = _.find(availablePlans, { id: PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID});    
          
          let tempAddOns = _.cloneDeep(postPayment.addOns)          
          onChangePostPayment("addOns", [
              ...tempAddOns,
              { ...jobmachaFacebookGeneralPostAddon, quantity: 1 },
              { ...jobmachaFacebookPinPostAddon, quantity: 1 },
            ])
          onChangeUpgradeHOC("showConfirmModal", false)
        }}
        onClickConfirm={() => {   
          const availablePlans = _.chain(plans)
            .cloneDeep()
            .map('data')
            .flatten()
            .value()
            
          const jobmachaFacebookGeneralPostAddon = _.find(availablePlans, { id: PACKAGE_CONST_FACEBOOK_PLAN_ID});
          const jobmachaFacebookPinPostAddon = _.find(availablePlans, { id: PACKAGE_CONST_FACEBOOK_PIN_PLAN_ID});    
          
          let tempAddOns = _.cloneDeep(postPayment.addOns)          
          onChangePostPayment("addOns", [
              ...tempAddOns,
              { ...jobmachaFacebookGeneralPostAddon, quantity: 1 },
              { ...jobmachaFacebookPinPostAddon, quantity: 1 },
            ])
          onChangeUpgradeHOC("showConfirmModal", false)
        }}
        title={`Confirm Upgrade`}
        message={`JobMacha Facebook Pin Post only can selected after purchase JobMacha Facebook Post.`}
        confirmContent="Confirm"
        confirmColor="primary"
        isCancelable={false}
      />
    </>
  )
}

export default UpgradeHOC(UpgradeModal)