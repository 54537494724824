import { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { MdVerified } from "react-icons/md";


function ResetPW({
	hasSentCode,
	verifyOTP,
	hasVerified,
	tempUser,
	sendOTP,
	updatePassword,
	jobSeekerProfile
}) {

	const [confirmPW, setConfirmPW] = useState("");
	const [newPW, setNewPW] = useState("");
	const [code, setCode] = useState("");

	return (
		<>
			<div className="p-4 bg-white mt-4">
				<h5 className="e-classified-cont-header mb-4">
					Change Password
				</h5>
				<Form>
					<FormGroup>
						<Label>
							<span style={{ fontWeight: 300 }}>New Password</span>
							<span className="text-danger">{` *`}</span>
						</Label>
						<Input
							type={"password"}
							value={newPW}
							onChange={(e) => setNewPW(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label>
							<span style={{ fontWeight: 300 }}>Confirmed Password</span>
							<span className="text-danger">{` *`}</span>
						</Label>
						<Input
							type={"password"}
							value={confirmPW}
							onChange={(e) => setConfirmPW(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label className="d-flex">
							<div>
								<span style={{ fontWeight: 300 }}>Code</span>
								<span className="text-danger">{` *`}</span>
							</div>
							<div className="ml-auto">
								{hasVerified && (
									<div className="d-flex align-items-center ml-2 mt-2">
										<MdVerified
											className="text-success"
											style={{ width: "1.5rem", height: "1.5rem" }}
										/>
										<span className="ml-1">
											Verified
										</span>
									</div>
								)}
								{!hasVerified && (
									<Button
										color="primary"
										disabled={hasSentCode && !code}
										onClick={() => {
											hasSentCode
												? verifyOTP(jobSeekerProfile?.user?.email, code)
												: sendOTP({
														email: jobSeekerProfile?.user?.email,
														platform: "jobmacha",
												  });
										}}>
										{!hasSentCode ? (
											'Send Code'
										) : (
											'Verify Code'
										)}
									</Button>
								)}
							</div>
						</Label>
						<Input
							disabled={!hasSentCode || hasVerified}
							value={code}
							onChange={(e) => setCode(e.target.value)}
						/>
					</FormGroup>
				</Form>
				<p className="text-secondary mt-4">Candidate ID: SSO ID</p>
				<p className="text-secondary mt-2">
					Account registered date: 23/05/2023
				</p>
				<div className="d-flex mt-4">
					<Button
						disabled={!confirmPW || !newPW || !code || !hasVerified}
						className="ml-auto"
						size={"lg"}
						color="primary"
						onClick={() =>
							updatePassword({
								confirm_password: confirmPW,
								password: newPW,
								email: jobSeekerProfile?.user?.email,
								code: code,
							})
						}>
						Save
					</Button>
				</div>
			</div>
		</>
	);
}

export default ResetPW;
