import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Card, CardBody, Row, Col, Container, ModalBody } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import ManageBannerInhouseAPI from "./action.js";
import { RolesPermission } from "Config";
import moment from "moment";
import "./index.scss";
import { CheckUserRolesPermissons } from "actions/roles";

class ManageBannerInhouse extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(7)) {
			return this.props.history.push("/");
		}

		this.props.getBanner();
	};

	componentDidUpdate = (pp) => {
		const { bannerInhousePages, getBanner } = this.props;
		const { filterQuery } = this.state;

		if (pp.bannerInhousePages !== bannerInhousePages) {
			getBanner(
				`${
					bannerInhousePages > 1
						? `&offset=${(bannerInhousePages - 1) * 10}`
						: ""
				}${!!filterQuery ? filterQuery : ""}`
			);
		}
	};

	state = {
		selectedRowData: null,
		filterQuery: "",
	};

	Publish = (val) => {
		this.props.onChangeHOC("selectedBannerId", val.bannerId);
		this.props.onChangeHOC("showPublishModal", true);
	};

	renderSampleTable = () => {
		const { bannerInhouseList, onLoadBanner, onChangeHOC } = this.props;

		return (
			<CustomTable
				data={bannerInhouseList}
				showPagination={false}
				loading={onLoadBanner}
				columnsContent={[
					{
						Header: "Banner ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.id}</>,
					},
					{
						Header: "Banner Title",
						accessorKey: "title",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.title}</>,
					},
					{
						Header: "Redirect Link",
						accessorKey: "link_url",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>
								<a href={val.link_url}>{val.link_url}</a>
							</>
						),
					},
					{
						Header: "Redirect Count",
						accessorKey: "redirect_count",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.redirect_count}</>,
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => <>{val.status}</>,
					},
					{
						Header: "Created Date",
						accessorKey: "created_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.created_at).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
					{
						Header: "Published Date",
						accessorKey: "start_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.start_date).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
				]}
				menuContent={
					[4].includes(CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id)
					? [
						{
							menuID: "PreviewPopup",
							menuTooltip: "Preview",
							type: "link",
						},
					]
					: [
						{
							menuID: "EditPopup",
							menuTooltip: "Edit",
							onClick: (val) => {
								this.props.history.push(`/banner-inhouse/edit/${val.id}`);
							},
						},
						{
							menuID: "PreviewPopup",
							menuTooltip: "Preview",
							type: "link",
						},
						{
							menuID: "PublishPopup",
							menuTooltip: "Publish",
							onClick: (val) => {
								this.Publish(val);
							},
						},
						{
							menuID: "SuspendPopup",
							menuTooltip: "Suspend",
							onClick: (val) => {
								this.setState({ selectedRowData: val });
								onChangeHOC("showSuspendModal", true);
							},
						},
						{
							menuID: "DisablePopup",
							menuTooltip: "Disable",
							onClick: (val) => {
								this.setState({ selectedRowData: val });
								onChangeHOC("showDisableModal", true);
							},
						},
					]
			}
			/>
		);
	};

	renderFilterModal = () => {
		const { getBanner, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Banner Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getBanner}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	renderEditModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showEditModal}
				onClose={() => this.props.onChangeHOC("showEditModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showEditModal", false)}
				title={`Are you sure you want to edit this banner? ID: ${this.props.selectedBannerId}`}
				message="Please confirm this action."
				confirmContent="Yes, edit"
				confirmColor="danger"
			/>
		);
	};

	renderPublishModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showPublishModal}
				onClose={() => this.props.onChangeHOC("showPublishModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showPublishModal", false)}
				title={`Are you sure you want to publish this banner? ID: ${this.props.selectedBannerId}`}
				message="Please confirm this action."
				confirmContent="Yes, publish"
				confirmColor="danger"
			/>
		);
	};

	renderSuspendModal = () => {
		const { selectedRowData } = this.state;
		const { showSuspendModal, onChangeHOC, updateBanner } = this.props;

		return (
			<ConfirmationModal
				open={showSuspendModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					onChangeHOC("showSuspendModal", false);
				}}
				onClickConfirm={() => {
					updateBanner({
						title: selectedRowData.title,
						status: "expired",
						id: selectedRowData.id,
						type: selectedRowData.type,
					});
					this.setState({
						selectedRowData: null,
					});
				}}
				title={`Are you sure you want to suspend this banner? ID: ${selectedRowData?.id}`}
				message="Please confirm this action."
				confirmContent="Yes, suspend"
				confirmColor="danger"
			/>
		);
	};

	renderDisableModal = () => {
		const { selectedRowData } = this.state;
		const { showDisableModal, onChangeHOC, updateBanner } = this.props;

		return (
			<ConfirmationModal
				open={showDisableModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					onChangeHOC("showDisableModal", false);
				}}
				onClickConfirm={() => {
					updateBanner({
						title: selectedRowData.title,
						status: "disabled",
						id: selectedRowData.id,
						type: selectedRowData.type,
					});
					this.setState({
						selectedRowData: null,
					});
				}}
				title={`Are you sure you want to disable this banner? ID: ${selectedRowData?.id}`}
				message="Please confirm this action."
				confirmContent="Yes, disable"
				confirmColor="danger"
			/>
		);
	};
	render = () => {
		const {
			onChangeHOC,
			onLoadBanner,
			bannerInhousePages,
			bannerInhouseCount,
			getBanner,
		} = this.props;

		const { filterQuery } = this.state;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Manage Banner / <b>Manage In-House Banner</b>"
										buttons={
											[4].includes(CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id) 
											? [
												{
													color: "",
													className: "btn-outline-primary",
													onClick: () => onChangeHOC("showFilterModal", true),
													content: (
														<>
															<span>Filter</span>
														</>
													),
												},
											]
											: [
												{
													color: "primary",
													className: "btn-icon mr-2",
													onClick: () => {
														this.props.history.push("/banner-inhouse/create");
													},
													content: (
														<>
															<span>Create Banner</span>
														</>
													),
												},
												{
													color: "",
													className: "btn-outline-primary",
													onClick: () => onChangeHOC("showFilterModal", true),
													content: (
														<>
															<span>Filter</span>
														</>
													),
												},
											]
									}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<h5>Manage In-House Banner</h5>
										<br></br>
										<Card className="main-card mb-3">
											<CardBody>
												{this.renderSampleTable()}
												<Pagination
													pageIndex={bannerInhousePages}
													totalCount={bannerInhouseCount}
													onChangePage={(val) =>
														onChangeHOC("bannerInhousePages", val)
													}
												/>
											</CardBody>
										</Card>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderEditModal()}
									{this.renderPublishModal()}
									{this.renderSuspendModal()}
									{this.renderDisableModal()}
									{this.props.onLoadBanner && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ManageBannerInhouseAPI)(ManageBannerInhouse);
