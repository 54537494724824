export const StatesData = [
	{ va: "johor", en: "Johor", zh: "柔佛" },
	{ va: "kedah", en: "Kedah", zh: "吉打" },
	{ va: "kelantan", en: "Kelantan", zh: "吉兰丹" },
	{ va: "melaka", en: "Melaka", zh: "马六甲" },
	{ va: "negeri_sembilan", en: "Negeri Sembilan", zh: "森美兰" },
	{ va: "pahang", en: "Pahang", zh: "彭亨" },
	{ va: "penang", en: "Penang", zh: "槟城" },
	{ va: "perak", en: "Perak", zh: "霹雳" },
	{ va: "perlis", en: "Perlis", zh: "玻璃市" },
	{ va: "selangor", en: "Selangor", zh: "雪兰莪" },
	{ va: "terengganu", en: "Terengganu", zh: "登嘉楼" },
	{ va: "sabah", en: "Sabah", zh: "沙巴" },
	{ va: "sarawak", en: "Sarawak", zh: "砂拉越" },
	{ va: "kl", en: "Kuala Lumpur", zh: "吉隆坡" },
	{ va: "labuan", en: "Labuan", zh: "纳闽" },
	{ va: "putrajaya", en: "Putrajaya", zh: "布城" },
	{ va: "singapore", en: "Singapore", zh: "新加坡" },
];

export const ExperienceData = [
	{ label: "No Experience Required", value: "0" },
	{ label: "< 1 Year", value: "< 1" },
	{ label: "1 Year of Experience", value: "1" },
	{ label: "1 - 2 Years", value: "1 - 2" },
	{ label: "2 - 3 Years", value: "2 - 3" },
	{ label: "3 - 5 Years", value: "3 - 5" },
	{ label: "5+ Years", value: "5+" },
];

export const JobSpecs = [
	{
		en: "Accounting / Finance",
		zh: "会计 / 金融专业",
		children: [
			{
				en: "Audit & Taxation",
				zh: "稽核 & 税务",
				value: "audit_taxation",
			},
			{
				en: "Banking / Financial",
				zh: "银行 / 金融",
				value: "banking_financial",
			},
			{
				en: "Cashier",
				zh: "收银员",
				value: "cashier",
			},
			{
				en: "Corporate Finance / Investment",
				zh: "企业金融 / 投资",
				value: "corporate_finance_investment",
			},
			{
				en: "General / Cost Accounting",
				zh: "普通 / 成本会计工作",
				value: "general_cost_accounting",
			},
		],
		value: "accounting_finance",
	},
	{
		en: "Admin / Human Resources",
		zh: "行政 / 人力资源",
		children: [
			{
				en: "Administrative / Clerical / Secretarial",
				zh: "行政 / 文书 / 秘书",
				value: "administrative_clerical_secretarial",
			},
			{
				en: "Human Resources",
				zh: "人力资源",
				value: "human_resources",
			},
		],
		value: "admin_human_resources",
	},
	{
		en: "Arts / Communications / Media",
		zh: "艺术 / 传播 / 媒体",
		children: [
			{
				en: "Advertising",
				zh: "广告",
				value: "advertising",
			},
			{
				en: "Arts / Creative Design",
				zh: "艺术 / 创意设计",
				value: "arts_creative_design",
			},
			{
				en: "Editor / Journalist",
				zh: "记者 / 编辑",
				value: "editor_journalist",
			},
			{
				en: "Public Relations",
				zh: "公共关系",
				value: "public_relations",
			},
		],
		value: "arts_communications_media",
	},
	{
		en: "Building / Construction / Property",
		zh: "建筑 / 营建 / 产业",
		children: [
			{
				en: "Architect / Interior Design",
				zh: "建筑师 / 室内设计",
				value: "architect_interior_design",
			},
			{
				en: "Civil Engineering / Construction",
				zh: "土木工程 / 建筑",
				value: "civil_engineering_construction",
			},
			{
				en: "Property / Real Estate",
				zh: "物业 / 房地产",
				value: "property_real_estate",
			},
			{
				en: "Quantity Surveying",
				zh: "工料测量",
				value: "quantity_surveying",
			},
		],
		value: "building_construction_property",
	},
	{
		en: "Engineering",
		zh: "工程",
		children: [
			{
				en: "Automotive / Mechanical Engineering",
				zh: "机械 / 汽车工程",
				value: "automotive_mechanical_engineering",
			},
			{
				en: "Chemical Engineering",
				zh: "化学工程",
				value: "chemical_engineering",
			},
			{
				en: "Electrical & Electronics Engineering",
				zh: "电子 & 电气工程",
				value: "electrical_electronics_engineering",
			},
			{
				en: "Environmental Engineering",
				zh: "环境工程",
				value: "environmental_engineering",
			},
			{
				en: "Industrial Engineering",
				zh: "工业工程",
				value: "industrial_engineering",
			},
			{
				en: "Oil / Gas Engineering",
				zh: "石油 / 天然气工程",
				value: "oil_gas_engineering",
			},
			{
				en: "Other Engineering",
				zh: "其他工程",
				value: "other_engineering",
			},
		],
		value: "engineering",
	},
	{
		en: "Healthcare / Beauty / Sports",
		zh: "医疗保健 / 美容美发 / 体育",
		children: [
			{
				en: "Doctor / Diagnosis",
				zh: "医生 / 诊断",
				value: "doctor_diagnosis",
			},
			{
				en: "Fitness / Sports Coach",
				zh: "健身 / 运动教练",
				value: "fitness_sports_coach",
			},
			{
				en: "Hair Stylist / Beautician / Manicurist",
				zh: "理发师 / 美容师 / 美甲师",
				value: "hair_stylist_beautician_manicurist",
			},
			{
				en: "Massage / SPA",
				zh: "按摩 / SPA",
				value: "massage_spa",
			},
			{
				en: "Nursing / Nurse / Medical Support",
				zh: "护理 / 护士 / 医疗支援",
				value: "nursing_nurse_medical_support",
			},
			{
				en: "Pharmacy",
				zh: "药房 / 药剂",
				value: "pharmacy",
			},
		],
		value: "healthcare_beauty_sports",
	},
	{
		en: "Hotel / Restaurant / Tourism",
		zh: "酒店 / 餐饮 / 旅游",
		children: [
			{
				en: "Food / Beverage / Bakery / Restaurant",
				zh: "餐饮 / 烘焙",
				value: "food_beverage_bakery_restaurant",
			},
			{
				en: "Hotel / Tourism",
				zh: "酒店 / 旅游",
				value: "hotel_tourism",
			},
		],
		value: "hotel_restaurant_tourism",
	},
	{
		en: "IT / Science & Technology",
		zh: "电子科技 / 理工",
		children: [
			{
				en: "Actuarial / Statistics",
				zh: "物理精算 / 统计",
				value: "actuarial_statistics",
			},
			{
				en: "Science & Technology",
				zh: "科技",
				value: "science_technology",
			},
			{
				en: "Mobile Repair",
				zh: "手机维修",
				value: "mobile_repair",
			},
			{
				en: "Agriculture / Aquaculture",
				zh: "农业 / 水产养殖",
				value: "agriculture_aquaculture",
			},
			{
				en: "Biotechnology",
				zh: "生物科技",
				value: "biotechnology",
			},
			{
				en: "Chemistry",
				zh: "化学",
				value: "chemistry",
			},
			{
				en: "Food Tech / Nutritionist",
				zh: "食品科技 / 营养",
				value: "food_tech_nutritionist",
			},
			{
				en: "Geology / Geophysics",
				zh: "地质 / 地球",
				value: "geology_geophysics",
			},
			{
				en: "IT - Hardware",
				zh: "电子科技 - 硬体设备",
				value: "it_hardware",
			},
			{
				en: "IT - Software",
				zh: "电子科技 - 软体相关",
				value: "it_software",
			},
			{
				en: "Network / System / Database Admin",
				zh: "网络 / 系统 / 数据行政管理",
				value: "network_system_database_admin",
			},
		],
		value: "it_science_technology",
	},
	{
		en: "Manufacturing / Maintenance children",
		zh: "制造 / 维护操作",
		children: [
			{
				en: "Car Beauty / Decoration / Repair",
				zh: "汽车维修 / 汽车美容 / 汽车装饰",
				value: "car_beauty_decoration_repair",
			},
			{
				en: "Maintenance",
				zh: "维护操作",
				value: "maintenance",
			},
			{
				en: "Manufacturing",
				zh: "生产制造",
				value: "manufacturing",
			},
			{
				en: "Purchasing / Cargo / Warehouse Management",
				zh: "采购 / 货物 / 货仓管理",
				value: "purchasing_cargo_warehouse_management",
			},
			{
				en: "Quality Assurance",
				zh: "品质控管",
				value: "quality_assurance",
			},
			{
				en: "Electrical Appliances / Air Conditioning",
				zh: "电器 / 冷气",
				value: "electrical_appliances_air_conditioning",
			},
			{
				en: "Furniture / Hardware Accessories",
				zh: "傢俬 / 五金",
				value: "furniture_hardware_accessories",
			},
		],
		value: "manufacturing_maintenance_children",
	},
	{
		zh: "教育 / 培训",
		en: "Education / Training",
		children: [
			{
				zh: "教育",
				en: "Education",
				value: "education",
			},
			{
				zh: "培训与开发",
				en: "Training & Development",
				value: "training_development",
			},
		],
		value: "education_training",
	},
	{
		en: "Research Development (R&D)",
		zh: "研究开发相关",
		children: [
			{
				en: "Behavioral Research",
				zh: "行为研究",
				value: "behavioral_research",
			},
			{
				en: "Online Research",
				zh: "网路 / 问卷研究",
				value: "online_research",
			},
			{
				en: "Project Research & Development",
				zh: "项目 / 产品研发",
				value: "project_research_development",
			},
		],
		value: "research_development_r_d_",
	},
	{
		en: "Sales / Marketing",
		zh: "销售 / 市场营销",
		children: [
			{
				en: "Digital Marketing / E-commerce",
				zh: "数码营销 / 电子商务",
				value: "digital_marketing_e_commerce",
			},
			{
				en: "Marketing / Business Development",
				zh: "市场营销 / 业务发展",
				value: "marketing_business_development",
			},
			{
				en: "Merchandising",
				zh: "销售",
				value: "merchandising",
			},
			{
				en: "Retail Sales",
				zh: "零售销售",
				value: "retail_sales",
			},
			{
				en: "Sales / Promoter",
				zh: "门市销售 / 柜台销售 / 推销员",
				value: "sales_promoter",
			},
			{
				en: "Telesales / Telemarketing",
				zh: "电话行销 / 营销",
				value: "telesales_telemarketing",
			},
			{
				en: "Property Agent",
				zh: "产业代理",
				value: "property_agent",
			},
		],
		value: "sales_marketing",
	},
	{
		en: "Services",
		zh: "服务",
		children: [
			{
				en: "Armed Forces / Security",
				zh: "警卫 / 保安",
				value: "armed_forces_security",
			},
			{
				en: "Customer Service",
				zh: "客户服务",
				value: "customer_service",
			},
			{
				en: "Decorative Plumbing",
				zh: "管道装置",
				value: "decorative_plumbing",
			},
			{
				en: "Exhibition & Event Management",
				zh: "展览 & 活动管理",
				value: "exhibition_event_management",
			},
			{
				en: "Insurance",
				zh: "保险业",
				value: "insurance",
			},
			{
				en: "Law / Legal Services",
				zh: "法律服务",
				value: "law_legal_services",
			},
			{
				en: "Social Services",
				zh: "社会服务",
				value: "social_services",
			},
			{
				en: "Driver / Transportation / Logistic",
				zh: "司机 / 运输 / 物流",
				value: "driver_transportation_logistic",
			},
			{
				en: "Tech & Helpdesk Support",
				zh: "技术与服务端支援",
				value: "tech_helpdesk_support",
			},
		],
		value: "services",
	},
	{
		en: "Other Industries",
		zh: "其他",
		children: [
			{
				en: "Aviation Industry",
				zh: "航空业",
				value: "aviation_industry",
			},
			{
				en: "Entertainment",
				zh: "娱乐业",
				value: "entertainment",
			},
			{
				en: "Flower Arrangement / Gardener",
				zh: "插花 / 园丁",
				value: "flower_arrangement_gardener",
			},
			{
				en: "Karaoke / Pub / Longue / Bar",
				zh: "卡拉OK / 酒吧",
				value: "karaoke_pub_longue_bar",
			},
			{
				en: "Babysitter / Maid / Confinement",
				zh: "保姆 / 女佣 / 陪月",
				value: "babysitter_maid_confinement",
			},
			{
				en: "Publishing",
				zh: "印刷业",
				value: "publishing",
			},
			{
				en: "Clock / Watch",
				zh: "钟表业",
				value: "clock_watch",
			},
			{
				en: "Jewellery",
				zh: "金钻业",
				value: "jewellery",
			},
		],
		value: "other_industries",
	},
];

export const Gender = [
	{ value: "male", en: "Male", zh: "男" },
	{ value: "female", en: "Female:", zh: "女" },
	{ value: "n/a", en: "N/A", zh: "不方便透露" },
];

export const MaritalStatus = [
	{ value: "single", en: "Single", zh: "单身" },
	{ value: "married", en: "Married", zh: "已婚" },
	{ value: "divorced", en: "Divorced", zh: "离异" },
	{ value: "widowed", en: "Widowed", zh: "丧偶" },
];

export const Race = [
	{ value: "malay", en: "Malay", zh: "马来语" },
	{ value: "chinese", en: "Chinese", zh: "中国" },
	{ value: "indian", en: "Indian", zh: "印度" },
	{ value: "other", en: "Other", zh: "其他" },
];

export const PositionLevels = [
	{
		id: 1,
		name: "Intern",
		name_zh: "实习生",
	},
	{
		id: 2,
		name: "Fresh Graduate",
		name_zh: "新鲜人",
	},
	{
		id: 3,
		name: "Assistant",
		name_zh: "助理",
	},
	{
		id: 4,
		name: "Coordinator",
		name_zh: "协调员",
	},
	{
		id: 5,
		name: "Specialist",
		name_zh: "专员",
	},
	{
		id: 6,
		name: "Junior Executive",
		name_zh: "初级执行员",
	},
	{
		id: 7,
		name: "Senior Executive",
		name_zh: "高级执行员",
	},
	{
		id: 8,
		name: "Supervisor",
		name_zh: "主管",
	},
	{
		id: 9,
		name: "Manager",
		name_zh: "经理",
	},
	{
		id: 10,
		name: "Consultant",
		name_zh: "顾问",
	},
];

export const EmploymentTypes = [
	{
		id: "full_time",
		en: "Full-time",
		zh: "全职",
	},
	{
		id: "part_time",
		en: "Part-time",
		zh: "兼职",
	},
	{
		id: "full_time_or_part_time",
		en: "Full-time / Part-time",
		zh: "全职/兼职",
	},
	{
		id: "contract",
		en: "Contract",
		zh: "合约",
	},
	{
		id: "freelance",
		en: "Freelance",
		zh: "自由工作者",
	},
];

export const ShiftTypes = [
	{
		id: "4_days_workweek",
		en: "4 Days Workweek",
		zh: "4天工作制",
	},
	{
		id: "5_days_workweek",
		en: "5 Days Workweek",
		zh: "5天工作制",
	},
	{
		id: "5.5_days_workweek",
		en: "5.5 Days Workweek",
		zh: "5天半工作制",
	},
	{
		id: "6_weekwork",
		en: "6 days work",
		zh: "六天工作制",
	},
	{
		id: "rotating_shift",
		en: "Rotating Shift",
		zh: "轮班制",
	},
	{
		id: "rotating_weekends",
		en: "Rotating Weekends",
		zh: "假日轮班",
	},
	{
		id: "12_hour_rotating_shift",
		en: "12-Hour Rotating Shift",
		zh: "12小时轮班制",
	},
	{
		id: "day_shift",
		en: "Day Shift",
		zh: "日班",
	},
	{
		id: "night_shift",
		en: "Night Shift",
		zh: "晚班",
	},
	{
		id: "morning_afternoon_evening_shift",
		en: "Morning-Afternoon-Evening Shift",
		zh: "早中晚班制",
	},
];

export const Taggings = [
	{
		id: "new_graduate_friendly",
		en: "New Graduate Friendly",
		zh: "新鲜人友善",
	},
	{
		id: "on-the-job_training",
		en: "On-the-Job Training",
		zh: "在职培训",
	},
	{
		id: "vocational_work",
		en: "Vocational Work",
		zh: "技职工作",
	},
	{
		id: "high-paying_jobs",
		en: "High-paying Jobs",
		zh: "高薪职缺",
	},
	{
		id: "5_day_workweek",
		en: "5 Day Workweek",
		zh: "5天工作制",
	},
	{
		id: "no_educational_requirement",
		en: "No Educational Requirement",
		zh: "无学历要求",
	},
	{
		id: "no_educational_requirement",
		en: "No Experience Required",
		zh: "无经验要求",
	},
	{
		id: "excellent_benefits",
		en: "Excellent Benefits",
		zh: "福利优渥",
	},
];

export const JobTypes = [
	{
		id: "fresh_graduate",
		en: "Fresh Graduates available",
		zh: "适合应届毕业生",
	},
	{
		id: "work_from_home",
		en: "Work From Home",
		zh: "居家作业",
	},
];

export const LanguageData = [
	{
		value: "chinese",
		en: "Chinese",
		zh: "中文",
	},
	{
		value: "english",
		en: "English",
		zh: "英语",
	},
	{
		value: "malay",
		en: "Bahasa Malay",
		zh: "马来文",
	},
	{
		value: "tamil",
		en: "Tamil",
		zh: "淡米尔文",
	},
	{
		value: "cantonese",
		en: "Cantonese",
		zh: "广东话",
	},
	{
		value: "hokkien",
		en: "Hokkien",
		zh: "福建话",
	},
	{
		value: "hakka",
		en: "Hakka",
		zh: "客家话",
	},
	{
		value: "teochew",
		en: "Teochew",
		zh: "潮州话",
	},
	{
		value: "fuzhou",
		en: "Fuzhou",
		zh: "福州话",
	},
	{
		value: "hainan",
		en: "Hainan",
		zh: "海南话",
	},
	{
		value: "thai",
		en: "Thai",
		zh: "泰文",
	},
	{
		value: "vietnamese",
		en: "Vietnamese",
		zh: "越南文",
	},
	{
		value: "japanese",
		en: "Japanese",
		zh: "日文",
	},
	{
		value: "korean",
		en: "Korean",
		zh: "韩文",
	},
	{
		value: "french",
		en: "French",
		zh: "法文",
	},
	{
		value: "german",
		en: "German",
		zh: "德文",
	},
	{
		value: "russian",
		en: "Russian",
		zh: "俄文",
	},
	{
		value: "spanish",
		en: "Spanish",
		zh: "西班牙文",
	},
	{
		value: "portuguese",
		en: "Portuguese",
		zh: "葡文",
	},
	{
		value: "arabic",
		en: "Arabic",
		zh: "阿拉伯文",
	},
	{
		value: "others",
		en: "Others",
		zh: "其他",
	},
];

export const BenefitsData = [
	{
		id: 1,
		name: "5 Working Days",
		name_zh: "五天工作制",
	},
	{
		id: 2,
		name: "Meal Allowance",
		name_zh: "餐补",
	},
	{
		id: 3,
		name: "Transport Allowance",
		name_zh: "交通补贴",
	},
	{
		id: 4,
		name: "Yearly Bonus",
		name_zh: "年终奖金",
	},
];

export const CategoryOptions = [
	{ id: 0, label: "全部" },
	{ id: 2, label: "职场看透透" },
	{ id: 3, label: "生活贴士" },
	{ id: 4, label: "时下Fun事" },
	{ id: 5, label: "新鲜人专区" },
	{ id: 6, label: "求职攻略" },
];

export const LangOptions = [];

export const Personalities = [
	{ key: "responsibility", label_en: "Responsibility", label_zh: "责任心" },
	{ key: "self_motivation", label_en: "Self-Motivation", label_zh: "上进心" },
	{
		key: "willing_to_learn",
		label_en: "Willing to Learn",
		label_zh: "愿意学习",
	},
	{
		key: "attention_to_detal",
		label_en: "Attention to Detal",
		label_zh: "注重细节",
	},
	{ key: "adaptability", label_en: "Adaptability", label_zh: "适应能力" },
	{ key: "collaboration", label_en: "Collaboration", label_zh: "合作能力" },
	{ key: "independently", label_en: "Independently", label_zh: "独立" },
	{ key: "efficiency", label_en: "Efficiency", label_zh: "效率" },
	{ key: "harkworking", label_en: "Harkworking", label_zh: "勤奋" },
	{ key: "proactive", label_en: "Proactive", label_zh: "积极" },
	{ key: "initiative", label_en: "Initiative", label_zh: "主动" },
	{ key: "patience", label_en: "Patience", label_zh: "耐心" },
	{ key: "caring", label_en: "Caring", label_zh: "爱心" },
	{ key: "passion", label_en: "Passion", label_zh: "热忱" },
	{ key: "confidence", label_en: "Confidence", label_zh: "自信" },
	{ key: "punctuality", label_en: "Punctuality", label_zh: "守时" },
	{ key: "self_disciplie", label_en: "Self-disciplie", label_zh: "自律" },
	{ key: "integrity", label_en: "Integrity", label_zh: "诚信" },
	{ key: "creativity", label_en: "Creativity", label_zh: "创造力" },
	{ key: "flexibility", label_en: "Flexibility", label_zh: "灵活性" },
	{ key: "goal_oriented", label_en: "Goal-oriented", label_zh: "目标导向" },
];

export const SoftwareSkills = [
	{
		label_en: "Microsoft Office",
		label_zh: "Microsoft Office",
		key: "Microsoft Office",
	},
	{
		label_en: "Microsoft Word",
		label_zh: "Microsoft Word",
		key: "Microsoft Word",
	},
	{
		label_en: "Microsoft Excel",
		label_zh: "Microsoft Excel",
		key: "Microsoft Excel",
	},
	{
		label_en: "Microsoft Power Point",
		label_zh: "Microsoft Power Point",
		key: "Microsoft Power Point",
	},
	{
		label_en: "Microsoft Outlook ",
		label_zh: "Microsoft Outlook ",
		key: "Microsoft Outlook ",
	},
	{
		label_en: "Microsoft Navision",
		label_zh: "Microsoft Navision",
		key: "Microsoft Navision",
	},
	{
		label_en: "Content Management System",
		label_zh: "Content Management System",
		key: "Content Management System",
	},
	{
		label_en: "ERP Management System",
		label_zh: "ERP Management System",
		key: "ERP Management System",
	},
	{
		label_en: "Payroll System",
		label_zh: "Payroll System",
		key: "Payroll System",
	},
	{
		label_en: "AutoCount Accounting Software",
		label_zh: "AutoCount Accounting Software",
		key: "AutoCount Accounting Software",
	},
	{
		label_en: "SQL Accounting Software",
		label_zh: "SQL Accounting Software",
		key: "SQL Accounting Software",
	},
	{
		label_en: "UBS Accounting Software",
		label_zh: "UBS Accounting Software",
		key: "UBS Accounting Software",
	},
	{
		label_en: "QNE Accounting Software",
		label_zh: "QNE Accounting Software",
		key: "QNE Accounting Software",
	},
	{
		label_en: "QuickBooks Accounting Software",
		label_zh: "QuickBooks Accounting Software",
		key: "QuickBooks Accounting Software",
	},
	{
		label_en: "AutoCAD Software",
		label_zh: "AutoCAD Software",
		key: "AutoCAD Software",
	},
	{
		label_en: "MasterCAM Software",
		label_zh: "MasterCAM Software",
		key: "MasterCAM Software",
	},
	{
		label_en: "Adobe Photoshop",
		label_zh: "Adobe Photoshop",
		key: "Adobe Photoshop",
	},
	{
		label_en: "Adobe Illustrator",
		label_zh: "Adobe Illustrator",
		key: "Adobe Illustrator",
	},
	{
		label_en: "Adobe InDesign",
		label_zh: "Adobe InDesign",
		key: "Adobe InDesign",
	},
	{
		label_en: "Adobe Premiere Pro",
		label_zh: "Adobe Premiere Pro",
		key: "Adobe Premiere Pro",
	},
	{
		label_en: "Adobe After Effects",
		label_zh: "Adobe After Effects",
		key: "Adobe After Effects",
	},
	{
		label_en: "Adobe Animate ",
		label_zh: "Adobe Animate ",
		key: "Adobe Animate ",
	},
	{
		label_en: "Adobe Dreamweaver",
		label_zh: "Adobe Dreamweaver",
		key: "Adobe Dreamweaver",
	},
	{
		label_en: "Adobe XD",
		label_zh: "Adobe XD",
		key: "Adobe XD",
	},
	{
		label_en: "Google Drive",
		label_zh: "Google Drive",
		key: "Google Drive",
	},
	{
		label_en: "Google Docs",
		label_zh: "Google Docs",
		key: "Google Docs",
	},
	{
		label_en: "Google Sheets",
		label_zh: "Google Sheets",
		key: "Google Sheets",
	},
	{
		label_en: "Google Slides",
		label_zh: "Google Slides",
		key: "Google Slides",
	},
	{
		label_en: "Google Forms",
		label_zh: "Google Forms",
		key: "Google Forms",
	},
	{
		label_en: "Java",
		label_zh: "Java",
		key: "Java",
	},
	{
		label_en: "PHP",
		label_zh: "PHP",
		key: "PHP",
	},
	{
		label_en: "MySQL",
		label_zh: "MySQL",
		key: "MySQL",
	},
	{
		label_en: "SQL",
		label_zh: "SQL",
		key: "SQL",
	},
	{
		label_en: "C#",
		label_zh: "C#",
		key: "C#",
	},
	{
		label_en: "C++",
		label_zh: "C++",
		key: "C++",
	},
	{
		label_en: "JavaScript",
		label_zh: "JavaScript",
		key: "JavaScript",
	},
	{
		label_en: "Python",
		label_zh: "Python",
		key: "Python",
	},
];

export const HardSkills = [
	{ key: "date_entry", label_en: "Data Entry", label_zh: "数据输入" },
	{
		key: "project_mngmt",
		label_en: "Project Management",
		label_zh: "项目管理",
	},
	{
		key: "human_resource_mngmt",
		label_en: "Human Resource Management",
		label_zh: "人力资源管理",
	},
	{
		key: "customer_relation_mngmt",
		label_en: "Customer Relationship Management",
		label_zh: "客户关系管理",
	},
	{
		key: "marketing_campaign_mngmt",
		label_en: "Marketing Campaign Management",
		label_zh: "营销活动管理",
	},
	{
		key: "digital_marketing",
		label_en: "Digital Marketing",
		label_zh: "数码营销",
	},
	{ key: "market_research", label_en: "Market Research	", label_zh: "市场调查" },
	{ key: "data_analysis", label_en: "Data Analysis	", label_zh: "数据分析" },
	{
		key: "financial_analysis",
		label_en: "Financial Analysis",
		label_zh: "财务分析",
	},
	{
		key: "statiscal_analysis",
		label_en: "Statistical Analysis",
		label_zh: "统计分析",
	},
	{
		key: "writing_editing",
		label_en: "Writing and Editing	",
		label_zh: "写作编辑",
	},
	{ key: "lang_trans", label_en: "Language Translation", label_zh: "语言翻译" },
	{ key: "video_editing", label_en: "Video Editing", label_zh: "影片剪辑" },
	{ key: "graphic", label_en: "Graphic Design	", label_zh: "平面设计" },
	{
		key: "ui/ux_design",
		label_en: "UI / UX Design",
		label_zh: "UI / UX  设计",
	},
	{
		key: "programming_lang",
		label_en: "Programming Languages",
		label_zh: "程式语言",
	},
	{
		key: "server_maintainance",
		label_en: "Server Maintenance",
		label_zh: "伺服器维护",
	},
];

export const SoftSkills = [
	{
		key: "interpersonal_skills",
		label_en: "Interpersonal Skills",
		label_zh: "人际交往能力",
	},
	{
		key: "negotiation_skills",
		label_en: "Negotiation Skills",
		label_zh: "谈判能力",
	},
	{ key: "teamwork", label_en: "Teamwork", label_zh: "团队合作" },
	{ key: "leadership", label_en: "Leadership", label_zh: "领导能力" },
	{
		key: "problem_solving_skills",
		label_en: "Problem-Solving Skills",
		label_zh: "解决问题能力",
	},
	{
		key: "multitasking_skills",
		label_en: "Multitasking Skills",
		label_zh: "多工处理能力",
	},
	{ key: "mngmt_skills", label_en: "Management Skills", label_zh: "管理能力" },
	{
		key: "presentation_skills",
		label_en: "Presentation Skills",
		label_zh: "呈现能力",
	},
	{
		key: "prioritization_skills",
		label_en: "Prioritization Skills",
		label_zh: "工作排序能力",
	},
	{ key: "time_mngmt", label_en: "Time-Management", label_zh: "时间管理" },
	{ key: "self_mngmt", label_en: "Self-Management", label_zh: "自我管理" },
	{ key: "decision_making", label_en: "Decision Making", label_zh: "决策能力" },
	{
		key: "critical_thinking",
		label_en: "Critical Thinking",
		label_zh: "批判性思维",
	},
	{
		key: "logical_thinking",
		label_en: "Logical Thinking",
		label_zh: "逻辑思维",
	},
	{ key: "sales_skills", label_en: "Sales Skills", label_zh: "销售技能" },
];

export const Lang = [
	{ key: "zh", label_en: "Chinese", label_zh: "中文" },
	{ key: "en", label_en: "English", label_zh: "英文" },
	{ key: "my", label_en: "Bahasa Malay", label_zh: "马来文" },
	{ key: "tm", label_en: "Tamil", label_zh: "淡米尔文" },
	{ key: "cn", label_en: "Cantonese", label_zh: "广东话" },
	{ key: "hk", label_en: "Hokkien", label_zh: "福建话" },
	{ key: "hkk", label_en: "Hakka", label_zh: "客家话" },
	{ key: "tc", label_en: "Teochew", label_zh: "潮州话" },
	{ key: "fz", label_en: "Fuzhou", label_zh: "	福州话" },
	{ key: "hn", label_en: "Hainan", label_zh: "海南话" },
	{ key: "th", label_en: "Thai", label_zh: "泰文" },
	{ key: "vt", label_en: "Vietnamese", label_zh: "越南文" },
	{ key: "jp", label_en: "Japanese", label_zh: "日文" },
	{ key: "kr", label_en: "Korean", label_zh: "韩文" },
	{ key: "fr", label_en: "French", label_zh: "法文" },
	{ key: "de", label_en: "German", label_zh: "德文" },
	{ key: "rs", label_en: "Russian", label_zh: "俄文" },
	{ key: "sp", label_en: "Spanish", label_zh: "西班牙文" },
	{ key: "pg", label_en: "Portuguese", label_zh: "葡文" },
	{ key: "ab", label_en: "Arabic", label_zh: "阿拉伯文" },
];

export const Licenses = [
	{ key: "classb/c", label_en: "Class B/C", label_zh: "B/C驾照" },
	{ key: "classd", label_en: "Class D", label_zh: "D驾照" },
	{ key: "gdl", label_en: "Goods Driving License (GDL)", label_zh: "GDL驾照" },
	{ key: "psv", label_en: "Public Service Vehicle (PSV)", label_zh: "PSV驾照" },
	{ key: "class1/2", label_en: "Class 1/2", label_zh: "1/2号驾照" },
	{ key: "class3", label_en: "Class 3", label_zh: "3号驾照" },
	{ key: "class4", label_en: "Class 4", label_zh: "4号驾照" },
];

export const CountryOption = [
	{
		id: 136,
		name: "Malaysia",
		phone: 60,
		symbol: "RM",
		currency: "MYR",
		alpha_2: "MY",
		alpha_3: "MYS",
		created_at: "2023-10-14T15:44:48.850Z",
		updated_at: "2023-10-14T15:44:48.850Z",
	},
	{
		id: 203,
		name: "Singapore",
		phone: 65,
		symbol: "$",
		currency: "SGD",
		alpha_2: "SG",
		alpha_3: "SGP",
		created_at: "2023-10-14T15:44:48.864Z",
		updated_at: "2023-10-14T15:44:48.864Z",
	},
];

export const MobileCountryCode = [
	{
		name: "Malaysia",
		dial_code: "+60",
		code: "MY",
		icon: "🇲🇾",
	},
	{
		name: "Singapore",
		dial_code: "+65",
		code: "SG",
		icon: "🇸🇬",
	},
	{
		name: "Thailand",
		dial_code: "+66",
		code: "TH",
		icon: "🇹🇭",
	},
];

export const RolesPermission = [
	{
		id: 1,
		name: "Super Admin",
		display_name: "Super Admin",
		permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
	},
	{
		id: 2,
		name: "Operation Admin",
		display_name: "Operation Admin",
		permissions: [2, 3, 4, 5, 6, 7, 8, 10, 14],
	},
	{
		id: 3,
		name: "Finance Admin",
		display_name: "Finance Admin",
		permissions: [2, 3, 4, 5, 8, 10, 13, 14],
	},
	{
		id: 4,
		name: "Project Lead",
		display_name: "Project Lead",
		permissions: [1, 2, 3, 4, 5, 6, 7, 8, 10, 13, 14],
	},
	{
		id: 5,
		name: "Agent Manager",
		display_name: "Agent Manager",
		permissions: [2, 3, 4, 8],
	},
	{
		id: 6,
		name: "Agent Lead",
		display_name: "Agent Lead",
		permissions: [2, 3, 4, 8],
	},
	{
		id: 7,
		name: "Agent",
		display_name: "Agent",
		permissions: [2, 3, 4, 8, 9],
	},
	{
		id: 8,
		name: "Agent served Advertiser",
		display_name: "Agent served Advertiser",
		permissions: [2, 3, 4, 8, 9, 11, 12],
	},
	{
		id: 9,
		name: "Public Advertiser",
		display_name: "Public Advertiser",
		permissions: [2, 3, 4, 8, 11, 12],
	},
	{
		id: 10,
		name: "Normal User (Candidate)",
		display_name: "Normal User (Candidate)",
		permissions: [],
	},
];

export const Industries = [
    { key: "agriculture", label_en: "Agriculture / Forestry / Fishing", label_zh: "农业 / 林业 / 渔业" },
    { key: "mining", label_en: "Mining / Quarrying", label_zh: "采矿业 / 采石业" },
    { key: "manufacturing", label_en: "Manufacturing", label_zh: "制造业" },
    { key: "electricity", label_en: "Electricity / Gas / Steam / Air Conditioning Supply", label_zh: "电力 / 燃气 / 蒸汽 / 冷气供应" },
    { key: "water", label_en: "Water Supply / Sewerage, Waste Management / Remediation Activities", label_zh: "供水、污水处理、废物管理和修缮" },
    { key: "construction", label_en: "Construction", label_zh: "建筑业" },
    { key: "wholesale", label_en: "Wholesale / Retail Trade", label_zh: "批发 / 零售贸易" },
    { key: "vehicles", label_en: "Motor Vehicles / Motorcycles", label_zh: "汽车 / 摩哆行业" },
    { key: "transportation", label_en: "Transportation / Storage", label_zh: "运输 / 仓储业" },
    { key: "accommodation", label_en: "Accommodation / Food Service", label_zh: "住宿 / 餐饮业" },
    { key: "information", label_en: "Information / Communication", label_zh: "资讯 / 通讯业" },
    { key: "financial", label_en: "Financial / Insurance / Takaful", label_zh: "金融 / 保险 / 伊斯兰保险" },
    { key: "realestate", label_en: "Real Estate", label_zh: "房地产业" },
    { key: "professional", label_en: "Professional / Scientific / Technical", label_zh: "专业 / 科学 / 技术业" },
    { key: "administrative", label_en: "Administrative / Support Services", label_zh: "行政 / 支援服务业" },
    { key: "publicadministration", label_en: "Public Administration / Defence / Compulsory Social Activities", label_zh: "公共行政 / 国防 / 强制性社会活动" },
    { key: "education", label_en: "Education", label_zh: "教育业" },
    { key: "health", label_en: "Human Health / Social Work Activities", label_zh: "医疗保健 / 社会工作服务业" },
    { key: "arts", label_en: "Arts / Entertainment / Recreation", label_zh: "艺术 / 娱乐 / 休闲服务业" },
    { key: "household", label_en: "Household Employment / In-House Goods, Services", label_zh: "家庭雇佣 / 商品, 服务" },
    { key: "extraterritorial", label_en: "Extraterritorial Organisations / Bodies", label_zh: "境外组织 / 机构" },
    { key: "others", label_en: "Others Services", label_zh: "其他服务业" }
];