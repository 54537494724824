import React, { useState, useEffect } from "react";
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { validateEmail } from "utils/helper";
import { connect } from "react-redux";
import ConfirmationModal from "components/Modal/confirmation";
import Switch from "react-switch";
import "../index.scss";
import AgentCodeSearch from "./Components/agentCodeSearch";

function EditUserForm({
  user,
  onSubmit,
  getRole,
  searchUser,
  id,
  getUser,
  createRole,
  deleteRole,
}) {
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [roleSwitchLoading, setRoleSwitchLoading] = useState(false);
  const [agentCode, setAgentCode] = useState("");
  const [agentLeadOptions, setAgentLeadOptions] = useState([]);
  const [agentLeadLoading, setAgentLeadLoading] = useState(false);
  const [agentLead, setAgentLead] = useState("");
  const [agentLeadCode, setAgentLeadCode] = useState("");
  const [managerOptions, setManagerOptions] = useState([]);
  const [managerLoading, setManagerLoading] = useState(false);
  const [manager, setManager] = useState("");
  const [agent, setAgent] = useState("");
  const [managerCode, setManagerCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumberPrefix, setContactNumberPrefix] = useState("+60");
  const [contactNumber, setContactNumber] = useState("");
  const [sstNumber, setSstNumber] = useState("");
  const [excludeSstCharges, setExcludeSstCharges] = useState(false);
  const [isHouseAdvertiser, setIsHouseAdvertiser] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingPostcode, setBillingPostcode] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [description, setDescription] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onDismissAlert = () => {
    setAlertVisible(false);
    setAlertError("");
  };

  const createRoleAsync = (dataToSubmit) => {
    return new Promise((resolve, reject) => {
      createRole(dataToSubmit, resolve, reject);
    });
  };

  const deleteRoleAsync = (dataToSubmit) => {
    return new Promise((resolve, reject) => {
      deleteRole(dataToSubmit, resolve, reject);
    });
  };

  const handleRoleSwitchChange = async (roleId) => {
    try {
      setRoleSwitchLoading(true);
      const tmpRoleList = [...userRoleList];
      const index = tmpRoleList.indexOf(roleId);
      if (index === -1) {
        // api call to post role
        await createRoleAsync({
          user_id: parseInt(id),
          role_id: roleId,
        });
        // frontend state handling
        tmpRoleList.push(roleId);
      } else {
        // api call to delete role
        await deleteRoleAsync({
          user_id: parseInt(id),
          role_id: roleId,
        });
        // frontend state handling
        tmpRoleList.splice(index, 1);
      }

      setUserRoleList(tmpRoleList);
    } catch (error) {
      // Handle error appropriately
      console.log("switch error");
      throw error;
    } finally {
      setRoleSwitchLoading(false);
    }
  };

  const handleSearchAgentLead = async () => {
    try {
      setAgentLeadLoading(true);
      const results = await new Promise((resolve, reject) => {
        searchUser(agentLeadCode, resolve, reject);
      });

      setAgentLeadOptions(
        results.map((result) => ({
          label: result.name,
          value: result.id,
        }))
      );
    } catch (error) {
      // Handle error appropriately
      throw error;
    } finally {
      setAgentLeadLoading(false);
    }
  };

  const handleSearchManager = async () => {
    try {
      setManagerLoading(true);
      const results = await new Promise((resolve, reject) => {
        searchUser(managerCode, resolve, reject);
      });

      setManagerOptions(
        results.map((result) => ({
          label: result.name,
          value: result.id,
        }))
      );
    } catch (error) {
      // Handle error appropriately
      throw error;
    } finally {
      setManagerLoading(false);
    }
  };

  const validateForm = () => {
    // Reset all error states

    setAlertVisible(false);
    setAlertError("");

    let isValid = true;

    if (!name) {
      // Validate name
      setAlertError("Name is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (!email) {
      // Validate email
      setAlertError("Email is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (!validateEmail(email)) {
      setAlertError("Email is not valid.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !contactNumber
    ) {
      setAlertError("Contact Number is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !registrationNumber
    ) {
      setAlertError("Registration Number is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !address
    ) {
      setAlertError("Address is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !postcode
    ) {
      setAlertError("Postcode is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !city
    ) {
      setAlertError("City is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !billingAddress
    ) {
      setAlertError("Billing Address is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !billingPostcode
    ) {
      setAlertError("Billing Postcode is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !billingCity
    ) {
      setAlertError("Billing City is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !description
    ) {
      setAlertError("Description is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (
      (userRoleList.includes(8) || userRoleList.includes(9)) &&
      !websiteUrl
    ) {
      setAlertError("Website URL is required.");
      setAlertVisible(true);
      isValid = false;
    }

    // Add more validation rules for other fields as needed
    setShowConfirmModal(false);
    return isValid;
  };

  const handleSubmit = () => {
    // Check form validation
    if (validateForm()) {
      // Call the onSubmit callback if the form is valid
      onSubmit(
        {
          id: id,
          email: email,
          name: name,
          mobile_number: !contactNumber
            ? null
            : contactNumberPrefix + contactNumber,
          code: !agentCode ? null : agentCode,
          is_first_time: fetchedData?.is_first_time,
          status: fetchedData?.status,
          whatsapp_number: fetchedData?.whatsapp_number,
          agent_lead_id: !agentLead ? null : agentLead.value,
          manager_id: !manager ? null : manager.value,
          agent_id: !agent ? null : agent.value,
          is_house_advertiser: isHouseAdvertiser,
          sst_registration_number: sstNumber,
          is_exclude_sst: excludeSstCharges,
          registration_number: registrationNumber,
          advertiser_address: address,
          postcode: postcode,
          city: city,
          billing_address: billingAddress,
          billing_postcode: billingPostcode,
          billing_city: billingCity,
          description: description,
          website_url: websiteUrl,
        },
        userRoleList.includes(8) || userRoleList.includes(9)
      );
    }
  };

  const asyncGetRole = async () => {
    try {
      setLoading(true);
      const results = await getRole();

      setRoleOptions(results);
    } catch (error) {
      // Handle error appropriately
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getUserAsync = (id = "") => {
    return new Promise((resolve, reject) => {
      getUser(id, resolve, reject);
    });
  };

  const fillValues = (data) => {
    const {
      agent_lead,
      code,
      email,
      manager,
      mobile_number,
      name,
      roles,
      user_companies,
    } = data;

    const mobileNumberPrefix = mobile_number?.slice(0, 3);
    const mobileNumber = mobile_number?.slice(3);

    setAgentLeadOptions(
      !agent_lead
        ? []
        : [
            {
              label: agent_lead?.name,
              value: agent_lead?.id,
            },
          ]
    );
    setAgentLead(agent_lead?.id);
    setAgentCode(code);
    setEmail(email);
    setManagerOptions(
      !manager
        ? []
        : [
            {
              label: manager?.name,
              value: manager?.id,
            },
          ]
    );
    setManager(manager?.id);
    setName(name);
    setContactNumberPrefix(!mobileNumberPrefix ? "+60" : mobileNumberPrefix);
    setContactNumber(mobileNumber);
    if (!!user_companies) {
      setSstNumber(user_companies[0]?.sst_registration_number);
      setExcludeSstCharges(user_companies[0]?.is_exclude_sst);
      setIsHouseAdvertiser(user_companies[0]?.is_house_advertiser);
      setRegistrationNumber(user_companies[0]?.registration_number);
      setAddress(user_companies[0]?.advertiser_address);
      setPostcode(user_companies[0]?.postcode);
      setCity(user_companies[0]?.city);
      setBillingAddress(user_companies[0]?.billing_address);
      setBillingPostcode(user_companies[0]?.billing_postcode);
      setBillingCity(user_companies[0]?.billing_city);
      setDescription(user_companies[0]?.description);
      setWebsiteUrl(user_companies[0]?.website_url);
      setAgent(user_companies[0]?.agent_id);
    }
    setUserRoleList(roles.map((role) => role.id));
    setFetchedData(data);
  };

  const fetchData = async (id) => {
    try {
      setLoading(true);
      const response = await getUserAsync(id);
      fillValues(response[0]);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    asyncGetRole();
    // fetch data and fill form fields if id is provided, meaning the page is in edit mode
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <div className="role-switch-container">
            <h6 style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
              User Role
            </h6>
            {loading ? (
              <Spinner />
            ) : roleOptions?.length < 1 ? (
              "No user roles found"
            ) : (
              roleOptions.map((role) => (
                <FormGroup className="each-switch">
                  <Label className="role-label">{role.display_name}</Label>
                  <Switch
                    checked={userRoleList.includes(role.id)}
                    disabled={roleSwitchLoading}
                    onChange={() => handleRoleSwitchChange(role.id)}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#91a3fa"
                    onHandleColor="#5570F1"
                  />
                </FormGroup>
              ))
            )}
          </div>
          <AgentCodeSearch roleId={5} onSelectionChanged={(val) => setManager(val)} defaultValue={manager}/>
          <AgentCodeSearch roleId={6} onSelectionChanged={(val) => setAgentLead(val)} defaultValue={agentLead}/>
          <AgentCodeSearch roleId={7} onSelectionChanged={(val) => setAgent(val)} defaultValue={agent} />
        </CardBody>
      </Card>
      <Card className="main-card mb-3">
        <CardBody>
          <h6 style={{ fontWeight: "bold" }}>Basic Information</h6>
          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Name</Label>
            <Input
              type={"text"}
              placeholder={"Enter User Name"}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Email Address</Label>
            <Input
              type={"text"}
              placeholder={"Enter User Email Address"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Contact Number</Label>
            <InputGroup>
              <UncontrolledDropdown>
                <DropdownToggle
                  color="#ffffff"
                  caret
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem 0 0 0.25rem",
                  }}
                >
                  {contactNumberPrefix}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setContactNumberPrefix("+60")}>
                    +60
                  </DropdownItem>
                  <DropdownItem onClick={() => setContactNumberPrefix("+61")}>
                    +61
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Input
                type={"text"}
                placeholder={"Enter User Contact Number"}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}
                value={contactNumber}
              />
            </InputGroup>
          </FormGroup>

          {(userRoleList.includes(8) || userRoleList.includes(9)) && (
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>
                SST Registration Number
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter SST Registration Number"}
                onChange={(e) => {
                  setSstNumber(e.target.value);
                }}
                value={sstNumber}
              />
              <FormGroup check className="check-option">
                <Input
                  type="checkbox"
                  checked={excludeSstCharges}
                  onChange={(e) => setExcludeSstCharges(e.target.checked)}
                />
                <Label check className="role-label">
                  Exclude SST Charges
                </Label>
              </FormGroup>
            </FormGroup>
          )}

          {(userRoleList.includes(8) || userRoleList.includes(9)) && (
            <FormGroup>
              <FormGroup check className="check-option">
                <Input
                  type="checkbox"
                  checked={isHouseAdvertiser}
                  onChange={(e) => setIsHouseAdvertiser(e.target.checked)}
                />
                <Label check className="role-label">
                  Are you a House Advertiser?
                </Label>
              </FormGroup>
            </FormGroup>
          )}

          {(userRoleList.includes(8) || userRoleList.includes(9)) && (
            <>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>
                  Registration Number
                </Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Registration Number"}
                  onChange={(e) => {
                    setRegistrationNumber(e.target.value);
                  }}
                  value={registrationNumber}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Address</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Address"}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Postcode</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Postcode"}
                  onChange={(e) => {
                    setPostcode(e.target.value);
                  }}
                  value={postcode}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>City</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter City"}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  value={city}
                />
              </FormGroup>

              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Billing Address</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Billing Address"}
                  onChange={(e) => {
                    setBillingAddress(e.target.value);
                  }}
                  value={billingAddress}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Billing Postcode</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Billing Postcode"}
                  onChange={(e) => {
                    setBillingPostcode(e.target.value);
                  }}
                  value={billingPostcode}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Billing City</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Billing City"}
                  onChange={(e) => {
                    setBillingCity(e.target.value);
                  }}
                  value={billingCity}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Description</Label>
                <Input
                  type={"textarea"}
                  placeholder={"Enter Description"}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Website URL</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Website URL"}
                  onChange={(e) => {
                    setWebsiteUrl(e.target.value);
                  }}
                  value={websiteUrl}
                />
              </FormGroup>
            </>
          )}
        </CardBody>
      </Card>
      <div className="container-fluid mb-3">
        <div className="row justify-content-end">
          <div className="col-auto" style={{ padding: "6px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowConfirmModal(true)}
            >
              <i className="pe-lg pe-7s-diskette"></i> Save User
            </button>
          </div>
        </div>
      </div>

      <ConfirmationModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onClickConfirm={() => {
          handleSubmit();
        }}
        title="Are you sure you want to save?"
        message="Please confirm this action."
        confirmContent="Yes, Save"
        confirmColor="primary"
      />
      <Alert color="danger" isOpen={alertVisible} toggle={onDismissAlert}>
        {alertError}
      </Alert>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.ProfileReducer.profile,
});

export default connect(mapStateToProps)(EditUserForm);
