import React, { Component } from "react";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			educationLevels: [],
		};

		onChangeEduHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getEducationLevels = () => {
			Get(
				`/jobads/education-levels`,
				this.getEducationLevelsSuccess,
				(error) => requestError(error.message),
				this.load,
				true
			);
		};
		getEducationLevelsSuccess = (payload) =>
			this.setState({ educationLevels: payload.data });

		createEdu = (dataToSubmit, cb) =>
			Post(
				`/jobseeker-profile-education-levels`,
				dataToSubmit,
				() => cb(),
				this.createEduError,
				this.load,
				true
			);
		createEduError = (error) => requestError(error.message);

		deleteEdu = (id) =>
			Delete(
				`/jobseeker-profile-education-levels/${id}`,
				() => {},
				this.deleteEduError,
				this.load,
				true
			);
		deleteEduError = (error) => requestError(error.message);

		updateEdu = (dataToSubmit, cb) =>
			Put(
				`/jobseeker-profile-education-levels/${dataToSubmit.id}`,
				dataToSubmit,
				() => cb(),
				this.updateEduError,
				this.load,
				true
			);
		updateEduSucces = (payload) => {
			this.getEdu();
			this.setState({ showUpdateEduModal: false });
			requestSuccess("Record updated successfully.");
		};
		updateEduError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onLoadEdu={this.state.loading}
					getEducationLevels={this.getEducationLevels}
					createEdu={this.createEdu}
					deleteEdu={this.deleteEdu}
					updateEdu={this.updateEdu}
				/>
			);
		};
	}

	return WithHOC;
};

export default HOC;
