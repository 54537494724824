import { Component } from "react";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			applied: [],
		};

		onChangeAppliedHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getApplied = (userID) => {
			Get(
				`/job-applications?user_id=${userID}`,
				this.getAppliedSuccess,
				this.getAppliedError,
				this.load,
				true
			);
		};
		getAppliedSuccess = (payload) => {
			let temp = [];
			payload.data.map((appliedChild) => {
				temp.push({
					...appliedChild,
					...appliedChild.jobad,
					appplied_date: appliedChild.created_at,
				});
			});

			this.setState({ applied: temp });
		};
		getAppliedError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					getApplied={this.getApplied}
					onChangeAppliedHOC={this.onChangeAppliedHOC}
				/>
			);
		};
	}

	return WithHOC;
};

export default HOC;
