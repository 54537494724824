import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Post, Get } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      roleList: [],
      showPreviewModal: false,
      selectedInbox: null,
    };

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    createNewInbox = (dataToSubmit) => {
      Post(
        `/inboxes`,
        dataToSubmit,
        this.createNewInboxSuccess,
        this.createNewInboxError,
        this.load
      );
    };
    createNewInboxSuccess = () => {
      requestSuccess("Inbox created successfully.");
      setTimeout(() => {
        this.props.history.push("/inbox");
      }, 2000);
    };
    createNewInboxError = (error) => requestError(error);

    getRole = () =>
      Get(
        `/roles?platform=jobmacha`,
        this.getRoleSuccess,
        this.getRoleError,
        this.load
      );
    getRoleSuccess = (payload) => {
      this.setState({
        roleList: payload.data,
      });
    };
    getRoleError = (error) => requestError(error);

    searchUser = (user, resolve, reject) =>
      Get(
        `/users?platform=jobmacha${user ? `&name=${user}` : ""}`,
        (response) => {
          resolve(response.data); // Resolve the promise with options
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    load = (param) => this.setState({ loading: param });

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadLogin={this.state.loading}
          onChangeHOC={this.onChangeHOC}
          getRole={this.getRole}
          roleList={this.state.roleList}
          searchUser={this.searchUser}
          createNewInbox={this.createNewInbox}
          showPreviewModal={this.state.showPreviewModal}
          selectedInbox={this.state.selectedInbox}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setProfile,
  })(WithHOC);
};
export default HOC;
