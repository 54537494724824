// eClassified Button Component
// eClassified Tag Button
// Client Side Component

import React from 'react';
import { Button } from 'reactstrap';
import './index.scss'

function TagButton({
  key,
  isActiveWithOutline,
  selectedTab,
  item,
  label,
  onSelect
}) {
  return (
    <Button
      key={ key }
      color={ isActiveWithOutline ? 'transparent' : selectedTab === item ? 'primary' : 'transparent' }
      className={ `e-classifed-tag-button ${ selectedTab === item ? '' : isActiveWithOutline ? 'outline not-selected' : 'not-selected' }` }
      onClick={ () => onSelect( item ) }>
      <span>
        { label }
      </span>
    </Button>
  )
};

export default TagButton;