import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [logID, setLogID] = useState("");
	const [adminID, setAdminID] = useState("");
	const [actions, setActions] = useState("");
	const [ipAddress, setIP] = useState("");
	const [timestamp, setTime] = useState(null);

	const handleClear = () => {
		setTime("");
		setLogID("");
		setAdminID("");
		setActions("");
		setIP("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (logID) {
			queryString += `&invoice_no=${logID}`;
		}
		if (adminID) {
			queryString += `&billing_name=${adminID}`;
		}
		if (actions) {
			queryString += `&order_description=${actions}`;
		}
		if (ipAddress) {
			queryString += `&selling_price=${ipAddress}`;
		}
		if (timestamp) {
			queryString += `&agent_name=${timestamp}`;
		}

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="timestamp">Timestamp </Label>
								<Input
									type="date"
									placeholder="Enter Timestamp"
									name="timestamp"
									id="timestamp"
									value={timestamp}
									onChange={(e) => {
										setTime(e.target.value);
									}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="logID">Log ID </Label>
								<Input
									placeholder="Enter Log ID"
									name="logID"
									id="logID"
									value={logID}
									onChange={(e) => {
										setLogID(e.target.value);
									}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="adminID">Admin ID </Label>
								<Input
									placeholder="Enter Admin ID"
									name="adminID"
									id="adminID"
									value={adminID}
									onChange={(e) => {
										setAdminID(e.target.value);
									}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="actions">Actions </Label>
								<Input
									placeholder="Enter Actions"
									name="actions"
									id="actions"
									value={actions}
									onChange={(e) => {
										setActions(e.target.value);
									}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="ipAddress">IP Address </Label>
								<Input
									placeholder="Enter IP Address"
									name="ipAddress"
									id="ipAddress"
									value={ipAddress}
									onChange={(e) => {
										setIP(e.target.value);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
