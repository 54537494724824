import React from "react";
import { convertBase64ToObject } from "utils/objToBase64";

const PaymentGatewayForm = ({ id, value }) => {
  return (
    <form
      id={id}
      name="payment-gateway-form"
      method={"post"}
      action="https://uatpaymenthub.infinitium.com/api/pymt/pw/v1.1/payment"
    >
      <input
        type="hidden"
        name="jwt"
        value={convertBase64ToObject(value).jwt}
      />
    </form>
  );
};

export default PaymentGatewayForm;
