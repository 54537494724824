import React, { Component, Suspense, lazy } from "react";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { compose } from "redux";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { getItem } from "utils/tokenStore";
import { RolesPermission } from "Config";

import "react-toastify/dist/ReactToastify.css";
import { CheckUserRolesPermissons } from "actions/roles";

const Sample = lazy(() => import("./Sample"));

const SuspenseWrapper = ({ children }) => (
	<Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
);

class Dashboard extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});
		if (tempModule && !tempModule.permissions.includes(1)) {
			return this.props.history.push("/");
		}
		if (!getItem("CHINA_PRESS_TOKEN")) {
			this.props.history.push("/login");
		} else {
		}
	};

	render = () => {
		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<SuspenseWrapper>
									<Route
										path={`/dashboard/sample`}
										render={(props) => <Sample {...props} />}
									/>
								</SuspenseWrapper>
							</div>
						</div>
					</div>
					<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
				</TemplateContainerMain>
			</>
		);
	};
}

const mapStateToProps = (state) => ({ data: state });
export default compose(
	connect(mapStateToProps, {
		withRouter,
	})
)(Dashboard);
