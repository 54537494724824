import React from "react";
import { ModalBody } from "reactstrap";

export default function CustomModalBody({ children, style, fullScreen }) {
	return (
		<ModalBody
			className={`${
				fullScreen
					? "china-press-dialog-content-full-screen"
					: "china-press-dialog-content"
			}`}
			style={style}>
			{children}
		</ModalBody>
	);
}
