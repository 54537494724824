import { Fragment, useState } from "react";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Collapse } from "reactstrap";

import { MenuData } from "../assets";

function MeLink({
  currentPath,
  setCurrentPath
}) {

	return (
		<div className="e-classfied-me-menu__cont">
			{MenuData.map((menuChild, menuIndex) => (
				<Fragment key={`${menuChild.label}_${menuIndex}`}>
					{menuChild.children?.[0] && (
						<CollapsableMenuItem
							menuChild={menuChild}
							currentPath={currentPath}
              setCurrentPath={ setCurrentPath }
						/>
					)}
					{!menuChild.children && (
						<div
							href={menuChild.route}
							className={`e-classfied-me-menu__cont-item ${
								currentPath === `/zh/${menuChild.route}` ||
								currentPath === `/${menuChild.route}`
									? "e-classfied-me-menu__cont-item-active"
									: ""
							}`}
              onClick={ () => {
                setCurrentPath( menuChild.route )
              }}
            >
							{menuChild.icon}
							<span>
                {
                  menuChild.label
                }
							</span>
						</div>
					)}
				</Fragment>
			))}
		</div>
	);
}

const CollapsableMenuItem = ({ menuChild, currentPath,setCurrentPath  }) => {
	const [isExtend, setExtend] = useState(false);
	return (
		<>
			<div
				className="e-classfied-me-menu__cont-item"
				style={{ cursor: "pointer" }}
				onClick={() => setExtend(!isExtend)}>
				{menuChild.icon}
				<span>
          {
            menuChild.label
          }
				</span>
				<AiOutlineDownCircle className="ml-auto" />
			</div>
			<Collapse isOpen={isExtend}>
				{menuChild.children.map((tempChild) => (
					<div
						key={`menu_${tempChild.route}`}
						className={`e-classfied-me-menu__cont-item ${
							currentPath === `/zh/${tempChild.route}` ||
							currentPath === `/${tempChild.route}`
								? "e-classfied-me-menu__cont-item-active"
								: ""
						}`}
            onClick={() => setCurrentPath( tempChild.route )}
          >
						<span className="ml-4" style={{ fontWeight: 400 }}>
              { tempChild.label }
						</span>
					</div>
				))}
			</Collapse>
		</>
	);
};

export default MeLink;
