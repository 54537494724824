import React, { useState } from "react";
import { convertBase64ToObject } from "utils/objToBase64";
import { compose } from "redux";
import { Card, CardBody, Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CreatePostAPI from "./action.js";

const PostCompletePage = (props) => {
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const [payload, setPayload] = useState(
    convertBase64ToObject(query.get("payload") || "")
  );

  return (
    <>
      <TemplateContainerMain>
        <TemplateHeader
          onClickToggleProfileModal={() => {}}
          history={props.history}
          user={props.data.ProfileReducer.profile}
        />
        <div className="app-main">
          <TemplateSidemenu
            userPermissions={props.userPermissions}
            user={props.user}
            userRole={props.userRole}
          />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Card>
                <CardBody>
                  <div className="d-flex flex-column">
                    <span>
                      {payload.status === "success"
                        ? "The payment has been completed"
                        : "The payment has failed. Please try again or contact the admin for more info"}
                    </span>
                    <Button
                      className="mt-2"
                      style={{ width: "max-content" }}
                      outline
                      color="primary"
                      onClick={() => props.history.push("/post")}
                    >
                      Go to Manage Post
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </TemplateContainerMain>
    </>
  );
};

export default compose(CreatePostAPI)(PostCompletePage);
