import React, { Component } from "react";
import { connect } from "react-redux";
import { Get, Put, GetFile, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import _ from "lodash";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showErrorMessage: false,
      showCreateCompanyModal: false,
      advertiserList: [],
      advertiserPages: 1,
      advertiserCount: 0,
      selectionCreate: [],
      showFilterModal: false,
      showEditModal: false,

      showPreviewModal: false,
      selectedCompany: {},
      selectedCompanyLogo: "",
      selectedCompanyGallery: [],

      showInactiveModal: false,
      showCreateAdvertiser: false,
      natureList: [],

      filterQuery: null,
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getAdvertiser = (queryString = "") => {
      Get(
        "/companies?limit=10" + queryString,
        this.getAdvertiserSuccess,
        this.getAdvertiserError,
        this.load
      );
    };
    getAdvertiserSuccess = (payload) => {
      this.setState({
        advertiserList: payload.data.rows,
        advertiserCount: payload.data.count,
      });
    };
    getAdvertiserError = (error) => requestError(error);

    getNature = () => {
      this.setState({ loading: true });
      Get(
        "/jobads/specializations",
        this.getNatureSuccess,
        this.getNatureError,
        this.load
      );
    };
    getNatureSuccess = (payload) => {
      this.setState({
        natureList: payload.data,
      });
    };
    getNatureError = (error) => requestError(error);

    updateAdvertiser = (dataToSubmit) => {
      Put(
        `/companies/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateAdvertiserSuccess,
        this.updateAdvertiserError,
        this.load
      );
    };
    updateAdvertiserSuccess = () => {
      this.getAdvertiser();
      this.setState({
        showInactiveModal: false,
      });
      requestSuccess("Advertiser updated successfully.");
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    };
    updateAdvertiserError = (error) => requestError(error);

    createSample = (dataToSubmit) => {
      Post(
        `/mywheels/samples`,
        dataToSubmit,
        this.createSampleSuccess,
        this.createSampleError,
        this.load
      );
    };
    createSampleSuccess = () => {
      this.getAdvertiser();
      this.setState({ showCreateCompanyModal: false });
      requestSuccess("Record created successfully.");
    };
    createSampleError = (error) => requestError(error);



    getCompanyImage = id => GetFile(
      `/companies-gen-image/${id}`,
      `generate_as_image_company_${id}.png`,
      () => requestSuccess( 'Image Downloaded' ),
      error => requestError( error ),
      this.load
    )
    getSelectedCompany = (id, type) =>
      Get(
        `/companies/${id}`,
        (payload) => this.getSelectedCompanySuccess(payload, type),
        (error) => requestError(error),
        this.load
      )
    getSelectedCompanySuccess = (payload, type) =>
      this.setState(
        {
          selectedCompany: payload.data,
          selectedCompanyLogo: payload.data.logo_url,
        },
        () => this.getSelectedCompanyGalleries(payload.data.id, type)
      )

    getSelectedCompanyGalleries = (company_id, type) =>
      Get(
        `/company-galleries?company_id=${company_id}`,
        (payload) => this.getSelectedCompanyGalleriesSuccess(payload, type),
        (error) => requestError(error),
        this.load
      )
    getSelectedCompanyGalleriesSuccess = (payload, type) =>
      this.setState({
        selectedCompany: {
          ...this.state.selectedCompany,
          company_galleries: _.map(payload.data, "url"),
        },
        [type]: true,
        selectedCompanyGallery: payload.data,
      })

    exportReport = () => {
        Get(
            `/report-generate/xlsx/manage-advertisers-reports?${this.state.filterQuery?.slice(1) || ""}`,
            this.exportReportSuccess,
            this.exportReportError,
            this.load
        );
    };
    exportReportSuccess = (payload) => {
        requestSuccess(payload.message);
        window.open(payload.ossUrl, "_blank");
    };
    exportReportError = (error) => requestError(error);

      

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          showCreateCompanyModal={this.state.showCreateCompanyModal}
          getAdvertiser={this.getAdvertiser}
          updateAdvertiser={this.updateAdvertiser}
          createSample={this.createSample}
          onChangeHOC={this.onChangeHOC}
          advertiserList={this.state.advertiserList}
          advertiserPages={this.state.advertiserPages}
          advertiserCount={this.state.advertiserCount}
          showFilterModal={this.state.showFilterModal}
          showEditModal={this.state.showEditModal}
          showPreviewModal={this.state.showPreviewModal}
          showInactiveModal={this.state.showInactiveModal}
          showCreateAdvertiser={this.state.showCreateAdvertiser}
          natureList={this.state.natureList}
          getNature={this.getNature}

          getSelectedCompany={this.getSelectedCompany}
          getCompanyImage={this.getCompanyImage}

          exportReport={this.exportReport}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};
export default HOC;
