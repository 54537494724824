import { Component } from "react";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			jobAds: [],
		};

		onChangeJobAdsHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getJobAds = (userID) => {
			Get(
				`/user-jobads?user_id=${userID}`,
				this.getJobAdsSuccess,
				this.getJobAdsError,
				this.load,
				true
			);
		};
		getJobAdsSuccess = (payload) => {
			let temp = payload.data.map((payloadChild) => ({
				...payloadChild,
				is_applied: true,
			}));

			this.setState({ jobAds: temp });
		};
		getJobAdsError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					getJobAds={this.getJobAds}
				/>
			);
		};
	}

	return WithHOC;
};

export default HOC;
