import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
  const [messageId, setMessageId] = useState("");
  const [message, setMessage] = useState("");
  const [recipient, setRecipient] = useState("");
  const [openCount, setOpenCount] = useState("");
  const [clickCount, setClickCount] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [status, setStatus] = useState("");

  const handleClear = () => {
    setMessageId("");
    setMessage("");
    setRecipient("");
    setOpenCount("");
    setClickCount("");
    setCreatedDate("");
    setPublishedDate("");
    setStatus("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (!!messageId) {
      queryString += `&id=${messageId}`;
    }
    if (!!message) {
      queryString += `&message=${message}`;
    }
    if (!!recipient) {
      queryString += `&user_email=${recipient}`;
    }
    if (!!openCount) {
      queryString += `&open_count=${openCount}`;
    }
    if (!!clickCount) {
      queryString += `&click_count=${clickCount}`;
    }
    if (!!createdDate) {
      queryString += `&created_at=${createdDate}`;
    }
    if (!!publishedDate) {
      queryString += `&published_at=${publishedDate}`;
    }
    if (!!status) {
      queryString += `&status=${status}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-inbox-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="messageId">Message ID</Label>
                <Input
                  placeholder="Enter Message ID"
                  name="messageId"
                  id="messageId"
                  value={messageId}
                  onChange={(e) => setMessageId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="message">Message Title</Label>
                <Input
                  placeholder="Enter Search Text"
                  name="message"
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="recipient">Recipient</Label>
                <Input
                  placeholder="Enter Recipient"
                  name="recipient"
                  id="recipient"
                  value={recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="openCount">Open Count</Label>
                <Input
                  placeholder="Enter Open Count"
                  name="openCount"
                  id="openCount"
                  value={openCount}
                  onChange={(e) => setOpenCount(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Click Count</Label>
                <Input
                  placeholder="Enter Click Count"
                  name="clickCount"
                  id="clickCount"
                  value={clickCount}
                  onChange={(e) => setClickCount(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="createdDate">Created Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="createdDate"
                  id="createdDate"
                  value={createdDate}
                  onChange={(e) => setCreatedDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="publishedDate">Published Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="publishedDate"
                  id="publishedDate"
                  value={publishedDate}
                  onChange={(e) => setPublishedDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option disabled value="">
                    Select status
                  </option>
                  <option>sent</option>
                  <option>draft</option>
                  <option>scheduled</option>
                  <option>disabled</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Search",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("advertiserPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
