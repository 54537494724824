import Moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import _ from 'lodash'

const SampleWorkingExp = {
	company_name: "",
	job_title: "",
	specialisation: "",
	industry: "",
	start_month: null,
	end_month: null,
	is_till_present: false,
	user_id: null,
};

function WorkingExpEdit({ userExp, tempUser, createExp, deleteExp, updateExp }) {
	const [showCreate, setShowCreate] = useState(false);
	return (
		<>
			<div className="d-flex" style={{ marginTop: "2.5rem" }}>
				<h5 className="mb-3 e-classified-cont-header">
					Working Experience
				</h5>
				<AiOutlinePlusCircle
					className="ml-auto text-primary"
					onClick={() => {
						setShowCreate(true);
					}}
					style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
				/>
			</div>
			{showCreate && (
				<FormItem
					tempUser={tempUser}
					child={SampleWorkingExp}
					createExp={createExp}
					context={"create"}
					onRemoveItem={() => {
						setShowCreate(false);
					}}
				/>
			)}
			{userExp.map((child) => (
				<FormItem
					child={child}
					tempUser={tempUser}
					updateExp={updateExp}
					onRemoveItem={() => deleteExp(child.id, child.user_id)}
				/>
			))}
		</>
	);
}

const FormItem = ({
	child,
	tempUser,
	onRemoveItem,
	updateExp,
	context,
	createExp,
}) => {
	const [tempForm, setTempForm] = useState(SampleWorkingExp);

	const onChangeField = (key, val) => {
		let temp = _.cloneDeep(tempForm);
		temp[key] = val;

		setTempForm(temp);
	};

	useEffect(() => {
		if (child) {
			setTempForm({
				...child,
				user_id: tempUser.id,
				start_month: !child.start_month ? null : new Date(child.start_month),
				end_month: !child.end_month ? null : new Date(child.end_month),
			});
		}
	}, [child]);

	return (
		<>
			<div className="e-classified-profile-view-card e-classfied-me-menu__form-cont mb-4">
				<div
					className="d-flex align-items-center"
					style={{ marginBottom: "2.5rem" }}>
					<h5 className="e-classified-cont-header">
						Edit Working Experience
					</h5>
					<AiOutlineCloseCircle
						style={{ width: "1.75rem", height: "1.75rem", cursor: "pointer" }}
						className="text-secondary ml-auto"
						onClick={() => onRemoveItem()}
					/>
				</div>
				<Form>
					<div className="row">
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									Job Title
								</Label>
								<Input
									value={tempForm.job_title}
									onChange={(e) => onChangeField("job_title", e.target.value)}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									Company Name
								</Label>
								<Input
									value={tempForm.company_name}
									onChange={(e) =>
										onChangeField("company_name", e.target.value)
									}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									Job Specs
								</Label>
								<Input
									value={tempForm.specialisation}
									onChange={(e) =>
										onChangeField("specialisation", e.target.value)
									}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									Industry
								</Label>
								<Input
									value={tempForm.industry}
									onChange={(e) => onChangeField("industry", e.target.value)}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									Start At
								</Label>
								<DatePicker
									className="form-control"
									selected={tempForm.start_month}
									min={Moment().subtract(120, "years").toDate()}
									maxDate={Moment().subtract(16, "years").toDate()}
									onChange={(date) => {
										onChangeField("start_month", date);
									}}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dateFormat="MM/yyyy"
								/>
							</FormGroup>
						</div>
						<div className="col-lg-6">
							<FormGroup>
								<Label>
									End At
								</Label>
								<DatePicker
									className="form-control"
									selected={tempForm.end_month}
									min={Moment().subtract(120, "years").toDate()}
									maxDate={Moment().subtract(16, "years").toDate()}
									onChange={(date) => {
										onChangeField("end_month", date);
									}}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dateFormat="MM/yyyy"
								/>
							</FormGroup>
						</div>
						<div className="col-lg-12">
							<FormGroup check style={{ marginBottom: "2rem" }}>
								<Input
									type={"checkbox"}
									checked={tempForm.is_till_present}
									onChange={(e) =>
										onChangeField("is_till_present", e.target.checked)
									}
								/>
								<Label check style={{ fontWeight: 400, fontSize: "1rem" }}>
									I am currently working in this role
								</Label>
							</FormGroup>
						</div>
						<div className="col-lg-12">
							<FormGroup>
								<Label>
									Current Salary
								</Label>
								<Input
									value={tempForm.current_salary}
									type={"number"}
									onChange={(e) =>
										onChangeField("current_salary", parseFloat(e.target.value))
									}
								/>
							</FormGroup>
						</div>
						<div className="col-lg-12">
							<FormGroup>
								<Label
									className="d-flex"
									style={{ fontWeight: 500, fontSize: "1rem" }}>
									Jobs Description
									<span
										style={{ fontWeight: 300 }}
										className="text-secondary ml-auto">
										{`${"0"}/100 `}
										Characters
									</span>
								</Label>
								<Input
									type="textarea"
									value={tempForm.description}
									onChange={(e) => onChangeField("description", e.target.value)}
								/>
							</FormGroup>
						</div>
					</div>
				</Form>
				<div className="d-flex" style={{ marginTop: "2.5rem" }}>
					<Button
						className="ml-auto"
						color="primary"
						size="lg"
						onClick={() =>
							context === "create" ? createExp(tempForm) : updateExp(tempForm)
						}>
						Save
					</Button>
				</div>
			</div>
		</>
	);
};

export default WorkingExpEdit;
