import CollapsableCheckbox from "components/CollapsableCheckbox";
import _ from 'lodash';
import { Fragment } from "react";

function JobPrefForm({
	jobSpecs,
	jobSeekerProfile,
	jobSeekerSpec,
	postJobSeekerSpec,
	deleteJobSeekerSpec,
}) {

	const onCheck = (specID) => {
		const index = jobSeekerSpec.findIndex((item) => item.id === specID);
		if (index > -1) {
			deleteJobSeekerSpec(jobSeekerSpec[index].jobSeekerSpecID, jobSeekerProfile.user_id);
		} else {
			postJobSeekerSpec(specID, jobSeekerProfile.user_id);
		}
	};

	return (
		<>
			<h4 className="mb-3">
				<span style={{ fontSize: "1.375rem", fontWeight: 500 }}>
					Job Preferences
				</span>
				<span style={{ fontSize: "1rem" }}>
					{` `}
					(Select up to 5)
				</span>
			</h4>
			{jobSeekerSpec?.[0] && (
				<div className="d-flex align-items-center flex-wrap">
					{jobSeekerSpec.map((item) => (
						<span
							className="badge badge-primary mr-2 py-2 px-3"
							style={{ fontSize: "1.125rem" }}>
							{item.name['en']}
						</span>
					))}
				</div>
			)}
			{/* <FormGroup className="mt-4">
				<Input
					placeholder={transHandler("SEARCH_EG", "LOGIN")}
					value={searchKey}
					onChange={(e) => setSearchKey(e.target.value)}
				/>
			</FormGroup> */}
			<div
				className="bg-white mb-3"
				style={{
					maxHeight: "30rem",
					overflow: "auto",
					padding: "1rem 1.5rem",
				}}>
				{jobSpecs.map((spec) => (
					<>
						<Fragment key={`parent_spec_${spec.value}`}>
							<CollapsableCheckbox
								specItem={spec}
								checkedItems={_.map(jobSeekerSpec, (specChild) => specChild.id)}
								onCheck={onCheck}
							/>
							<hr />
						</Fragment>
					</>
				))}
			</div>
		</>
	);
}

export default JobPrefForm;
