import { FormGroup, Label, Input } from "reactstrap";

export const CustomInput = ({
  label,
  type,
  id,
  placeholder,
  value,
  onChange,
  children,
  style,
  disabled
}) => {
  return (
    <FormGroup style={style}>
      <Label htmlFor={id} style={{ fontWeight: "bold" }}>
        {label}
      </Label>
      <Input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </Input>
    </FormGroup>
  );
};
