import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [advertiser, setAdvertiser] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [date, setDate] = useState("");
	const [agentName, setAgentName] = useState("");
	const [sellingPrice, setSellingPrice] = useState("");
	const [orderDesc, setOrderDesc] = useState("");
	const [agentID, setAgentID] = useState(null);
	const [agentLeadID, setAgentLeadID] = useState(null);

	const [promoCode, setPromoCode] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");

	const handleClear = () => {
		setAgentLeadID("");
		setAdvertiser("");
		setInvoiceNumber("");
		setDate("");
		setAgentName("");
		setSellingPrice("");
		setOrderDesc("");
		setAgentID("");
		setPromoCode("");
		setAgentLeadName("");
	};

	const generateQuery = () => {
		let queryString = "";

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="promoCode">Promo Code </Label>
								<Input
									placeholder="Enter Promo Code"
									name="promoCode"
									id="orderDesce"
									value={promoCode}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="orderDesc">Order Desc </Label>
								<Input
									placeholder="Enter Order Desc"
									name="orderDesc"
									id="orderDesce"
									value={orderDesc}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="date">Date </Label>
								<Input
									placeholder="Enter Date"
									name="date"
									id="date"
									value={date}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceNumber">Invoice No. </Label>
								<Input
									placeholder="Enter Invoice No."
									name="invoiceNumber"
									id="invoiceNumber"
									value={invoiceNumber}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="sellingPrice">Selling Price </Label>
								<Input
									placeholder="Enter Selling Price"
									name="sellingPrice"
									id="sellingPrice"
									value={sellingPrice}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentID">Agent ID </Label>
								<Input
									placeholder="Enter Agent ID"
									name="agentID"
									id="agentID"
									value={agentID}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentName"
									value={agentName}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Agent Lead ID </Label>
								<Input
									placeholder="Enter Agent Lead Name"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadID}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Agent Lead Name </Label>
								<Input
									placeholder="Enter Agent Lead Name"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadName}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
