import React from "react";
// import { useLocale } from 'next-intl'
import { Button, FormGroup, Label } from "reactstrap";
import _ from "lodash";

import "./index.scss";

const CustomRadioButton = ({ 
  label,
  value,
  onChangeData,
  style,
  options,
  context,
  required,
  labelClass,
  labelChildren,
  optionValuelKey,
  row,
  multiple,
  labelCol,
  formGroupClass,
  formGroupStyle,
  customClass
}) => {
//   const locale = useLocale()
  const locale = 'en'

  const onChange = (val) => {
    if( multiple ){
      let temp = _.cloneDeep(value)
      const index = temp.indexOf(val)
      if (index > -1) {
        temp.splice(index, 1)
      } else {
        temp.push(val)
      }
      onChangeData(temp)
    } else {
      onChangeData(val)
    }
  }

  return (
    <FormGroup 
      style={{... formGroupStyle}} 
      className={ formGroupClass || '' }>
      {label !== undefined && (
        <Label
          {...(row ? labelCol : {})}
          className={`custom-form-label ${labelClass}`}
          for={context}>
          {label}
          {required ? <span className="text-danger">*</span> : ""}
          {labelChildren}
        </Label>
			)}
      {(options && options.length > 0) && (
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
          {options?.map((opt, index) => {
            const selected = multiple
              ? value?.includes(opt[optionValuelKey || 'value'])
              : value === opt[optionValuelKey || 'value']

            return (
              <Button
                key={index}
                color={selected ? 'primary' : 'secondary'}
                outline={!selected}
                onClick={() => onChange(opt[optionValuelKey || 'value'])}
                style={{ ...style }}
                className={`custom-radio-button ${customClass || ''}`}>
                {(opt.zh && opt.en) 
                  ? (locale === 'zh' ? opt.zh : opt.en)
                  : opt.name
                }
              </Button>
            )
          })}
        </div>
      )}
    </FormGroup>
  );
};

export default CustomRadioButton;
