import React, { useState, useCallback, useRef } from "react";
import { Button, UncontrolledTooltip, Label } from "reactstrap";

import { BsFillImageFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import Loader from "react-loaders";

import "./index.scss";
import HOC from "./action";
import MediaDB from "components/Modal/MediaDB";

const InputFile = ({
  label,
  onRemoveFile,
  fileValue,
  onLoadAsset,
  onSelectImage,
  createAssets,
  multipleInput,
  returnBase64 = false,
  maxSizeInBytes,
  setBase64Callback,
}) => {
  const [showMediaDB, setShowMediaDB] = useState(false);
  let fileInputRef = useRef(null);

  let onDropFile = useCallback(
    (event) => {
      if (event.target.files[0]) {
        if (maxSizeInBytes && event.target.files[0].size > maxSizeInBytes) {
          // Check file size and reject if it exceeds maxSizeInBytes
          alert(
            "File size exceeds the 5MB limit. Please select a smaller file."
          );
          return;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          // onSelectImage

          if (returnBase64) {
            setBase64Callback(e.target.result);
          } else {
            createAssets({
              base64: e.target.result,
              filename: event.target.files[0].name,
              alt: event.target.files[0].name,
              title: event.target.files[0].name,
              platforms: ['jobmacha']
            }, (val) => onSelectImage(val));
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    [
      returnBase64,
      maxSizeInBytes,
      onSelectImage,
      createAssets,
      setBase64Callback,
    ]
  );

  return (
    <>
      <Label className="d-flex align-items-end">{label}</Label>
      {!onLoadAsset && (
        <div className="mana_file-input">
          <div
            className={`mana_file-input-empty-div ${
              !fileValue ? "" : "d-none"
            }`}
            onClick={() => fileInputRef.current.click()}
          >
            <input
              ref={fileInputRef}
              type={"file"}
              multiple={multipleInput}
              onChange={(event) => onDropFile(event)}
              style={{ display: "none" }}
              accept={".jpg,.jpeg,.png"}
            />
            <BsFillImageFill className="mana_file-input-bg-svg" />
            <span className="mana_file-input-help-text">
              Drop and Upload your image here.
            </span>
          </div>
          <div
            className={`mana_file-input-filled-div ${
              fileValue ? "" : "d-none"
            }`}
          >
            <img className="mana_file-input-img" src={fileValue} alt="" />
            <AiFillDelete
              id={"RemoveFile"}
              className={"mana_file-input-remove-btn"}
              onClick={() => {
                onRemoveFile();
                fileInputRef.current.value = "";
              }}
            />
            <UncontrolledTooltip target={`RemoveFile`} placement="top">
              Click to remove the file here.
            </UncontrolledTooltip>
          </div>
        </div>
      )}
      {onLoadAsset && (
        <div style={{ marginTop: 35, marginBottom: 30 }}>
          <Loader type="ball-pulse-rise" />
        </div>
      )}
      <MediaDB
        isOpen={showMediaDB}
        onSelect={(val) => {
          onSelectImage(val);
          setShowMediaDB(false);
        }}
        onClose={() => setShowMediaDB(false)}
      />
    </>
  );
};

export default HOC(InputFile);
