import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import _ from 'lodash'
import {
	Button,
	Label,
	FormGroup,
	Input,
	Card,
	CardBody,
	FormFeedback,
	Spinner,
} from "reactstrap";
import InputFile from "components/Input/File";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { debounce } from "utils/helper";
import { requestError } from "utils/requestHandler";

export function CreatePopupForm({
	onSubmit,
	roleList,
	searchUser,
	id,
	getBanner,
}) {
	const [title, setTitle] = useState("");
	const [userGroupList, setUserGroupList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [userIdText, setUserIdText] = useState("");
	const [usernameText, setUsernameText] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [frequency, setFrequency] = useState("");
	const [numberOfTimes, setNumberOfTimes] = useState("");
	const [linkURL, setLinkURL] = useState("");
	const [remarks, setRemarks] = useState("");
	const [imageCNBase64, setImageCNBase64] = useState("");
	const [imageENBase64, setImageENBase64] = useState("");
	const [ deleteAssetsList, setDeleteAsset ] = useState( [] )

	// loading state
	const [loading, setLoading] = useState(false);

	// validation state variables
	const [titleError, setTitleError] = useState("");

	const setUserGroupInput = (selectedRole, selectedRoleId) => {
		if (!userGroupList.some((userRole) => userRole.roleId === selectedRoleId)) {
			setUserGroupList([
				...userGroupList,
				{ roleName: selectedRole, roleId: selectedRoleId },
			]);
		}
	};

	const deleteUserGroupInput = (indexToDelete) => {
		const updatedUserRoleList = userGroupList.filter(
			(_, index) => index !== indexToDelete
		);
		setUserGroupList(updatedUserRoleList);
	};

	const addUserList = (userInput) => {
		if (userInput.userIdText && userInput.usernameText) {
			if (
				!userList.some(
					(user) =>
						user.userId === userInput.userIdText &&
						user.username === userInput.usernameText
				)
			) {
				setUserList([
					...userList,
					{ userId: userInput.userIdText, username: userInput.usernameText },
				]);
			}
		}
	};

	const deleteUserList = (indexToDelete) => {
		const updatedUserList = userList.filter(
			(_, index) => index !== indexToDelete
		);
		setUserList(updatedUserList);
	};

	const debouncedSearchUser = debounce(searchUser, 300); // Adjust the debounce delay as needed

	const promiseOptions = async (inputValue) => {
		try {
			const results = await new Promise((resolve, reject) => {
				debouncedSearchUser(inputValue, resolve, reject);
			});

			return results.map((result) => ({
				label: result.name,
				value: { id: result.id, name: result.name },
			}));
		} catch (error) {
			// Handle error appropriately
			throw error;
		}
	};

	const validateForm = () => {
		// Reset all error states
		setTitleError("");

		let isValid = true;

		if (!title) {
			setTitleError("Title is required.");
			requestError("Title is required.");
			isValid = false;
		}

		return isValid;
	};

	const handleSubmit = () => {
		// Check form validation
		if (validateForm()) {
			// Call the onSubmit callback if the form is valid
			const dataToSubmit = {
				title,
				start_date: !!startDate ? Moment(startDate).format("YYYY-MM-DD") : null,
				end_date: !!endDate ? Moment(endDate).format("YYYY-MM-DD") : null,
				frequency: !frequency ? null : frequency,
				number_of_times: !numberOfTimes ? null : parseInt(numberOfTimes),
				popup_image_en_url: !imageENBase64 ? null : imageENBase64,
				popup_image_cn_url: !imageCNBase64 ? null : imageCNBase64,
				link_url: linkURL,
				remarks: remarks,
				status: "active",
				type: "popup",
				location: "Jobmacha Popup",
				user_ids: userList.map((user) => user.userId),
				user_roles: userGroupList.map((role) => role.roleId),
			};
			if (!!id) {
				dataToSubmit["id"] = id;
			}

			let tempDelete = _.cloneDeep( deleteAssetsList )
			_.filter( tempDelete, val => {
				return val.indexOf('base64') < 0
			})
			onSubmit(dataToSubmit, tempDelete);
		}
	};

	const fillValues = (data) => {
		const {
			title,
			start_date,
			end_date,
			frequency,
			number_of_times,
			popup_image_en_url,
			popup_image_cn_url,
			link_url,
			remarks,
			popup_banner_roles,
			popup_banner_ec_users,
		} = data;

		const startDateValue = !start_date ? "" : new Date(start_date);
		const endDateValue = !end_date ? "" : new Date(end_date);

		setTitle(title);
		setStartDate(startDateValue);
		setEndDate(endDateValue);
		setFrequency(!frequency ? "" : frequency);
		setNumberOfTimes(!number_of_times ? "" : number_of_times);
		setImageENBase64(!popup_image_en_url ? "" : popup_image_en_url);
		setImageCNBase64(!popup_image_cn_url ? "" : popup_image_cn_url);
		setLinkURL(!link_url ? "" : link_url);
		setRemarks(!remarks ? "" : remarks);
		setUserGroupList(
			!popup_banner_roles
				? []
				: popup_banner_roles.map((role) => ({
						roleId: role.role_id,
						roleName: role.name,
				  }))
		);
		setUserList(
			!popup_banner_ec_users
				? []
				: popup_banner_ec_users.map((user) => ({
						userId: user.user_id,
						username: user.name,
				  }))
		);
	};

	const getBannerAsync = (querystring = "") => {
		return new Promise((resolve, reject) => {
			getBanner(querystring, resolve, reject);
		});
	};

	const fetchData = async (id) => {
		try {
			setLoading(true);
			const response = await getBannerAsync(`&id=${id}`);
			fillValues(response[0]);
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		// fetch data and fill form fields if id is provided, meaning the page is in edit mode
		if (id) {
			fetchData(id);
		}
	}, [id]);

	return (
		<>
			<Card className="main-card mb-3">
				<CardBody>
					{loading ? (
						<Spinner />
					) : (
						<>
							{!!id && (
								<>
									<span style={{ fontWeight: "bold" }}>Pop Up ID: {id}</span>
									<br></br>
									<br></br>
								</>
							)}

							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>Pop-Up Window Title</Label>
								<Input
									type={"text"}
									placeholder={"Enter Pop-Up Title"}
									value={title}
									onChange={(e) => {
										setTitle(e.target.value);
									}}
									invalid={!!titleError}
								/>
								<FormFeedback>{titleError}</FormFeedback>
							</FormGroup>

							<FormGroup>
								<Label style={{ fontWeight: "bold" }} for="userRole">
								Recipient (by User Role)
								</Label>
								<Select
									name="userRoleSelect"
									placeholder="Select..."
									isClearable
									isSearchable
									onChange={(newValue) => {
										if (!!newValue) {
											setUserGroupInput(newValue.label, newValue.value);
										}
									}}
									options={roleList.map((role) => ({
										label: role.display_name,
										value: role.id,
									}))}
								/>
							</FormGroup>
							<span>
								<ul style={{ paddingLeft: "1%" }}>
									{userGroupList.map((userRole, index) => {
										return (
											<li key={index}>
												<div
													style={{
														margin: "0.2%",
														display: "flex",
														alignItems: "center",
														justifyContent: "space-between",
													}}>
													<span style={{ color: "blue" }}>
														{userRole.roleName}
													</span>
													<Button
														id={index}
														onClick={() => deleteUserGroupInput(index)}
														style={{ backgroundColor: "none" }}
														className="pe-7s-close-circle"
													/>
												</div>
											</li>
										);
									})}
								</ul>
							</span>

							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>
									Recipients (By Specific Users)
								</Label>
								{userList.map((user, index) => {
									return (
										<div
											key={index}
											style={{
												margin: "0.2%",
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
											}}>
											<Input
												type={"text"}
												style={{ width: "45%" }}
												value={user.userId}
												readOnly
											/>
											<Input
												type={"text"}
												style={{ width: "45%" }}
												value={user.username}
												readOnly
											/>
											<Button
												onClick={() => deleteUserList(index)}
												style={{ backgroundColor: "none" }}
												className="pe-7s-close-circle"
											/>
										</div>
									);
								})}
							</FormGroup>

							<FormGroup>
								<AsyncSelect
									cacheOptions
									defaultOptions
									loadOptions={promiseOptions}
									styles={{ input: (styles) => ({ ...styles, width: "100%" }) }}
									onChange={(newValue) => {
										if (!!newValue) {
											setUserIdText(newValue.value.id);
											setUsernameText(newValue.value.name);
										} else {
											setUserIdText("");
											setUsernameText("");
										}
									}}
									isClearable
								/>
							</FormGroup>
							<button
								type="button"
								className="btn btn-lg btn-block"
								style={{ border: "2px dotted #000" }}
								onClick={() => addUserList({ userIdText, usernameText })}>
								<div className="pe-lg pe-7s-plus"></div> Add Specific User
							</button>
							<br></br>
							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>Start Date</Label>
								<div
									style={{
										position: "relative",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<div style={{ width: "96%" }}>
										<DatePicker
											dateFormat="dd-MM-yyyy"
											showYearDropdown
											className="form-control"
											selected={startDate}
											placeholderText={"Select Start Date"}
											onChange={(val) => {
												setStartDate(val);
											}}
										/>
									</div>
									<div className="pe-2x pe-7s-date"></div>
								</div>
							</FormGroup>

							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>End Date</Label>
								<div
									style={{
										position: "relative",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<div style={{ width: "96%" }}>
										<DatePicker
											dateFormat="dd-MM-yyyy"
											showYearDropdown
											className="form-control"
											selected={endDate}
											placeholderText={"Select End Date"}
											onChange={(val) => {
												setEndDate(val);
											}}
										/>
									</div>
									<div className="pe-2x pe-7s-date"></div>
								</div>
							</FormGroup>

							<div style={{ display: "flex" }}>
								<FormGroup style={{ width: "50%", paddingRight: "2%" }}>
									<Label for="frequency" style={{ fontWeight: "bold" }}>
										Frequency
									</Label>
									<Input
										type="select"
										name="stateInputButton"
										id="stateInputButton"
										value={frequency}
										onChange={(e) => {
											setFrequency(e.target.value);
										}}>
										<option disabled value="">
											Select Frequency
										</option>
										<option>daily</option>
										<option>weekly</option>
										<option>monthly</option>
									</Input>
								</FormGroup>
								<FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
									<Label
										for="numberOfTimesButton"
										style={{ fontWeight: "bold" }}>
										Number of Times{" "}
									</Label>
									<Input
										type="select"
										name="numberOfTimesButton"
										id="numberOfTimesButton"
										value={numberOfTimes}
										onChange={(e) => {
											setNumberOfTimes(e.target.value);
										}}>
										<option disabled value="">
											Select Number of Times
										</option>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</Input>
								</FormGroup>
							</div>

							<FormGroup>
								<div
									style={{
										marginBottom: "1rem",
										display: "flex",
										justifyContent: "space-between",
									}}>
									<Label for="previewImageCN" style={{ fontWeight: "bold" }}>
										Pop-Up Image (CN){" "}
									</Label>
									<Button
										id="previewImageCN"
										className={`btn-icon btn-icon-only`}
										onClick={() => {}}>
										<i className="pe-7s-look btn-icon-wrapper primary"> </i>
									</Button>
								</div>
								<InputFile
									label="Upload Image"
									onLoadAsset={true}
									multipleInput={false}
									returnBase64={true}
									maxSizeInBytes={5242880} // 5MB in bytes
									setBase64Callback={setImageCNBase64}
									fileValue={imageCNBase64}
									onRemoveFile={() => {
										setImageCNBase64("")
										setDeleteAsset([
											... deleteAssetsList,
											imageCNBase64
										])
									}}></InputFile>
								<span> * File size should not exceed 5 MB</span>
							</FormGroup>

							<FormGroup>
								<div
									style={{
										marginBottom: "1rem",
										display: "flex",
										justifyContent: "space-between",
									}}>
									<Label for="previewImageEN" style={{ fontWeight: "bold" }}>
										Pop-Up Image (EN){" "}
									</Label>
									<Button
										id="previewImageEN"
										className={`btn-icon btn-icon-only`}
										onClick={() => {}}>
										<i className="pe-7s-look btn-icon-wrapper primary"> </i>
									</Button>
								</div>

								<InputFile
									label="Upload Image"
									onLoadAsset={true}
									multipleInput={false}
									returnBase64={true}
									maxSizeInBytes={5242880} // 5MB in bytes
									setBase64Callback={setImageENBase64}
									fileValue={imageENBase64}
									onRemoveFile={() => { 
										setImageENBase64("")
										setDeleteAsset([
											... deleteAssetsList,
											imageENBase64
										])
									}}></InputFile>
								<span> * File size should not exceed 5 MB</span>
							</FormGroup>

							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>Link URL</Label>
								<Input
									type={"text"}
									value={linkURL}
									placeholder={"Enter Link URL"}
									onChange={(e) => {
										setLinkURL(e.target.value);
									}}
								/>
							</FormGroup>

							<FormGroup>
								<div
									style={{ display: "flex", justifyContent: "space-between" }}>
									<Label for="remarks" style={{ fontWeight: "bold" }}>
										Remarks
									</Label>
									<text>{remarks.length}/2000 characters</text>
								</div>

								<Input
									type="textarea"
									placeholder="Enter Text Here"
									style={{
										height: "10rem",
										width: "100%",
										verticalAlign: "top",
									}}
									value={remarks}
									onChange={(e) => {
										setRemarks(e.target.value);
									}}
								/>
							</FormGroup>
						</>
					)}
				</CardBody>
			</Card>
			<div className="container-fluid">
				<div className="row justify-content-end">
					<div className="col-auto" style={{ padding: "6px" }}>
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleSubmit}>
							<i className="pe-lg pe-7s-paper-plane"></i> Publish
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
