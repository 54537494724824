import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import BillingDetailsAPI from "./action.js";
import { BillingDetailsForm } from "./Form.js";

const BillingDetailsWrapper = (props) => {
  const { id } = useParams();

  return <BillingDetails id={!!id ? id : null} {...props} />;
};
class BillingDetails extends Component {
  render = () => {
    const { id, getBilling } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Billing / <b>Billing Details</b>"></PageTitle>
                <BillingDetailsForm id={id} getBilling={getBilling} />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(BillingDetailsAPI)(BillingDetailsWrapper);
