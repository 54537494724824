"use client"

import React from "react"
import { Modal, ModalBody, Button } from "reactstrap"
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai"

// import Trans from "components/i18next"
import Trans from "components/Trans";

const SuspendModal = ({
  isOpen,
  title,
  positiveText,

  positiveAction,
  onClose
}) => {

  return(
    <Modal size="md" isOpen={isOpen}>
      <ModalBody className="e-classified-advertiser__suspend-modal">
        <AiOutlineInfoCircle style={{ width: '3.125rem', height: '3.125rem' }}/>
        <h4 className="mt-3">{title}</h4>
        {/* <p className="text-secondary">{<Trans langKey={ 'CONFIRM_ACTION' } />}</p> */}
        <div>
          {/* <Button size="lg" color="light" style={{ marginRight: '0.5rem' }} onClick={onClose}>
            <Trans langKey={ 'CANCEL' } />
          </Button> */}
          <Button size="lg" color="primary" onClick={positiveAction}>
            {/* <AiOutlineClose style={{ marginRight: '0.5rem' }} /> */}
            {positiveText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SuspendModal