function ViewOthers({ setIsEditing, ...props }) {
	return (
		<>
			<div className="d-flex">
				<h5 className="mb-3 e-classified-cont-header">
          Other Info
				</h5>
			</div>
			<div className="e-classified-profile-view-card">
				<h5 className="e-classified-cont-header">
          Do you have your own transportation?
				</h5>
				<p className="mb-4 text-black" style={{ fontSize: "0.875rem" }}>
					{ 
            props.jobSeekerProfile?.own_transportation ? 'Yes' : 'No'
          }
				</p>

				<h5 className="e-classified-cont-header">
          Special Remarks
				</h5>
				<p className="text-black" style={{ fontSize: "0.875rem" }}>
					{props.jobSeekerProfile?.about_me || "N/A"}
				</p>
			</div>
		</>
	);
}

export default ViewOthers;
