import InputFile from "components/Input/File";
import _ from 'lodash';
import Moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
	Button,
	Card,
	CardBody,
	FormFeedback,
	FormGroup,
	Input,
	Label
} from "reactstrap";
import { requestError } from "utils/requestHandler";

export function CreateBannerForm({ onSubmit, id, getBanner }) {
	const [title, setTitle] = useState("");
	const [location, setLocation] = useState("");
	const [startDate, setStartDate] = useState("");
	const [linkURL, setLinkURL] = useState("");
	const [remarks, setRemarks] = useState("");
	const [imageCNBase64, setImageCNBase64] = useState("");
	const [imageENBase64, setImageENBase64] = useState("");
	const [mobileImageCNBase64, setMobileImageCNBase64] = useState("");
	const [mobileImageENBase64, setMobileImageENBase64] = useState("");
	const [ deleteAssetsList, setDeleteAsset ] = useState( [] )
	// loading state
	const [loading, setLoading] = useState(false);

	// validation state variables
	const [titleError, setTitleError] = useState("");

	const validateForm = () => {
		// Reset all error states
		setTitleError("");

		let isValid = true;

		if (!title) {
			setTitleError("Title is required.");
			requestError("Title is required.");
			isValid = false;
		}

		return isValid;
	};
	
	const handleSubmit = () => {
		// Check form validation
		if (validateForm()) {
			// Call the onSubmit callback if the form is valid
			const dataToSubmit = {
				title,
				start_date: !!startDate ? Moment(startDate).format("YYYY-MM-DD") : null,
				popup_image_en_url: !imageENBase64 ? null : imageENBase64,
				popup_image_cn_url: !imageCNBase64 ? null : imageCNBase64,
				banner_image_mobile_en_url: !mobileImageENBase64
					? null
					: mobileImageENBase64,
				banner_image_mobile_cn_url: !mobileImageCNBase64
					? null
					: mobileImageCNBase64,
				link_url: linkURL,
				remarks,
				status: "active",
				type: "inhouse",
				location: !location ? null : location,
			};
			if (!!id) {
				dataToSubmit["id"] = id;
			}

			let tempDelete = _.cloneDeep( deleteAssetsList )
			_.filter( tempDelete, val => {
				return val.indexOf('base64') < 0
			})

			onSubmit(dataToSubmit, deleteAssetsList);
		}
	};

	const fillValues = (data) => {
		const {
			title,
			start_date,
			popup_image_en_url,
			popup_image_cn_url,
			banner_image_mobile_en_url,
			banner_image_mobile_cn_url,
			location,
			link_url,
			remarks,
		} = data;

		const startDateValue = !start_date ? "" : new Date(start_date);

		setTitle(title);
		setLocation(!location ? "" : location);
		setStartDate(startDateValue);
		setImageENBase64(!popup_image_en_url ? "" : popup_image_en_url);
		setImageCNBase64(!popup_image_cn_url ? "" : popup_image_cn_url);
		setMobileImageENBase64(
			!banner_image_mobile_en_url ? "" : banner_image_mobile_en_url
		);
		setMobileImageCNBase64(
			!banner_image_mobile_cn_url ? "" : banner_image_mobile_cn_url
		);
		setLinkURL(!link_url ? "" : link_url);
		setRemarks(!remarks ? "" : remarks);
	};

	const getBannerAsync = (querystring = "") => {
		return new Promise((resolve, reject) => {
			getBanner(querystring, resolve, reject);
		});
	};

	const fetchData = async (id) => {
		try {
			setLoading(true);
			const response = await getBannerAsync(`&id=${id}`);
			fillValues(response[0]);
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		// fetch data and fill form fields if id is provided, meaning the page is in edit mode
		if (id) {
			fetchData( id )
		}
	}, [id]);

	return (
		<>
			<Card className="main-card mb-3">
				<CardBody>
					<>
							{id && (
								<>
									<span style={{ fontWeight: "bold" }}>
										In House Banner ID: B00001
									</span>
									<br></br>
									<br></br>
								</>
							)}
							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>Title</Label>
								<Input
									type={"text"}
									placeholder={"Enter Banner Title"}
									value={title}
									onChange={(e) => {
										setTitle(e.target.value);
									}}
									invalid={!!titleError}
								/>
								<FormFeedback>{titleError}</FormFeedback>
							</FormGroup>

							<FormGroup>
								<Label style={{ fontWeight: "bold" }} for="Location">
									Location
								</Label>
								<Input
									type="select"
									name="locationButton"
									id="locationButton"
									placeholder="Select"
									onChange={(e) => setLocation(e.target.value)}
									value={location}>
									<option disabled value="">
										Select Location
									</option>
									<option value={"Jobmacha Homepage Banner"}>
										Homepage Banner
									</option>
									<option value={"Jobmacha Advertiser Page"}>
										Advertiser Page
									</option>
									<option value={"Jobmacha Pricing Page"}>Pricing Page</option>
								</Input>
							</FormGroup>

							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>Start Date</Label>
								<div
									style={{
										position: "relative",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<div style={{ width: "96%" }}>
										<DatePicker
											dateFormat="dd-MM-yyyy"
											showYearDropdown
											className="form-control"
											selected={startDate}
											placeholderText={"Select Start Date"}
											onChange={(val) => {
												setStartDate(val);
											}}
										/>
									</div>
									<div className="pe-2x pe-7s-date"></div>
								</div>
							</FormGroup>

							<FormGroup>
								<div
									style={{
										marginBottom: "1rem",
										display: "flex",
										justifyContent: "space-between",
									}}>
									<Label
										for="bannerImageWebsiteCN"
										style={{ fontWeight: "bold" }}>
										Banner Image (Website CN){" "}
									</Label>
									<Button
										id="bannerImageWebsiteCN"
										className={`btn-icon btn-icon-only`}
										onClick={() => {}}>
										<i className="pe-7s-look btn-icon-wrapper primary"> </i>
									</Button>
								</div>
								<InputFile
									label="Upload Image"
									onLoadAsset={true}
									multipleInput={false}
									returnBase64={true}
									maxSizeInBytes={5242880} // 5MB in bytes
									setBase64Callback={setImageCNBase64}
									fileValue={imageCNBase64}
									onRemoveFile={() => {
										setImageCNBase64("")
										setDeleteAsset([
											... deleteAssetsList,
											imageCNBase64
										])
									}}></InputFile>
								<span> * File size should not exceed 5 MB</span>
							</FormGroup>

							<FormGroup>
								<div
									style={{
										marginBottom: "1rem",
										display: "flex",
										justifyContent: "space-between",
									}}>
									<Label
										for="bannerImageWebsiteEN"
										style={{ fontWeight: "bold" }}>
										Banner Image (Website EN){" "}
									</Label>
									<Button
										id="bannerImageWebsiteEN"
										className={`btn-icon btn-icon-only`}
										onClick={() => {}}>
										<i className="pe-7s-look btn-icon-wrapper primary"> </i>
									</Button>
								</div>
								<InputFile
									label="Upload Image"
									onLoadAsset={true}
									multipleInput={false}
									returnBase64={true}
									maxSizeInBytes={5242880} // 5MB in bytes
									setBase64Callback={setImageENBase64}
									fileValue={imageENBase64}
									onRemoveFile={() => {
										setImageENBase64("")
										setDeleteAsset([
											... deleteAssetsList,
											imageENBase64
										])
									}}></InputFile>
								<span> * File size should not exceed 5 MB</span>
							</FormGroup>

							<FormGroup>
								<div
									style={{
										marginBottom: "1rem",
										display: "flex",
										justifyContent: "space-between",
									}}>
									<Label for="logo" style={{ fontWeight: "bold" }}>
										Banner Image (Mobile CN){" "}
									</Label>
									<Button
										id="bannerImageMobileCN"
										className={`btn-icon btn-icon-only`}
										onClick={() => {}}>
										<i className="pe-7s-look btn-icon-wrapper primary"> </i>
									</Button>
								</div>
								<InputFile
									label="Upload Image"
									onLoadAsset={true}
									multipleInput={false}
									returnBase64={true}
									maxSizeInBytes={5242880} // 5MB in bytes
									setBase64Callback={setMobileImageCNBase64}
									fileValue={mobileImageCNBase64}
									onRemoveFile={() => {
										setMobileImageCNBase64("")
										setDeleteAsset([
											... deleteAssetsList,
											mobileImageCNBase64
										])
									}}></InputFile>
								<span> * File size should not exceed 5 MB</span>
							</FormGroup>

							<FormGroup>
								<div
									style={{
										marginBottom: "1rem",
										display: "flex",
										justifyContent: "space-between",
									}}>
									<Label for="logo" style={{ fontWeight: "bold" }}>
										Banner Image (Mobile EN){" "}
									</Label>
									<Button
										id="bannerImageMobileEN"
										className={`btn-icon btn-icon-only`}
										onClick={() => {}}>
										<i className="pe-7s-look btn-icon-wrapper primary"> </i>
									</Button>
								</div>
								<InputFile
									label="Upload Image"
									onLoadAsset={true}
									multipleInput={false}
									returnBase64={true}
									maxSizeInBytes={5242880} // 5MB in bytes
									setBase64Callback={setMobileImageENBase64}
									fileValue={mobileImageENBase64}
									onRemoveFile={() => {
										setMobileImageENBase64("")
										setDeleteAsset([
											... deleteAssetsList,
											mobileImageENBase64
										])
									}}
									></InputFile>
								<span> * File size should not exceed 5 MB</span>
							</FormGroup>

							<FormGroup>
								<Label style={{ fontWeight: "bold" }}>Link URL</Label>
								<Input
									type={"text"}
									value={linkURL}
									placeholder={"Enter Link URL"}
									onChange={(e) => {
										setLinkURL(e.target.value);
									}}
								/>
							</FormGroup>

							<FormGroup>
								<div
									style={{ display: "flex", justifyContent: "space-between" }}>
									<Label for="remarks" style={{ fontWeight: "bold" }}>
										Remarks
									</Label>
									<text>{remarks.length}/2000 characters</text>
								</div>

								<Input
									type="textarea"
									placeholder="Enter Text Here"
									style={{
										height: "10rem",
										width: "100%",
										verticalAlign: "top",
									}}
									value={remarks}
									onChange={(e) => {
										setRemarks(e.target.value);
									}}
								/>
							</FormGroup>
						</>
				</CardBody>
			</Card>
			<div className="container-fluid">
				<div className="row justify-content-end">
					<div className="col-auto" style={{ padding: "6px" }}>
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleSubmit}>
							<i className="pe-lg pe-7s-paper-plane"></i> Publish
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
