import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import CreatePopupAPI from "./actions/index.js";
import ImageHOC from './actions/image.js'
import { CreatePopupForm } from "./Form.js";

const CreatePopupPageWrapper = (props) => {
  const { id } = useParams();

  return <CreatePopupPage id={!!id ? id : null} {...props} />;
};

class CreatePopupPage extends Component {
  componentDidMount = () => {
    this.props.getRole();
  };

  render = () => {
    const {
      roleList,
      createNewBanner,
      searchUser,
      id,
      getBanner,
      updateBanner,
    } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Manage Banner/ <b>Manage Pop-Up Banner</b>"></PageTitle>
                <h5>{!!id ? "Edit" : "Create New"} Pop-Up Banner</h5> <br></br>
                <CreatePopupForm
                  onSubmit={( val, deleteList) => {
                    !id 
                      ? createNewBanner( val)
                      : updateBanner( val, deleteList)
                  }}
                  roleList={roleList}
                  searchUser={searchUser}
                  id={id}
                  getBanner={getBanner}
                />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(ImageHOC, CreatePopupAPI)(CreatePopupPageWrapper);
