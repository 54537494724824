import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import SampleHOC from "./action.js";
import CreateNewInboxForm from "./Form.js";

class CreateInboxForm extends Component {
  componentDidMount = () => this.props.getRole();

  render = () => {
    const { roleList, createNewInbox, searchUser, showPreviewModal, selectedInbox, onChangeHOC } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Manage Inbox / <b>Create New Message</b>"></PageTitle>
                <h5>Create New Message</h5> <br></br>
                <CreateNewInboxForm
                  onSubmit={createNewInbox}
                  roleList={roleList}
                  searchUser={searchUser}
                  showPreviewModal={showPreviewModal}
                  selectedInbox={selectedInbox}
                  onChangeHOC={onChangeHOC}
                />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(SampleHOC)(CreateInboxForm);
