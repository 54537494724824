import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      candidateList: [],
      candidatePages: 1,
      candidateCount: 0,
      showEditSampleModal: false,
      showCreateSampleModal: false,
      showFilterModal: false,
      showConfirmModal: false,
      showRecommendModal: false,
      selectedRows: [],
      selectedCandidates: [],
      statesList: [],

      showViewModal: false,
      selectedCandiate: null,
			jobSeekerSpec: [],
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getCandidate = (query = "") =>
      Get(
        `/list-recommended-profiles?limit=10&is_asking_for_job=true${
          !!query ? query : ""
        }`,
        this.getCandidateSuccess,
        this.getCandidateError,
        this.load
      );
    getCandidateSuccess = (payload) => {
      this.setState({
        candidateList: payload.data,
        candidateCount: payload.count,
      });
    };
    getCandidateError = (error) => requestError(error);

    recommendCandidate = (dataToSubmit) => {
      Post(
        `/shortlist-candidates-for-jobad`,
        dataToSubmit,
        this.recommendCandidateSuccess,
        this.recommendCandidateError,
        this.load
      );
    };
    recommendCandidateSuccess = (payload) => {
      requestSuccess(payload.message);
      this.setState({
        showRecommendModal: false,
      });
    };
    recommendCandidateError = (error) => requestError(error);

    applyJob = async (dataToSubmit) => {
			await Post(
				`/job-applications`,
				dataToSubmit,
				// Using the fields directly from dataToSubmit ensures they are present
				(response) => {},
				// (response) => this.applyJobSuccess(response),
				this.applyJobError,
				this.load,
				true
			);
		};
		applyJobSuccess = (response) => {
      requestSuccess(response.message);
    };
    applyJobError = (error) => requestError(error);

    getState = () =>
      Get(
        `/countries/136/states`,
        this.getStateSuccess,
        this.getStateError,
        this.load
      );
    getStateSuccess = (payload) => {
      this.setState({
        statesList: payload.data,
      });
    };
    getStateError = (error) => requestError(error);

    getSelectedJobSeekerProfile = (userID, mode) => {
			Get(
				`/jobseeker-profiles?user_id=${userID}`,
				(payload) => {
					let tempSpecs = (payload?.data?.[0]?.specializations || []).map(
						(specChild) => ({
							...specChild,
							id: specChild.specialization_id,
							jobSeekerSpecID: specChild.id,
						})
					);

					this.setState({
						jobSeekerSpec: tempSpecs || [],
						selectedCandiate: payload?.data?.[0],
						...(mode === "view"
							? {
									showViewModal: true,
							  }
							: { showEditModal: true }),
					});
          console.log('###', {
						jobSeekerSpec: tempSpecs || [],
						selectedCandiate: payload?.data?.[0],
						...(mode === "view"
							? {
									showViewModal: true,
							  }
							: { showEditModal: true }),
					})
				},
				(error) => requestError(error.message),
				this.load
			);
		};

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          candidateList={this.state.candidateList}
          candidatePages={this.state.candidatePages}
          candidateCount={this.state.candidateCount}
          loading={this.state.loading}
          getCandidate={this.getCandidate}
          onChangeHOC={this.onChangeHOC}
          showFilterModal={this.state.showFilterModal}
          showRecommendModal={this.state.showRecommendModal}
          selectedRows={this.state.selectedRows}
          selectedCandidates={this.state.selectedCandidates}
          recommendCandidate={this.recommendCandidate}
          getSelectedJobSeekerProfile={this.getSelectedJobSeekerProfile}
          applyJob={this.applyJob}
          getState={this.getState}
          statesList={this.state.statesList}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
