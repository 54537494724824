import React, { useState, useEffect } from "react";
import {
  Label,
  FormGroup,
  Input,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
import InputFile from "components/Input/File";
import ImageField from "components/Modal/ImageField";
import Form from "components/Form";
import { connect } from "react-redux";
import ECFormInput from "components/Input/FormInput"
import PreviewModal from "../PreviewAdvertiser";

function CreateAdvertiserForm({ user, onSubmit, natureList, countries, states, getStates, onChangeHOC }) {
  const [industriesOptions, setIndustriesOptions] = useState([]);

  const [logoBase64, setLogoBase64] = useState("");
  const [picName, setPicName] = useState("");
  const [picContactNumberPrefix, setPicContactNumberPrefix] = useState("");
  const [picContactNumber, setPicContactNumber] = useState("");
  const [picWhatsappNumber, setPicWhatsappNumber] = useState("");
  const [picEmailAddress, setPicEmailAddress] = useState("");
  const [nature, setNature] = useState("");
  const [websiteAddress, setWebsiteAddress] = useState("");
  const [socialMediaURL, setSocialMediaURL] = useState("");
  const [companyAboutUs, setCompanyAboutUs] = useState("");
  const [companyPostcode, setCompanyPostcode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [stateId, setStateId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [photos, setPhotos] = useState([]);

  const [isSameAddress, setIsSameAddress] = useState(false);

  const [billingName, setBillingName] = useState("");
  const [billingEmailAddress, setBillingEmailAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingPostcode, setBillingPostcode] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCountry, setBillingCountry] = useState("");

  // validation state variables
  const [picNameError, setPicNameError] = useState("");
  const [picContactNumberError, setPicContactNumberError] = useState("");
  const [picEmailAddressError, setPicEmailAddressError] = useState("");
  const [natureError, setNatureError] = useState("");
  const [companyAboutUsError, setCompanyAboutUsError] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [billingNameError, setBillingNameError] = useState("");
  const [billingEmailAddressError, setBillingEmailAddressError] = useState("");
  const [billingAddressError, setBillingAddressError] = useState("");
  const [billingPostcodeError, setBillingPostcodeError] = useState("");
  const [billingCityError, setBillingCityError] = useState("");
  const [billingStateError, setBillingStateError] = useState("");
  const [billingCountryError, setBillingCountryError] = useState("");

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const togglePreviewModal = () => setShowPreviewModal(!showPreviewModal);

  const validateForm = () => {
    // Reset all error states
    setPicNameError("");
    setPicContactNumberError("");
    setPicEmailAddressError("");
    setNatureError("");
    setCompanyAboutUsError("");
    setCompanyAddressError("");
    setPostcodeError("");
    setCityError("");
    setStateError("");
    setCountryError("");
    setBillingNameError("");
    setBillingEmailAddressError("");
    setBillingAddressError("");
    setBillingPostcodeError("");
    setBillingCityError("");
    setBillingStateError("");
    setBillingCountryError("");

    let isValid = true;

    if (!picName) {
      setPicNameError("Person in Charge Name is required.");
      isValid = false;
    }

    if (!picContactNumber) {
      setPicContactNumberError("Person in Charge Contact Number is required.");
      isValid = false;
    }

    if (!picEmailAddress) {
      setPicEmailAddressError("Person in Charge Email Address is required.");
      isValid = false;
    }

    if (!nature) {
      setNatureError("Business Nature is required.");
      isValid = false;
    }

    if (!companyAboutUs) {
      setCompanyAboutUsError("Company About Us is required.");
      isValid = false;
    }

    if (!companyAddress) {
      setCompanyAddressError("Company Address is required.");
      isValid = false;
    }

    if (!companyPostcode) {
      setPostcodeError("Postcode is required.");
      isValid = false;
    }

    if (!companyCity) {
      setCityError("City is required.");
      isValid = false;
    }

    if (!stateId) {
      setStateError("State is required.");
      isValid = false;
    }

    if (!countryId) {
      setCountryError("Country is required.");
      isValid = false;
    }

    if (!billingName) {
      setBillingNameError("Billing Name is required.");
      isValid = false;
    }

    if (!billingEmailAddress) {
      setBillingEmailAddressError("Billing Email Address is required.");
      isValid = false;
    }

    if (!billingAddress) {
      setBillingAddressError("Billing Address is required.");
      isValid = false;
    }

    if (!billingPostcode) {
      setBillingPostcodeError("Billing Postcode is required.");
      isValid = false;
    }

    if (!billingCity) {
      setBillingCityError("Billing City is required.");
      isValid = false;
    }

    if (!billingState) {
      setBillingStateError("Billing State is required.");
      isValid = false;
    }

    if (!billingCountry) {
      setBillingCountryError("Billing Country is required.");
      isValid = false;
    }

    return isValid;
  };

  const formDataForPreview = {
    type: "individual",
    name: picName,
    registration_number: "",
    contact_number: picContactNumber,
    company_contact: picContactNumber,
    logo_url: logoBase64,
    advertiser_address: companyAddress,
    postcode: companyPostcode,
    city: companyCity,
    billing_name: billingName,
    billing_email: billingEmailAddress,
    billing_address: billingAddress,
    billing_postcode: billingPostcode,
    billing_city: billingCity,
    billing_state: billingState,
    billing_country: billingCountry,
    description: companyAboutUs,
    website_url: websiteAddress,
    agent_id: user.id,
    status: "active",
    email: picEmailAddress,
    social_media: socialMediaURL,
    slug: "",
    whatsapp_number: picWhatsappNumber,
    state: stateId,
    country: countryId,
    person_in_charge_name: picName,
    person_in_charge_contact: picContactNumber,
    person_in_charge_whatsapp: picWhatsappNumber,
    person_in_charge_email: picEmailAddress,
    industry: natureList.filter((industry) => industry.id === parseInt(nature))[0]
    // You might want to add or adjust fields based on what PreviewModal expects
  };

  const handleSubmit = () => {
    // Check form validation
    if (validateForm()) {
      // Call the onSubmit callback if the form is valid
      onSubmit({
        type: "individual",
        name: picName,
        registration_number: "",
        contact_number: picContactNumber,
        company_contact: picContactNumber,
        logo_url: logoBase64,
        advertiser_address: companyAddress,
        postcode: companyPostcode,
        city: companyCity,
        billing_name: billingName,
        billing_email: billingEmailAddress,
        billing_address: billingAddress,
        billing_postcode: billingPostcode,
        billing_city: billingCity,
        billing_state: billingState,
        billing_country: billingCountry,
        description: companyAboutUs,
        website_url: websiteAddress,
        agent_id: user.id,
        status: "active",
        email: picEmailAddress,
        social_media: socialMediaURL,
        slug: "",
        whatsapp_number: picWhatsappNumber,
        state: stateId,
        country: countryId,
        person_in_charge_name: picName,
        person_in_charge_contact: picContactNumber,
        person_in_charge_whatsapp: picWhatsappNumber,
        person_in_charge_email: picEmailAddress,
        industry_id: parseInt(nature),
      });
    }
  };

  const handleRemovePhoto = (photo, photoIndex) => {
    // Create a copy of the current photos array
    const photosCopy = [...photos];
    // Remove the element at the specified index
    photosCopy.splice(photoIndex, 1);
    // Update the state with the modified array
    setPhotos(photosCopy);
  };

  const handleCountryChange = (e, isBilling = false) => {
    const selectedCountryId = e.target.value;
    // Check if the change is for billingCountry or countryId
    if (isBilling) {
      setBillingCountry(selectedCountryId);
    } else {
      setCountryId(selectedCountryId);
    }
    // Assuming you want to fetch states for both company and billing addresses
    getStates(selectedCountryId);
  }

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsSameAddress(isChecked);
    if (isChecked) {
      setBillingAddress(companyAddress);
      setBillingPostcode(companyPostcode);
      setBillingCity(companyCity);
      setBillingState(stateId);
      setBillingCountry(countryId);
    } else {
      setBillingAddress('');
      setBillingPostcode('');
      setBillingCity('');
      setBillingState('');
      setBillingCountry('');
    }
  };

  useEffect(() => {
    const industries = natureList.map((industry) => ({value: industry.id, label: industry.name.en}));
    setIndustriesOptions(industries);
  }, [natureList]);

  useEffect(() => {
    if (isSameAddress) {
      setBillingAddress(companyAddress);
      setBillingPostcode(companyPostcode);
      setBillingCity(companyCity);
      setBillingState(stateId);
      setBillingCountry(countryId);
    }
  }, [companyAddress, companyPostcode, companyCity, stateId, countryId, isSameAddress]);  

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <span style={{ fontWeight: "bold" }}>Advertiser ID: 94920</span>
          <br></br>
          <br></br>
          <Label style={{ fontWeight: "bold" }} for="nature">
            Upload Logo
          </Label>
          <FormGroup>
            <InputFile
              onLoadAsset={true}
              multipleInput={false}
              returnBase64={true}
              maxSizeInBytes={5242880} // 5MB in bytes
              setBase64Callback={setLogoBase64}
              fileValue={logoBase64}
              onRemoveFile={() => setLogoBase64("")}
            ></InputFile>
            <span> *PNG, JPG or JPEG formats only</span>
            <br></br>
            <span> *Max Size: 5MB</span>
          </FormGroup>

          <FormGroup>
            <Label for="picName" style={{ fontWeight: "bold" }}>
              Person in Charge Name <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              type={"text"}
              placeholder={"Enter PIC Name"}
              value={picName}
              onChange={(e) => {
                setPicName(e.target.value);
              }}
              invalid={!!picNameError}
            />
            <FormFeedback>{picNameError}</FormFeedback>
          </FormGroup>
          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="picContactNumber" style={{ fontWeight: "bold" }}>
                Person in Charge Contact Number{" "}
                <span className="text-danger">*</span>{" "}
              </Label>
              <div>
                {/* <Input
                  type="select"
                  name="picContactNumberPrefixButton"
                  id="picContactNumberPrefixButton"
                  value="+60"
                  onChange={(e) => setPicContactNumberPrefix(e.target.value)}
                  style={{ width: "25%" }}
                >
                  <option>+60</option>
                  <option>+61</option>
                </Input>
                <Input
                  type={"text"}
                  placeholder={"Eg: 601234567890"}
                  onChange={(e) => {
                    setPicContactNumber(e.target.value);
                  }}
                  invalid={!!picContactNumberError}
                /> */}
                <ECFormInput
                  context="contact_number"
                  // label={
                  //   <Trans context={"COMPANY"} langKey={"COMPANY_CONTACT_NUMBER"} />
                  // }
                  required={true}
                  countryCode={true}
                  // value={formData.contact_number}
                  placeholder="Eg: 11-23456789"
                  onChangeData={(value) => {
                    setPicContactNumber(value)
                  }}
                  // errors={errors}
                />
                <FormFeedback>{picContactNumberError}</FormFeedback>
              </div>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="picWhatsappNumber" style={{ fontWeight: "bold" }}>
                Person in Charge WhatsApp Number{" "}
              </Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6
                  style={{
                    fontWeight: "bold",
                    paddingRight: "2%",
                    verticalAlign: "middle",
                  }}
                >
                  wa.me/
                </h6>
                <Input
                  type={"text"}
                  placeholder={"Eg: 601234567890"}
                  value={picWhatsappNumber}
                  onChange={(e) => {
                    setPicWhatsappNumber(e.target.value);
                  }}
                />
              </div>
            </FormGroup>
          </div>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>
              Person in Charge Email Address
              <span className="text-danger">*</span>
            </Label>
            <Input
              type={"text"}
              placeholder={"Enter PIC Email Address"}
              value={picEmailAddress}
              onChange={(e) => {
                setPicEmailAddress(e.target.value);
              }}
              invalid={!!picEmailAddressError}
            />
            <FormFeedback>{picEmailAddressError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }} for="nature">
              Industry <span className="text-danger">*</span>
            </Label>
            <Input
              type="select"
              name="natureInputButton"
              id="natureInputButton"
              value={nature}
              onChange={(e) => setNature(e.target.value)}
              invalid={!!natureError}
            >
              <option disabled value="">
                Select Industry
              </option>
              {industriesOptions.map((nature) => (
                <option value={nature.value}>{nature.label}</option>
              ))}
            </Input>
            <FormFeedback>{natureError}</FormFeedback>
          </FormGroup>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="websiteAddress" style={{ fontWeight: "bold" }}>
                Website URL{" "}
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter Website URL"}
                value={websiteAddress}
                onChange={(e) => {
                  setWebsiteAddress(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="socialMediaURL" style={{ fontWeight: "bold" }}>
                Social Media URL{" "}
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter Social Media URL"}
                value={socialMediaURL}
                onChange={(e) => {
                  setSocialMediaURL(e.target.value);
                }}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Label for="companyAboutUs" style={{ fontWeight: "bold" }}>
                About Me <span className="text-danger">*</span>
              </Label>
              <text>{companyAboutUs.length}/2000 characters</text>
            </div>

            <Input
              type="textarea"
              placeholder="Describe Yourself"
              style={{ height: "10rem", width: "100%", verticalAlign: "top" }}
              value={companyAboutUs}
              onChange={(e) => {
                setCompanyAboutUs(e.target.value);
              }}
              invalid={!!companyAboutUsError}
            />
            <FormFeedback>{companyAboutUsError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>
              Address<span className="text-danger">*</span>
            </Label>
            <Input
              type={"text"}
              placeholder={"Enter Company Address"}
              value={companyAddress}
              onChange={(e) => {
                setCompanyAddress(e.target.value);
              }}
              invalid={!!companyAddressError}
            />
            <FormFeedback>{companyAddressError}</FormFeedback>
          </FormGroup>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="postcode" style={{ fontWeight: "bold" }}>
                Postcode <span className="text-danger">*</span>
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter your postcode"}
                value={companyPostcode}
                onChange={(e) => {
                  setCompanyPostcode(e.target.value);
                }}
                invalid={!!postcodeError}
              />
              <FormFeedback>{postcodeError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="city" style={{ fontWeight: "bold" }}>
                City <span className="text-danger">*</span>
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter your city"}
                value={companyCity}
                onChange={(e) => {
                  setCompanyCity(e.target.value);
                }}
                invalid={!!cityError}
              />
              <FormFeedback>{cityError}</FormFeedback>
            </FormGroup>
          </div>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="state" style={{ fontWeight: "bold" }}>
                State <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="stateInputButton"
                id="stateInputButton"
                value={stateId}
                onChange={(e) => {
                  setStateId(e.target.value);
                }}
                invalid={!!stateError}
              >
                <option disabled value="">
                  Select State
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state.label}>
                    {state.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{stateError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="country" style={{ fontWeight: "bold" }}>
                Country <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="countryInputButton"
                id="stateInputButton"
                value={countryId}
                onChange={(e) => handleCountryChange(e)}
                invalid={!!countryError}
              >
                <option disabled value="">
                  Select Country
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{countryError}</FormFeedback>
            </FormGroup>
          </div>

          <FormGroup>
            <Label for="logo" style={{ fontWeight: "bold" }}>
              Upload Photo{" "}
            </Label>{" "}
            <br></br>
            <span>
              {" "}
              It's recommended to upload photos related to the working environment.<br/>
This will help candidates know you better!
<br/><br/>
*Size: 1280px x 720px<br/>
*Ratio: 16:9<br/>
*Max Size: 2MB
            </span>
            <br></br>
            <ImageField
              returnBase64={true}
              imageData={photos}
              onAddFile={(file) => setPhotos([...photos, { url: file }])}
              onRemove={handleRemovePhoto}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="main-card mb-3">
        <CardBody>
          <span style={{ fontWeight: "bold" }}>Billing Info</span>
          <br></br>
          <br></br>
          <FormGroup>
            <Label for="billingName" style={{ fontWeight: "bold" }}>
              Billing Name <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              type={"text"}
              placeholder={"Enter Billing Name"}
              value={billingName}
              onChange={(e) => {
                setBillingName(e.target.value);
              }}
              invalid={!!billingNameError}
            />
            <FormFeedback>{billingNameError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="billingEmailAddress" style={{ fontWeight: "bold" }}>
              Email Address <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              type={"text"}
              placeholder={"Enter Email Address"}
              value={billingEmailAddress}
              onChange={(e) => {
                setBillingEmailAddress(e.target.value);
              }}
              invalid={!!billingEmailAddressError}
            />
            <FormFeedback>{billingEmailAddressError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="address" style={{ fontWeight: "bold" }}>
              Address <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              type={"text"}
              placeholder={"Enter Address"}
              value={billingAddress}
              onChange={(e) => {
                setBillingAddress(e.target.value);
              }}
              invalid={!!billingAddressError}
            />
            <FormFeedback>{billingAddressError}</FormFeedback>
          </FormGroup>

          <FormGroup check>
            <Input 
              type="checkbox"
              checked={isSameAddress}
              onChange={handleCheckboxChange}
            />
            <Label check>Same as Company Address</Label>
          </FormGroup>
          <br></br>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="BillingPostcode" style={{ fontWeight: "bold" }}>
                Postcode <span className="text-danger">*</span>
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter your postcode"}
                value={billingPostcode}
                onChange={(e) => {
                  setBillingPostcode(e.target.value);
                }}
                invalid={!!billingPostcodeError}
              />
              <FormFeedback>{billingPostcodeError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="city" style={{ fontWeight: "bold" }}>
                City <span className="text-danger">*</span>
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter your city"}
                value={billingCity}
                onChange={(e) => {
                  setBillingCity(e.target.value);
                }}
                invalid={!!billingCityError}
              />
              <FormFeedback>{billingCityError}</FormFeedback>
            </FormGroup>
          </div>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="state" style={{ fontWeight: "bold" }}>
                State <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="stateInputButton"
                id="stateInputButton"
                value={billingState}
                onChange={(e) => {
                  setBillingState(e.target.value);
                }}
                invalid={!!billingStateError}
              >
                <option disabled value="">
                  Select State
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state.label}>
                    {state.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{billingStateError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="country" style={{ fontWeight: "bold" }}>
                Country <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="countryInputButton"
                id="stateInputButton"
                value={billingCountry}
                onChange={(e) => handleCountryChange(e, true)}
                invalid={!!billingCountryError}
              >
                <option disabled value="">
                  Select Country
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{billingCountryError}</FormFeedback>
            </FormGroup>
          </div>

          {/* <text>Agent Name: Jane Doe</text> */}
        </CardBody>
      </Card>
      <div className="container-fluid">
        <div className="row justify-content-end">
          <div className="col-auto" style={{ padding: "6px" }}>
            <button 
              type="button" 
              className="btn btn-outline-primary"
              onClick={togglePreviewModal}
            >
              Preview
            </button>
          </div>
          <div className="col-auto" style={{ padding: "6px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {showPreviewModal && (
        <PreviewModal
          showPreviewModal={showPreviewModal}
          selectedCompany={formDataForPreview}
          onChangeCompaniesHOC={(key, val) => setShowPreviewModal(val)}
          // Add any other props your PreviewModal needs
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.ProfileReducer.profile,
});

export default connect(mapStateToProps)(CreateAdvertiserForm);
