
import SavedCard from "./SavedCard";

function BlogListing({ blogPosts, selectedTab }) {
	return (
		<>
			<div className="row">
				{blogPosts &&
					blogPosts.map((item, index) => (
						<>
							{(!selectedTab || item.slug_category_id === selectedTab) && (
								<div key={`blog_${index}`} className="col-lg-6 col-md-12">
									<SavedCard item={item} deleteBlog={() => {}} />
								</div>
							)}
						</>
					))}
			</div>
		</>
	);
}

export default BlogListing;
