import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [invoiceDate, setInvoiceDate] = useState(null);
	const [expiredDate, setExpiredDate] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [postID, setPostID] = useState("");
	const [agentName, setAgentName] = useState("");
	const [priceAfterTax, setPriceAfterTax] = useState("");
	const [orderDesc, setOrderDesc] = useState("");
	const [agentLeadID, setAgentLeadID] = useState(null);
	const [agentID, setAgentID] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");

	const handleClear = () => {
		setInvoiceDate("");
		setOrderDesc("");
		setExpiredDate("");
		setAdvertiser("");
		setInvoiceNumber("");
		setPostID("");
		setAgentName("");
		setPriceAfterTax("");
		setAgentLeadID("");
		setAgentID("");
		setAgentLeadName("");
	};

	const generateQuery = () => {
		let queryString = "";

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceDate">Transaction Invoice Date </Label>
								<Input
									placeholder="Enter Transaction Invoice Date"
									name="invoiceDate"
									id="invoiceDate"
									value={invoiceDate}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="expiredDate">Forfeit Date </Label>
								<Input
									placeholder="Enter Forfeit Date"
									name="expiredDate"
									id="expiredDate"
									value={expiredDate}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="orderDesc">Order Desc </Label>
								<Input
									placeholder="Enter Order Desc"
									name="orderDesc"
									id="orderDesc"
									value={orderDesc}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceNumber">Invoice No. </Label>
								<Input
									placeholder="Enter Invoice No."
									name="invoiceNumber"
									id="invoiceNumber"
									value={invoiceNumber}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>

						<Col lg={3}>
							<FormGroup>
								<Label for="priceAfterTax">Price After Tax </Label>
								<Input
									placeholder="Enter Price After Tax"
									name="priceAfterTax"
									id="priceAfterTax"
									value={priceAfterTax}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="postID">Post ID </Label>
								<Input
									placeholder="Enter Post ID"
									name="postID"
									id="postID"
									value={postID}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>

						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>

						<Col lg={3}>
							<FormGroup>
								<Label for="agentID">Agent ID </Label>
								<Input
									placeholder="Enter Agent ID"
									name="agentID"
									id="agentID"
									value={agentID}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentName"
									value={agentName}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadID">Agent Lead ID </Label>
								<Input
									placeholder="Enter Agent Lead ID"
									name="agentLeadID"
									id="agentLeadID"
									value={agentLeadID}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Agent Lead Name </Label>
								<Input
									placeholder="Enter Agent Lead Name"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadName}
									onChange={(e) => {}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
