import React, { Component, useEffect } from "react";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import {
	Col,
	Row,
	Card,
	CardBody,
	Button,
	UncontrolledTooltip,
	InputGroup,
	Input,
} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import DatePicker from "react-datepicker";

// import PreviewJob from "components/Modal/PreviewJob";
import PreviewJob from "./PreviewJob";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import ButtonContent from "components/ButtonContent";
import FilterForm from "./FilterForm";
import UpgradeForm from "./UpgradeForm";
import PostAPI from "./actions";
import moment from "moment";

import "./index.scss";
import { CheckUserRolesPermissons } from "actions/roles";

class Post extends Component {
	constructor(props){
		super(props)
		this.props.getSpecializations()
	}

	state = {
		selectedRowData: null,
		filterQuery: "",
		page: 0,
	};

	renderSampleTable = () => {
		const { postList, postPages, onLoadPost, getPost, selectedPostType, updateOrderPlan } =
			this.props;

		const { page, filterQuery } = this.state;

		return (
			<CustomTable
				collapseOnDataChange={false}
				data={postList}
				pages={postPages}
				page={page}
				onPageChange={(pageIndex) => this.setState({ page: pageIndex })}
				loading={onLoadPost}
				manual
				columnsContent={[
					{
						Header: "JobMacha ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.platform_uuid}</>,
					},
					{
						Header: "EC ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.old_system_data?.id ?? "-"}</>,
					},
					{
						Header: "Job Title",
						accessorKey: "job_title",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.job_title.en}</>,
					},
					{
						Header: "Advertiser Name",
						accessorKey: "company",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.company.name}</>,
					},
					{
						Header: "Agent Name",
						accessorKey: "person_in_charge_name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.company_agent?.name}</>,
					},
					{
						Header: "Specialization",
						accessorKey: "specialization",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.specialization?.name?.en ?? "-"}</>,
					},
					{
						Header: "Last Modified Date",
						accessorKey: "updated_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.updated_at).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
					{
						Header: "Published Date",
						accessorKey: "published_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.published_date ? moment(val.published_date).format("DD/MM/YYYY hh:mm A") : "-"}</>
						),
					},
					{
						Header: "View Count",
						accessorKey: "views",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => <>{val.views}</>,
					},
					{
						Header: "Job Applied Count",
						accessorKey: "job_application_count",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => <>{val.job_application_count}</>,
					},
				]}
				menuContent={
					![3, 4, 5, 6].includes(CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id)
						? [
								{
									menuID: "EditPost",
									menuTooltip: "Edit",
									onClick: (val) =>
										this.props.history.push(
											`/post/update-post?slug=${val.slug}`
										),
								},
								{
									menuID: "PreviewPost",
									menuTooltip: "Preview",
									onClick: (val) => this.props.getSelectedPost(val.slug),
								},
                                ...(selectedPostType !== "Disabled" ? [
                                    {
                                        menuID: "UpgradeInbox",
                                        menuTooltip: "Upgrade",
                                        onClick: (val) => {
                                            this.setState({ selectedRowData: val });
                                            this.props.onChangeHOC("showUpgradeModal", true);
                                        },
                                    },
                                    {
                                        menuID: "SuspendInbox",
                                        menuTooltip: "Suspend",
                                        onClick: (val) => {
                                            this.setState({ selectedRowData: val });
                                            this.props.onChangeHOC("showConfirmModal", true);
                                        },
                                    },
                                    {
                                        menuID: "DisableInbox",
                                        menuTooltip: "Disable",
                                        onClick: (val) => {
                                            this.setState({ selectedRowData: val });
                                            this.props.onChangeHOC("showDisableModal", true);
                                        },
                                    },
                                    {
                                        menuID: "DuplicateInbox",
                                        menuTooltip: "Repeat Order",
                                        onClick: (val) => {
                                            this.setState({ selectedRowData: val });
                                            this.props.onChangeHOC("showDuplicateModal", true);
                                        },
                                    },
                                ] : [
                                    {
                                        menuID: "EnableInbox",
                                        menuTooltip: "Enable",
                                        onClick: (val) => {
                                            this.setState({ selectedRowData: val });
                                            this.props.onChangeHOC("showEnableModal", true);
                                        },
                                    },
                                ])
						  ]
						: [
								{
									menuID: "PreviewPost",
									menuTooltip: "Preview",
									onClick: (val) => this.props.getSelectedPost(val.slug),
								},
						  ]
				}
				onFetchData={(state) => {
					getPost(
						`${state.page > 0 ? `&offset=${state.page * 10}` : ""}${
							!!filterQuery ? filterQuery : ""
						}${this.getStatus(selectedPostType)}`
					);
				}}
				SubComponent={(row) => {
					const handleCopyClick = (inputId) => {
						const inputElement = document.getElementById(inputId);

						if (inputElement) {
							if (inputElement.value.trim() !== "") {
								inputElement.select();
								document.execCommand("copy");
								// Remove the text selection after copying
								window.getSelection().removeAllRanges();
							} else {
								if (navigator.clipboard) {
									// Use the Clipboard API to set an empty string
									navigator.clipboard
										.writeText("")
										.then(() => {
											console.log("Clipboard cleared");
										})
										.catch((err) => {
											console.error("Unable to clear clipboard: ", err);
										});
								} else {
									// Fallback for browsers that don't support the Clipboard API
									console.log("Clipboard API not supported");
								}
							}
						}
					};

					console.log('###', row)

					return (
						<div className="subrow">


							<div className="e-classified-advertiser__manage-post-expand-cont">
        { row.original.order_plans?.map((item, index) => {
          // skip showing editing plan
          if(item.plan_id == 2) {
            return;
          }
          
          // let startDate = item.start_date
          //   ? ( new Date( item.start_date ) > new Date() ? `From ${item.start_date} ` : '' )
          //   : ''
		  let region = item.regions?.map(e => e.name.en).join(', ')
		  let extraTitle = region?.length ? ` (${region})` : ''

          return (
            <div key={index}>
              <div className="each-item">
                <b>{item.plan_name.en + extraTitle}</b>
                { item.start_at && (
                  <span>Valid { item.start_at ? moment(item.start_at).format('DD/MM/YYYY') : '-' } Until { item.end_at ? moment(item.end_at).format('DD/MM/YYYY') : '-'}</span>
                )}
				{[9,10,11,12].includes(item.plan_id) && <>
					<DatePicker
						dateFormat="dd-MM-yyyy"
						showYearDropdown
						className="datepicker"
						selected={item.start_at ? moment(item.start_at).toDate() : null}
						placeholderText={"Select Start Date & Time"}
                        showTimeSelect
						onChange={(val) => {
							item.start_at = val
							updateOrderPlan({
								...item,
								start_at: val
							})
						}}
					/>
					<div className="pe-2x pe-7s-date" />
					{/* <DatePicker
						dateFormat="dd-MM-yyyy"
						showYearDropdown
						className="datepicker"
						selected={item.end_at ? moment(item.end_at).toDate() : null}
						placeholderText={"Select End Date & Time"}
						onChange={(val) => {
							item.end_at = val
							updateOrderPlan({
								...item,
								end_at: val
							})
						}}
					/> */}
					{/* <div className="pe-2x pe-7s-date" /> */}
				</>}
								      
              </div>
              { [9,10,11,12,15].includes(item.plan_id) && (
                <InputGroup className="py-2">
					<Input id={`subrow-${item.id}-sinchew-link`} value={item.link} onChange={(e) => {
						item.link = e.target.value
						updateOrderPlan({
							...item,
							link: e.target.value
						})
					}} />
					<Button
						color="link"
						onClick={() =>
							handleCopyClick(`subrow-${item.id}-sinchew-link`)
						}
						id="sinChewCopyButton">
						<div className="pe-lg pe-7s-copy-file" />
					</Button>
					<UncontrolledTooltip
						placement="top"
						trigger="click"
						target="sinChewCopyButton"
						autohide>
						Copied!
					</UncontrolledTooltip>
					{/* <Button color="link">
						<div className="pe-lg pe-7s-share" />
					</Button>
					<Button color="link">
						<div className="pe-lg pe-7s-link" />
					</Button> */}
				</InputGroup>
              )} 
            </div>
          )
        }) }
      </div>
							
						</div>
					);
				}}
			/>
		);
	};

	getStatus = (keyword) => {
		switch (keyword) {
			case "All":
				return "";
			case "Published":
				return "&status=active";
			case "Draft":
				return "&status=draft";
			case "Suspended":
				return "&status=archived";
			case "Submitted":
				return "&status=expired";
			case "Disabled":
				return "&status=disabled";
			default:
				return "";
		}
	};

	renderFilterModal = () => {
		return (
			<CustomModal
				size={"lg"}
				title="Manage Post Filter"
				isOpen={this.props.showFilterModal}
				onClose={() => this.props.onChangeHOC("showFilterModal", false)}>
				<FilterForm specializations={this.props.specializations} onSubmit={this.props.getPost} onChangeHOC={this.props.onChangeHOC} />
			</CustomModal>
		);
	};

	renderConfirmModal = () => {
		const { selectedRowData } = this.state;

		return (
			<ConfirmationModal
				open={this.props.showConfirmModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					this.props.onChangeHOC("showConfirmModal", false);
				}}
				onClickConfirm={() => {
					this.props.suspendPost(selectedRowData.id);
					this.setState({
						selectedRowData: null,
					});
				}}
				title="Are you sure you want to suspend?"
				message="Please confirm this action."
				confirmContent="Yes, suspend"
				confirmColor="danger"
			/>
		);
	};

	renderDuplicateModal = () => {
		const { selectedRowData } = this.state;

		return (
			<ConfirmationModal
				open={this.props.showDuplicateModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					this.props.onChangeHOC("showDuplicateModal", false);
				}}
				onClickConfirm={() => {
					this.props.duplicatePost(selectedRowData.id);
					this.setState({
						selectedRowData: null,
					});
				}}
				title="Are you sure you want to duplicate?"
				message="Please confirm this action."
				confirmContent="Yes, Duplicate"
				confirmColor="danger"
			/>
		);
	};

	renderDisableModal = () => {
        const { selectedRowData } = this.state;
        const { disableOrder } = this.props;

		return (
			<ConfirmationModal
				open={this.props.showDisableModal}
				onClose={() => this.props.onChangeHOC("showDisableModal", false)}
				onClickConfirm={() => disableOrder(selectedRowData.id)}
				title="Are you sure you want to disable this post?"
				message="You can click to enable the post after this process if necessary."
				confirmContent="Disable Post"
				confirmColor="danger"
			/>
		);
	};

    renderEnableModal = () => {
        const { selectedRowData } = this.state;
        const { enableOrder } = this.props;

		return (
			<ConfirmationModal
				open={this.props.showEnableModal}
				onClose={() => this.props.onChangeHOC("showEnableModal", false)}
				onClickConfirm={() => enableOrder(selectedRowData.id)}
				title="Are you sure you want to enable this post?"
				message="Please confirm this action."
				confirmContent="Enable Post"
			/>
		);
	};

	renderUpgradeModal = () => (
		<UpgradeForm
			data={this.props.data}
			isOpen={this.props.showUpgradeModal}
			selectedPost={this.state.selectedRowData}
			getPosts={this.props.getPost}
			onClose={() => {
				this.setState({
					selectedRowData: null,
				});
				this.props.onChangeHOC("showUpgradeModal", false);
			}}
            onChangeHOC={this.props.onChangeHOC}
			showConfirmModal={this.props.showConfirmModal}
		/>
	);

	render = () => {
		const { onChangeHOC, selectedPostType, getPost } = this.props;
		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Manage Post"
										buttons={[
											...(![3, 4, 5, 6].includes(
												CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id
											)
												? [
														{
															color: "primary",
															className: "btn-icon mr-2",
															onClick: () => {
																this.props.history.push("/post/create-post");
															},
															content: (
																<ButtonContent>
																	<i className="pe-lg pe-7s-plus" />
																	<span>Create Post</span>
																</ButtonContent>
															),
														},
												  ]
												: []),
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<div
										className="container-fluid"
										style={{ paddingBottom: "6px" }}>
										<div className="row">
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "All" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "All") {
															onChangeHOC("selectedPostType", "All");
															getPost();
														}
													}}>
													All
												</button>
											</div>
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Published" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Published") {
															onChangeHOC("selectedPostType", "Published");
															getPost("&status=active");
														}
													}}>
													Published
												</button>
											</div>
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Draft" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Draft") {
															onChangeHOC("selectedPostType", "Draft");
															getPost("&status=draft");
														}
													}}>
													Draft
												</button>
											</div>
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Suspended" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Suspended") {
															onChangeHOC("selectedPostType", "Suspended");
															getPost("&status=archived");
														}
													}}>
													Suspended
												</button>
											</div>
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Submitted" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Submitted") {
															onChangeHOC("selectedPostType", "Submitted");
															getPost("&status=expired");
														}
													}}>
													Expired
												</button>
											</div>
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Disabled" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Disabled") {
															onChangeHOC("selectedPostType", "Disabled");
															getPost("&status=disabled");
														}
													}}>
													Disabled
												</button>
											</div>
										</div>
									</div>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>{this.renderSampleTable()}</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderConfirmModal()}
									{this.renderDisableModal()}
                                    {this.renderEnableModal()}
									{this.renderUpgradeModal()}
									{this.renderDuplicateModal()}
									{this.props.onLoadPost && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
					<PreviewJob
						selectedJob={this.props.selectedPost}
						isOpen={this.props.showPreviewModal}
						onLoad={this.props.onLoadPost}
						onToggleDownload={this.props.downloadImage}
						onClose={() => this.props.onChangeHOC("showPreviewModal", false)}
					/>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(PostAPI)(Post);
