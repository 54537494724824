import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			inboxList: [],
			inboxPages: 1,
			inboxCount: 0,
			selectedInbox: null,
			showEditSampleModal: false,
			showFilterModal: false,
			showEditModal: false,
			showPreviewModal: false,
			showDuplicateModal: false,
			showDisableModal: false,
			selectedMessageId: "",
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getInbox = (querystring = "") =>
			Get(
				"/inboxes?limit=10" + querystring,
				this.getInboxSuccess,
				this.getInboxError,
				this.load
			);
		getInboxSuccess = (payload) => {
			this.setState({
				inboxList: payload.data,
				inboxCount: payload.count,
			});
		};
		getInboxError = (error) => requestError(error);

		updateInbox = (dataToSubmit) => {
			Put(
				`/inboxes/${dataToSubmit.id}`,
				dataToSubmit,
				this.updateInboxSuccess,
				this.updateInboxError,
				this.load
			);
		};
		updateInboxSuccess = () => {
			this.getInbox();
			this.setState({
				showDisableModal: false,
			});
			requestSuccess("Inbox updated successfully.");
		};
		updateInboxError = (error) => requestError(error);

		getSelectedInbox = (id) =>
			Get(
				`/inboxes?id=${id}`,
				(payload) => this.setState({ selectedInbox: payload?.data?.[0] }),
				(error) => requestError(error),
				this.load
			);

		duplicateInbox = (dataToSubmit) => {
			Post(
				`/inboxes`,
				dataToSubmit,
				this.duplicateInboxSuccess,
				this.duplicateInboxError,
				this.load
			);
		};
		duplicateInboxSuccess = () => {
			this.setState({ showDuplicateModal: false });
			this.getInbox();
			requestSuccess("Inbox duplicated successfully.");
		};
		duplicateInboxError = (error) => requestError(error);

		// function to toggle display filter modal
		toggleShowFilterModal = () => {
			this.setState((prevState) => ({
				showFilterModal: !prevState.showFilterModal,
			}));
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					selectedInbox={this.state.selectedInbox}
					inboxList={this.state.inboxList}
					inboxPages={this.state.inboxPages}
					inboxCount={this.state.inboxCount}
					showEditSampleModal={this.state.showEditSampleModal}
					selectedSample={this.state.selectedSample}
					onLoadInbox={this.state.loading}
					getInbox={this.getInbox}
					getSelectedInbox={this.getSelectedInbox}
					updateInbox={this.updateInbox}
					duplicateInbox={this.duplicateInbox}
					onChangeHOC={this.onChangeHOC}
					showFilterModal={this.state.showFilterModal}
					showEditModal={this.state.showEditModal}
					showPreviewModal={this.state.showPreviewModal}
					showDuplicateModal={this.state.showDuplicateModal}
					showDisableModal={this.state.showDisableModal}
					selectedMessageId={this.state.selectedMessageId}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
