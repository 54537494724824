import React, { Component } from "react";
import { Button } from "reactstrap";
import _ from "lodash";

class Pagination extends Component {
	state = {
		pages: [],
		interval: 10,
	};

	componentDidMount = () => {
		this.updatePages();
	};

	componentDidUpdate = (pp) => {
		if (this.props.totalCount !== pp.totalCount) {
			this.updatePages();
		}
	};

	updatePages = () => {
		let tempPage = Math.ceil(this.props.totalCount / 10);
		let tmp = [];
		for (let i = 0; i < tempPage; i++) {
			tmp.push(i + 1);
		}
		this.setState({ pages: tmp });
	};

	render = () => {
		return (
			<div className="mt-2">
				<div
					className="d-flex pt-1"
					style={{ justifyContent: "center", alignItems: "center" }}>
					<Button
						className="mr-3"
						color={this.props.pageIndex === 1 ? "secondary" : "primary"}
						disabled={this.props.pageIndex === 1}
						onClick={() => {
							this.props.onChangePage(this.props.pageIndex - 1);
						}}>
						Prev
					</Button>
					{this.state.interval > 10 && (
						<Button
							className="mr-1"
							onClick={() => {
								let tmp = _.cloneDeep(this.state.interval);
								this.setState({ interval: tmp - 10 });
							}}>
							...
						</Button>
					)}
					{this.state.pages.map((item) => {
						if (
							item > this.state.interval - 10 &&
							item <= this.state.interval
						) {
							return (
								<Button
									key={`Pagination-${item}`}
									outline
									className="mr-1 border-0 btn-transition"
									color="dark"
									onClick={() => this.props.onChangePage(item)}
									active={item === this.props.pageIndex}>
									{item}
								</Button>
							);
						}
					})}
					{this.state.interval < Math.ceil(this.props.totalCount / 10) && (
						<Button
							className="mr-1"
							onClick={() => {
								let tmp = _.cloneDeep(this.state.interval);
								this.setState({ interval: tmp + 10 });
							}}>
							...
						</Button>
					)}
					<Button
						className="ml-3"
						color={
							this.props.pageIndex === Math.ceil(this.props.totalCount / 10)
								? "secondary"
								: "primary"
						}
						disabled={
							this.props.pageIndex === Math.ceil(this.props.totalCount / 10)
						}
						onClick={() => {
							this.props.onChangePage(this.props.pageIndex + 1);
						}}>
						Next
					</Button>
				</div>
			</div>
		);
	};
}

export default Pagination;
