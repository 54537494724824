import React from "react";
import Moment from "moment";
import ReactHTMLParser from "react-html-parser";
import { AiOutlineLeft } from "react-icons/ai";
import "./index.scss";

function Preview(props) {
	return (
		<div className="e-classified-inbox-cont">
			{props.selectedInbox && (
				<div style={{ padding: "1rem", background: "white" }} className="w-100">
					<div
						className="d-flex align-items-center"
						style={{ marginBottom: "2.5rem" }}>
						<AiOutlineLeft
							className={"mr-3"}
							style={{ width: "1.25rem", height: "1.25rem", cursor: "pointer" }}
							onClick={() => {}}
						/>
						{props.selectedInbox && (
							<div>
								<h4 style={{ fontWeight: 600, marginBottom: "0.5rem" }}>
									{props.selectedInbox.title}
								</h4>
								<p style={{ color: "#8C8C8C", fontSize: "0.875rem" }}>
									{Moment(props.selectedInbox.published_at).format(
										"DD/MM/YYYY hh:mmA"
									)}
									{` , Sent from Job Macha`}
								</p>
							</div>
						)}
					</div>
					<div className="ck-content">
						{ReactHTMLParser(props.selectedInbox.message)}
					</div>
				</div>
			)}
		</div>
	);
}

export default Preview;
