

import { Empty } from "antd";
import JobCard from "components/Cards/JobCard";
import { useEffect } from "react";

import HOC from "./action";

function AppliedJobs(props) {
	useEffect(() => {
    props.getJobAds(props.jobSeekerProfile.user_id);
  }, [])

	return (
		<>
			<h5 className="mb-4 e-classified-cont-header">
				Saved Jobs
			</h5>
			{props.jobAds?.[0] && (
				<div className="row">
					{props.jobAds.map((item) => (
						<div className="col-lg-6 mb-4" key={item.id}>
							<JobCard
								item={item}
								onClickUnsave={() => {}}
							/>
						</div>
					))}
				</div>
			)}
			{props.jobAds.length < 1 && (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"暂无内容"} />
			)}
		</>
	);
}

export default HOC(AppliedJobs);
