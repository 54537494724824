import React, { Component } from "react";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Card, CardBody } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import _ from "lodash";
import { RolesPermission } from "Config";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import ManageUserAPI from "./action.js";
import { CheckUserRolesPermissons } from "actions/roles";

class ManageUser extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(10)) {
			return this.props.history.push("/");
		}

		this.props.getRole();
		this.props.getUserList();
	};

	componentDidUpdate = (pp) => {
		const { userListPages, getUserList } = this.props;
		const { filterQuery } = this.state;

		if (pp.userListPages !== userListPages) {
			getUserList(
				`${userListPages > 1 ? `&offset=${(userListPages - 1) * 10}` : ""}${!!filterQuery ? filterQuery : ""
				}`
			);
		}
	};

	state = {
		selectedRowData: null,
		filterQuery: "",
	};

	View = (val) => {
		this.props.onChangeHOC("selectedUsername", val.username);
		this.props.onChangeHOC("showViewModal", true);
	};
	Edit = (val) => {
		this.props.onChangeHOC("selectedUsername", val.username);
		this.props.onChangeHOC("showEditModal", true);
	};

	renderSampleTable = () => {
		const { userList, onLoad, onChangeHOC } = this.props;
		const renderUserStatusText = (x) => {
			if (x.status !== 'active' || x.roles?.filter(e => e?.id === 10).length <= 0) return x.status
			if (x.roles?.filter(e => [8, 9].includes(e?.id)).length > 0) return 'Active (Both)'
			return 'Active (Candidate)'
		}

		return (
			<CustomTable
				data={userList}
				showPagination={false}
				loading={onLoad}
				columnsContent={[
					{
						Header: "User ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.id}</>,
					},
					{
						Header: "Username",
						accessorKey: "name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.name}</>,
					},
					{
						Header: "Email",
						accessorKey: "userEmail",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.email}</>,
					},
					{
						Header: "User Type",
						accessorKey: "company",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.platform}</>,
					},
					{
						Header: "User Role",
						accessorKey: "role",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.roles?.map(e => e?.name)?.join("\n")}</>,
					},
					{
						Header: "Agent ID",
						accessorKey: "code",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.agent?.id}</>,
					},
					{
						Header: "Agent Name",
						accessorKey: "agent_name",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.agent?.name}</>,
					},
					{
						Header: "Agent Lead ID",
						accessorKey: "agent_lead_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.agent_lead_id}</>,
					},
					{
						Header: "Agent Lead Name",
						accessorKey: "agent_lead",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.agent_lead?.name}</>,
					},
					{
						Header: "Manager ID",
						accessorKey: "manager_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.manager_id}</>,
					},
					{
						Header: "Manager Name",
						accessorKey: "manager",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.manager?.name}</>,
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => <>{renderUserStatusText(val)}</>,
					},
					{
						Header: "Created Date",
						accessorKey: "created_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{moment(val.created_at).format("DD/MM/YYYY hh:mm A")}</>
						),
					},
				]}
				menuContent={[
					{
						menuID: "EditCandidate",
						menuTooltip: "Edit",
						onClick: (val) => {
							this.props.history.push(`/manage-user/edit/${val.id}`);
						},
					},
					{
						menuID: "InactiveCandidate",
						menuTooltip: "Inactive",
						onClick: (val) => {
							this.setState({ selectedRowData: val });
							onChangeHOC("showInactiveModal", true);
						},
					},
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getUserList, onChangeHOC, showFilterModal, roleList, selectedRoleType } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Manage User Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getUserList}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
					roleList={roleList}
					selectedRoleType={selectedRoleType}
				/>
			</CustomModal>
		);
	};

	renderViewModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showViewModal}
				onClose={() => this.props.onChangeHOC("showViewModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showViewModal", false)}
				title={`Are you sure you want to view this user? ${this.props.selectedUsername}`}
				message="Please confirm this action."
				confirmContent="Yes, view"
				confirmColor="danger"
			/>
		);
	};

	renderEditModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showEditModal}
				onClose={() => this.props.onChangeHOC("showEditModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showEditModal", false)}
				title={`Are you sure you want to edit this user? ${this.props.selectedUsername}`}
				message="Please confirm this action."
				confirmContent="Yes, Edit"
				confirmColor="danger"
			/>
		);
	};
	renderInactiveModal = () => {
		const { selectedRowData } = this.state;
		const { showInactiveModal, onChangeHOC, updateUser } = this.props;

		return (
			<ConfirmationModal
				open={showInactiveModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					onChangeHOC("showInactiveModal", false);
				}}
				onClickConfirm={() => {
					updateUser({
						status: "inactive",
						id: selectedRowData.id,
					});
					this.setState({
						selectedRowData: null,
					});
				}}
				title={`Are you sure you want to inactive this user? ${selectedRowData?.name}`}
				message="Please confirm this action."
				confirmContent="Yes, Inactive"
				confirmColor="danger"
			/>
		);
	};

	render = () => {
		const { onChangeHOC, onLoad, userListPages, userListCount, getUserList, selectedRoleType } =
			this.props;

		const { filterQuery } = this.state;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => { }}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Manage User"
										buttons={[
											{
												color: "primary",
												className: "btn-icon mr-2",
												onClick: () => {
													this.props.history.push("/manage-user/create");
												},
												content: (
													<>
														<span>Create User</span>
													</>
												),
											},
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<div
										className="container-fluid"
										style={{ paddingBottom: "6px" }}>
										<div className="row">
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													style={{ marginRight: "6px", marginTop: "6px", marginBottom: "6px" }}
													className={`btn btn-outline-primary ${
														selectedRoleType === 0 ? "active" : ""
													}`}
													onClick={() => {
														if (selectedRoleType !== 0) {
															onChangeHOC("selectedRoleType", 0);
															setTimeout(getUserList, 0)
														}
													}}>
													All
												</button>
												{
													RolesPermission.map(e => <button
														type="button"
														style={{ marginRight: "6px", marginTop: "6px", marginBottom: "6px" }}
														className={`btn btn-outline-primary ${
															selectedRoleType === e.id ? "active" : ""
														}`}
														onClick={() => {
															if (selectedRoleType !== e.id) {
																onChangeHOC("selectedRoleType", e.id);
																setTimeout(getUserList, 0)
															}
														}}>
														{ e.name }
													</button>)	
												}
											</div>
										</div>
									</div>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderSampleTable()}
														<Pagination
															pageIndex={userListPages}
															totalCount={userListCount}
															onChangePage={(val) =>
																onChangeHOC("userListPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderViewModal()}
									{this.renderEditModal()}
									{this.renderInactiveModal()}
									{this.props.onLoadSample && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ManageUserAPI)(ManageUser);
