import _ from "lodash";
import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import CustomTable from "components/Table";
import TemplateContainerMain from "components/Template";
import PageTitle from "components/Title/PageTitle";
import { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Card, CardBody, Col, Row, ModalBody } from "reactstrap";
import { RolesPermission } from "Config";
import { compose } from "redux";
import CandidateProfile from "./CandidateProfile";
import FilterForm from "./Form";
import CandidateAPI from "./action.js";
import { CheckUserRolesPermissons } from "actions/roles";

class CandidateList extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(9)) {
			return this.props.history.push("/");
		}
		this.props.getCandidateList();
		// Malaysia code = 136
		this.props.getStates(136);
	};

	componentDidUpdate = (pp) => {
		const { candidateListPages, getCandidateList, onChangeHOC } = this.props;
		const { filterQuery } = this.state;

		if (pp.candidateListPages !== candidateListPages) {
			getCandidateList(
				`${
					candidateListPages > 1
						? `&offset=${(candidateListPages - 1) * 10}`
						: ""
				}${!!filterQuery ? filterQuery : ""}`
			);
		}
	};

	state = {
		selectedRowData: null,
		filterQuery: "",
	};

	View = (val) => {
		this.props.getSelectedJobSeekerProfile(val.user_id, "view");
	};
	Edit = (val) => {
		this.props.getSelectedJobSeekerProfile(val.user_id, "edit");
	};

	renderSampleTable = () => {
		const { candidateList, onLoad, onChangeHOC } = this.props;

		return (
			<CustomTable
				data={candidateList}
				showPagination={false}
				loading={onLoad}
				columnsContent={[
					{
						Header: "User ID",
						accessorKey: "user_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.user_id}</>,
					},
					{
						Header: "Name",
						accessorKey: "user",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.user?.name}</>,
					},
					{
						Header: "Contact Number",
						accessorKey: "user",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.user?.mobile_number}</>,
					},
					{
						Header: "Whatsapp",
						accessorKey: "user",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.user?.whatsapp_number}</>,
					},
					{
						Header: "Email",
						accessorKey: "user",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.user?.email}</>,
					},
					{
						Header: "City",
						accessorKey: "city",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.city}</>,
					},
					{
						Header: "State",
						accessorKey: "state",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.state}</>,
					},
				]}
				menuContent={[
					{
						menuID: "ViewCandidate",
						menuTooltip: "View",
						onClick: (val) => {
							this.View(val);
						},
					},
					{
						menuID: "EditCandidate",
						menuTooltip: "Edit",
						onClick: (val) => {
							this.Edit(val);
						},
					},
					{
						menuID: "InactiveCandidate",
						menuTooltip: "Inactive",
						onClick: (val) => {
							this.setState({ selectedRowData: val });
							onChangeHOC("showInactiveModal", true);
						},
					},
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getCandidateList, onChangeHOC, showFilterModal, stateList } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Candidates List Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getCandidateList}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
					stateList={stateList}
				/>
			</CustomModal>
		);
	};

	renderViewModal = () => {
		return (
			<CustomModal
				isOpen={this.props.showViewModal}
				fullScreen={true}
				onClose={() => this.props.onChangeHOC("showViewModal", false)}>
				<ModalBody>
					<CandidateProfile
						{...this.props}
						context={"view"}
						jobSeekerProfile={this.props.selectedCandiate}
					/>
				</ModalBody>
			</CustomModal>
		);
	};
	renderEditModal = () => {
		return (
			<>
				<CustomModal
					isOpen={this.props.showEditModal}
					fullScreen={true}
					onClose={() => this.props.onChangeHOC("showEditModal", false)}>
					<ModalBody>
						<CandidateProfile
							{...this.props}
							context={"edit"}
							jobSeekerProfile={this.props.selectedCandiate}
						/>
					</ModalBody>
				</CustomModal>
			</>
		);
	};
	renderInactiveModal = () => {
		const { selectedRowData } = this.state;
		const { showInactiveModal, onChangeHOC, updateUser } = this.props;

		return (
			<ConfirmationModal
				open={showInactiveModal}
				onClose={() => {
					this.setState({
						selectedRowData: null,
					});
					onChangeHOC("showInactiveModal", false);
				}}
				onClickConfirm={() => {
					updateUser({
						status: "inactive",
						id: selectedRowData.id,
					});
					this.setState({
						selectedRowData: null,
					});
				}}
				title={`Are you sure you want to inactive this candidate? ${selectedRowData?.user?.name}`}
				message="Please confirm this action."
				confirmContent="Yes, Inactive"
				confirmColor="danger"
			/>
		);
	};

	render = () => {
		const { onChangeHOC, candidateListPages, candidateListCount } = this.props;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Candidate List"
										buttons={[
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderSampleTable()}
														<Pagination
															pageIndex={candidateListPages}
															totalCount={candidateListCount}
															onChangePage={(val) =>
																onChangeHOC("candidateListPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderViewModal()}
									{this.renderEditModal()}
									{this.renderInactiveModal()}
									{this.props.onLoad && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(CandidateAPI)(CandidateList);
