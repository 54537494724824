import React, { useState } from "react"
import {
  Modal,
  ModalBody,
  Button,
  List,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap"
import {
  AiOutlineDownload,
  AiOutlineEdit,
  AiOutlineCloseCircle,
  AiOutlineDollarCircle,
  AiOutlineFileDone,
  AiOutlineShareAlt,
  AiFillLeftCircle,
  AiFillRightCircle,
  AiOutlineCalendar,
  AiOutlineCar,
  AiOutlineMore,
} from "react-icons/ai"
// import { useLocale } from "next-intl"
import { BsBookmark } from "react-icons/bs"
import { GiMeal } from "react-icons/gi"
import { Empty } from "antd";

// import Trans from "components/i18next"
import Trans from "components/Trans"
import LoadingOverlay from "components/Indicator/LoadingOverlay"

import { companyDetailsAssets } from "./assets"
import "./index.scss"

const PreviewJob = ({
  isOpen,
  onLoad,
  selectedJob,
  
  onClose,
  onToggleDownload
}) => {
  // const locale = useLocale()
  const locale = 'en'
  const images =
    selectedJob &&  selectedJob.jobads_galleries ? selectedJob.jobads_galleries.map((item) => item.url) : [];

  const [activeIndex, setActiveIndex] = useState(0)

  const moment = require("moment")

  const imageSlide = () => {
    const next = () => {
      const maxIndex = Math.floor(images.length / 4)
      const nextIndex = activeIndex === maxIndex ? 0 : activeIndex + 1
      setActiveIndex(nextIndex)
    }

    const previous = () => {
      const maxIndex = Math.floor(images.length / 4)
      const nextIndex = activeIndex === 0 ? maxIndex : activeIndex - 1
      setActiveIndex(nextIndex)
    }

    const goToIndex = (newIndex) => setActiveIndex(newIndex)

    const items = images.reduce((result, src, index) => {
      if (index % 4 === 0) {
        result.push(
          <CarouselItem key={index}>
            <div className="d-flex">
              {images.slice(index, index + 4).map((src, innerIndex) => (
                <div className="bg-secondary w-25" key={innerIndex}>
                  <img src={src} style={{ width: "100%", height: "100%", borderRadius: "2px" }} />
                </div>
              ))}
            </div>
          </CarouselItem>
        )
      }
      return result
    }, [])

    return (
      <Carousel
        className="e-classified-advertiser__image-slide"
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={null}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {items}
        <a className="carousel-control-prev">
          <AiFillLeftCircle onClick={previous} />
        </a>
        <a className="carousel-control-next">
          <AiFillRightCircle onClick={next} />
        </a>
      </Carousel>
    )
  }

  function getBenefitContent(benefitName) {
    let icon, text

    switch (benefitName) {
      case "5 Working Days":
        icon = <AiOutlineCalendar />
        text = "5 Working Days"
        break
      case "Meal Allowance":
        icon = <GiMeal />
        text = "Meal Allowance"
        break
      case "Transport Allowance":
        icon = <AiOutlineCar />
        text = "Transport Allowance"
        break
      case "Others":
        icon = <AiOutlineMore />
        text = "Others"
        break
      default:
        icon = <AiOutlineMore />
        text = benefitName
    }

    return (
      <>
        {icon}
        <span>{text}</span>
      </>
    )
  }

  const handleOnError = (e) => {
    e.target.src =
      "/fallback.svg";
  };

  return (
    <Modal
      className="e-classified-advertiser__preview-job"
      size="xl"
      isOpen={isOpen}
      toggle={() => onClose()}
      centered
      scrollable
    >
      <ModalBody className="e-classified-advertiser__preview-job-body">
        {selectedJob && (
          <div className="e-classified-advertiser__preview-job-body-header">
            <Row xs="1" style={{ margin: "1rem" }}>
              <Col className="header-button-col">
                <Button color="primary"  onClick={() => onToggleDownload(selectedJob.id)}>
                  <AiOutlineDownload style={{ margin: "0.25rem" }}/>
                  <Trans context="ADVERTISER" langKey={"DOWNLOAD_AS_IMAGE"}/>
                </Button>
                {/* <a
                  className="header-button"
                  onClick={() => window.location.href = `/advertiser/manage-post/${selectedJob.slug}`}>
                  <AiOutlineEdit></AiOutlineEdit>
                </a> */}
                <a className="header-button" onClick={onClose}>
                  <AiOutlineCloseCircle></AiOutlineCloseCircle>
                </a>
              </Col>
            </Row>
            <div className="e-classified-cont">
              <div className="e-classified-job-details-cont">
                <div className="row">
                  <div className="col-xl-7 col-12">
                    <Row>
                      <Col xs="3">
                        <div className="e-classified-job-details-cont__logo-cont">
                            <img src={selectedJob.is_confidential ? "/logo-trans.png" : selectedJob.company.logo_url || `/logo-trans.png`} />
                        </div>
                      </Col>
                      <Col>
                        <div className="e-classified-job-details-cont__logo-title-cont">
                          <h5 style={{ fontWeight: 'bold' }}>{`${selectedJob.job_title.zh}`}</h5>
                          <h5 style={{ fontWeight: 'bold' }}>{`${selectedJob.job_title.en}`}</h5>
                          <span>{selectedJob.is_confidential ? "Confidential Advertiser" : selectedJob.company.name || "N/A"}</span>
                          <div className="d-flex mt-4">
                            <div className="mr-2">
                              {selectedJob.created_at
                                ? moment(selectedJob.created_at,"YYYY-MM-DD HH:mm").format("DD/MM/YYYY h:mm A")
                                : moment.format("DD/MM/YYYY h:mm A")}
                            </div>
                            <div className="text-primary">
                              {selectedJob?.specialization?.name?.[locale] || "N/A"}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex -flex-wrap">
                      <div className="e-classified-job-details-cont__card">
                        <p>
                          <Trans langKey={"JOB_TYPE"} />
                        </p>
                        <p className="text-primary">
                          {selectedJob.employment_type === "full_time" && (
                            <Trans langKey={"FULL_TIME"} />
                          )}
                          {selectedJob.employment_type === "part_time" && (
                            <Trans langKey={"PART_TIME"} />
                          )}
                          {selectedJob.employment_type === "internship" && (
                            <Trans langKey={"INTERNSHIP"} />
                          )}
                        </p>
                      </div>
                      <div className="e-classified-job-details-cont__card">
                        <p>
                          <Trans langKey={"YEAR_EXP"} />
                        </p>
                        <p className="text-primary">
                          {selectedJob.total_experience}
                        </p>
                      </div>
                      <div className="e-classified-job-details-cont__card">
                        <p>
                          <Trans langKey={"JOB_LEVEL"} />
                        </p>
                        <p className="text-primary">
                          {selectedJob.position_level?.name?.[locale]}
                        </p>
                      </div>
                      <div className="e-classified-job-details-cont__card">
                        <p>
                          <Trans langKey={"JOB_VACANCIES"} />
                        </p>
                        <p className="text-primary">
                          {selectedJob.total_vacancies}
                        </p>
                      </div>
                    </div>
                    <div className="e-classified-job-details-cont__position-cont">
                      <h3>
                        <Trans
                          context="ALL"
                          langKey={"JOB_REQUIREMENT"}
                        ></Trans>
                      </h3>
                      <List>
                        <li>
                          <Trans
                            context="ALL"
                            langKey={"JOB_EDUCATION_LEVEL"}
                          ></Trans>{" "}
                          : {selectedJob.education_level.name?.[locale]}
                        </li>

                        <li>
                          <Trans
                            context="ADVERTISER"
                            langKey={"LANGUAGE"}
                          ></Trans>
                          :{" "}
                          {selectedJob &&
                            selectedJob.language.map((language, index) => (
                              <a key={index}>
                                {language === "english" ? (
                                  <Trans
                                    context="ALL"
                                    langKey={"ENGLISH_LANG"}
                                  ></Trans>
                                ) : language === "chinese" ? (
                                  <Trans
                                    context="ALL"
                                    langKey={"CHINESE_LANG"}
                                  ></Trans>
                                ) : language === "malay" ? (
                                  <Trans
                                    context="ALL"
                                    langKey={"MALAY_LANG"}
                                  ></Trans>
                                ) : language === "tamil" ? (
                                  <Trans
                                    context="ALL"
                                    langKey={"TAMIL_LANG"}
                                  ></Trans>
                                ) : null}
                                {index < selectedJob.language.length - 1 &&
                                  ", "}
                              </a>
                            ))}
                        </li>

                        {selectedJob && selectedJob.shift_type > 0 && (
                          <li>
                            {selectedJob.shift_type.map((shiftType, index) => (
                              <li key={index}>
                                {" "}
                                {shiftType === "morning" ? (
                                  <Trans
                                    context="ALL"
                                    langKey={shiftType.toUpperCase()}
                                  />
                                ) : shiftType === "night" ? (
                                  <Trans
                                    context="ALL"
                                    langKey={shiftType.toUpperCase()}
                                  />
                                ) : (
                                  shiftType
                                )}
                              </li>
                            ))}
                          </li>
                        )}

                        {selectedJob.job_types.length > 0 && (
                          <li>
                            {selectedJob.job_types.map((jobType, index) => (
                              <li key={index}>
                                {" "}
                                {jobType === "fresh_graduate" ? (
                                  <Trans context="ALL" langKey={"FRESH_GRAD"} />
                                ) : jobType === "work_from_home" ? (
                                  <Trans
                                    context="ALL"
                                    langKey={"WORK_FROM_HOME"}
                                  />
                                ) : (
                                  jobType
                                )}
                              </li>
                            ))}
                          </li>
                        )}

                        {selectedJob.job_requirements &&
                          selectedJob.job_requirements.filter(
                            (requirement) => requirement.checked === true
                          ).length > 0 && (
                            <li>
                              {selectedJob.job_requirements
                                .filter(
                                  (requirement) => requirement.checked === true
                                )
                                .map((requirement, index) => (
                                  <li key={index}>{requirement.name}</li>
                                ))}
                            </li>
                          )}
                      </List>
                      <br />
                      <h3>
                        <Trans langKey={"JOB_DESC"} />
                      </h3>
                      <div dangerouslySetInnerHTML={{ __html: selectedJob.job_responsibilities?.[locale] }}></div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-12">
                    <div className="e-classified-job-details-cont__summary-cont">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="e-classified-job-details-cont__summary-cont-salary-title-cont">
                            <AiOutlineDollarCircle />
                            <span>
                              <Trans langKey={"SALARY_RANGE"} />
                            </span>
                          </div>
                          <h5 className="e-classified-job-details-cont__summary-cont-salary-text">
                            {
                                !selectedJob.is_negotiable ?
                                `RM ${selectedJob.min_salary} - RM ${selectedJob.max_salary}`
                                : "Salary Negotiable"
                            }
                          </h5>
                        </div>
                        <AiOutlineShareAlt
                          className="ml-auto text-primary"
                          style={{
                            width: "2.25rem",
                            height: "2.25rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        />
                      </div>
                      <div className="e-classified-job-details-cont__summary-cont-action-cont">
                        <Button
                          style={{ flex: 1, justifyContent: "center" }}
                          color="primary"
                          onClick={() => {}}
                        >
                          <span>
                            <Trans langKey={"APPLY_NOW"} />
                          </span>
                          <AiOutlineFileDone
                            style={{ width: "1.125rem", height: "1.125rem" }}
                          />
                        </Button>
                        <Button
                          style={{ flex: 1, justifyContent: "center" }}
                          className="btn btn-outline-primary bg-white w-100"
                          onClick={() => {}}
                        >
                          <span>
                            <Trans langKey={"SAVE_JOBS"} />
                          </span>
                          <BsBookmark
                            style={{ width: "1.125rem", height: "1.125rem" }}
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="e-classified-job-details-cont__company-details-cont">
                      <h5 className="e-classified-job-details-cont__company-details-title">
                        <Trans langKey={"COMPANY_INFO"} />
                      </h5>
                      <div className="e-classified-job-details-cont__company-details-cont-title-cont">
                        <img
                          src={selectedJob.is_confidential ? `/logo-trans.png` : selectedJob.company.logo_url || `/logo-trans.png`}
                        />
                        <div className="e-classified-job-details-cont__company-details-cont-title-cont-text-cont">
                          <p>{selectedJob.is_confidential ? "Confidential Advertiser" : selectedJob.company.name}</p>
                        </div>
                      </div>


                      <table className="mt-4">
                      {companyDetailsAssets(selectedJob).map((asset, index) => (

                        <tr
                          key={index}
                          className="e-classified-job-details-cont__company-details-cont-data"
                        >
                          <td class="detail-title">
                          {asset.icon && (
                            <div onClick={asset.action} style={{display: 'inline'}}>{asset.icon}</div>
                          )}
                          {asset.label} :
                          </td>

                          <td className={(asset.cssClass === "social") ? "social-media" : (asset.cssClass === "address") ? "company-address" : "others"}>{asset.value}</td>
                        </tr>

                      ))}
                      </table>

                    </div>
                    <div className="e-classified-job-details-cont__company-benefits">
                      <h5 className="e-classified-job-details-cont__company-benefits-title">
                        <Trans langKey={"COMPANY_BENEFIT"} />
                      </h5>
                      <Row className="g-2">
                        {!selectedJob.jobads_benefits && (
                          <Col>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Trans langKey={'NO_CONTENT'} />} />
                          </Col>
                        )}
                        {selectedJob.jobads_benefits &&
                          selectedJob.jobads_benefits.map((benefitChild, index) => {
                          const imgSrc = benefitChild.benefits.image_url;

                          return (
                            <Col xs="3" key={index}>
                              <div className="e-classified-job-details-cont__company-benefits-card">
                                <img
                                  src={imgSrc}
                                  onError={handleOnError}
														    />
														    <span className="mt-2">{benefitChild.benefits?.name?.[locale] ?? "-"}</span>
                              </div>
                            </Col>
                          )})}
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="e-classified-job-details-cont__company-env-cont">
                  <h5>
                    <Trans langKey={"WORK_ENV"} />
                  </h5>
                  <div className="row mb-3">{imageSlide()}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        {onLoad && <LoadingOverlay />}
      </ModalBody>
    </Modal>
  )
}

export default PreviewJob
