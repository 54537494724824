import React, { Component } from "react";
import { connect } from "react-redux";

import { storeItem } from "utils/tokenStore";
import { setProfile } from "actions/profile";
import Axios from "axios";
import getDomainURL from "utils/api";
import Cookies from "universal-cookie";
import { COOKIES_LOGGED_IN } from "constants/Constants";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			showPassword: false,
			showErrorMessage: false,
		};

		cookies = new Cookies();

		onChangeHOC = (val, context) => this.setState({ [context]: val });

		onClickLogin = ({ email, password }) => {
			Axios.post(`${getDomainURL()}/auth-user?platform=jobmacha`, {
				email,
				password,
				encode_type: "JWT",
			})
				.then((response) => {
					this.setState({ loading: false });

					this.cookies.set(COOKIES_LOGGED_IN, JSON.stringify(response.data.user), { path: '/' });
					
					this.props.setProfile(response.data.user);

					storeItem("CHINA_PRESS_TOKEN", response.data.access_token);
					storeItem("SHOW_SIDE_MENU", response.data.user.roles.find(role => role.is_read === true) ? true : false );

					this.props.history.push("/dashboard/post");
				})
				.catch((error) => {
					this.setState({ showErrorMessage: true, loading: false });
				});
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					showPassword={this.state.showPassword}
					showErrorMessage={this.state.showErrorMessage}
					onLoadLogin={this.state.loading}
					onChangeHOC={this.onChangeHOC}
					onClickLogin={this.onClickLogin}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps, {
		setProfile,
	})(WithHOC);
};
export default HOC;
