import _ from "lodash";
import Moment from "moment";

function view({ tempEducation, educationLevels }) {
	return (
		<>
			<div className="d-flex" style={{ marginTop: "2.5rem" }}>
				<h5 className="mb-3 e-classified-cont-header">Education</h5>
			</div>
			<div className="e-classified-profile-view-card">
				{tempEducation.map((educationChild) => (
					<EducationItem
						item={educationChild}
						educationLevels={educationLevels}
					/>
				))}
			</div>
		</>
	);
}

const EducationItem = ({ item, educationLevels }) => {
	return (
		<>
			<p className="mb-2 text-black" style={{ fontWeight: 600 }}>
				{item.institution}
			</p>
			<p className="mb-3 text-black">
				{_.find(educationLevels, { id: item.education_level_id })?.name?.[
					"en"
				] ?? "N/A"}
				{` - `}
				{item.field_of_study}
			</p>
			<div className="e-classified-profile-tag-cont mt-4">
				<div className="e-classified-tag-cont-secondary">
					{`${item.enrollment_month}/${Moment(item.enrollment_year).format(
						"YYYY"
					)}`}
					{` - `}
					{`${item.graduation_month}/${Moment(item.graduation_year).format(
						"YYYY"
					)}`}
				</div>
			</div>
		</>
	);
};

export default view;
