import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
  const [popupId, setPopupId] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [status, setStatus] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleClear = () => {
    setPopupId("");
    setPopupTitle("");
    setRedirectLink("");
    setStatus("");
    setCreatedDate("");
    setStartDate("");
    setEndDate("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (popupId) {
      queryString += `&id=${popupId}`;
    }
    if (popupTitle) {
      queryString += `&title=${popupTitle}`;
    }
    if (redirectLink) {
      queryString += `&link_url=${redirectLink}`;
    }
    if (status) {
      queryString += `&status=${status}`;
    }
    if (createdDate) {
      queryString += `&created_at=${createdDate}`;
    }
    if (startDate) {
      queryString += `&start_date=${startDate}`;
    }
    if (endDate) {
      queryString += `&end_date=${endDate}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-pop-up-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="popupId">Pop-Up ID</Label>
                <Input
                  placeholder="Enter Pop-Up ID"
                  name="popupId"
                  id="popupId"
                  value={popupId}
                  onChange={(e) => setPopupId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="popupTitle">Pop-Up Title</Label>
                <Input
                  placeholder="Enter Pop-Up Title"
                  name="popupTitle"
                  id="popupTitle"
                  value={popupTitle}
                  onChange={(e) => setPopupTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="redirectLink">Redirect Link</Label>
                <Input
                  placeholder="Enter Redirect Link"
                  name="redirectLink"
                  id="redirectLink"
                  value={redirectLink}
                  onChange={(e) => setRedirectLink(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option disabled value="">
                    Select Status
                  </option>
                  <option>scheduled</option>
                  <option>active</option>
                  <option>failed</option>
                  <option>expired</option>
                  <option>disabled</option>
                </Input>
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="createdDate">Created Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="createdDate"
                  id="createdDate"
                  value={createdDate}
                  onChange={(e) => setCreatedDate(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup>
                <Label for="startDate">Start Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="endDate">End Date</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Apply",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("bannerPopupPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
