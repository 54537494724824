import React, { useEffect, useState } from "react";
import {
  Label,
  FormGroup,
  Input,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
import InputFile from "components/Input/File";
import ImageField from "components/Modal/ImageField";
import PreviewModal from "../PreviewAdvertiser";
import { connect } from "react-redux";

function CreateAdvertiserForm({ user, onSubmit, natureList, countries, states, getStates, onChangeHOC, initialFormData = [] }) {

  const [industriesOptions, setIndustriesOptions] = useState([]);

  const defaultFormFields = {
    logoBase64: "",
    picName: "",
    picContactNumberPrefix: "",
    picContactNumber: "",
    picWhatsappNumber: "",
    picEmailAddress: "",
    nature: "",
    companyName: "",
    businessNature: "",
    registrationNumber: "",
    companyContactNumberPrefix: "",
    companyContactNumber: "",
    companyWhatsappNumber: "",
    companyEmailAddress: "",
    companyWebsiteAddress: "",
    companySocialMediaURL: "",
    companyAboutUs: "",
    companyPostcode: "",
    companyCity: "",
    companyAddressText: "",
    stateId: "",
    countryId: "",
    companyCountry: "",
    billingCompanyName: "",
    billingCompanyEmailAddress: "",
    billingCompanyAddress: "",
    billingPostcode: "",
    billingCity: "",
    billingState: "",
    billingCountry: "",
    user:user
  }

  // State for form fields, pre-filled if in edit mode
  const [formFields, setFormFields] = useState({
    ...defaultFormFields,
    ...(initialFormData || []),
  });

  // need some mapping, because fields accepted by backend has some conflicting variables like "name".
  // which may break front end componenets and properties.
  const convertFormFields = (formFields) => {
    return {
      type: "company",
      name: formFields.picName,
      registration_number: formFields.registrationNumber,
      contact_number: formFields.picContactNumber,
      company_contact: formFields.companyContactNumber,
      company_name: formFields.companyName,
      logo_url: formFields.logoBase64,
      advertiser_address: formFields.companyAddressText,
      postcode: formFields.companyPostcode,
      city: formFields.companyCity,
      billing_name: formFields.billingCompanyName,
      billing_email: formFields.billingCompanyEmailAddress,
      billing_address: formFields.billingCompanyAddress,
      billing_postcode: formFields.billingPostcode,
      billing_city: formFields.billingCity,
      billing_state: formFields.billingState,
      billing_country: formFields.billingCountry,
      description: formFields.companyAboutUs,
      website_url: formFields.companyWebsiteAddress,
      agent_id: formFields.user.id,
      status: "active",
      email: formFields.companyEmailAddress,
      social_media: formFields.companySocialMediaURL,
      slug: "",
      whatsapp_number: formFields.companyWhatsappNumber,
      state: formFields.stateId,
      country: formFields.countryId,
      person_in_charge_name: formFields.picName,
      person_in_charge_contact: formFields.picContactNumber,
      person_in_charge_whatsapp: null,
      person_in_charge_email: formFields.picEmailAddress,
      industry_id: parseInt(formFields.businessNature),
      industry: natureList.filter((industry) => industry.id === parseInt(formFields.businessNature))[0]
    }
  }

  useEffect(() => {
    const industries = natureList.map((industry) => ({value: industry.id, label: industry.name.en}));
    setIndustriesOptions(industries);
  }, [natureList]);

  const isEditMode = !!initialFormData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields(prev => ({ ...prev, [name]: value }));
  };

  const [photos, setPhotos] = useState([]);

  const [isSameAddress, setIsSameAddress] = useState(false);

  // validation state variables
  const [picNameError, setPicNameError] = useState("");
  const [picContactNumberError, setPicContactNumberError] = useState("");
  const [picEmailAddressError, setPicEmailAddressError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyContactNumberError, setCompanyContactNumberError] =
    useState("");
  const [companyEmailAddressError, setCompanyEmailAddressError] = useState("");
  const [businessNatureError, setBusinessNatureError] = useState("");
  const [registrationNumberError, setRegistrationNumberError] = useState("");
  const [companyAboutUsError, setCompanyAboutUsError] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [billingNameError, setBillingNameError] = useState("");
  const [billingEmailAddressError, setBillingEmailAddressError] = useState("");
  const [billingAddressError, setBillingAddressError] = useState("");
  const [billingPostcodeError, setBillingPostcodeError] = useState("");
  const [billingCityError, setBillingCityError] = useState("");
  const [billingStateError, setBillingStateError] = useState("");
  const [billingCountryError, setBillingCountryError] = useState("");

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const togglePreviewModal = () => setShowPreviewModal(!showPreviewModal);

  const validateForm = () => {
    // Reset all error states
    setPicNameError("");
    setPicContactNumberError("");
    setPicEmailAddressError("");
    setCompanyNameError("");
    setCompanyContactNumberError("");
    setCompanyEmailAddressError("");
    setBusinessNatureError("");
    setRegistrationNumberError("");
    setCompanyAboutUsError("");
    setCompanyAddressError("");
    setPostcodeError("");
    setCityError("");
    setStateError("");
    setCountryError("");
    setBillingNameError("");
    setBillingEmailAddressError("");
    setBillingAddressError("");
    setBillingPostcodeError("");
    setBillingCityError("");
    setBillingStateError("");
    setBillingCountryError("");

    let isValid = true;

    if (!formFields.picName) {
      setPicNameError("Person in Charge Name is required.");
      isValid = false;
    }

    if (!formFields.picContactNumber) {
      setPicContactNumberError("Person in Charge Contact Number is required.");
      isValid = false;
    }

    if (!formFields.picEmailAddress) {
      setPicEmailAddressError("Person in Charge Email is required.");
      isValid = false;
    }

    if (!formFields.companyName) {
      setCompanyNameError("Company Name is required.");
      isValid = false;
    }

    if (!formFields.companyContactNumber) {
      setCompanyContactNumberError("Company Contact Number is required.");
      isValid = false;
    }

    if (!formFields.companyEmailAddress) {
      setCompanyEmailAddressError("Company Email Address is required.");
      isValid = false;
    }

    if (!formFields.businessNature) {
      setBusinessNatureError("Business Nature is required.");
      isValid = false;
    }

    if (!formFields.registrationNumber) {
      setRegistrationNumberError("Registration Number is required.");
      isValid = false;
    }

    if (!formFields.companyAboutUs) {
      setCompanyAboutUsError("Company About Us is required.");
      isValid = false;
    }

    if (!formFields.companyAddressText) {
      setCompanyAddressError("Company Address is required.");
      isValid = false;
    }

    if (!formFields.companyPostcode) {
      setPostcodeError("Postcode is required.");
      isValid = false;
    }

    if (!formFields.companyCity) {
      setCityError("City is required.");
      isValid = false;
    }

    if (!formFields.stateId) {
      setStateError("State is required.");
      isValid = false;
    }

    if (!formFields.countryId) {
      setCountryError("Country is required.");
      isValid = false;
    }

    if (!formFields.billingCompanyName) {
      setBillingNameError("Billing Name is required.");
      isValid = false;
    }

    if (!formFields.billingCompanyEmailAddress) {
      setBillingEmailAddressError("Billing Email Address is required.");
      isValid = false;
    }

    if (!formFields.billingCompanyAddress) {
      setBillingAddressError("Billing Address is required.");
      isValid = false;
    }

    if (!formFields.billingPostcode) {
      setBillingPostcodeError("Billing Postcode is required.");
      isValid = false;
    }

    if (!formFields.billingCity) {
      setBillingCityError("Billing City is required.");
      isValid = false;
    }

    if (!formFields.billingState) {
      setBillingStateError("Billing State is required.");
      isValid = false;
    }

    if (!formFields.billingCountry) {
      setBillingCountryError("Billing Country is required.");
      isValid = false;
    }

    return isValid;
  };

  // const formDataForPreview = {
  //   picName,
  //   picContactNumberPrefix,
  //   picContactNumber,
  //   picWhatsappNumber,
  //   picEmailAddress,
  //   nature,
  //   companyWebsiteAddress,
  //   companySocialMediaURL,
  //   companyAboutUs,
  //   companyPostcode,
  //   companyCity,
  //   companyAddressText,
  //   stateId,
  //   countryId,
  //   photos,
  //   isSameAddress,
  //   billingCompanyName,
  //   billingCompanyEmailAddress,
  //   billingCompanyAddress,
  //   billingPostcode,
  //   billingCity,
  //   billingState,
  //   billingCountry,
  // };

  const handleSubmit = () => {
    if (validateForm()) {
      const submitFields = convertFormFields(formFields);
      if (isEditMode) {
        // Handle update logic
        onSubmit({ ...submitFields, id: initialFormData.id }, true);
      } else {
        // Handle create logic
        onSubmit(submitFields, false);
      }
    }
  };

  const handleLogoChange = (base64Value) => {
    handleChange({ target: { name: 'logoBase64', value: base64Value } });
  };

  const handleRemovePhoto = (photo, photoIndex) => {
    // Create a copy of the current photos array
    const photosCopy = [...photos];
    // Remove the element at the specified index
    photosCopy.splice(photoIndex, 1);
    // Update the state with the modified array
    setPhotos(photosCopy);
  };

  const handleCountryChange = (e, isBilling = false) => {
    const { value: selectedCountryId } = e.target;
    // Determine the field name based on whether it's billing or not
    const fieldName = isBilling ? 'billingCountry' : 'countryId';
  
    // Update the appropriate countryId/billingCountry in formFields state
    setFormFields(prev => ({ ...prev, [fieldName]: selectedCountryId }));
  
    // Fetch states for the selected country
    getStates(selectedCountryId);
  };
  
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    // Update isSameAddress directly in the formFields state
    setFormFields(prev => ({ ...prev, isSameAddress: isChecked }));
  
    if (isChecked) {
      // Copy company address fields to billing address fields
      setFormFields(prev => ({
        ...prev,
        billingCompanyAddress: prev.companyAddressText,
        billingCompanyEmailAddress: prev.companyEmailAddress,
        billingPostcode: prev.companyPostcode,
        billingCity: prev.companyCity,
        billingState: prev.stateId,
        billingCountry: prev.countryId,
      }));
    } else {
      // Clear billing address fields
      setFormFields(prev => ({
        ...prev,
        billingCompanyName: '',
        billingCompanyEmailAddress: '',
        billingCompanyAddress: '',
        billingPostcode: '',
        billingCity: '',
        billingState: '',
        billingCountry: '',
      }));
    }
  };

  useEffect(() => {
    // Only sync the addresses if the checkbox is checked
    if (formFields.isSameAddress) {
      setFormFields(prev => ({
        ...prev,
        billingCompanyName: prev.companyName,
        billingCompanyEmailAddress: prev.companyEmailAddress,
        billingCompanyAddress: prev.companyAddressText,
        billingPostcode: prev.companyPostcode,
        billingCity: prev.companyCity,
        billingState: prev.stateId,
        billingCountry: prev.countryId,
      }));
    }
  }, [
    formFields.companyName,
    formFields.companyEmailAddress,
    formFields.companyAddressText,
    formFields.companyPostcode,
    formFields.companyCity,
    formFields.stateId,
    formFields.countryId,
    formFields.isSameAddress
  ]);
  

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <span style={{ fontWeight: "bold" }}>Advertiser ID: 94920</span>
          <br></br>
          <br></br>

          <FormGroup>
            <Label for="picName" style={{ fontWeight: "bold" }}>
              Person in Charge Name <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              name="picName"
              type={"text"}
              placeholder={"Enter PIC Name"}
              value={formFields.picName}
              onChange={handleChange}
              invalid={!!picNameError}
            />
            <FormFeedback>{picNameError}</FormFeedback>
          </FormGroup>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="picContactNumber" style={{ fontWeight: "bold" }}>
                Person in Charge Contact Number{" "}
                <span className="text-danger">*</span>{" "}
              </Label>
              <div style={{ display: "flex" }}>
                <Input
                  type="select"
                  name="picContactNumberPrefixButton"
                  id="picContactNumberPrefixButton"
                  value={formFields.picContactNumberPrefix}
                  onChange={handleChange}
                  style={{ width: "25%" }}
                >
                  <option>+60</option>
                  <option>+61</option>
                </Input>
                <Input
                  name="picContactNumber"
                  type={"text"}
                  placeholder={"Eg: 601234567890"}
                  value={formFields.picContactNumber}
                  onChange={handleChange}
                  invalid={!!picContactNumberError}
                />
                <FormFeedback>{picContactNumberError}</FormFeedback>
              </div>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label style={{ fontWeight: "bold" }}>
                Person in Charge Email Address
                <span className="text-danger">*</span>
              </Label>
              <Input
                name="picEmailAddress"
                type={"text"}
                placeholder={"Enter PIC Email Address"}
                value={formFields.picEmailAddress}
                onChange={handleChange}
                invalid={!!picEmailAddressError}
              />
              <FormFeedback>{picEmailAddressError}</FormFeedback>
            </FormGroup>
          </div>
          <Label style={{ fontWeight: "bold" }} for="nature">
            Upload Logo
          </Label>
          <FormGroup>
            <InputFile
              onLoadAsset={true}
              multipleInput={false}
              returnBase64={true}
              maxSizeInBytes={5242880} // 5MB in bytes
              setBase64Callback={handleLogoChange}
              fileValue={formFields.logoBase64}
              onRemoveFile={handleLogoChange}
            ></InputFile>
            <span>
              {" "}
              *PNG, JPG or JPEG formats only
            </span>
            <br></br>
            <span> *Max Size: 5MB</span>
          </FormGroup>

          <FormGroup>
            <Label for="companyName" style={{ fontWeight: "bold" }}>
              Company Name <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              name="companyName"
              type={"text"}
              placeholder={"Enter Company Name"}
              value={formFields.companyName}
              onChange={handleChange}
              invalid={!!companyNameError}
            />
            <FormFeedback>{companyNameError}</FormFeedback>
          </FormGroup>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label style={{ fontWeight: "bold" }} for="nature">
                Company Industry <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="businessNature"
                id="businessInputButton"
                value={formFields.businessNature}
                onChange={handleChange}
                invalid={!!businessNatureError}
              >
                <option disabled value="">
                  Select Company Industry
                </option>
                {industriesOptions.map((nature) => (
                  <option value={nature.value}>{nature.label}</option>
                ))}
              </Input>
              <FormFeedback>{businessNatureError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="registrationNumber" style={{ fontWeight: "bold" }}>
                Registration Number <span className="text-danger">*</span>{" "}
              </Label>
              <Input
                name="registrationNumber"
                type={"text"}
                placeholder={"Enter Registration Number"}
                value={formFields.registrationNumber}
                onChange={handleChange}
                invalid={!!registrationNumberError}
              />
              <FormFeedback>{registrationNumberError}</FormFeedback>
            </FormGroup>
          </div>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="companyContactNumber" style={{ fontWeight: "bold" }}>
                Company Contact Number <span className="text-danger">*</span>{" "}
              </Label>
              <div style={{ display: "flex" }}>
                <Input
                  type="select"
                  name="companyContactNumberPrefixButton"
                  id="companyContactNumberPrefixButton"
                  value={formFields.companyContactNumberPrefix}
                  onChange={handleChange}
                  style={{ width: "25%" }}
                >
                  <option>+60</option>
                  <option>+61</option>
                </Input>
                <Input
                  name="companyContactNumber"
                  type={"text"}
                  placeholder={"Eg: 601234567890"}
                  value={formFields.companyContactNumber}
                  onChange={handleChange}
                  invalid={!!companyContactNumberError}
                />
                <FormFeedback>{companyContactNumberError}</FormFeedback>
              </div>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="companyWhatsappNumber" style={{ fontWeight: "bold" }}>
                Company WhatsApp Number{" "}
              </Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6
                  style={{
                    fontWeight: "bold",
                    paddingRight: "2%",
                    verticalAlign: "middle",
                  }}
                >
                  wa.me/
                </h6>
                <Input
                  name="companyWhatsappNumber"
                  type={"text"}
                  placeholder={"Eg: 601234567890"}
                  value={formFields.companyWhatsappNumber}
                  onChange={handleChange}
                />
              </div>
            </FormGroup>
          </div>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>
              Company Email Address<span className="text-danger">*</span>
            </Label>
            <Input
              name="companyEmailAddress"
              type={"text"}
              placeholder={"Enter Company Email Address"}
              value={formFields.companyEmailAddress}
              onChange={handleChange}
              invalid={!!companyEmailAddressError}
            />
            <FormFeedback>{companyEmailAddressError}</FormFeedback>
          </FormGroup>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="websiteAddress" style={{ fontWeight: "bold" }}>
                Company Website URL{" "}
              </Label>
              <Input
                name="companyWebsiteAddress"
                type={"text"}
                placeholder={"Enter Website URL"}
                value={formFields.companyWebsiteAddress}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="socialMediaURL" style={{ fontWeight: "bold" }}>
                Company Social Media URL{" "}
              </Label>
              <Input
                name="companySocialMediaURL"
                type={"text"}
                placeholder={"Enter Social Media URL"}
                value={formFields.companySocialMediaURL}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Label for="companyAboutUs" style={{ fontWeight: "bold" }}>
                Company About Us <span className="text-danger">*</span>
              </Label>
              <text>{formFields.companyAboutUs.length}/2000 characters</text>
            </div>

            <Input
              name="companyAboutUs"
              type="textarea"
              placeholder="Describe Your Company"
              style={{ height: "10rem", width: "100%", verticalAlign: "top" }}
              value={formFields.companyAboutUs}
              onChange={handleChange}
              invalid={!!companyAboutUsError}
            />
            <FormFeedback>{companyAboutUsError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>
              Address<span className="text-danger">*</span>
            </Label>
            <Input
              name="companyAddressText"
              type={"text"}
              placeholder={"Enter Company Address"}
              value={formFields.companyAddressText}
              onChange={handleChange}
              invalid={!!companyAddressError}
            />
            <FormFeedback>{companyAddressError}</FormFeedback>
          </FormGroup>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="postcode" style={{ fontWeight: "bold" }}>
                Postcode <span className="text-danger">*</span>
              </Label>
              <Input
                name="companyPostcode"
                type={"text"}
                placeholder={"Enter your postcode"}
                value={formFields.companyPostcode}
                onChange={handleChange}
                invalid={!!postcodeError}
              />
              <FormFeedback>{postcodeError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="city" style={{ fontWeight: "bold" }}>
                City <span className="text-danger">*</span>
              </Label>
              <Input
                name="companyCity"
                type={"text"}
                placeholder={"Enter your city"}
                value={formFields.companyCity}
                onChange={handleChange}
                invalid={!!cityError}
              />
              <FormFeedback>{cityError}</FormFeedback>
            </FormGroup>
          </div>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="state" style={{ fontWeight: "bold" }}>
                State <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="stateId"
                id="stateInputButton"
                value={formFields.stateId}
                onChange={handleChange}
                invalid={!!stateError}
              >
                <option disabled value="">
                  Select State
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state.label}>
                    {state.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{stateError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="country" style={{ fontWeight: "bold" }}>
                Country <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="countryId"
                id="stateInputButton"
                value={formFields.countryId}
                onChange={(e) => handleCountryChange(e, false)}
                invalid={!!countryError}
              >
                <option disabled value="">
                  Select Country
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{countryError}</FormFeedback>
            </FormGroup>
          </div>

          <FormGroup>
            <Label for="photo" style={{ fontWeight: "bold" }}>
              Upload Photo{" "}
            </Label>{" "}
            <br></br>
            <span>
              {" "}
              It's recommended to upload photos related to your company, such as the work environment, team-building activities, annual dinners, and company trips.<br />
              This will help candidates know your company better!<br /><br />
              *Size: 1280px x 720px<br />
              *Ratio: 16:9<br />
              *Max Size: 2MB

            </span>
            <br></br>
            <ImageField
              returnBase64={true}
              imageData={photos}
              onAddFile={(file) => setPhotos([...photos, { url: file }])}
              onRemove={handleRemovePhoto}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card className="main-card mb-3">
        <CardBody>
          <span style={{ fontWeight: "bold" }}>Billing Info</span>
          <br></br>
          <br></br>
          <FormGroup>
            <Label for="billingCompanyName" style={{ fontWeight: "bold" }}>
              Company Name <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              name="billingCompanyName"
              type={"text"}
              placeholder={"Enter Company Name"}
              value={formFields.billingCompanyName}
              onChange={handleChange}
              invalid={!!billingNameError}
            />
            <FormFeedback>{billingNameError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label
              for="billingCompanyEmailAddress"
              style={{ fontWeight: "bold" }}
            >
              Company Email Address <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              name="billingCompanyEmailAddress"
              type={"text"}
              placeholder={"Enter Company Email Address"}
              value={formFields.billingCompanyEmailAddress}
              onChange={handleChange}
              invalid={!!billingEmailAddressError}
            />
            <FormFeedback>{billingEmailAddressError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="billingCompanyAddress" style={{ fontWeight: "bold" }}>
              Address <span className="text-danger">*</span>{" "}
            </Label>
            <Input
              name="billingCompanyAddress"
              type={"text"}
              placeholder={"Enter Company Address"}
              value={formFields.billingCompanyAddress}
              onChange={handleChange}
              invalid={!!billingAddressError}
            />
            <FormFeedback>{billingAddressError}</FormFeedback>
          </FormGroup>

          <FormGroup check>
            <Input 
              type="checkbox" 
              checked={formFields.isSameAddress}
              onChange={handleCheckboxChange}
            />
            <Label check>Same as Company Address</Label>
          </FormGroup>
          <br></br>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="billingPostcode" style={{ fontWeight: "bold" }}>
                Postcode <span className="text-danger">*</span>
              </Label>
              <Input
                name="billingPostcode"
                type={"text"}
                placeholder={"Enter postcode"}
                value={formFields.billingPostcode}
                onChange={handleChange}
                invalid={!!billingPostcodeError}
              />
              <FormFeedback>{billingPostcodeError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="billingCity" style={{ fontWeight: "bold" }}>
                City <span className="text-danger">*</span>
              </Label>
              <Input
                name="billingCity"
                type={"text"}
                placeholder={"Enter your city"}
                value={formFields.billingCity}
                onChange={handleChange}
                invalid={!!billingCityError}
              />
              <FormFeedback>{billingCityError}</FormFeedback>
            </FormGroup>
          </div>

          <div style={{ display: "flex" }}>
            <FormGroup style={{ width: "50%", paddingRight: "2%" }}>
              <Label for="billingState" style={{ fontWeight: "bold" }}>
                State <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="billingState"
                id="stateInputButton"
                value={formFields.billingState}
                onChange={handleChange}
                invalid={!!billingStateError}
              >
                <option disabled value="">
                  Select State
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state.label}>
                    {state.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{billingStateError}</FormFeedback>
            </FormGroup>
            <FormGroup style={{ width: "50%", paddingLeft: "2%" }}>
              <Label for="country" style={{ fontWeight: "bold" }}>
                Country <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="billingCountry"
                id="stateInputButton"
                value={formFields.billingCountry}
                onChange={(e) => handleCountryChange(e, true)}
                invalid={!!billingCountryError}
              >
                <option disabled value="">
                  Select Country
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{billingCountryError}</FormFeedback>
            </FormGroup>
          </div>

          {/* <text>Agent Name: Jane Doe</text> */}
        </CardBody>
      </Card>
      <div className="container-fluid">
        <div className="row justify-content-end">
          <div className="col-auto" style={{ padding: "6px" }}>
            <button 
              type="button" 
              className="btn btn-outline-primary"
              onClick={togglePreviewModal}
            >
              Preview
            </button>
          </div>
          <div className="col-auto" style={{ padding: "6px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {showPreviewModal && (
        <PreviewModal
          showPreviewModal={showPreviewModal}
          selectedCompany={convertFormFields(formFields)}
          onChangeCompaniesHOC={(key, val) => setShowPreviewModal(val)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.ProfileReducer.profile,
});

export default connect(mapStateToProps)(CreateAdvertiserForm);
