import { Empty } from "antd";
import { useEffect } from "react";
import HOC from "./action";
import JobCard from "components/Cards/JobCard";

function AppliedJobs(props) {

	useEffect(() => {
    props.getApplied(props.jobSeekerProfile.user_id);
  }, [])

	return (
		<>
			<h5 className="e-classified-cont-header mb-4">
        My Job Application
			</h5>
			{props.applied?.[0] && (
				<div className="row">
					{props.applied.map((item) => (
						<div className="col-lg-6 mb-4">
							<JobCard item={item} context={"applied"} />
						</div>
					))}
				</div>
			)}
			{props.applied.length < 1 && (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"暂无内容"} />
			)}
		</>
	);
}

export default HOC(AppliedJobs);
