import PDfViewer from "components/PDFViewer";
import { useState } from "react";
import { AiOutlineDownload, AiOutlineEdit } from "react-icons/ai";
import { downloadImage } from "utils/downloadImg";
import HOC from "../actions/index";
import UploadIcon from "./components/uploadIcon";
import getDomainURL from "utils/api";

function Resume(props) {

	const [currentTab, setCurrentTab] = useState("jobmacha");

	console.log('props here');
	console.log(props);

	return (
		<>
			<h5 className="mb-4s" style={{ fontWeight: 600 }}>
				My Resume
			</h5>
			<div className="d-flex" style={{ borderBottom: "1px solid #D9D9D9" }}>
				<div
					onClick={() => setCurrentTab("jobmacha")}
					style={{ cursor: "pointer" }}
					className={`p-3 ${
						currentTab === "jobmacha"
							? "text-primary border-bottom border-primary font-weight-bold"
							: "text-secondary"
					}`}>
					<span>JobMacha Resume</span>
				</div>
				<div
					onClick={() => setCurrentTab("uploaded")}
					style={{ cursor: "pointer" }}
					className={`p-3 ${
						currentTab === "uploaded"
							? "text-primary border-bottom border-primary font-weight-bold"
							: "text-secondary"
					}`}>
					<span>Uploaded Resume</span>
				</div>
			</div>
			{props.jobSeekerProfile && (
				<>
					<div className="d-flex mt-4 mb-4">
						{currentTab === "jobmacha" && (
							<AiOutlineEdit
								className="ml-auto"
								style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
							/>
						)}
						{currentTab === "uploaded" && <UploadIcon {...props} />}
						{(currentTab !== "uploaded" ||
							(currentTab === "uploaded" &&
								props.jobSeekerProfile.resume_url)) && (
							<AiOutlineDownload
								className="ml-4"
								style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
								onClick={() => {
									currentTab === "uploaded"
										? window.open(props.jobSeekerProfile.resume_url)
										: downloadImage(
												`${getDomainURL()}/jobseeker-profiles-gen-image/${props.jobSeekerProfile.id}`,
												`${props.jobSeekerProfile.user.name}_resume`
										  );
								}}
							/>
						)}
					</div>
					<div
						style={{ background: "#F5F5F5", borderRadius: "0.625rem" }}
						className="p-3 w-100">
						{currentTab !== "uploaded" && props.jobSeekerProfile && (
							<PDfViewer
								url={`${getDomainURL()}/jobseeker-profiles-gen-image/${props.jobSeekerProfile.id}`}
							/>
						)}
						{currentTab === "uploaded" && props.jobSeekerProfile.resume_url && (
							<PDfViewer url={props.jobSeekerProfile.resume_url} />
						)}
					</div>
				</>
			)}
		</>
	);
}

export default HOC(Resume);
