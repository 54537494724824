export function downloadImage(apiUrl, fileName) {
	// Make a GET request to the API endpoint
	fetch(apiUrl)
		.then((response) => {
			// Check if the response is successful (status code 200)
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			return response.blob(); // Extract the image data as a Blob
		})
		.then((blob) => {
			// Create a link element
			const link = document.createElement("a");

			// Create a Blob URL for the image data
			const blobUrl = window.URL.createObjectURL(blob);

			// Set the href attribute of the link to the Blob URL
			link.href = blobUrl;

			// Set the download attribute to specify the file name
			link.download = fileName;

			// Append the link to the document
			document.body.appendChild(link);

			// Trigger a click on the link to initiate the download
			link.click();

			// Remove the link from the document
			document.body.removeChild(link);
		})
		.catch((error) => {
			console.error("Error downloading image:", error);
		});
}
