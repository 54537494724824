// eClassified Modal Component
// eClassified Modal Header
// Server Side Component

import React from 'react';
import { Button } from 'reactstrap';
import { IoMdClose } from  'react-icons/io';
import "./index.scss";

export default function CustomModalHeader({
  leftChild,
  title,
  rightChild,
  style,
  onPressCloseModal,
}) {
  return (
    <div className="e-classified-dialog-header-container" style={ style }>
      { leftChild
        ? leftChild
        : <div className="e-classified-dialog-header-icon-container" />
      }
      <p className='e-classified-dialog-header text-black text-center'>
        { title }
      </p>
      { rightChild
        ? rightChild
        : <div className="e-classified-dialog-header-icon-container">
            <Button
              onClick={ onPressCloseModal }
              className="e-classified-dialog-header-icon-button"
              color={ 'transparent' }>
              <IoMdClose fontSize={ 20 } className='text-primary' />
            </Button>
          </div>
      }
    </div>    
  )
}
