import React, { useState, useEffect } from "react";
import {
  Label,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
} from "reactstrap";

export function BillingDetailsForm({ id, getBilling }) {
  const [orderId, setOrderId] = useState("");
  const [proformaNo, setProformaNo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [paymentReferences, setPaymentReferences] = useState("");
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [remark, setRemark] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageDetails, setPackageDetails] = useState("");
  const [printPrice, setPrintPrice] = useState("");
  const [grandTotal, setGrandTotal] = useState("");

  // loading state
  const [loading, setLoading] = useState(false);

  const fillValues = (data) => {
    const {
      id,
      proforma_invoice_number,
      invoice_number,
      receipt_number,
      payment_reference_number,
      company,
      billing_name,
      billing_address,
      billing_postcode,
      billing_city,
      billing_state,
      billing_country,
      subtotal,
      discount,
      tax,
      grand_total,
      description,
      payment_method,
      print_price,
    } = data;

    setOrderId(id);
    setProformaNo(proforma_invoice_number);
    setInvoiceNo(invoice_number);
    setReceiptNo(receipt_number);
    setPaymentReferences(payment_reference_number);
    setName(billing_name);
    setEmailAddress(company?.email);
    setPhoneNumber(company?.contact_number);
    setAddress(billing_address);
    setPostcode(billing_postcode);
    setCity(billing_city);
    setState(billing_state);
    setCountry(billing_country);
    setSubtotal(Number(subtotal).toFixed(2));
    setDiscount(Number(discount).toFixed(2));
    setTax(Number(tax).toFixed(2));
    setTotal(Number(grand_total).toFixed(2));
    setReferenceNumber(payment_reference_number);
    setRemark(description);
    setPaymentMethod(payment_method);
    setPackageName(data.order_plans ? data.order_plans[0].plan_name.en : "");
    setPackageDetails(data.order_plans ? data.order_plans[0].plan_description.en : "");
    setPrintPrice(print_price);
    setGrandTotal(grand_total);
  };

  const getBillingAsync = (querystring = "") => {
    return new Promise((resolve, reject) => {
      getBilling(querystring, resolve, reject);
    });
  };

  const fetchData = async (id) => {
    try {
      setLoading(true);
      const response = await getBillingAsync(id);
      fillValues(response[0]);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetch data and fill form fields if id is provided
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <CardTitle tag="h5">Billing Details</CardTitle>
              <Row style={{ marginTop: "20px" }}>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="orderId">
                      <b>Order No.</b>
                    </Label>
                    <Input
                      name="orderId"
                      id="orderId"
                      value={orderId}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={8}>
                  <FormGroup>
                    <Label for="paymentReferences">
                      <b>Payment Ref No.</b>
                    </Label>
                    <Input
                      name="paymentReferences"
                      id="paymentReferences"
                      value={paymentReferences}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="proformaNo">
                      <b>Proforma Invoice</b>
                    </Label>
                    <Input
                      name="proformaNo"
                      id="proformaNo"
                      value={proformaNo}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="invoiceNo">
                      <b>Invoice No.</b>
                    </Label>
                    <Input
                      name="invoiceNo"
                      id="invoiceNo"
                      value={invoiceNo}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="receiptNo">
                      <b>Receipt No.</b>
                    </Label>
                    <Input
                      name="receiptNo"
                      id="receiptNo"
                      value={receiptNo}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>

      <Card className="main-card mb-3">
        <CardBody>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <CardTitle tag="h5">User Details</CardTitle>
              <Row style={{ marginTop: "20px" }}>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="name">
                      <b>Name</b>
                    </Label>
                    <Input name="name" id="name" value={name} disabled />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="emailAddress">
                      <b>Email Address</b>
                    </Label>
                    <Input
                      name="emailAddress"
                      id="emailAddress"
                      value={emailAddress}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="phoneNumber">
                      <b>Phone Number</b>
                    </Label>
                    <Input
                      name="phoneNumber"
                      id="phoneNumber"
                      value={phoneNumber}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="address">
                      <b>Address</b>
                    </Label>
                    <Input
                      name="address"
                      id="address"
                      value={address}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="postcode">
                      <b>Postcode</b>
                    </Label>
                    <Input
                      name="postcode"
                      id="postcode"
                      value={postcode}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="city">
                      <b>City</b>
                    </Label>
                    <Input name="city" id="city" value={city} disabled />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="state">
                      <b>State</b>
                    </Label>
                    <Input name="state" id="state" value={state} disabled />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="country">
                      <b>Country</b>
                    </Label>
                    <Input
                      name="country"
                      id="country"
                      value={country}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>

      <Card className="main-card mb-3">
        <CardBody>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <CardTitle tag="h5">Payment Details</CardTitle>
              <Row style={{ marginTop: "20px" }}>
                <Col lg={3}>
                  <FormGroup>
                    <Label for="subtotal">
                      <b>Sub-Total</b>
                    </Label>
                    <Input
                      name="subtotal"
                      id="subtotal"
                      type="number"
                      value={subtotal}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <Label for="discount">
                      <b>Discount</b>
                    </Label>
                    <Input
                      name="discount"
                      id="discount"
                      type="number"
                      value={discount}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <Label for="tax">
                      <b>Taxes</b>
                    </Label>
                    <Input
                      name="tax"
                      id="tax"
                      type="number"
                      value={tax}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <Label for="total">
                      <b>Total</b>
                    </Label>
                    <Input
                      name="total"
                      id="total"
                      type="number"
                      value={total}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="printPrice">
                      <b>Print Ads Price (Inc Tax)</b>
                    </Label>
                    <Input
                      name="printPrice"
                      id="printPrice"
                      type="number"
                      value={printPrice}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="grandTotal">
                      <b>Grand Total</b>
                    </Label>
                    <Input
                      name="grandTotal"
                      id="grandTotal"
                      type="number"
                      value={grandTotal}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="referenceNumber">
                      <b>Ref No.</b>
                    </Label>
                    <Input
                      name="referenceNumber"
                      id="referenceNumber"
                      value={referenceNumber}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="remark">
                      <b>Remark</b>
                    </Label>
                    <Input name="remark" id="remark" value={remark} disabled />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="paymentMethod">
                      <b>Payment Method</b>
                    </Label>
                    <Input
                      name="paymentMethod"
                      id="paymentMethod"
                      value={paymentMethod}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>

      <Card className="main-card mb-3">
        <CardBody>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <CardTitle tag="h5">Plan Details</CardTitle>
              <Row style={{ marginTop: "20px" }}>
                <Col lg={2}>
                  <FormGroup>
                    <Label for="packageName">
                      <b>Plan</b>
                    </Label>
                    <Input
                      name="packageName"
                      id="packageName"
                      value={packageName}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={10}>
                  <FormGroup>
                    <Label for="packageDetails">
                      <b>Plan Details</b>
                    </Label>
                    <Input
                      name="packageDetails"
                      id="packageDetails"
                      value={packageDetails}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
