import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Label,
  FormGroup,
  Input,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { debounce } from "utils/helper";
import "./editPromoCode.scss";

export function EditNewVoucherForm({
  selectedTag,
  onSubmit,
  roleList,
  searchUser,
  getVoucher,
  selectedRowData,
  getRole,
}) {
  const [userRoleList, setUserRoleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUser, setSelectedUser] = useState({
    userIdText: "",
    usernameText: "",
  });
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherType, setVoucherType] = useState("amount");
  const [value, setValue] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [remarks, setRemarks] = useState("");

  // validation state variables
  const [voucherTypeError, setVoucherTypeError] = useState("");
  const [voucherCodeError, setVoucherCodeError] = useState("");
  const [valueError, setValueError] = useState("");
  const [quantityError, setQuantityError] = useState("");

  const [promisedUserList, setPromisedUserList] = useState([]);
  const [updatedVoucherRoles, setUpdatedVoucherRoles] = useState([]);
  const [updatedVoucherUsers, setUpdatedVoucherUsers] = useState([]);

  const validateForm = () => {
    // Reset all error states
    setVoucherTypeError("");
    setVoucherCodeError("");
    setValueError("");
    setQuantityError("");

    let isValid = true;

    // Validate voucherType
    if (!voucherType) {
      setVoucherTypeError("Voucher Type is required.");
      isValid = false;
    }

    // Validate voucherCode
    if (!voucherCode) {
      setVoucherCodeError("Voucher Code is required.");
      isValid = false;
    }

    // Validate value
    if (value <= 0) {
      setValueError("Value must be greater than zero.");
      isValid = false;
    }

    // Validate quantity
    if (quantity <= 0) {
      setQuantityError("Quantity must be greater than zero.");
      isValid = false;
    }

    // Add more validation rules for other fields as needed

    return isValid;
  };

  const handleSubmit = () => {
    // Check form validation
    if (validateForm()) {
      // Call the onSubmit callback if the form is valid
      onSubmit({
        type: voucherType,
        code: voucherCode,
        value: parseFloat(value),
        quantity: parseInt(quantity),
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        remark: remarks,
        status: "publish",
        uuid: uuidv4(),
        voucher_users: userList.map((user) => user.userId),
        voucher_roles: userRoleList.map((role) => role.roleId),
        platform: "jobmacha",
      });
    }
  };

  const setUserRoleInput = (selectedRole, selectedRoleId) => {
    if (!userRoleList.some((userRole) => userRole.roleId === selectedRoleId)) {
      setUserRoleList([
        ...userRoleList,
        { roleName: selectedRole, roleId: selectedRoleId },
      ]);
    }
  };

  const deleteUserRoleInput = (indexToDelete) => {
    const updatedUserRoleList = userRoleList.filter(
      (_, index) => index !== indexToDelete
    );
    setUserRoleList(updatedUserRoleList);
  };

  const addUserList = (userInput) => {
    if (userInput.userIdText && userInput.usernameText) {
      if (
        !userList.some(
          (user) =>
            user.userId === userInput.userIdText &&
            user.username === userInput.usernameText
        )
      ) {
        setUserList([
          ...userList,
          { userId: userInput.userIdText, username: userInput.usernameText },
        ]);
      }
    }
  };

  const deleteUserList = (indexToDelete) => {
    const updatedUserList = userList.filter(
      (_, index) => index !== indexToDelete
    );
    setUserList(updatedUserList);
  };

  const debouncedSearchUser = debounce(searchUser, 300); // Adjust the debounce delay as needed

  const promiseOptions = async (inputValue) => {
    try {
      const results = await new Promise((resolve, reject) => {
        debouncedSearchUser(inputValue, resolve, reject);
      });

      setPromisedUserList(results);
      return results.map((result) => ({
        label: result.name,
        value: { id: result.id, name: result.name },
      }));
    } catch (error) {
      // Handle error appropriately
      throw error;
    }
  };

  const updateVoucherRoles = () => {
    if (selectedRowData?.voucher_roles && roleList?.length > 0) {
      const updatedRoles = selectedRowData.voucher_roles.map((voucherRole) => {
        const roleDetail = roleList.find(
          (role) => role.id === voucherRole.role_id
        );
        return {
          ...voucherRole,
          roleName: roleDetail ? roleDetail.display_name : "",
          roleId: roleDetail ? roleDetail.id : 0
        };
      });
      setUpdatedVoucherRoles(updatedRoles);
    }
  };

  const updateVoucherUsers = () => {
    if (selectedRowData?.voucher_users && promisedUserList?.length > 0) {
      const updatedUsers = selectedRowData.voucher_users.map((voucherUser) => {
        const userDetails = promisedUserList.find(
          (user) => user.id === voucherUser.user_id
        );
        return {
          ...voucherUser,
          username: userDetails ? userDetails.name : "User not found",
          userId : userDetails ? userDetails.id : 0
        };
      });
      setUpdatedVoucherUsers(updatedUsers);
    }
  };

  useEffect(() => {
    getVoucher();
    getRole();
  }, []);

  useEffect(() => {
    if (roleList?.length > 0 && selectedRowData?.id) {
      updateVoucherRoles();
      updateVoucherUsers();
    }
    if(updatedVoucherRoles?.length > 0 || updatedVoucherUsers?.length > 0){
        setUserRoleList(updatedVoucherRoles)
        setUserList(updatedVoucherUsers)
        setVoucherCode(selectedRowData?.code)
        setVoucherType(selectedRowData?.type)
        setValue(selectedRowData?.value)
        setQuantity(selectedRowData?.quantity)
        setStartDate(new Date(selectedRowData?.start_date) || null)
        setEndDate(new Date(selectedRowData?.end_date) || null)
        setRemarks(selectedRowData?.remark)
    }
  }, [
    selectedRowData?.id,
    roleList?.length,
    updatedVoucherRoles?.length,
    promisedUserList?.length,
    updatedVoucherUsers?.length,
  ]);

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <>
            <span style={{ fontWeight: "bold" }}>Promo Code ID: V00001</span>
            <br></br>
            <br></br>
            <FormGroup>
              <Label style={{ fontWeight: "bold" }} for="userRole">
                User (by User Role)
              </Label>
              <Select
                name="userRoleSelect"
                placeholder="Select..."
                isClearable
                isSearchable
                onChange={(newValue) => {
                  if (!!newValue) {
                    setUserRoleInput(newValue.label, newValue.value);
                  }
                }}
                options={roleList.map((role) => ({
                  label: role.display_name,
                  value: role.id,
                }))}
              />
            </FormGroup>
            <span>
              <ul style={{ paddingLeft: "1%" }}>
                {userRoleList.map((userRole, index) => {
                  return (
                    <li key={index}>
                      <div
                        style={{
                          margin: "0.2%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}>
                        <span style={{ color: "blue" }}>
                          {userRole.roleName}
                        </span>
                        <Button
                          id={index}
                          onClick={() => deleteUserRoleInput(index)}
                          style={{ backgroundColor: "none" }}
                          className="pe-7s-close-circle"
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </span>

            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>
                User (by Specific User)
              </Label>
              <div className="selectSpecificUser">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  styles={{ input: (styles) => ({ ...styles, width: "100%" }) }}
                  onChange={(newValue) => {
                    if (!!newValue) {
                      setSelectedUser({
                        userIdText: newValue.value.id,
                        usernameText: newValue.value.name,
                      });
                    } else {
                      setSelectedUser({
                        userIdText: "",
                        usernameText: "",
                      });
                    }
                  }}
                  isClearable
                />
                <button
                  className="add-btn"
                  type="button"
                  onClick={() => addUserList(selectedUser)}>
                  Add
                </button>
              </div>
            </FormGroup>

            <FormGroup>
              {userList.map((user, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      margin: "0.5rem 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <Input
                      type={"text"}
                      style={{
                        width: "48%",
                        backgroundColor: "white",
                        color: "blue",
                      }}
                      value={user.userId}
                      readOnly
                    />
                    <Input
                      type={"text"}
                      style={{
                        width: "48%",
                        backgroundColor: "white",
                        color: "blue",
                      }}
                      value={user.username}
                      readOnly
                    />
                    <Button
                      onClick={() => deleteUserList(index)}
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        color: "gray",
                        fontSize: "1.5rem",
                        padding: "0",
                      }}
                      className="pe-7s-close-circle"
                    />
                  </div>
                );
              })}
            </FormGroup>

            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>Promo Code</Label>
              <Input
                type={"text"}
                placeholder={"Enter Promo Code"}
                value={voucherCode}
                onChange={(e) => {
                  setVoucherCode(e.target.value);
                }}
                invalid={!!voucherCodeError}
              />
              <FormFeedback>{voucherCodeError}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>Promo Type</Label>
              <Input
                type="select"
                placeholder="Select"
                value={voucherType}
                onChange={(e) => {
                  setVoucherType(e.target.value);
                }}
                invalid={!!voucherTypeError}>
                <option>amount</option>
                <option>percentage</option>
              </Input>
              <FormFeedback>{voucherTypeError}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>Promo Value</Label>
              <Input
                type={"number"}
                placeholder={"Example RM100.00"}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                invalid={!!valueError}
              />
              <FormFeedback>{valueError}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>Quantity</Label>
              <Input
                type={"number"}
                placeholder="Enter Quantity"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                invalid={!!quantityError}
              />
              <FormFeedback>{quantityError}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>Start Date</Label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <div style={{ width: "96%" }}>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    className="form-control"
                    selected={startDate}
                    placeholderText={"Select Start Date"}
                    onChange={(val) => {
                      setStartDate(val);
                    }}
                  />
                </div>

                <div className="pe-2x pe-7s-date"></div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>End Date</Label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <div style={{ width: "96%" }}>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    className="form-control"
                    selected={endDate}
                    placeholderText={"Select End Date"}
                    onChange={(val) => {
                      setEndDate(val);
                    }}
                  />
                </div>

                <div className="pe-2x pe-7s-date"></div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>Remarks</Label>
              <Input
                type="textarea"
                placeholder="Enter text here"
                value={remarks}
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </FormGroup>
          </>
        </CardBody>
      </Card>
      <div className="container-fluid">
        <div className="row justify-content-end">
          <div className="col-auto" style={{ padding: "6px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}>
              <i className="pe-lg pe-7s-paper-plane"></i> Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
