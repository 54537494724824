import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			bannerPopupList: [],
			bannerPopupPages: 1,
			bannerPopupCount: 0,
			showFilterModal: false,
			showEditModal: false,
			showPreviewModal: false,
			showPublishModal: false,
			showSuspendModal: false,
			showDisableModal: false,
			selectedPopupId: "",
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getBanner = (querystring = "") =>
			Get(
				`/popup-banners?limit=10&type=popup&location=Jobmacha` + querystring,
				this.getBannerSuccess,
				this.getBannerError,
				this.load
			);
		getBannerSuccess = (payload) => {
			this.setState({
				bannerPopupList: payload.data,
				bannerPopupCount: payload.count,
			});
		};
		getBannerError = (error) => requestError(error);

		updateBanner = (dataToSubmit) => {
			Put(
				`/popup-banners/${dataToSubmit.id}`,
				dataToSubmit,
				this.updateBannerSuccess,
				this.updateBannerError,
				this.load
			);
		};
		updateBannerSuccess = () => {
			this.getBanner();
			this.setState({
				// close modal states
				showSuspendModal: false,
				showDisableModal: false,
			});
			requestSuccess("Banner updated successfully.");
		};
		updateBannerError = (error) => requestError(error);

		// function to toggle display filter modal
		toggleShowFilterModal = () => {
			this.setState((prevState) => ({
				showFilterModal: !prevState.showFilterModal,
			}));
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					bannerPopupList={this.state.bannerPopupList}
					bannerPopupPages={this.state.bannerPopupPages}
					bannerPopupCount={this.state.bannerPopupCount}
					onLoadBanner={this.state.loading}
					getBanner={this.getBanner}
					updateBanner={this.updateBanner}
					onChangeHOC={this.onChangeHOC}
					showFilterModal={this.state.showFilterModal}
					showEditModal={this.state.showEditModal}
					showPreviewModal={this.state.showPreviewModal}
					showPublishModal={this.state.showPublishModal}
					showSuspendModal={this.state.showSuspendModal}
					showDisableModal={this.state.showDisableModal}
					selectedPopupId={this.state.selectedPopupId}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
