import React, { useState } from "react";

import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogoutOutlined } from "@ant-design/icons";
import { clearItem } from "utils/tokenStore";
import { compose } from "redux";
import { connect } from "react-redux";
// import NotificationContent from "./Notification";
import CustomModal from "components/Modal";
import CustomModalBody from "components/Modal/body";

import LoadingOverlay from "components/Indicator/LoadingOverlay";

import WithForgotPassword from "./actions";
import Cookies from "universal-cookie";
import { COOKIES_LOGGED_IN } from "constants/Constants";

const Details = ({ open, content, onClose }) => {
  return (
    <CustomModal isOpen={open} size={"md"} onClose={() => onClose()}>
      <CustomModalBody>
        <span>{content}</span>
      </CustomModalBody>
    </CustomModal>
  );
};

const Userbox = ({ user, history, onLoadForgotPassword }) => {
  const [showDetails, onToggleDetail] = useState(false);
  const [selectedNotification, onChangeSelected] = useState(false);

  const cookies = new Cookies();

  const handleLogout = () => {
    // clear cookies
    cookies.remove(COOKIES_LOGGED_IN);

    // clear local storage
    clearItem("CHINA_PRESS_TOKEN");
    clearItem("SHOW_SIDE_MENU");

    history.push("/login");
  }

  return (
    <>
      <div className="ml-0 pl-0 pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              {/* <NotificationContent
								history={history}
								onToggleDetail={onToggleDetail}
								onChangeSelected={onChangeSelected}
							/> */}
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0" id="account">
                  admin
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                  <UncontrolledTooltip target="account">
                    Account
                  </UncontrolledTooltip>
                </DropdownToggle>
                <div
                  style={{
                    cursor: "pointer",
                    paddingLeft: "1.5rem",
                    backgroundColor: "transparent",
                  }}
                  onClick={handleLogout}
                >
                  <LogoutOutlined />
                </div>

                <DropdownMenu
                  right
                  className="rm-pointers dropdown-menu-lg p-0"
                >
                  <div
                    className="dropdown-menu-header"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="dropdown-menu-header-inner bg-white">
                      <div className="menu-header-image opacity-2" />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3"></div>
                            <div className="widget-content-left">
                              <div className="widget-heading text-dark">{`${user.name}`}</div>
                              <div className="widget-subheading text-dark opacity-8">
                                {user.email || "Email"}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button
                                className="btn-pill bg-dark text-light btn-shadow btn-shine btn-logout"
                                color="focus"
                                onClick={handleLogout}
                              >
                                Logout
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
        {onLoadForgotPassword && <LoadingOverlay />}
      </div>
      <Details
        open={showDetails}
        content={selectedNotification?.content ?? ""}
        onClose={() => onToggleDetail(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default compose(connect(mapStateToProps), WithForgotPassword)(Userbox);
