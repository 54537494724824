import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames'
import Cookies from "universal-cookie";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

class Main extends Component {
  state = {
    closedSmallerSidebar: false,
    isCollapsed: false
  }

  render = () => {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props
    const cookies = new Cookies();
    const isCollapsed = (cookies.get('isNavCollapsed') || this.state.isCollapsed) ?? false
    // const isCollapsed = false
    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                {'fixed-header': enableFixedHeader},
                // {'fixed-sidebar': enableFixedSidebar || width < 1250},
                {'fixed-footer': enableFixedFooter},
                // {'closed-sidebar': enableClosedSidebar || width < 1250},
                {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                {'sidebar-mobile-open': enableMobileMenu},
                {'body-tabs-shadow-btn': enablePageTabsAlt},

                {'closed-sidebar': width < 992 || isCollapsed },
                {'fixed-sidebar': true},
              )}>
              { this.props.children }
              
              <div 
                onClick={() => {
                  const cookies2 = new Cookies();
                  cookies2.set('isNavCollapsed', !isCollapsed)
                  
                  this.setState({
                    isCollapsed: !isCollapsed
                  })


                }}
                style={
                {
                  width: 35,
                  height: 35,
                  borderRadius: 100,
                  background: 'var(--primary)',
                  zIndex: 500,
                  position: 'fixed',
                  top: 80,
                  left: isCollapsed ? 65 : 260,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: '130%',
                  ...width < 992 && {
                    display: 'none'
                  }
                }
                }>
                  { [<LeftOutlined/>, <RightOutlined/> ][+isCollapsed] } 
                </div>

            </div>
          </Fragment>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
})

export default connect( mapStateToProps )( Main )