import React, { Component } from "react";
import { compose } from "redux";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import CreatePostAPI from "./action.js";
import CreatePostForm from "./Form.js";

class CreatePost extends Component {
  componentDidMount = () => {
      this.props.getBenefits()
      this.props.getAgentList()
      this.props.getAdvertiser();
      this.props.getNature();
      this.props.getEducationLevel();
      this.props.getPositionLevel();
      this.props.getPackage();
      this.props.getPlan();
      this.props.getCountry();
      this.props.getRegions();
  };

  render = () => {

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle heading="Manage Post / <b>Create Post</b>"></PageTitle>
                <CreatePostForm mode={'Create'} {...this.props} />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(CreatePostAPI)(CreatePost);
