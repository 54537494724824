import React, { Component } from "react";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import PageTitle from "components/Title/PageTitle";
import CreateUserAPI from "./action.js";
import CreateNewUserForm from "./CreateForm.js";
import EditUserForm from "./EditForm.js";

const CreateUserFormWrapper = (props) => {
  const { id } = useParams();

  return <CreateUserForm id={!!id ? id : null} {...props} />;
};

class CreateUserForm extends Component {
  componentDidMount = () => this.props.getRole();

  render = () => {
    const {
      getRole,
      createUser,
      searchUser,
      id,
      getUser,
      createRole,
      deleteRole,
      updateUser,
    } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <PageTitle
                  heading={`Manage User / <b>${
                    !!id ? "Edit" : "Create New"
                  } User</b>`}
                ></PageTitle>
                <h5>{!!id ? "Edit" : "Create New"} User</h5> <br></br>
                {!!id ? (
                  <EditUserForm
                    onSubmit={updateUser}
                    searchUser={searchUser}
                    getRole={getRole}
                    id={id}
                    getUser={getUser}
                    createRole={createRole}
                    deleteRole={deleteRole}
                  />
                ) : (
                  <CreateNewUserForm
                    onSubmit={createUser}
                    searchUser={searchUser}
                    getRole={getRole}
                  />
                )}
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </>
    );
  };
}

export default compose(CreateUserAPI)(CreateUserFormWrapper);
