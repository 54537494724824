import Moment from "moment";

function view({ userExp = [] }) {
	return (
		<>
			<div className="d-flex" style={{ marginTop: "2.5rem" }}>
				<h5 className="mb-3 e-classified-cont-header">Working Experience</h5>
			</div>
			<div className="e-classified-profile-view-card">
				{userExp.map((expItem) => (
					<>
						<WorkingExpItem item={expItem} />
						<hr />
					</>
				))}
			</div>
		</>
	);
}
const WorkingExpItem = ({ item }) => {
	return (
		<>
			<p className="mb-2 text-black" style={{ fontWeight: 600 }}>
				{item.job_title}
			</p>
			<p className="mb-3 text-black">{item.company_name}</p>
			<div className="e-classified-profile-tag-cont mt-4 mb-4">
				<div className="e -classified-tag-cont-secondary text-black">
					{Moment(item.start_month).format("MM/YYYY")}
					{` - `}
					{item.is_till_present
						? "Present"
						: Moment(item.end_month).format("MM/YYYY")}
				</div>
			</div>
			<p className="text-secondary">Job Specialisation</p>
			<p className="text-black mb-4">{item.specialisation}</p>
			<p className="text-secondary">Industry</p>
			<p className="text-black mb-4">{item.industry}</p>
			<p className="text-secondary">Job Descriptions:</p>
			<p className="text-black mb-4">{item.description}</p>
		</>
	);
};

export default view;
