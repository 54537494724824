import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Card, CardBody, Button, FormGroup, Input } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import Pagination from "components/Pagination";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import FilterForm from "./Form";
import ApplicantRecommendationCandidatePostAPI from "./action.js";

class ApplicantRecommendationCandidatePost extends Component {
	componentDidMount = () => {
		const currentURL = window.location.href;
		const urlParts = currentURL.split("/");
		const id = urlParts[urlParts.length - 1];

		this.props.getSelectedCandidate(id);
	};

	handleCheck = (id) => {
		let temp = [...this.props.checkedJob];

		let tempIndex = _.findIndex(temp, (val) => val === id);

		if (tempIndex > -1) {
			temp.splice(tempIndex, 1);
		} else {
			temp.push(id);
		}
		this.props.onChangeHOC("checkedJob", temp);
	};

	componentDidUpdate = (pp) => {
		if (
			pp.selectedCandidate !== this.props.selectedCandidate &&
			this.props.selectedCandidate
		) {
			let tempSpecs = _.map(this.props.selectedCandidate.specializations, "specialization_id");
			this.props.getPost(this.props.selectedCandidate.id, tempSpecs, this.props.jobQuery);
		}

		if (this.props.selectedCandidate && pp.jobQuery !== this.props.jobQuery) {
			let tempSpecs = _.map(this.props.selectedCandidate.specializations, "specialization_id");
			this.props.getPost(this.props.selectedCandidate.id, tempSpecs, this.props.jobQuery);
		}
		if (
			this.props.selectedCandidate &&
			pp.currentPage !== this.props.currentPage
		) {
			let tempSpecs = _.map(this.props.selectedCandidate.specializations, "specialization_id");
			this.props.getPost(this.props.selectedCandidate.id, tempSpecs, this.props.jobQuery);
		}
		
		if (pp.postPages !== this.props.postPages) {
			let tempSpecs = _.map(this.props.selectedCandidate.specializations, "specialization_id");
			this.props.getPost(this.props.selectedCandidate.id, tempSpecs, this.props.jobQuery);
		}
	};
	View = (val) => {};

	renderJobTable = () => {
		return (
			<>
				<CustomTable
					hidePagination={true}
					data={this.props.postList}
					columnsContent={[
						{
							Header: "Post ID",
							accessorKey: "id",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.id}</>,
						},
						{
							Header: "Job Title",
							accessorKey: "job_title",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.job_title.en}</>,
						},
						{
							Header: "Specialization",
							accessorKey: "specialization",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.specialization?.name?.en}</>,
						},
						{
							Header: "Published Date",
							accessorKey: "published_date",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => (
								<>
									{val.is_published
										? moment(val.published_date).format("DD/MM/YYYY hh:mm A")
										: "Not Published"}
								</>
							),
						},
						{
							Header: "View Count",
							accessorKey: "views",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.views}</>,
						},
						{
							Header: "Job Apply Count",
							accessorKey: "job_application_count",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.job_application_count}</>,
						},
						{
							Header: "Recommended Count",
							accessorKey: "shortlist_count",
							centerColumn: true,
							isStatusButton: false,
							Cell: (val) => <>{val.shortlist_count}</>,
						},
					]}
					actionsContent={[
						{
							content: (val) => (
								<FormGroup check>
									{" "}
									<Input
										type="checkbox"
										checked={
											this.props.checkedJob.findIndex(
												(item) => item === val.id
											) !== -1
										}
									/>
								</FormGroup>
							),
							actionID: "SelectCandidate",
							color: "none",
							tooltipContent: "Select",
							onClick: (val) => {
								this.handleCheck(val.id);
							},
						},
					]}
				/>
				<Pagination
					pageIndex={this.props.postPages} 
					totalCount={this.props.postCount}
					onChangePage={(val) => this.props.onChangeHOC("postPages", val)}
				/>
			</>
		);
	};

	renderFilterModal = () => {
		return (
			<CustomModal
				size={"lg"}
				title="求职者推荐 Filter"
				isOpen={this.props.showFilterModal}
				onClose={() => this.props.onChangeHOC("showFilterModal", false)}>
				<FilterForm onSubmit={this.props.createJob} />
			</CustomModal>
		);
	};

	renderRecommendModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showRecommendModal}
				onClose={() => this.props.onChangeHOC("showRecommendModal", false)}
				onClickConfirm={() => {
					this.props.onChangeHOC("showRecommendModal", false);
				}}
				title={`Are you sure you want to recommend postings to this candidate? `}
				message="Please confirm this action."
				confirmContent="Yes, Recommend"
				confirmColor="success"
			/>
		);
	};

	render = () => {
		const { onChangeHOC } = this.props;
		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="求职者推荐 - For Candidate / <b>Post Listing</b>"
										buttons={[
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<h5>Post Listing</h5>
										<br></br>
										<div
											style={{
												display: "flex",
												width: "100%",
												marginBottom: "2rem",
												justifyContent: "flex-end",
											}}>
											<Button
												color="primary"
												size="lg"
												onClick={() => {
													this.props.checkedJob.map((id) =>
														this.props.recommendCandidate({
															jobad_id: id,
															create_jobseeker_profile_ids: [
																this.props.selectedCandidate.id,
															],
															delete_jobseeker_profile_ids: [],
														})
													);
												}}>
												Recommend to Candidate
											</Button>
										</div>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>{this.renderJobTable()}</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderRecommendModal()}
									{this.props.onLoadJob && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ApplicantRecommendationCandidatePostAPI)(
	ApplicantRecommendationCandidatePost
);
