import React, { Component } from "react";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Card, CardBody } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import _ from "lodash";
import { RolesPermission } from "Config";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import ApplicantRecommendationAdvertiserAPI from "./action.js";
import { CheckUserRolesPermissons } from "actions/roles";

class ApplicantRecommendationAdvertiser extends Component {
	componentDidMount = () => {
		let tempModule = _.find(RolesPermission, {
			moduleID: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles).id,
		});

		if (tempModule && !tempModule.permissions.includes(11)) {
			return this.props.history.push("/");
		}
		this.props.getPost();
	};

	componentDidUpdate = (pp) => {
		const { postPages, getPost } = this.props;
		const { filterQuery } = this.state;

		if (pp.postPages !== postPages) {
			getPost(
				`${postPages > 1 ? `&offset=${(postPages - 1) * 10}` : ""}${
					!!filterQuery ? filterQuery : ""
				}`
			);
		}
	};

	View = (val) => {};

	state = {
		selectedRowData: null,
		filterQuery: "",
	};

	renderSampleTable = () => {
		const { postList, loading } = this.props;

		return (
			<CustomTable
				data={postList}
				showPagination={false}
				loading={loading}
				columnsContent={[
					{
						Header: "Post ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.id}</>,
					},
					{
						Header: "Job Title",
						accessorKey: "job_title",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.job_title.en}</>,
					},
					{
						Header: "Specialization",
						accessorKey: "specialization",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.specialization?.name?.en}</>,
					},
					{
						Header: "Published Date",
						accessorKey: "published_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>
								{val.is_published
									? moment(val.published_date).format("DD/MM/YYYY hh:mm A")
									: "Not Published"}
							</>
						),
					},
					{
						Header: "View Count",
						accessorKey: "views",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.views}</>,
					},
					{
						Header: "Job Apply Count",
						accessorKey: "job_application_count",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.job_application_count}</>,
					},
					{
						Header: "Recommended Count",
						accessorKey: "shortlist_count",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.shortlist_count}</>,
					},
				]}
				menuContent={[
					{
						menuID: "RecommendCandidate",
						menuTooltip: "推荐求职者",
						onClick: (val) => {
							this.props.onChangeHOC("showRecommendModal", true);
							this.props.onChangeHOC("selectedPost", { ...val });
						},
					},
				]}
			/>
		);
	};

	renderFilterModal = () => {
		const { getPost, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="求职者推荐 Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					onSubmit={getPost}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	renderRecommendModal = () => {
		const { showRecommendModal, onChangeHOC, selectedPost } = this.props;
		console.log("hey", this.props.selectedPost);
		return (
			<ConfirmationModal
				open={showRecommendModal}
				onClose={() => {
					onChangeHOC("showRecommendModal", false);
					onChangeHOC("selectedPost", {});
				}}
				onClickConfirm={() => {
					onChangeHOC("showRecommendModal", false);
					this.props.history.push(
						`/applicant-recommendation-advertiser/candidate/${selectedPost?.specialization_id}/${selectedPost?.id}`
					);
				}}
				title={`Are you sure you want to provide recommendation to this posting? Post ID: ${selectedPost?.id}`}
				message="Please confirm this action."
				confirmContent="Yes, Recommend"
				confirmColor="success"
			/>
		);
	};

	render = () => {
		const { postPages, postCount, onChangeHOC } = this.props;
		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="求职者推荐 - For Advertiser"
										buttons={[
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderSampleTable()}
														<Pagination
															pageIndex={postPages}
															totalCount={postCount}
															onChangePage={(val) =>
																onChangeHOC("postPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderRecommendModal()}
									{this.props.onLoadSample && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(ApplicantRecommendationAdvertiserAPI)(
	ApplicantRecommendationAdvertiser
);
