import { BsBookmarkFill } from "react-icons/bs";

import HOC from "./action";

import { useEffect } from "react";


function SavedAdvertisers(props) {

	useEffect(() => {
    props.getSaved(props.jobSeekerProfile.user_id);
  }, [])

	return (
		<>
			<h5 className="mb-4 e-classified-cont-header">
				Saved Company
			</h5>
			<div className="row">
				{props.savedAdvertisers.map((adChild) => (
					<div className="col-lg-6" key={adChild}>
						<AdvetiserCard
							item={adChild}
						/>
					</div>
				))}
			</div>
		</>
	);
}


const AdvetiserCard = ({ item }) => {
	return (
		<>
			<div
				className="d-flex align-items-center p-4"
				style={{ boxShadow: "0px 4px 6px 0px #AFAFAF40" }}>
				<img
					src={ item.logo_url }
					alt="company_img"
					className="mr-3"
					style={{ border: "0.84px solid #D9D9D9", borderRadius: "0.375rem", width: 40 }}
				/>
				<div>
					<h5 style={{ fontWeight: 600, fontSize: "1rem" }} className="mb-2">
						{ item.name }
					</h5>
					<p style={{ color: "#8C8C8C", fontSize: "0.8rem" }}>
						{ item.city }, { item.country }
					</p>
				</div>
				<BsBookmarkFill
					style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
					onClick={() => {}}
					className="text-primary ml-auto"
				/>
			</div>
		</>
	);
};


export default HOC(SavedAdvertisers);
