import BoxContent from "./BoxContent";
import MeLink from "./MeLink";
import "./index.scss";
import _ from "lodash";

import { Button } from "reactstrap";
import { useEffect, useState, useRef } from "react";
import AppliedJobs from "./AppliedJobs";
import Skills from "./Profile/Skills";
import SavedAdverstisers from "./SavedAdverstisers";
import SavedArticles from "./SavedArticles";
import SavedJobs from "./SavedJobs";
import Other from "./Profile/Other";
import MyResume from "./Profile/MyResume";
import ProfileExp from "./Profile/ProfileExp";
import Settings from "./Settings";
import "react-datepicker/dist/react-datepicker.css";
import html2canvas from "html2canvas";

function CandidateProfile(props) {
	const [currentPath, setCurrentPath] = useState("profile-exp");
	const [mode, setMode] = useState("view");
	const [candidateForm, setCandidateForm] = useState(false);
	const [showExportConfirmation, setExportConfirmation] = useState(false);

	const divRef = useRef();

	useEffect(() => {
		showExportConfirmation && downloadImageWithPadding();
	}, [showExportConfirmation]);

	const downloadImageWithPadding = async () => {
		const padding = 20; // Adjust the padding as needed
		const originalElement = divRef.current;
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");

		// Convert the div to a canvas using html2canvas or similar method
		// html2canvas is not a plugin, but a widely-used JS library for this purpose.
		// However, if you prefer not to use any external libraries, this step becomes significantly more complex.
		const divCanvas = await html2canvas(originalElement);

		// Set the canvas size, including padding
		canvas.width = divCanvas.width + padding * 2;
		canvas.height = divCanvas.height + padding * 2;

		// Fill the background if needed
		context.fillStyle = "#fff"; // Or any background color
		context.fillRect(0, 0, canvas.width, canvas.height);

		// Draw the original canvas onto the new canvas with padding
		context.drawImage(divCanvas, padding, padding);

		// Convert the final canvas to a data URL and trigger download
		const image = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		const link = document.createElement("a");
		link.download = "my-image.png";
		link.href = image;
		link.click();
		setExportConfirmation(false);
	};

	const onChangeForm = (key, val) => {
		let temp = _.cloneDeep(candidateForm);
		temp[key] = val;

		setCandidateForm(temp);
	};

	useEffect(() => {
		setMode(props.context);
	}, [props.context]);

	useEffect(() => {
		setCandidateForm(props.jobSeekerProfile);
	}, [props.jobSeekerProfile]);

	return (
		<>
			<div className="row e-classfied-me-menu__root-cont">
				<div className="col-md-4">
					<div className="e-classfied-me__cont">
						<BoxContent
							{...props}
							candidateForm={candidateForm}
							onChangeForm={onChangeForm}
						/>
						<MeLink currentPath={currentPath} setCurrentPath={setCurrentPath} />
					</div>
				</div>
				<div className="col-md-8">
					<div className="d-flex flex-wrap mb-3 mt-3">
						<Button
							color="primary"
							className={"ml-lg-auto mb-2"}
							onClick={() => {
								mode === "edit" ? setMode("view") : setMode("edit");
							}}>
							{mode === "edit" ? "View" : "Edit"}
						</Button>
						<Button
							className={"ml-2 mb-2"}
							onClick={() => {
								props.updateUser({
									status: "inactive",
									id: props.jobSeekerProfile.id,
								});
							}}>
							Inactive Applicant
						</Button>
						<Button
							className={"ml-2 mb-2"}
							onClick={() =>
								(window.location.href = `mailto:${props.jobSeekerProfile.user.email}`)
							}>
							Send Email
						</Button>
						<Button
							className={"ml-2 mb-2"}
							onClick={() => {
								window.open(
									`//api.whatsapp.com/send?phone=${
										props.jobSeekerProfile.user.mobile_number
									}&text=${"Lorem 50"}`,
									"_blank"
								);
							}}>
							Whatsapp
						</Button>
						<Button
							color="primary"
							className={"ml-2 mb-2"}
							onClick={() => {
								setExportConfirmation(true);
							}}>
							Export
						</Button>
					</div>
					<div className="custom-screenshot-cont" ref={divRef}>
						{(currentPath === "profile-exp" || showExportConfirmation) && (
							<ProfileExp
								{...props}
								candidateForm={candidateForm}
								onChangeForm={onChangeForm}
								mode={mode}
							/>
						)}
						{(currentPath === "skill" || showExportConfirmation) && (
							<Skills
								{...props}
								candidateForm={candidateForm}
								onChangeForm={onChangeForm}
								mode={mode}
								setMode={setMode}
							/>
						)}
						{(currentPath === "other" || showExportConfirmation) && (
							<>
								<Other
									{...props}
									candidateForm={candidateForm}
									onChangeForm={onChangeForm}
									mode={mode}
								/>
							</>
						)}
						{currentPath === "my-resume" && (
							<MyResume {...props} candidateForm={candidateForm} mode={mode} />
						)}
						{currentPath === "applied-jobs" && (
							<>
								<AppliedJobs
									{...props}
									candidateForm={candidateForm}
									onChangeForm={onChangeForm}
									mode={mode}
								/>
							</>
						)}
						{currentPath === "saved-jobs" && (
							<SavedJobs
								{...props}
								candidateForm={candidateForm}
								onChangeForm={onChangeForm}
								mode={mode}
							/>
						)}
						{currentPath === "saved-advertisers" && (
							<SavedAdverstisers
								{...props}
								candidateForm={candidateForm}
								onChangeForm={onChangeForm}
								mode={mode}
							/>
						)}
						{currentPath === "saved-articles" && (
							<SavedArticles
								{...props}
								candidateForm={candidateForm}
								onChangeForm={onChangeForm}
								mode={mode}
							/>
						)}
						{currentPath === "settings" && (
							<Settings
								{...props}
								candidateForm={candidateForm}
								onChangeForm={onChangeForm}
								mode={mode}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default CandidateProfile;
