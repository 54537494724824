import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    };

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    getBilling = (id, resolve, reject) =>
      Get(
        `/orders/${id}`,
        (response) => {
          resolve(response.data);
        },
        (error) => {
          requestError(error);
          reject(error);
        },
        this.load
      );

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onChangeHOC={this.onChangeHOC}
          onLoad={this.state.loading}
          getBilling={this.getBilling}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setProfile,
  })(WithHOC);
};
export default HOC;
