import React, { useState, useEffect } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { Collapse, FormGroup, Input, Label } from "reactstrap";
// import { useLocale } from "next-intl";
import _ from 'lodash'
// import Trans from "components/Trans";

function CollapsableCheckbox({ specItem, checkedItems, onCheck }) {
	// const locale = useLocale();
	const locale = 'en';
	const [isExpand, setExpand] = useState(false);
	const [fontSize, setFontSize] = useState('1.5rem');
	const [itemAlignment, setItemAlignment] = useState('center');
	const [checkboxSize, setCheckboxSize] = useState('small');
	const [parentLeftMargin, setParentLeftMargin] = useState('0');
	const [childLeftMargin, setChildLeftMargin] = useState('3');

	useEffect(() => {
		const handleResize = () => {
			const settings = window.innerWidth >= 768
            ? { fontSize: '1rem', checkboxSize: 'unset', parentLeftMargin: '0' , childLeftMargin: '3' }
            : { fontSize: '1.2rem', checkboxSize: 'small', parentLeftMargin: '4' , childLeftMargin: '5' };

        setFontSize(settings.fontSize);
        setItemAlignment(settings.itemAlignment);
        setCheckboxSize(settings.checkboxSize);
		setParentLeftMargin(settings.parentLeftMargin);
		setChildLeftMargin(settings.childLeftMargin);
    	};
		window.addEventListener('resize', handleResize);
	
		handleResize();
	
		return () => window.removeEventListener('resize', handleResize);
	  }, []);

	return (
		<>
			<div
				className="d-flex align-items-baseline justify-content-between mb-4"
			>
				<FormGroup
					className={`d-flex align-items-baseline mt-2 ml-${parentLeftMargin}`}
					key={`${specItem.value}_${specItem.id}`}
					style={{ marginBottom: "-1rem" }}
					check
				>
					<Input
						checked={
							_.findIndex(checkedItems, (item) => item === specItem.id ) > -1
						}
						type={"checkbox"}
						style={{ fontSize: checkboxSize }}
						onChange={() => onCheck(specItem.id, specItem.children ? specItem.children.map(child => child.id) : [])}
					/>
					<Label style={{ fontSize: fontSize, fontWeight: "600" }} check>
						{specItem?.name?.[locale]}
					</Label>
				</FormGroup>
				<AiOutlineDown
					className="mr-2"
					style={{
						width: "1rem",
						height: "1rem",
						cursor: "pointer",
						transform: isExpand ? "rotate(180deg)" : "none",
					}}
					onClick={() => setExpand(!isExpand)}
				/>
			</div>
			{specItem.children && (
				<Collapse isOpen={isExpand}>
					<div className="d-flex flex-column gap-3">
						{specItem.children.map((child, childIndex) => (
							<FormGroup
								className={`d-flex ml-${childLeftMargin} align-items-baseline`}
								key={`${child.value}_${childIndex}`}
								check>
								<Input
									checked={
										_.findIndex(checkedItems, (item) => item === child.id ) > -1
									}
									type={"checkbox"}
									style={{ fontSize: checkboxSize }}
									onChange={() => onCheck(child.id )}
								/>
								<Label style={{ fontSize: fontSize }} check>
									{child?.name?.[locale]}
								</Label>
							</FormGroup>
						))}
					</div>
				</Collapse>
			)}
		</>
	);
}

export default CollapsableCheckbox;
