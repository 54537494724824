// eClassified Job Container
// Job
// Client Side Component: Category Modal

"use client";

import { Fragment, useEffect, useState } from "react";
// import { usePathname } from "next/navigation";
import { useLocation } from "react-router-dom";
// import { useLocale } from "next-intl";
import { AiOutlineSearch } from "react-icons/ai";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import CustomModalHeader from "../CustomModalHeader/CustomModalHeader";
// import Trans from "components/i18next";
// import { transHandler } from "utils/i18next";
import CollapsableCheckbox from "components/CollapsableCheckbox";

import "./index.scss";

import Trans from "components/Trans";

function CategoryModal({
  jobSpecs,
  showFilterByCategoryModal,
  postFilters,
  selectedJobSpecs,
  setSelectedJobSpecs,
  onPressCloseModal,
  setDisplayValue,
  isJobsPage,
}) {
  // const locale = useLocale();
  const locale = "en";
  const { pathname } = useLocation();
  // General States
  const [tmpCategorySearchKeyword, setTmpCategorySearchKeyword] = useState("");
  const sanitizeInput = (input) => {
    // If input is already an array, return it directly
    if (Array.isArray(input)) {
      return input;
    }
    // If input is undefined or null, return an empty array
    if (input === undefined || input === null) {
      return [];
    }
    // If input is a single object or any other non-array value, return it as a single element array
    return [input];
  };
  const [tmpSelectedPositionCategories, setTmpSelectedPositionCategories] =
    useState(sanitizeInput(selectedJobSpecs));
  const [tmpSelectedName, setTmpSelectedName] = useState("");
  const [displayOptions, setDisplayOptions] = useState(jobSpecs);
  const [selectedName, setSelectedName] = useState("");
  // Component-specific Functions
  const filterOptions = () => {
    let searchKeyword = tmpCategorySearchKeyword.toLowerCase();
    let tmp =
      locale === "en"
        ? jobSpecs.filter((item) =>
            item.name.toLowerCase().includes(searchKeyword)
          )
        : jobSpecs.filter((item) =>
            item.name_zh.toLowerCase().includes(searchKeyword)
          );
    setDisplayOptions(tmp);
  };
  // Component Did Update
  useEffect(() => {
    if (showFilterByCategoryModal) {
      setDisplayOptions(jobSpecs);
      setTmpSelectedPositionCategories(selectedJobSpecs);
    }
  }, [showFilterByCategoryModal]);
  useEffect(() => {
    if (tmpCategorySearchKeyword === "") {
      setDisplayOptions(jobSpecs);
    } else {
      filterOptions();
    }
  }, [tmpCategorySearchKeyword]);

  return (
    <Modal
      centered={true}
      isOpen={showFilterByCategoryModal}
      size="lg"
      style={{ position: "relative", boxShadow: "none" }}
    >
      <CustomModalHeader
        title={<Trans langKey={"JOB_POSITION_LIST"} />}
        onPressCloseModal={() => {
          onPressCloseModal();
          setTmpSelectedPositionCategories([]);
        }}
      />
      <ModalBody>
        {isJobsPage && (
          <div className="e-classified-job__filter-modal-body-container">
            <InputGroup className="e-classified-job__filter-modal-input-container">
              <InputGroupText className="e-classified-job__filter-modal-input icon">
                <AiOutlineSearch className="text-primary" size={14} />
              </InputGroupText>
              <Input
                className="e-classified-job__filter-modal-input input"
                value={tmpCategorySearchKeyword}
                onChange={(e) => setTmpCategorySearchKeyword(e.target.value)}
                placeholder={<Trans langKey={"SEARCH_SPECIALIZATION"} />}
              />
            </InputGroup>
          </div>
        )}
        <div className="e-classified-job__filter-modal-body-external-container">
          <div className="e-classified-job__filter-modal-body-container">
            {jobSpecs && jobSpecs.length > 0 ? (
              jobSpecs.map((item, index) => (
                <Fragment key={index}>
                  <CollapsableCheckbox
                    specItem={item}
                    checkedItems={tmpSelectedPositionCategories}
                    onCheck={(id) => {
                      if (isJobsPage) {
                        let newSelectedCategories = [
                          ...(tmpSelectedPositionCategories || []),
                        ];

                        // Check if the id is already selected
                        const isSelected = newSelectedCategories.includes(id);

                        if (isSelected) {
                          // If selected, remove it from the array
                          newSelectedCategories = newSelectedCategories.filter(
                            (item) => item !== id
                          );
                        } else {
                          // If not selected, add it to the array
                          newSelectedCategories.push(id);
                        }

                        // Update the state with the new array
                        setTmpSelectedPositionCategories(newSelectedCategories);
                      } else {
                        let newSelectedCategory = [];
                        // Check if the newSelectedCategory array is not empty
                        if (newSelectedCategory.length > 0) {
                          // If the first item matches the id, clear the array and reset the temporary selected name
                          if (newSelectedCategory[0] === id) {
                            newSelectedCategory = [];
                            setTmpSelectedName("");
                          } else {
                            // Otherwise, remove the first item and add the new id
                            newSelectedCategory.shift();
                            newSelectedCategory.push(id);
                            const child = item.children?.find(
                              (child) => child.id === id
                            );
                            if (child) {
                              // If a matching child is found, use the child's name
                              setTmpSelectedName(child.name[locale]);
                            } else {
                              // Fallback to the item's name if no matching child is found
                              setTmpSelectedName(item.name[locale]);
                            }
                          }
                        } else {
                          // If the array is empty, simply add the new id
                          newSelectedCategory.push(id);
                          const child = item.children?.find(
                            (child) => child.id === id
                          );
                          if (child) {
                            setTmpSelectedName(child.name[locale]);
                          } else {
                            setTmpSelectedName(item.name[locale]);
                          }
                        }

                        // Update the position categories with the potentially modified array
                        setTmpSelectedPositionCategories(newSelectedCategory);
                      }
                    }}
                  />
                  <hr />
                </Fragment>
              ))
            ) : (
              <div className="e-classified-job__filter-modal-no-results-container">
                <h5>
                  <Trans langKey={"FILTER_NO_RESULTS_FOUND"} />
                </h5>
                <p>
                  <Trans langKey={"FILTER_NO_RESULTS_DESC"} />
                </p>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {isJobsPage && (
          <Button
            color={"transparent"}
            className="e-classified-job__filter-modal-action-button border-primary text-primary"
            onClick={() => setTmpSelectedPositionCategories([])}
          >
            <Trans langKey={"JOB_CLEAR_ALL"} />
          </Button>
        )}
        <Button
          color={"primary"}
          className="e-classified-job__filter-modal-action-button"
          onClick={() => {
            if (tmpSelectedPositionCategories.length === 0) {
              let tmp = tmpSelectedPositionCategories;
              jobSpecs.map((item) => tmp.push(item.name));
              setTmpSelectedPositionCategories(tmp);
            } else {
              let tmp = tmpSelectedPositionCategories;
              onPressCloseModal();
              if (isJobsPage) {
                setSelectedJobSpecs(tmp);
              } else if (pathname.includes("/me/profile/profile-exp")) {
                setSelectedJobSpecs(tmp[0]);
                setDisplayValue(tmpSelectedName);
              } else {
                setSelectedJobSpecs(tmp[0]);
                setDisplayValue(tmpSelectedName);
              }
              setTmpSelectedPositionCategories([]);
            }
          }}
        >
          <Trans langKey={isJobsPage ? "FILTER_SELECT" : "SELECT"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CategoryModal;
