import React from "react";
import { Label } from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import CustomModal from "components/Modal";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";

const Confirmation = ({
  open,
  title,
  loading,
  message,
  onClose,
  onClickConfirm,
  confirmContent = "",
  confirmColor,
  isCancelable = true,
}) => {
  return (
    <CustomModal isOpen={open} onClose={() => onClose()} title={title}>
      <CustomModalBody>
        <Label style={{'white-space': 'pre-wrap'}}>{message}</Label>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={isCancelable 
          ? [
          {
            color: "primary",
            outline: true,
            content: "Cancel",
            onClick: () => onClose(),
          },
          {
            color: confirmColor,
            content: confirmContent,
            onClick: () => onClickConfirm(),
          },
        ]
        : [
          {
            color: confirmColor,
            content: confirmContent,
            onClick: () => onClickConfirm(),
          },
        ]}
      />
      {loading && <LoadingOverlay />}
    </CustomModal>
  );
};

export default Confirmation;
