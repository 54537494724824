import React, { Component } from "react";
import { connect } from "react-redux";
import { Get, Put, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			candidateList: [],
			candidateListPages: 1,
			candidateListCount: 0,
			showFilterModal: false,
			showViewModal: false,
			showEditModal: false,
			showInactiveModal: false,
			selectedCandidateName: "",
			selectedCandiate: null,
			jobSeekerSpec: [],
			stateList: [],
		};

		onChangeHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getCandidateList = (querystring = "") =>
			Get(
				"/jobseeker-profiles?limit=10" + querystring,
				this.getCandidateListSuccess,
				this.getCandidateListError,
				this.load
			);
		getCandidateListSuccess = (payload) => {
			console.log(payload.data)
			this.setState({
				candidateList: payload.data,
				candidateListCount: payload.count,
			});
		};
		getCandidateListError = (error) => requestError(error);

		getStates = (id) => {
      Get(
        `/countries/${id}/states`,
        this.getStatesSuccess,
        error => requestError(error),
        this.load
      )
    }
    getStatesSuccess = response => {
      if (response?.data.length > 0) {
        let tempData = response.data.map(state => {
          return {
            label: state.name,
            value: state.id
          }
        })
				const sortedData = tempData.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ stateList: sortedData })
      }
    }

		updateUser = (dataToSubmit) => {
			Put(
				`/users/${dataToSubmit.id}`,
				dataToSubmit,
				this.updateUserSuccess,
				this.updateUserError,
				this.load
			);
		};
		updateUserSuccess = () => {
			this.getCandidateList();
			this.setState({
				showInactiveModal: false,
			});
			requestSuccess("Candidate updated successfully.");
		};
		updateUserError = (error) => requestError(error);

		getSelectedJobSeekerProfile = (userID, mode) => {
			Get(
				`/jobseeker-profiles?user_id=${userID}`,
				(payload) => {
					let tempSpecs = (payload?.data?.[0]?.specializations || []).map(
						(specChild) => ({
							...specChild,
							id: specChild.specialization_id,
							jobSeekerSpecID: specChild.id,
						})
					);

					this.setState({
						jobSeekerSpec: tempSpecs || [],
						selectedCandiate: payload?.data?.[0],
						...(mode === "view"
							? {
									showViewModal: true,
							  }
							: { showEditModal: true }),
					});
				},
				(error) => requestError(error.message),
				this.load
			);
		};

		updateJobSeekerProfile = (dataToSubmit) => {
			Put(
				`/jobseeker-profiles/${dataToSubmit.id}`,
				{
					...dataToSubmit,
					current_salary: parseFloat(dataToSubmit.current_salary),
					expected_salary: parseFloat(dataToSubmit.expected_salary),
				},
				(payload) => {
					this.getSelectedJobSeekerProfile(payload.data.user_id);
					requestSuccess("Record updated successfully.");
				},
				(error) => requestError(error.message),
				this.load,
				true
			);
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onChangeHOC={this.onChangeHOC}
					getCandidateList={this.getCandidateList}
					updateUser={this.updateUser}
					getSelectedJobSeekerProfile={this.getSelectedJobSeekerProfile}
					updateJobSeekerProfile={this.updateJobSeekerProfile}
					getStates={this.getStates}
          stateList={this.state.stateList}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
