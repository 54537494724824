import NoDataImg from "assets/images/no-file.jpg";
import { BiZoomIn } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { UncontrolledTooltip } from "reactstrap";

import { AiFillFileImage } from "react-icons/ai";

import "./index.scss";

function DriveTable({
	mode,
	assets,

	selectedAsset,
	onClickElement,

	onClickView,
	onClickEdit,
	onClickDelete,

	onSelect,
}) {
	return (
		<div className="mywheels-drive_cont">
			<h5 className="mywheels-drive-title">Files</h5>
			{assets.length < 1 && (
				<div className={"text-center"} style={{ width: "max-content" }}>
					<img src={NoDataImg} style={{ width: 200 }} />
					<small className="d-block">No Data Available</small>
				</div>
			)}
			{assets?.[0] && (
				<div className="mywheels-drive-files_cont">
					{assets.map((file) => (
						<div
							key={`file${file.id}`}
							className="mywheels-drive_files-item"
							style={{
								background: selectedAsset === file.id ? "#DCDCDC" : "unset",
							}}
							onClick={() => onClickElement(file.id)}
							onDoubleClick={() => onClickView(file)}>
							<div className="mywheels-drive_files-item-content_cont">
								<img
									style={{ objectFit: "cover" }}
									src={file.url}
									alt={`${file.alt}`}
								/>
								<div
									className="mywheels-drive_files_actions-cont"
									style={{
										...(mode === "view" && { transform: "translateX( 0% )" }),
									}}>
									{mode === "view" && (
										<div
											className="mywheels-drive_files_action mywheels-drive_files_action-text bg-primary"
											onClick={() => onSelect(file.url)}>
											<span className="text-white">Select</span>
										</div>
									)}
									{mode === "edit" && (
										<>
											<div
												className="mywheels-drive_files_action mywheels-drive_files_action-icon bg-danger"
												id="delete"
												onClick={() => onClickDelete(file)}>
												<FaTrash />
												<UncontrolledTooltip target="delete">
													Delete
												</UncontrolledTooltip>
											</div>
											<div
												className="mywheels-drive_files_action mywheels-drive_files_action-icon bg-primary"
												id="edit"
												onClick={() => onClickEdit(file)}>
												<MdModeEditOutline />
												<UncontrolledTooltip target="edit">
													Edit
												</UncontrolledTooltip>
											</div>
										</>
									)}
									<div
										className="mywheels-drive_files_action bg-warning mywheels  mywheels-drive_files_action-icon-hover"
										onClick={() => onClickView(file)}>
										<BiZoomIn />
									</div>
								</div>
							</div>
							<div className="mywheels-drive-details">
								<AiFillFileImage className="text-danger" />
								<span>{file.title}</span>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}
export default DriveTable;
